import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './minProgramTemplate';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 小程序开发-基础模板管理
 */

// Epics
// 分页查询小程序模版接口
export const minProgramTemplateEpic = actions$ => actions$.pipe(
    ofType(actions.minProgramTemplate.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { name = '', page, size } = action.payload;
        let url = `${urls.wechat.minTemplate.minTemplate}/view?page=${page}&size=${size}&sort=id,desc`;
        if (name) {
            url += `&name=${name}`;
        }
        return ajax({
            ...apiBaseConfig,
            url,
        }).pipe(
            map(res => actions.minProgramTemplate.SUCCESS(res.response)),
            catchError(error => of(actions.minProgramTemplate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 添加/修改 小程序模版接口
export const minProgramTemplateModifyEpic = actions$ => actions$.pipe(
    ofType(actions.minProgramTemplateModify.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { id } = action.payload;
        let url = `${urls.wechat.minTemplate.minTemplate}/`;
        if (id) {
            url += `edit`;
        } else {
            url += `add`;
        }
        return ajax({
            ...ajaxBaseConfig,
            url,
            body: action.payload,
            method: id ? 'PUT' : 'POST'
        }).pipe(
            mergeMap(res => [actions.minProgramTemplateModify.SUCCESS(res.status), uiActions.notification(action.payload.id ? '修改成功' : '创建成功', 'success')]),
            catchError(error => of(actions.minProgramTemplateModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 获取模板列表
export const minProgramTemplateCodePackageEpic = actions$ => actions$.pipe(
    ofType(actions.minProgramTemplateCodePackage.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { appId, code } = action.payload;
        return ajax({
            ...apiBaseConfig,
            url: `${urls.wechat.minTemplate.minTemplate}/code-package?appId=${appId}&code=${code}`,
        }).pipe(
            map(res => actions.minProgramTemplateCodePackage.SUCCESS(res.response)),
            catchError(error => of(actions.minProgramTemplateCodePackage.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 指定代码包
export const minProgramTemplateAppointEpic = actions$ => actions$.pipe(
    ofType(actions.minProgramTemplateAppoint.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.wechat.minTemplate.minTemplate}/appoint`,
            body: action.payload,
            method: 'PUT',
        }).pipe(
            mergeMap(res => [actions.minProgramTemplateAppoint.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
            catchError(error => of(actions.minProgramTemplateAppoint.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);


// 查询模板已经分配的企业
export const minProgramTemplateAllocatedEpic = actions$ => actions$.pipe(
    ofType(actions.minProgramTemplateAllocated.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { id } = action.payload;
        return ajax({
            ...apiBaseConfig,
            url: `${urls.wechat.minTemplate.minTemplate}/allocated?id=${id}`,
        }).pipe(
            map(res => actions.minProgramTemplateAllocated.SUCCESS(res.response)),
            catchError(error => of(actions.minProgramTemplateAllocated.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 指定代码包
export const minProgramTemplateDistributionEpic = actions$ => actions$.pipe(
    ofType(actions.minProgramTemplateDistribution.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.wechat.minTemplate.minTemplate}/distribution`,
            body: action.payload,
            method: 'POST'
        }).pipe(
            mergeMap(res => [actions.minProgramTemplateDistribution.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
            catchError(error => of(actions.minProgramTemplateDistribution.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);


/**
 * 企业模板
 * @param {*} actions$ 
 */

// 企业模板查询（分页查询）
export const minTemplateEseEpic = actions$ => actions$.pipe(
    ofType(actions.minTemplateEse.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { keywords = '', appId, page, size } = action.payload;
        let url = `${urls.wechat.minTemplate.minTemplateEse}?appId=${appId}&page=${page}&size=${size}&sort=id,desc`;
        if (keywords) {
            url += `&keywords=${keywords}`;
        }
        return ajax({
            ...apiBaseConfig,
            url,
        }).pipe(
            map(res => actions.minTemplateEse.SUCCESS(res.response)),
            catchError(error => of(actions.minTemplateEse.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);


// 企业模板体验
export const minTemplateEseTrialEpic = actions$ => actions$.pipe(
    ofType(actions.minTemplateEseTrial.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { id } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.wechat.minTemplate.minTemplateEse}/${id}/trial`,
            body: action.payload,
            method: 'PATCH'
        }).pipe(
            mergeMap(res => [actions.minTemplateEseTrial.SUCCESS(res.status), uiActions.notification('体验版发布成功', 'success')]),
            catchError(error => of(actions.minTemplateEseTrial.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

// 企业模板发布
export const minTemplateEsePublishEpic = actions$ => actions$.pipe(
    ofType(actions.minTemplateEsePublish.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { id } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.wechat.minTemplate.minTemplateEse}/${id}/publish`,
            body: action.payload,
            method: 'PATCH'
        }).pipe(
            mergeMap(res => [actions.minTemplateEsePublish.SUCCESS(res.status), uiActions.notification('正式版发布成功', 'success')]),
            catchError(error => of(actions.minTemplateEsePublish.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

// 企业模板删除
export const minTemplateEseDeleteEpic = actions$ => actions$.pipe(
    ofType(actions.minTemplateEseDelete.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { id } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.wechat.minTemplate.minTemplateEse}/${id}`,
            body: action.payload,
            method: 'DELETE'
        }).pipe(
            mergeMap(res => [actions.minTemplateEseDelete.SUCCESS(res.status), uiActions.notification('删除成功', 'success')]),
            catchError(error => of(actions.minTemplateEseDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

// 企业模板撤销
export const minTemplateEseCancelEpic = actions$ => actions$.pipe(
    ofType(actions.minTemplateEseCancel.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { id } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.wechat.minTemplate.minTemplateEse}/${id}/undocode`,
            body: action.payload,
            method: 'PATCH'
        }).pipe(
            mergeMap(res => [actions.minTemplateEseCancel.SUCCESS(res.status), uiActions.notification('撤销成功', 'success')]),
            catchError(error => of(actions.minTemplateEseCancel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

// 根据id查询企业的模板
export const minTemplateEseByIdEpic = actions$ => actions$.pipe(
    ofType(actions.minTemplateEseById.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { id } = action.payload;
        return ajax({
            ...apiBaseConfig,
            url: `${urls.wechat.minTemplate.minTemplateEse}?id=${id}`,
        }).pipe(
            map(res => actions.minTemplateEseById.SUCCESS(res.response)),
            catchError(error => of(actions.minTemplateEseById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 查询已分配给企业的模板
export const minTemplateEseDistributionEpic = actions$ => actions$.pipe(
    ofType(actions.minTemplateEseDistribution.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        return ajax({
            ...apiBaseConfig,
            url: `${urls.wechat.minTemplate.minTemplateEse}/distribution`,
        }).pipe(
            map(res => actions.minTemplateEseDistribution.SUCCESS(res.response)),
            catchError(error => of(actions.minTemplateEseDistribution.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 添加/修改 企业模板
export const minTemplateEseModifyEpic = actions$ => actions$.pipe(
    ofType(actions.minTemplateEseModify.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { id } = action.payload;
        let url = `${urls.wechat.minTemplate.minTemplateEse}`;
        if (id) {
            url += '/edit';
        }
        return ajax({
            ...ajaxBaseConfig,
            url,
            body: action.payload,
            method: id ? 'PUT' : 'POST'
        }).pipe(
            map(res => actions.minTemplateEseModify.SUCCESS(res.response)),
            catchError(error => of(actions.minTemplateEseModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 添加/修改 企业模板json文件
export const minTemplateEseEditJsonEpic = actions$ => actions$.pipe(
    ofType(actions.minTemplateEseEditJson.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.wechat.minTemplate.minTemplateEse}`,
            body: action.payload,
            method: 'PUT'
        }).pipe(
            map(res => actions.minTemplateEseEditJson.SUCCESS(res.status)),
            catchError(error => of(actions.minTemplateEseEditJson.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 获取模板json文件
export const getSaveDataEpic = actions$ => actions$.pipe(
    ofType(actions.getSaveData.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        return ajax({
            // ...apiBaseConfig,
            url: action.payload + `?v=${+new Date()}`,
            // body: action.payload,
            // method: 'PUT'
        }).pipe(
            map(res => actions.getSaveData.SUCCESS(res.response)),
            catchError(error => of(actions.getSaveData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

