
import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions, setScrollTop } from '../../utils';

const moduleName = 'liveManageMent';
/**
 * TODO:页面
 */
export const loadList = fasterActions(moduleName, 'loadList');

// 页面列表
export const loadLiveManageList = fasterActions(moduleName, 'loadLiveManageList');

// 启用/禁用
export const modifyLiveManageList = fasterActions(moduleName, 'modifyLiveManageList');


export const liveCopy = fasterActions(moduleName, 'liveCopy');
// 删除
export const liveDelete = fasterActions(moduleName, 'liveDelete');
// 创建
export const liveCreate = fasterActions(moduleName, 'liveCreate');
// 修改
export const liveEdit = fasterActions(moduleName, 'liveEdit');
// 搜索关键字
export const changeKeyWords = createAction(`${moduleName}/changeKeyWords`);
export const uploadFactorySearchKey = createAction(`${moduleName}/uploadFactorySearchKey`);

// 登记信息弹窗
export const registerDialogVisible = createAction(`${moduleName}/registerDialogVisible`);
// 模板列表
export const loadtemplatesSelect = fasterActions(moduleName, 'loadtemplatesSelect');
// 模板Id查询
export const loadtemplatesId = fasterActions(moduleName, 'loadtemplatesId');

// 新增第一步

export const liveCreateobj = createAction(`${moduleName}/liveCreateobj`);
// 未发发布列表

export const LoadUnpublishedList = fasterActions(moduleName, 'LoadUnpublishedList');
// 发布新增列表

export const loadLiveTemList = fasterActions(moduleName, 'loadLiveTemList');
// 编辑
export const loadLiveTemListEdit = fasterActions(moduleName, 'loadLiveTemListEdit');
// 查看id
export const loadLiveTemListId = fasterActions(moduleName, 'loadLiveTemListId');
// 版本号发布

export const loadLiveTemFabu = fasterActions(moduleName, 'loadLiveTemFabu');
// 替换元素
export const loadLiveTemTihuan = fasterActions(moduleName, 'loadLiveTemTihuan');
// 替换元素查询Id
// export const loadLiveTemListId = fasterActions(moduleName, 'loadLiveTemListId');
// 使用情况
export const loadUsedList = fasterActions(moduleName, 'loadUsedList');
// 历史
export const loadHistoriesList = fasterActions(moduleName, 'loadHistoriesList');
// 历史信息详情列表
export const loadParticularsList = fasterActions(moduleName, 'loadParticularsList');

export const loadunpublishedListParam = createAction(`${moduleName}/unpublishedListParam`);


// 模板列表
export const loadLiveSelect = fasterActions(moduleName, 'loadLiveSelect');
export const loadLiveSelectAgain = fasterActions(moduleName, 'loadLiveSelectAgain');
// 查模板元素
export const loadTemFind = fasterActions(moduleName, 'loadTemFind');
// 复制小程序appid
export const loadTemApp = fasterActions(moduleName, 'loadTemApp');
// 设置为最新
export const loadSetLast = fasterActions(moduleName, 'loadSetLast');
// 还原默认
export const loadPageStyle = fasterActions(moduleName, 'loadPageStyle');



// export const loadLiveSelect = fasterActions(moduleName, 'loadLiveSelect');
//清空数据
export const unmount = fasterActions(moduleName, 'unmount');
const initialState = {
  listLoaded: false,
  promotionsLoading: false,
  prizeLoading: false,
  promotionsLoaded: false,
  liveCreate: {},
  unpublishedList: [],

  paging: {
    page: 0,
    size: 20,
    type: [],
    keyWords: '',
    sort: 'id,desc',
    totalElements: 0
  },
  // 历史详情列表
  particularsListParam: {
    page: 0,
    size: 20,

  },
  unpublishedListParam: {
    page: 0,
    size: 20,
    type: [],
    keyWords: '',
    totalElements: 0,
    sort: 'id,desc'
  },
  historiesParam: {
    page: 0,
    size: 20,
    keyWords: '',
    pageManageId: '',
  },
  usedList: { length: 0, content: [] },
  // totalElements: 0,
  liveManageList: [],
  content: [],
  isDisabledRegisterInfo: true, // 是否禁用登记信息
  registerDialogShow: false,
  loadLiveTemListId: []
}


// Reducer
const reducer = handleActions(
  {

    /**
     * TODO:奖品 
     */
    // 奖品列表
    [loadList.SUCCESS]: (state, { payload }) => {
      let selector = [{ value: '', text: '请选择卡片奖品' }];
      for (let elem of payload.content.values()) {
        selector.push({ value: elem.id, text: elem.name })
      }
      return {
        ...state,
        selector,
        list: payload.content,
        listLoaded: true,
      }
    },


    [loadUsedList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [loadUsedList.SUCCESS]: (state, { payload }) => ({ ...state, usedList: payload, loading: false }),
    [loadUsedList.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [loadSetLast.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [loadSetLast.SUCCESS]: (state, { payload }) => ({ ...state, loading: false }),
    [loadSetLast.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),


    [loadPageStyle.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [loadPageStyle.SUCCESS]: (state, { payload }) => ({ ...state, loading: false }),
    [loadPageStyle.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),



    [liveCreateobj]: (state, { payload }) => ({ ...state, liveCreate: payload }),

    [LoadUnpublishedList.REQUEST]: (state, { payload }) => {
      return ({ ...state, ...payload, loading: true })
    },
    [LoadUnpublishedList.SUCCESS]: (state, { payload }) => {
      return ({ ...state, unpublishedList: payload, loading: false })
    },
    [LoadUnpublishedList.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),


    [loadHistoriesList.REQUEST]: (state, { payload }) => {
      return ({ ...state, ...payload, loading: true })
    },
    [loadHistoriesList.SUCCESS]: (state, { payload }) => {
      return ({ ...state, historiesList: payload, loading: false, })
    },
    [loadHistoriesList.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),



    [loadLiveManageList.REQUEST]: (state, { payload }) => {
      setScrollTop();
      return ({ ...state, ...payload, loading: true })
    },
    [loadLiveManageList.SUCCESS]: (state, { payload }) => {

      return ({ ...state, liveManageList: payload, loading: false })
    },
    [loadLiveManageList.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),



    [modifyLiveManageList.REQUEST]: (state, { payload }) => ({ ...state, payload, modifyLiveManageListLoad: false }),
    [modifyLiveManageList.SUCCESS]: (state, { payload }) => ({ ...state, modifyLiveManageListLoad: true }),
    [modifyLiveManageList.FAIL]: (state, { payload }) => ({ ...state, modifyLiveManageListLoad: false }),

    // 历史详情列表


    [loadParticularsList.REQUEST]: (state, { payload }) => {
      setScrollTop();
      return ({ ...state, prizeLoading: true, ...payload })
    },
    [loadParticularsList.SUCCESS]: (state, { payload }) => {
      const { pageable = {}, totalElements = 0 } = payload;

      return ({ ...state, particularsListParam: { ...state.particularsListParam, totalElements: totalElements || 0, page: pageable.pageNumber, size: pageable.pageSize }, particularsList: payload, registerDialogShow: false, })
    },
    [loadParticularsList.FAIL]: (state, { payload }) => ({ ...state, prizeLoading: false, promotionsLoaded: false, error: payload }),



    // 奖品复制
    [liveCopy.REQUEST]: (state, { payload }) => ({ ...state }),
    [liveCopy.SUCCESS]: (state, { payload }) => ({ ...state }),
    [liveCopy.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    // 发布
    [loadLiveTemFabu.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadLiveTemFabu.SUCCESS]: (state, { payload }) => ({ ...state }),
    [loadLiveTemFabu.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 替换
    [loadLiveTemTihuan.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadLiveTemTihuan.SUCCESS]: (state, { payload }) => ({ ...state }),
    [loadLiveTemTihuan.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



    [loadLiveTemList.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [loadLiveTemList.SUCCESS]: (state, { payload }) => {
      return ({ ...state, liveTemList: payload, loading: false })
    },
    [loadLiveTemList.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    // 删除
    [liveDelete.REQUEST]: (state, { payload }) => ({ ...state, payload, liveDeleteLoad: false }),
    [liveDelete.SUCCESS]: (state, { payload }) => ({ ...state, liveDeleteLoad: true }),
    [liveDelete.FAIL]: (state, { payload }) => ({ ...state, liveDeleteLoad: false }),


    [loadLiveTemListId.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadLiveTemListId.SUCCESS]: (state, { payload }) => ({ ...state, loadLiveTemListId: payload }),
    [loadLiveTemListId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 根据模板chaID
    [loadTemApp.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadTemApp.SUCCESS]: (state, { payload }) => {
      let liveSelect = []
      console.log(payload)
      // console.log(payload.content)
      if (payload) {

        liveSelect = payload.map(val => {
          return {
            text: val.name, value: val.appId, authType: val.authType
          }
        })
      }

      return ({ ...state, AppIdArr: liveSelect })
    },



    [loadTemApp.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 模板id
    [loadtemplatesId.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadtemplatesId.SUCCESS]: (state, { payload }) => ({ ...state, loadtemplates: payload }),
    [loadtemplatesId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    // 奖品详情
    // [prizeDetail.REQUEST]: (state, { payload }) => ({ ...state}),
    // [prizeDetail.SUCCESS]: (state, { payload }) => ({ ...state, prizeDetail: payload }),
    // [prizeDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 模板下拉
    [loadtemplatesSelect.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadtemplatesSelect.SUCCESS]: (state, { payload }) => {
      let templatesSelect
      // console.log(payload.content)
      if (payload.content) {

        templatesSelect = payload.content.map(val => {
          return {
            text: val.name, value: val.id, flowChart: val.flowChart, awardNumber: val.awardNumber, pageTemplateType: val.pageTemplateType, code: val.code,
          }
        })
      }

      return ({ ...state, templatesSelect: templatesSelect })
    },
    [loadtemplatesSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    // 奖品创建
    [liveCreate.REQUEST]: (state, { payload }) => ({ ...state }),
    [liveCreate.SUCCESS]: (state, { payload }) => ({ ...state }),
    [liveCreate.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 奖品编辑
    [liveEdit.REQUEST]: (state, { payload }) => ({ ...state }),
    [liveEdit.SUCCESS]: (state, { payload }) => ({ ...state }),
    [liveEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [uploadFactorySearchKey]: (state, { payload }) => ({ ...state, unpublishedListParam: { ...state.unpublishedListParam, keyWords: payload, page: 0 } }),
    // 搜索关键字
    [changeKeyWords]: (state, { payload }) => ({ ...state, paging: { ...state.paging, keyWords: payload, page: 0 } }),
    [loadunpublishedListParam]: (state, { payload }) => ({ ...state, loadLiveTemListId: {} }),

    // 登记信息Dialog
    [registerDialogVisible]: (state, { payload }) => {
      return ({ ...state, ...payload })
    },


    // 模板下拉
    [loadLiveSelect.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadLiveSelect.SUCCESS]: (state, { payload }) => {
      let liveSelect = []
      // console.log(payload.content)
      if (payload.content) {

        liveSelect = payload.content.map(val => {
          return {
            text: val.name, value: val.id, flowChart: val.flowChart, historyId: val.historyId,
          }
        })
      }

      return ({ ...state, liveSelect: liveSelect })
    },
    [loadLiveSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    // 模板下拉2
    [loadLiveSelectAgain.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadLiveSelectAgain.SUCCESS]: (state, { payload }) => {
      let liveSelect2 = []
      // console.log(payload.content)
      if (payload.content) {

        liveSelect2 = payload.content.map(val => {
          return {
            text: val.name, value: val.id, flowChart: val.flowChart, historyId: val.historyId,
          }
        })
      }

      return ({ ...state, liveSelect2: liveSelect2 })
    },
    [loadLiveSelectAgain.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [loadTemFind.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadTemFind.SUCCESS]: (state, { payload }) => ({ ...state, loadTemFindObj: payload }),
    [loadTemFind.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [unmount.REQUEST]: (state, { payload }) => ({
      templatesSelect: [],
      liveSelect: [],
    }),

  },
  initialState
)
export default reducer;