import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import * as actions from './codeQueryTool';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 字段分配
 */

// 列表
export const getCodeInfoEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getCodeInfo.REQUEST.toString()),
  mergeMap(action => {
    actionApi();

    const { enterprisesSelectionsId = '' } = state$.value.queryTools;
    const { query = '', type = {}, callback } = action.payload;

    let escapeQuery = query;
    let typeValue = type.value || [];
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.codeQueryTool.search}?type=${typeValue}&eseId=${enterprisesSelectionsId}&query=${escapeQuery}`
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.getCodeInfo.SUCCESS(res.response)]
      }),
      catchError(error => {
        return of(actions.getCodeInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      })
    )
  }
  )
);

// 扫码信息
export const getScanRecordEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getScanRecord.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    let { page = 0, size = 20, ip = '', openId = '', callback, codeUri = '', seqNum = '', unionId = '', searchValue = '', scanType = {} } = action.payload;
    if (scanType.value === 'openId') {
      openId = searchValue;
    } else if (scanType.value === 'unionId') {
      unionId = searchValue;
    } else if (scanType.value === 'ip') {
      ip = searchValue;
    }

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.codeQueryTool.queryRecords}?page=${page}&size=${size}&codeUri=${codeUri}&seqNum=${seqNum}&ip=${ip}&openId=${openId}&unionId=${unionId}`
    }).pipe(
      map(res => {
        callback && callback(res);
        return actions.getScanRecord.SUCCESS(res.response);
      }),
      catchError(error => of(actions.getScanRecord.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
)

// addPinCode
export const addPinCodeEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.addPinCode.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { seqNum = '', eseSeqnum = '', codeUri = '', callback } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.codeQueryTool.addpincode}`,
      body: {
        seqNum, eseSeqNum: eseSeqnum, codeUri
      },
      method: 'POST'
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.addPinCode.SUCCESS(res.response);
      }),
      catchError(error => of(actions.addPinCode.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
)

// addPinCode
export const labelCycleEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.labelCycle.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { seqNum = '', groupCode = '', codeUri = '', callback } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.codeQueryTool.labelCycle}`,
      body: {
        seqNum, groupCode, codeUri
      },
      method: 'POST'
    }).pipe(
      map(res => {
        callback && callback(res.response.content);
        return actions.labelCycle.SUCCESS(res.response);
      }),
      catchError(error => of(actions.labelCycle.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
)

export const getIpEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getIp.REQUEST.toString()),
  mergeMap(action => {
    actionApi()

    const { ip, callback } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.codeQueryTool.ipLocation}?ip=${ip}`
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.getIp.SUCCESS(res.response);
      }),
      catchError(error => of(actions.getIp.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
)

export const getGpsEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getGps.REQUEST.toString()),
  mergeMap(action => {
    actionApi()

    const { longitude, latitude, callback } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.codeQueryTool.coordinate}?latitude=${latitude}&longitude=${longitude}`
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.getGps.SUCCESS(res.response);
      }),
      catchError(error => of(actions.getGps.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
)

// 获取第三方码域名
export const getThirdPlainDomainEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getThirdPlainDomain.REQUEST.toString()),
  mergeMap(action => {
    actionApi()

    const { status = '', callback } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.codeQueryTool.thirdPlainDomain}?codeSourceType=CODE_IMPORT&status=${status}`
      // url: `${urls.codeQueryTool.thirdPlainDomain}?status=ENABLE`
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.getThirdPlainDomain.SUCCESS(res.response);
      }),
      catchError(error => { console.log(error, 174); return of(actions.getThirdPlainDomain.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)) })
    )
  })
);

// 获取码类型数据
export const singleCodeQueryToolTypeListEpic = actions$ => actions$.pipe(
  ofType(actions.singleCodeQueryToolTypeList.REQUEST.toString()),
  mergeMap((action) => {
    actionApi();
    const { status = '', modelCode = '', callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.codeQueryTool.codeType}?status=${status}&modelCode=${modelCode}`,
      method: 'GET',

    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.singleCodeQueryToolTypeList.SUCCESS(res)
      }),
      catchError(error => {
        return of(actions.singleCodeQueryToolTypeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      })
    )
  }
  )
);