import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'membershipSystem';

// upstate
export const changeMemberTab = createAction(`${moduleName}/changeMemberTab`);
/* 会员体系 */

export const getMemberSystemTable = fasterActions(moduleName, 'getMemberSystemTable');
export const getMemberSystemById = fasterActions(moduleName, 'getMemberSystemById');
export const createMemberSystem = fasterActions(moduleName, 'createMemberSystem');
export const putMemberSystem = fasterActions(moduleName, 'putMemberSystem');

export const importMemberSystemProduct = fasterActions(moduleName, 'importMemberSystemProduct');

export const getMemberSystemSelection = fasterActions(moduleName, 'getMemberSystemSelection');
export const deleteMemberSystem = fasterActions(moduleName, 'deleteMemberSystem');
export const publishMemberSystem = fasterActions(moduleName, 'publishMemberSystem');
export const disabledOrEnableMemberSystem = fasterActions(moduleName, 'disabledOrEnableMemberSystem');

export const getSystemHistory = fasterActions(moduleName, 'getSystemHistory');
export const getSystemHistoryById = fasterActions(moduleName, 'getSystemHistoryById');
export const putSystemHistory = fasterActions(moduleName, 'putSystemHistory');

export const changeSystemHistoryName = createAction(`${moduleName}/changeSystemHistoryName`);
export const unmountSystemHistory = fasterActions(moduleName, 'unmountSystemHistory');
export const unmountSystemHistorySearch = fasterActions(moduleName, 'unmountSystemHistorySearch');

/* 会员等级 */
export const getMemberLevelList = fasterActions(moduleName, 'getMemberLevelList');
export const deleteMemberLevel = fasterActions(moduleName, 'deleteMemberLevel');
export const createMemberLevel = fasterActions(moduleName, 'createMemberLevel');
export const putMemberLevel = fasterActions(moduleName, 'putMemberLevel');

/* 会员权益 */
export const changeRightsName = createAction(`${moduleName}/changeRightsName`);
export const getMemberRightsTable = fasterActions(moduleName, 'getMemberRightsList');
export const createMemberRights = fasterActions(moduleName, 'createMemberRights');
export const putMemberRights = fasterActions(moduleName, 'putMemberRights');
export const deleteMemberRights = fasterActions(moduleName, 'deleteMemberRights');
export const getMemberRightsSelect = fasterActions(moduleName, 'getMemberRightsSelect');

export const putMemberTags = fasterActions(moduleName, 'putMemberTags');

/* 会员字段  完善信息 */

export const getMemberFieldsTable = fasterActions(moduleName, 'getMemberFieldsTable');
export const deleteMemberFields = fasterActions(moduleName, 'deleteMemberFields');
export const disabledOrEnableMemberFields = fasterActions(moduleName, 'disabledOrEnableMemberFields');
export const createMemberFields = fasterActions(moduleName, 'createMemberFields');
export const putMemberFields = fasterActions(moduleName, 'putMemberFields');
export const getMemberFieldsSelect = fasterActions(moduleName, 'getMemberFieldsSelect');

export const changeFieldsName = createAction(`${moduleName}/changeFieldsName`);
export const unmountFields = fasterActions(moduleName, 'unmountFields');


/* 获取被其它体系使用的奖池奖品 */
export const getOtherSystenPoolPrizes = fasterActions(moduleName, 'getOtherSystenPoolPrizes');


export const unmount = fasterActions(moduleName, 'unmount');

// 绑定code

export const bindCode = fasterActions(moduleName, 'bindCode');



const initialState = {
    // 会员体系
    systemData: {},
    memberSystemTable: [],

    systemById: {},
    systemByIdH: {},

    memberSystemSelection: [], // 非草稿的会员体系
    systemHistorySearch: { page: 0, size: 20 },
    memberSystemHistoryTable: [],
    // 会员等级
    memberLevelList: [],
    // 会员权益
    memberRightsTable: [],
    memberRightsSearch: { page: 0, size: 20 },

    // 会员字段
    memberFieldsSearch: { page: 0, size: 20 },
    memberFieldsTable: [],
    otherPoolPrizeList: [],
}

// Reducer
const reducer = handleActions(
    {
        [changeMemberTab]: (state, { payload }) => ({ ...state, memberTab: payload }),
        /* 会员体系 */

        [getMemberSystemTable.REQUEST]: (state, { payload }) => ({ ...state, systemLoading: true }),
        [getMemberSystemTable.SUCCESS]: (state, { payload }) => {
            const { content = [], totalElements } = payload;
            return { ...state, systemLoading: false, memberSystemTable: content, totalElements }
        },
        [getMemberSystemTable.FAIL]: (state, { payload }) => ({ ...state, systemLoading: false, }),


        // 会员体系byid
        [getMemberSystemById.REQUEST]: (state, { payload }) => ({ ...state, }),
        [getMemberSystemById.SUCCESS]: (state, { payload }) => ({ ...state, systemById: payload, }),
        [getMemberSystemById.FAIL]: (state, { payload }) => ({ ...state, }),


        // 会员体系selection
        [getMemberSystemSelection.REQUEST]: (state, { payload }) => ({ ...state, systemSelectionLoading: true }),
        [getMemberSystemSelection.SUCCESS]: (state, { payload }) => {
            let selector = [];
            if (payload) {
                for (let elem of payload.values()) {
                    selector.push({ ...elem, value: elem.systemId, text: elem.name })
                }
            }
            return { ...state, systemSelectionLoading: false, memberSystemSelection: selector }
        },
        [getMemberSystemSelection.FAIL]: (state, { payload }) => ({ ...state, systemSelectionLoading: false, }),
        // 删除会员体系
        [deleteMemberSystem.REQUEST]: (state, { payload }) => ({ ...state }),
        [deleteMemberSystem.SUCCESS]: (state, { payload }) => ({ ...state }),
        [deleteMemberSystem.FAIL]: (state, { payload }) => ({ ...state }),
        // 发布会员体系
        [publishMemberSystem.REQUEST]: (state, { payload }) => ({ ...state, publishSystemLoading: true }),
        [publishMemberSystem.SUCCESS]: (state, { payload }) => ({ ...state, publishSystemLoading: false, }),
        [publishMemberSystem.FAIL]: (state, { payload }) => ({ ...state, publishSystemLoading: false }),
        // 禁用、启用会员体系
        [disabledOrEnableMemberSystem.REQUEST]: (state, { payload }) => ({ ...state }),
        [disabledOrEnableMemberSystem.SUCCESS]: (state, { payload }) => ({ ...state }),
        [disabledOrEnableMemberSystem.FAIL]: (state, { payload }) => ({ ...state }),
        // 创建会员体系
        [createMemberSystem.REQUEST]: (state, { payload }) => ({ ...state }),
        [createMemberSystem.SUCCESS]: (state, { payload }) => ({ ...state }),
        [createMemberSystem.FAIL]: (state, { payload }) => ({ ...state }),
        // 修改会员体系
        [putMemberSystem.REQUEST]: (state, { payload }) => ({ ...state, putSystemLoading: true }),
        [putMemberSystem.SUCCESS]: (state, { payload }) => ({ ...state, putSystemLoading: false }),
        [putMemberSystem.FAIL]: (state, { payload }) => ({ ...state, putSystemLoading: false }),
        // 获取会员体系历史列表
        [getSystemHistory.REQUEST]: (state, { payload }) => ({ ...state, historyLoading: true, systemHistorySearch: { page: 0, size: 20, name: '', sort: '', ...payload } }),
        [getSystemHistory.SUCCESS]: (state, { payload }) => {
            const { content = [], totalElements, first, last } = payload;
            let system = content.length > 0 ? content[0].system || {} : {};
            return {
                ...state,
                historyLoading: false,
                memberSystemHistoryTable: content,
                memberSystemHistoryAll: payload,
                historyTotalElements: totalElements,
                memberLevelList: system.levels || [],
                systemData: { name: system.name } || {}
            }
        },
        [getSystemHistory.FAIL]: (state, { payload }) => ({ ...state, historyLoading: false, }),

        [getSystemHistoryById.REQUEST]: (state, { payload }) => ({ ...state }),
        [getSystemHistoryById.SUCCESS]: (state, { payload }) => ({ ...state, systemByIdH: payload }),
        [getSystemHistoryById.FAIL]: (state, { payload }) => ({ ...state }),

        // 修改体系历史备注
        [putSystemHistory.REQUEST]: (state, { payload }) => ({ ...state }),
        [putSystemHistory.SUCCESS]: (state, { payload }) => ({ ...state }),
        [putSystemHistory.FAIL]: (state, { payload }) => ({ ...state }),

        //修改历史的筛选
        [changeSystemHistoryName]: (state, { payload }) => ({ ...state, systemHistorySearch: payload }),
        //清除历史
        [unmountSystemHistory.REQUEST]: (state, { payload }) => ({
            ...state,
            systemByIdH: {},
            memberSystemHistoryTable: [],
        }),
        //清除历史筛选
        [unmountSystemHistorySearch.REQUEST]: (state, { payload }) => ({
            ...state,
            systemHistorySearch: { page: 0, size: 20 },
        }),


        /* 会员等级 */
        [getMemberLevelList.REQUEST]: (state, { payload }) => ({ ...state, levelLoading: true }),
        [getMemberLevelList.SUCCESS]: (state, { payload }) => ({ ...state, levelLoading: false, memberLevelList: payload }),
        [getMemberLevelList.FAIL]: (state, { payload }) => ({ ...state, levelLoading: false, error: payload }),
        // 删除会员等级
        [deleteMemberLevel.REQUEST]: (state, { payload }) => ({ ...state }),
        [deleteMemberLevel.SUCCESS]: (state, { payload }) => ({ ...state }),
        [deleteMemberLevel.FAIL]: (state, { payload }) => ({ ...state }),
        // 创建会员等级
        [createMemberLevel.REQUEST]: (state, { payload }) => ({ ...state, isSubmit: true }),
        [createMemberLevel.SUCCESS]: (state, { payload }) => ({ ...state, isSubmit: Object.keys(payload).length ? false : true }),
        [createMemberLevel.FAIL]: (state, { payload }) => ({ ...state, isSubmit: false }),
        // 修改会员等级
        [putMemberLevel.REQUEST]: (state, { payload }) => ({ ...state, isSubmit: true }),
        [putMemberLevel.SUCCESS]: (state, { payload }) => ({ ...state, isSubmit: Object.keys(payload).length ? false : true }),
        [putMemberLevel.FAIL]: (state, { payload }) => ({ ...state, isSubmit: false }),



        /* 会员权益 */
        [changeRightsName]: (state, { payload }) => ({ ...state, memberRightsSearch: payload }),
        [getMemberRightsTable.REQUEST]: (state, { payload }) => ({ ...state, rightsLoading: true, memberRightsSearch: { page: 0, size: 20, name: '', rightsType: '', ...payload } }),
        [getMemberRightsTable.SUCCESS]: (state, { payload }) => {
            const { content = [], totalElements } = payload;
            return { ...state, rightsLoading: false, memberRightsTable: content, rightTotalElements: totalElements }
        },
        [getMemberRightsTable.FAIL]: (state, { payload }) => ({ ...state, rightsLoading: false, error: payload }),
        // 创建会员权益
        [createMemberRights.REQUEST]: (state, { payload }) => ({ ...state }),
        [createMemberRights.SUCCESS]: (state, { payload }) => ({ ...state }),
        [createMemberRights.FAIL]: (state, { payload }) => ({ ...state }),
        // 修改会员权益
        [putMemberRights.REQUEST]: (state, { payload }) => ({ ...state }),
        [putMemberRights.SUCCESS]: (state, { payload }) => ({ ...state }),
        [putMemberRights.FAIL]: (state, { payload }) => ({ ...state }),
        // 删除会员权益
        [deleteMemberRights.REQUEST]: (state, { payload }) => ({ ...state }),
        [deleteMemberRights.SUCCESS]: (state, { payload }) => ({ ...state }),
        [deleteMemberRights.FAIL]: (state, { payload }) => ({ ...state }),
        // 会员权益下拉
        [getMemberRightsSelect.REQUEST]: (state, { payload }) => ({ ...state }),
        [getMemberRightsSelect.SUCCESS]: (state, { payload }) => ({ ...state, memberRightsList: payload }),
        [getMemberRightsSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [putMemberTags.REQUEST]: (state, { payload }) => ({ ...state }),
        [putMemberTags.SUCCESS]: (state, { payload }) => ({ ...state }),
        [putMemberTags.FAIL]: (state, { payload }) => ({ ...state }),



        /* 会员字段 */

        [getMemberFieldsTable.REQUEST]: (state, { payload }) => ({ ...state, fieldsLoading: true, memberFieldsSearch: { page: 0, size: 20, name: '', status: [], ...payload } }),
        [getMemberFieldsTable.SUCCESS]: (state, { payload }) => {
            const { content = [], totalElements } = payload;
            return { ...state, fieldsLoading: false, memberFieldsTable: content, fieldsTotalElements: totalElements }
        },
        [getMemberFieldsTable.FAIL]: (state, { payload }) => ({ ...state, fieldsLoading: false, }),
        // 删除会员字段
        [deleteMemberFields.REQUEST]: (state, { payload }) => ({ ...state }),
        [deleteMemberFields.SUCCESS]: (state, { payload }) => ({ ...state }),
        [deleteMemberFields.FAIL]: (state, { payload }) => ({ ...state }),
        // 禁用、启用会员字段
        [disabledOrEnableMemberFields.REQUEST]: (state, { payload }) => ({ ...state }),
        [disabledOrEnableMemberFields.SUCCESS]: (state, { payload }) => ({ ...state }),
        [disabledOrEnableMemberFields.FAIL]: (state, { payload }) => ({ ...state }),
        // 创建会员字段
        [createMemberFields.REQUEST]: (state, { payload }) => ({ ...state }),
        [createMemberFields.SUCCESS]: (state, { payload }) => ({ ...state }),
        [createMemberFields.FAIL]: (state, { payload }) => ({ ...state }),
        // 修改会员字段
        [putMemberFields.REQUEST]: (state, { payload }) => ({ ...state }),
        [putMemberFields.SUCCESS]: (state, { payload }) => ({ ...state }),
        [putMemberFields.FAIL]: (state, { payload }) => ({ ...state }),

        [getMemberFieldsSelect.REQUEST]: (state, { payload }) => ({ ...state }),
        [getMemberFieldsSelect.SUCCESS]: (state, { payload }) => ({ ...state, memberFieldsSelect: payload }),
        [getMemberFieldsSelect.FAIL]: (state, { payload }) => ({ ...state }),

        [changeFieldsName]: (state, { payload }) => ({ ...state, memberFieldsSearch: payload }),
        [unmountFields.REQUEST]: (state, { payload }) => ({
            ...state,
            memberFieldsSearch: { page: 0, size: 20 },
            memberFieldsTable: [],
        }),


        // 获取其他体系的奖池奖品
        [getOtherSystenPoolPrizes.REQUEST]: (state, { payload }) => ({ ...state }),
        [getOtherSystenPoolPrizes.SUCCESS]: (state, { payload }) => ({ ...state, otherPoolPrizeList: payload }),
        [getOtherSystenPoolPrizes.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



        //导入会员体系。产品内容 
        [importMemberSystemProduct.REQUEST]: (state, { payload }) => ({ ...state }),
        [importMemberSystemProduct.SUCCESS]: (state, { payload }) => ({ ...state }),
        [importMemberSystemProduct.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



        [unmount.REQUEST]: (state, { payload }) => ({
            ...state,
            systemById: {},
            systemByIdH: {},
            memberLevelList: []
        }),



        //绑定code
        [bindCode.REQUEST]: (state, { payload }) => ({ ...state, payload, bindCodeLoaded: false }),
        [bindCode.SUCCESS]: (state, { payload }) => ({ ...state, bindCodeLoaded: payload }),
        [bindCode.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    },
    initialState
)
export default reducer;