import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './miniProgramEseRelations';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 小程序
 */
// 获取已设置的二维码规则
export const testersEpic = actions$ => actions$.pipe(
  ofType(actions.loadEseRelations.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: urls.wechat.miniProgramEseRelation.miniProgramEseRelation,
    }).pipe(
      map(res => actions.loadEseRelations.SUCCESS(res.response)),
      catchError(error => of(actions.loadEseRelations.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
