import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './dataScreen';
import { ajaxBaseConfig, actionApi } from '../../utils';

// Epics
export const getwarning_statisticsEpic = actions$ => actions$.pipe(
    ofType(actions.getwarning_statistics.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.statistics.dataScreen.warningStatistics}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response)
                return [actions.getwarning_statistics.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getwarning_statistics.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const getwarning_eventsEpic = actions$ => actions$.pipe(
    ofType(actions.getwarning_events.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.statistics.dataScreen.warningEvents}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response)
                return [actions.getwarning_events.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getwarning_events.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const getlink_dataEpic = actions$ => actions$.pipe(
    ofType(actions.getlink_data.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.statistics.dataScreen.linkData}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response)
                return [actions.getlink_data.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getlink_data.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


export const getout_control_ratesEpic = actions$ => actions$.pipe(
    ofType(actions.getout_control_rates.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.statistics.dataScreen.outControlRates}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response)
                return [actions.getout_control_rates.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getout_control_rates.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getinsp_control_ratesEpic = actions$ => actions$.pipe(
    ofType(actions.getinsp_control_rates.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.statistics.dataScreen.inspControlRates}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response)
                return [actions.getinsp_control_rates.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getinsp_control_rates.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)








export const getscan_numEpic = actions$ => actions$.pipe(
    ofType(actions.getscan_num.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.statistics.dataScreen.scanNum}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response)
                return [actions.getscan_num.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getscan_num.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getuser_countEpic = actions$ => actions$.pipe(
    ofType(actions.getuser_count.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.statistics.dataScreen.userCount}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response)
                return [actions.getuser_count.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getuser_count.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)




export const getmarketing_reportEpic = actions$ => actions$.pipe(
    ofType(actions.getmarketing_report.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.statistics.dataScreen.marketingReport}?selectBy=${data.data}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response)
                return [actions.getmarketing_report.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getmarketing_report.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


export const getlink_tendencyEpic = actions$ => actions$.pipe(
    ofType(actions.getlink_tendency.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.statistics.dataScreen.linkTendency}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response)
                return [actions.getlink_tendency.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getlink_tendency.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getarea_heat_mapsEpic = actions$ => actions$.pipe(
    ofType(actions.getarea_heat_maps.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.statistics.dataScreen.areaHeatMaps}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response)
                return [actions.getarea_heat_maps.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getarea_heat_maps.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

