import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'fileds';

/**
 * TODO: 
 */

// 字段管理
export const prodcutFileds = fasterActions(moduleName, 'prodcutFileds');
export const prodcutFiledsSelections = fasterActions(moduleName, 'prodcutFiledsSelections');
export const prodcutFiledsById = fasterActions(moduleName, 'prodcutFiledsById');
export const prodcutFiledsStatus = fasterActions(moduleName, 'prodcutFiledsStatus');
export const prodcutFiledsModify = fasterActions(moduleName, 'prodcutFiledsModify');
export const prodcutFiledsEnums = fasterActions(moduleName, 'prodcutFiledsEnums');


const initialState = {

}

const reducer = handleActions(
  {
    [prodcutFileds.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [prodcutFileds.SUCCESS]: (state, { payload }) => ({ ...state, prodcutFileds: payload, loading: false }),
    [prodcutFileds.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [prodcutFiledsSelections.SUCCESS]: (state, { payload }) => {
      if (payload.content) {
        payload.content.forEach(v => { v.text = v.name, v.value = v.id });
      }
      return ({ ...state, prodcutFiledsSelections: payload })
    },
    [prodcutFiledsSelections.FAIL]: (state, { payload }) => ({ ...state }),

    [prodcutFiledsById.SUCCESS]: (state, { payload }) => ({ ...state, prodcutFiledsById: payload }),
    [prodcutFiledsById.FAIL]: (state, { payload }) => ({ ...state }),

    [prodcutFiledsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, prodcutFiledsModifyLoaded: false }),
    [prodcutFiledsModify.SUCCESS]: (state, { payload }) => ({ ...state, prodcutFiledsModifyLoaded: payload, }),
    [prodcutFiledsModify.FAIL]: (state, { payload }) => ({ ...state }),

    [prodcutFiledsStatus.REQUEST]: (state, { payload }) => ({ ...state, payload, prodcutFiledsStatusLoaded: false }),
    [prodcutFiledsStatus.SUCCESS]: (state, { payload }) => ({ ...state, prodcutFiledsStatusLoaded: payload, }),
    [prodcutFiledsStatus.FAIL]: (state, { payload }) => ({ ...state }),

    [prodcutFiledsEnums.REQUEST]: (state, { payload }) => ({ ...state, payload, prodcutFiledsModifyLoaded: false }),
    [prodcutFiledsEnums.SUCCESS]: (state, { payload }) => ({ ...state, prodcutFiledsModifyLoaded: payload, }),
    [prodcutFiledsEnums.FAIL]: (state, { payload }) => ({ ...state }),



  },
  initialState
);

export default reducer;
