
import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions, setScrollTop } from '../../utils';

const moduleName = 'prizeManager';
/**
 * TODO:奖品 
 */
export const loadList = fasterActions(moduleName, 'loadList');
export const prizePaging = fasterActions(moduleName, 'prizePaging');
export const prizeCopy = fasterActions(moduleName, 'prizeCopy');
export const prizeDelete = fasterActions(moduleName, 'prizeDelete');
export const prizeDetail = fasterActions(moduleName, 'prizeDetail');
export const prizeCreate = fasterActions(moduleName, 'prizeCreate');
export const prizeEdit = fasterActions(moduleName, 'prizeEdit');
export const prizeSelect = fasterActions(moduleName, 'prizeSelect');
// 搜索关键字
export const changeKeyWords = createAction(`${moduleName}/changeKeyWords`);
// 登记信息弹窗
export const registerDialogVisible = createAction(`${moduleName}/registerDialogVisible`);

// 创建编辑核销码
export const submitVoucherCodeTemplateList = fasterActions(moduleName, 'submitVoucherCodeTemplateList');
// 初始化submitVoucherCodeTemplateList的状态
export const initSubmitVoucherCodeTemplateList = createAction(`${moduleName}/initSubmitVoucherCodeTemplateList`);
// 核销码规格
export const loadChoiceList = fasterActions(moduleName, 'loadChoiceList');
// 券码列表
export const voucherCodeList = fasterActions(moduleName, 'voucherCodeList');
// 券码备注
export const voucherCodeRemark = fasterActions(moduleName, 'voucherCodeRemark');
// 初始化备注状态
export const initVoucherCodeRemark = createAction(`${moduleName}/initVoucherCodeRemark`);
// 数量查询
export const voucherCodeNum = fasterActions(moduleName, 'voucherCodeNum');
// 校验免密金额总和
export const checkoverLimit = fasterActions(moduleName, 'checkoverLimit');



const initialState = {
  listLoaded: false,
  promotionsLoading: false,
  prizeLoading: false,
  promotionsLoaded: false,
  // 奖品列表操作
  paging: {
    page: 0,
    size: 20,
    type: [],
    keyWords: '',
    sort: 'id,desc'
  },
  totalElements: 0,
  prizeList: [],
  isDisabledRegisterInfo: true, // 是否禁用登记信息
  registerDialogShow: false,

  // 券码列表操作
  pagingVoucher: {
    page: 0,
    size: 20,
    type: [],
    keyWords: '',
    sort: 'id,desc'
  },
  totalElementsVoucher: 0,
  prizeListVoucher: [],
  
}


// Reducer
const reducer = handleActions(
  {

    /**
     * TODO:奖品 
     */
    // 奖品列表
    [loadList.SUCCESS]: (state, { payload }) => {
      let selector = [{ value: '', text: '请选择卡片奖品' }];
      let selectorAll = [];
      for (let elem of payload.content.values()) {
        selector.push({ value: elem.id, text: elem.name })
        selectorAll.push({ value: elem.id, text: elem.name })
      }
      return {
        ...state,
        selector,
        selectorAll,
        list: payload.content,
        listLoaded: true,
      }
    },
    [prizePaging.REQUEST]: (state, { payload }) => {
      setScrollTop();
      return ({ ...state, prizeLoading: true, ...payload })
    },
    [prizePaging.SUCCESS]: (state, { payload }) => ({ ...state, prizeLoading: false, promotionsLoaded: true, prizeList: payload.content, totalElements: payload.totalElements }),
    [prizePaging.FAIL]: (state, { payload }) => ({ ...state, prizeLoading: false, promotionsLoaded: false, error: payload }),
    // 奖品复制
    [prizeCopy.REQUEST]: (state, { payload }) => ({ ...state }),
    [prizeCopy.SUCCESS]: (state, { payload }) => ({ ...state }),
    [prizeCopy.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 奖品删除
    [prizeDelete.REQUEST]: (state, { payload }) => ({ ...state }),
    [prizeDelete.SUCCESS]: (state, { payload }) => ({ ...state }),
    [prizeDelete.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 奖品详情
    [prizeDetail.REQUEST]: (state, { payload }) => ({ ...state }),
    [prizeDetail.SUCCESS]: (state, { payload }) => ({ ...state, prizeDetail: payload }),
    [prizeDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 奖品创建
    [prizeCreate.REQUEST]: (state, { payload }) => ({ ...state }),
    [prizeCreate.SUCCESS]: (state, { payload }) => ({ ...state }),
    [prizeCreate.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 奖品编辑
    [prizeEdit.REQUEST]: (state, { payload }) => ({ ...state }),
    [prizeEdit.SUCCESS]: (state, { payload }) => ({ ...state }),
    [prizeEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [prizeSelect.REQUEST]: (state, { payload }) => ({ ...state }),
    [prizeSelect.SUCCESS]: (state, { payload }) => {
      let prizeSelection = [];
      for (let elem of payload.content.values()) {
        prizeSelection.push({ value: elem.id, text: elem.name })
      }
      return {
        ...state,
        prizeSelection,

      }
    },
    [prizeSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    

    // 搜索关键字
    [changeKeyWords]: (state, { payload }) => ({ ...state, paging: { ...state.paging, keyWords: payload, page: 0 } }),
    // 登记信息Dialog
    [registerDialogVisible]: (state, { payload }) => {
      return ({ ...state, ...payload })
    },

    // 创建编辑核销码
    [submitVoucherCodeTemplateList.REQUEST]: (state, { payload }) => ({ ...state, payload, submitVoucherStatus: 'LOADING' }),
    [submitVoucherCodeTemplateList.SUCCESS]: (state, { payload }) => ({ ...state, payload, submitVoucherStatus: 'SUCCESS' }),
    [submitVoucherCodeTemplateList.FAIL]: (state, { payload }) => ({ ...state, error: payload, submitVoucherStatus: 'FAIL' }),
    // 初始化submitVoucherCodeTemplateList的状态
    [initSubmitVoucherCodeTemplateList]: (state, { payload }) => ({ ...state, submitVoucherStatus: '' }),
      
    // 核销码规格
    [loadChoiceList.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [loadChoiceList.SUCCESS]: (state, { payload }) => {
      const choiceSelector = [];
      for (let elem of payload.content.values()) {
        choiceSelector.push({ value: elem.id, text: elem.name, combinationTagType: elem.combinationTagType, combinationConfigDtos: elem.combinationConfigDtos || [], historyTagTemplateConfig: elem.historyTagTemplateConfig || {} })
      }
      return { ...state, choiceSelector, listLoaded: true, }
    },
    [loadChoiceList.FAIL]: (state, { payload }) => ({ ...state, listLoaded: false, }),

    // 查询券码列表
    [voucherCodeList.REQUEST]: (state, { payload }) => {
      setScrollTop();
      return ({ ...state, prizeLoadingVoucher: true, ...payload })
    },
    [voucherCodeList.SUCCESS]: (state, { payload }) => ({ ...state, prizeLoadingVoucher: false, promotionsLoadedVoucher: true, prizeListVoucher: payload.content, totalElementsVoucher: payload.totalElements }),
    [voucherCodeList.FAIL]: (state, { payload }) => ({ ...state, prizeLoadingVoucher: false, promotionsLoadedVoucher: false, error: payload }),
        
    // 查询券码备注
    [voucherCodeRemark.REQUEST]: (state, { payload }) => ({ ...state, payload, voucherCodeRemarkStatus: 'LOADING' }),
    [voucherCodeRemark.SUCCESS]: (state, { payload }) => ({ ...state, payload, exitRemark: 'SUCCESS', voucherCodeRemarkStatus: 'SUCCESS' }),
    [voucherCodeRemark.FAIL]: (state, { payload }) => ({ ...state, error: payload, voucherCodeRemarkStatus: 'FAIL' }),
    // 初始化备注状态    
    [initVoucherCodeRemark]: (state, { payload }) => ({ ...state, voucherCodeRemarkStatus: '' }),
    
    // 查询券码数量
    [voucherCodeNum.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    // [voucherCodeNum.SUCCESS]: (state, { payload }) => ({ ...state, payload }),
    [voucherCodeNum.SUCCESS]: (state, { payload }) => ({ ...state, total: payload.total || '', surplus: payload.surplus || '', surplusNum: payload.surplusNum || '' }),
    [voucherCodeNum.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    
    // 校验免密金额总和
    [checkoverLimit.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    // [checkoverLimit.SUCCESS]: (state, { payload }) => ({ ...state, checkoverLimitStatus: payload }),
    [checkoverLimit.SUCCESS]: (state, { payload }) => {
      // debugger
      console.log(payload, '校验状态------');
      return { ...state, checkoverLimitStatus: payload }
    },
    [checkoverLimit.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
  },
  initialState
)
export default reducer;