import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';


const moduleName = 'miniProgramConfig';

/**
 * 小程序开发-基础配置: 
 */
export const qrcode = fasterActions(moduleName, 'qrcode');
export const qrcodeJump = fasterActions(moduleName, 'qrcodeJump');
export const qrcodeJumpEdit = fasterActions(moduleName, 'qrcodeJumpEdit');
export const qrcodeJumpRelease = fasterActions(moduleName, 'qrcodeJumpRelease');
export const categoryList = fasterActions(moduleName, 'categoryList');
export const page = fasterActions(moduleName, 'page');
export const qrcodeJumpDelete = fasterActions(moduleName, 'qrcodeJumpDelete');



const initialState = {
    wechatLoad: false,
}

const reducer = handleActions(
    {
        [qrcode.REQUEST]: (state, { payload }) => ({ ...state, payload, qrcodeLoading: false }),
        [qrcode.SUCCESS]: (state, { payload }) => ({ ...state, qrcodeImg: payload, qrcodeLoading: true }),
        [qrcode.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [qrcodeJump.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [qrcodeJump.SUCCESS]: (state, { payload }) => ({ ...state, qrcodeJump: payload, loading: false }),
        [qrcodeJump.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

        [qrcodeJumpEdit.REQUEST]: (state, { payload }) => ({ ...state, qrcodeJumpEdit: '' }),
        [qrcodeJumpEdit.SUCCESS]: (state, { payload }) => ({ ...state, qrcodeJumpEdit: payload }),
        [qrcodeJumpEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [qrcodeJumpRelease.REQUEST]: (state, { payload }) => ({ ...state, qrcodeJumpRelease: '' }),
        [qrcodeJumpRelease.SUCCESS]: (state, { payload }) => ({ ...state, qrcodeJumpRelease: payload }),
        [qrcodeJumpRelease.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [categoryList.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [categoryList.SUCCESS]: (state, { payload }) => {
            const categoryListSelector = [];
            for (let elem of payload.content) {
                let text = elem.first_class + '-' + elem.second_class;
                if (elem.third_class) {
                    text += '-' + elem.third_class;
                }
                categoryListSelector.push({ value: JSON.stringify(elem), text });
            }

            return ({ ...state, categoryList: payload, categoryListSelector });
        },
        [categoryList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [page.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [page.SUCCESS]: (state, { payload }) => {
            const pageSelector = [];
            for (let elem of payload.content) {
                pageSelector.push({ value: elem, text: elem });
            }
            return ({ ...state, pageSelector });
        },
        [page.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [qrcodeJumpDelete.REQUEST]: (state, { payload }) => ({ ...state, qrcodeJumpDeleteLoaded: '' }),
        [qrcodeJumpDelete.SUCCESS]: (state, { payload }) => ({ ...state, qrcodeJumpDeleteLoaded: payload }),
        [qrcodeJumpDelete.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    },

    initialState
);

export default reducer;
