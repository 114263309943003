import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './award';
import { ajaxBaseConfig, actionApi } from '../../utils';

//step1
//获取step1奖池
export const getPoolEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getPool.REQUEST.toString()),

  mergeMap(action => {
    actionApi()

    let url = `${urls.promotion.poolsV2.poolsV2}/selections`
    if (action.payload && action.payload.carveUpType) {
      url = `${urls.promotion.poolsV2.poolsV2}/${action.payload.carveUpType}/selections`
    }
    if (action.payload && action.payload.published) {
      url = url+'?published=true'
    }

    return ajax({
      ...ajaxBaseConfig,
      url
    }).pipe(
      concatMap(res => {

        action.payload && action.payload.callback && action.payload.callback(res.response)
        return [actions.getPool.SUCCESS(action.payload && action.payload.callback ? { ...res.response, awardListloading: true } : res.response)]

        // if (!action.payload || action.payload && Object.keys(action.payload).length == 0) {

        // } else {
        //   if (action.payload.historyId) {
        //     return [actions.getPool.SUCCESS(res.response), actions.getAwardHistory.REQUEST(action.payload)]
        //   }
        //   else {
        //     return [actions.getPool.SUCCESS(res.response), actions.getAward.REQUEST(action.payload)]
        //   }
        // }

      }),
      catchError(error => of(actions.getPool.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }

  )
);


//获取step1奖项全部
export const getAwardAllEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getAwardAll.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { poolList } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.awards.awards}/${action.payload.promotionId}`
    }).pipe(
      concatMap(res => {
        let Awardlist = [];
        if (poolList.length > 0) {
          for (let i = 0; i < res.response.content.length; i++) {
            let item = res.response.content[i];
            let normalRemark = item.awardType == 'GENERAL' ?
              poolList.filter(key => key.id == item.poolId)[0] ?
                (poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0] ?
                  (poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0].remark : '' : '' : ''
            let prizeType = item.awardType == 'GENERAL' ?
              poolList.filter(key => key.id == item.poolId)[0] ?
                (poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0] ?
                  (poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0].prizeType : '' : '' : item.awardType
            let normalName = (item.awardType == 'COMBINATION' || item.awardType == 'INTELLIGENT') ? item.combinationAwardName + `(奖品名称:${item.name})` :
              poolList.filter(key => key.id == item.poolId)[0] ?
                poolList.filter(key => key.id == item.poolId)[0].poolPrizes.filter(key => key.id == item.poolPrizeId)[0] ?
                  (poolList.filter(key => key.id == item.poolId)[0].name + '-' + (poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0].name) + `(备注:${normalRemark})`
                  : '' : ''
            let array = {
              ...item,
              normalName: normalName,
              normalRemark: normalRemark,
              prizeType: prizeType,
              value: item.id,
              text: normalName
            }
            Awardlist.push(array)
          }
        }
        return [actions.getAwardAll.SUCCESS(Awardlist)]
      }),
      // catchError(error => of(actions.getAwardAll.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      // )
    )
  }
  )
);

//获取step1奖项
export const getAwardEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getAward.REQUEST.toString()),
  mergeMap(action => {
    const { promotionId, filterType = '', keywords = '', page = 0, poolList, protectStatus = '', disabled = '', size = 50, keywords2 = '' } = action.payload;
    let url = `${urls.promotion.awards.awards}?promotionId=${promotionId}&disabled=${keywords ? '' : disabled}&prizeType=${filterType}&awardType=${filterType}&status=${protectStatus}&size=${size}&page=${page}`
    if (keywords2 == 'prizeName') {
      url = url + `&prizeName=${keywords}`
    }
    else if (keywords2 == 'poolName') {
      url = url + `&poolName=${keywords}`
    }
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: url,
    }).pipe(
      concatMap(res => {
        let Awardlist = [];
        if (poolList.length > 0) {
          for (let i = 0; i < res.response.content.length; i++) {
            let item = res.response.content[i];
            let normalName = (item.awardType == 'COMBINATION' || item.awardType == 'INTELLIGENT') ? item.combinationAwardName :
              poolList.filter(key => key.id == item.poolId)[0] ?
                poolList.filter(key => key.id == item.poolId)[0].poolPrizes.filter(key => key.id == item.poolPrizeId)[0] ?
                  (poolList.filter(key => key.id == item.poolId)[0].name + '-' + (poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0].name)
                  : '' : ''
            let normalRemark = item.awardType == 'GENERAL' ?
              poolList.filter(key => key.id == item.poolId)[0] ?
                (poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0] ?
                  (poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0].remark : '' : '' : ''
            let prizeType = item.awardType == 'GENERAL' ?
              poolList.filter(key => key.id == item.poolId)[0] ?
                (poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0] ?
                  (poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0].prizeType : '' : '' : item.awardType
            let array = {
              ...item,
              normalName: normalName,
              normalRemark: normalRemark,
              prizeType: prizeType,
              value: item.id,
              text: normalName
            }
            Awardlist.push(array)
          }
        }
        return [actions.getAwardHistory.SUCCESS({ ...res.response, Awardlist: Awardlist })]
      }),
      // catchError(error => of(actions.getAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      // )
    )
  }
  )
);

//获取step1奖项历史
export const getAwardHistoryEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getAwardHistory.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { promotionId, historyId, step, filterType = '', keywords = '', page = 0, poolList, protectStatus = '', disabled = '', size = 50 } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: urls.promotion.promotionHistories.step(historyId, promotionId, step) + `&prizeName=${keywords}&disabled=${disabled}&prizeType=${filterType}&awardType=${filterType}&status=${protectStatus}&size=${size}&page=${page}`
    }).pipe(
      concatMap(res => {
        let Awardlist = [];
        if (poolList.length > 0) {
          for (let i = 0; i < (res.response.awards?.content || res.response.awards).length; i++) {
            let item = res.response.awards?.content?.[i] || res.response.awards[i];
            let normalName = (item.awardType == 'COMBINATION' || item.awardType == 'INTELLIGENT') ? item.combinationAwardName :

              poolList.filter(key => key.id == item.poolId)[0] ?
                poolList.filter(key => key.id == item.poolId)[0].poolPrizes.filter(key => key.id == item.poolPrizeId)[0] ?
                  (poolList.filter(key => key.id == item.poolId)[0].name + '-' + (poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0].name)
                  : '' : ''
            let normalRemark = item.awardType == 'GENERAL' ?
              poolList.filter(key => key.id == item.poolId)[0] ?
                (poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0] ?
                  (poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0].remark : '' : '' : ''

            let prizeType = item.awardType == 'GENERAL' ?
              poolList.filter(key => key.id == item.poolId)[0] ?
                (poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0] ?
                  (poolList.filter(key => key.id == item.poolId)[0].poolPrizes).filter(key => key.id == item.poolPrizeId)[0].prizeType : '' : '' : item.awardType
            let array = {
              ...item,
              normalName: normalName,
              normalRemark: normalRemark,
              prizeType: prizeType,
              value: item.id,
              text: normalName
            }
            Awardlist.push(array)
          }
        }
        return [actions.getAwardHistory.SUCCESS({ ...res.response, Awardlist: Awardlist })]
      }),
      // catchError(error => of(actions.getAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      // )
    )
  }
  )
);
//保存step1奖项
export const createAwardEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.createAward.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: data,
      url: `${urls.promotion.awards.awards}`
    }).pipe(
      //
      concatMap(res => {
        callback && callback()
        return [actions.createAward.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.createAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
//保存step1组合奖项
export const createZHAwardEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.createZHAward.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: data,
      url: `${urls.promotion.awards.combination}`
    }).pipe(
      //
      concatMap(res => {
        callback && callback()
        return [actions.createZHAward.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.createZHAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
//保存step1优选奖奖项
export const createZNYXAwardEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.createZNYXAward.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: data,
      url: `${urls.promotion.awards.awards}/intelligent`
    }).pipe(
      //
      concatMap(res => {
        callback && callback()
        return [actions.createZNYXAward.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.createZNYXAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

//编辑step1奖项
export const editAwardEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.editAward.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({

      ...ajaxBaseConfig,
      method: 'PUT',
      body: data,
      url: `${urls.promotion.awards.awards}`
    }).pipe(
      //
      concatMap(res => {
        callback && callback()
        return [actions.editAward.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.editAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
//获取step1组合奖项byId
export const getZHAwardEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getZHAward.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.awards.awards}?awardId=${action.payload}`
    }).pipe(
      concatMap(res => {

        let newdata = JSON.parse(JSON.stringify(res.response.content));
        for (let i = 0; i < newdata.length; i++) {
          for (let j = 0; j < newdata[i].probabilityPrizes.length; j++) {
            newdata[i].probabilityPrizes[j].rate = parseFloat((newdata[i].probabilityPrizes[j].rate * 10000 * 100 / 10000).toPrecision(12))


          }
        }

        return [actions.getZHAward.SUCCESS(newdata)]
      }),
      catchError(error => of(actions.getZHAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
//获取step1优选奖项byId
export const getZNYXAwardEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getZNYXAward.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.awards.awards}/intelligent?awardId=${action.payload.id ? action.payload.id : action.payload}`
    }).pipe(
      concatMap(res => {
        action.payload.callback && action.payload.callback(res.response.intelligentPrizes)
        return [actions.getZNYXAward.SUCCESS(res.response.intelligentPrizes)]
      }),
      // catchError(error => of(actions.getZNYXAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      // )
    )
  }
  )
);
//获取step1组合奖项byId历史
export const getZHAwardHistoryEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getZHAwardHistory.REQUEST.toString()),
  // mergeMap(action => { actionApi()
  //   const { historyId, awardId } = action.payload;
  //   return ajax({
  //     ...ajaxBaseConfig,
  //     url: `${urls.promotion.promotionHistories.historyAward(historyId,awardId)}`
  //   }).pipe(
  concatMap(action => {
    actionApi();
    const { historyId, awardId } = action.payload;
    let newdata = JSON.parse(JSON.stringify(state$.value.award.awardListAll.filter(key => key.id == awardId)[0].prizeCombinations || []));

    // let newdata = res.response.prizeCombination;
    for (let i = 0; i < newdata.length; i++) {
      for (let j = 0; j < newdata[i].probabilityPrizes.length; j++) {
        newdata[i].probabilityPrizes[j].rate = parseFloat((newdata[i].probabilityPrizes[j].rate * 10000 * 100 / 10000).toPrecision(12))
      }
    }

    return [actions.getZHAwardHistory.SUCCESS(newdata)]
  }),
  //     catchError(error => of(actions.getZHAwardHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
  //     )
  //   )
  // }
  // )
);
//编辑step1组合奖项
export const editZHAwardEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.editZHAward.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      body: data,
      url: `${urls.promotion.awards.combination}`
    }).pipe(
      //
      concatMap(res => {
        callback && callback()
        return [actions.editZHAward.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.editZHAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

//编辑step1优选奖项
export const editZNYXAwardEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.editZNYXAward.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      body: data,
      url: `${urls.promotion.awards.awards}/intelligent`
    }).pipe(
      //
      concatMap(res => {
        callback && callback()
        return [actions.editZNYXAward.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.editZNYXAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
//删除step1奖项
export const deleteAwardEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.deleteAward.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'DELETE',
      url: `${urls.promotion.awards.awards}/${action.payload.data}`
    }).pipe(
      //
      concatMap(res => {
        callback && callback()
        return [actions.deleteAward.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.deleteAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
//able

export const ableAwardEpic = actions$ => actions$.pipe(
  ofType(actions.ableAward.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.promotion.awards.awards}/${data.id}?disabled=${data.disabled}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.ableAward.SUCCESS(res)
        }),
        catchError(error => of(actions.ableAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
export const changeAwardEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.changeAward.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { awardId, promotionId, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: `${urls.promotion.awards.awards}/change`,
      body: [{ awardId, promotionId }],
    }).pipe(
      map(res => {
        callback && callback()
        return actions.changeAward.SUCCESS(res)
      }),
      catchError(error => of(actions.changeAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

//搜索
// export const typeValueEpic = (actions$, state$) => actions$.pipe(
//   ofType(actions.typeValue.REQUEST.toString()),
//   concatMap(action => {
//     actionApi()
//     return [actions.typeFilter.REQUEST(state$.value.award.filterType)]
//   }),
// );
