import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import { _set } from 'ucode-utils';
import urls from '@/api/urls';

const moduleName = 'minProgramTemplate';

/**
 * TODO: 微信管理
 */

export const minProgramTemplate = fasterActions(moduleName, 'minProgramTemplate');
export const minProgramTemplateModify = fasterActions(moduleName, 'modifyMinProgramTemplate');

export const minProgramTemplateCodePackage = fasterActions(moduleName, 'minProgramTemplateCodePackage');
export const minProgramTemplateAppoint = fasterActions(moduleName, 'minProgramTemplateAppoint');
export const minProgramTemplateAllocated = fasterActions(moduleName, 'minProgramTemplateAllocated');
export const minProgramTemplateDistribution = fasterActions(moduleName, 'minProgramTemplateDistribution');



// 企业模板查询
export const minTemplateEse = fasterActions(moduleName, 'minTemplateEse');
export const minTemplateEseTrial = fasterActions(moduleName, 'minTemplateEseTrial');
export const minTemplateEsePublish = fasterActions(moduleName, 'minTemplateEsePublish');
export const minTemplateEseDelete = fasterActions(moduleName, 'minTemplateEseDelete');
export const minTemplateEseCancel = fasterActions(moduleName, 'minTemplateEseCancel');

export const minTemplateEseById = fasterActions(moduleName, 'minTemplateEseById');
export const minTemplateEseDistribution = fasterActions(moduleName, 'minTemplateEseDistribution');
// 修改企业模版
export const minTemplateEseModify = fasterActions(moduleName, 'minTemplateEseModify');
// 修改企业模版json文件
export const minTemplateEseEditJson = fasterActions(moduleName, 'minTemplateEseEditJson');




// export const getSaveData = createAction(`${moduleName}/getSaveData`);
export const getSaveData = fasterActions(`${moduleName}/getSaveData`);
export const updateSaveData = createAction(`${moduleName}/updateSaveData`);
export const getTestSaveData = createAction(`${moduleName}/getTestSaveData`);






const initialState = {

}

const reducer = handleActions(
  {



    [minProgramTemplate.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [minProgramTemplate.SUCCESS]: (state, { payload }) => ({ ...state, minProgramTemplate: payload, loading: false }),
    [minProgramTemplate.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),


    [minProgramTemplateModify.REQUEST]: (state, { payload }) => ({ ...state, payload, minProgramTemplateModifyLoaded: false }),
    [minProgramTemplateModify.SUCCESS]: (state, { payload }) => ({ ...state, minProgramTemplateModifyLoaded: payload }),
    [minProgramTemplateModify.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [minProgramTemplateCodePackage.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [minProgramTemplateCodePackage.SUCCESS]: (state, { payload }) => ({ ...state, minProgramTemplateCodePackage: payload }),
    [minProgramTemplateCodePackage.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [minProgramTemplateAppoint.REQUEST]: (state, { payload }) => ({ ...state, payload, minProgramTemplateAppointLoaded: false }),
    [minProgramTemplateAppoint.SUCCESS]: (state, { payload }) => ({ ...state, minProgramTemplateAppointLoaded: payload }),
    [minProgramTemplateAppoint.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [minProgramTemplateAllocated.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [minProgramTemplateAllocated.SUCCESS]: (state, { payload }) => ({ ...state, minProgramTemplateAllocated: payload }),
    [minProgramTemplateAllocated.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [minProgramTemplateDistribution.REQUEST]: (state, { payload }) => ({ ...state, payload, minProgramTemplateDistributionLoaded: false }),
    [minProgramTemplateDistribution.SUCCESS]: (state, { payload }) => ({ ...state, minProgramTemplateDistributionLoaded: payload }),
    [minProgramTemplateDistribution.FAIL]: (state, { payload }) => ({ ...state, error: payload }),





    [minTemplateEse.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [minTemplateEse.SUCCESS]: (state, { payload }) => ({ ...state, minTemplateEse: payload, loading: false }),
    [minTemplateEse.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [minTemplateEseTrial.REQUEST]: (state, { payload }) => ({ ...state, payload, minTemplateEseTrialLoaded: false }),
    [minTemplateEseTrial.SUCCESS]: (state, { payload }) => {
      const { publish } = state.payload;
      let minTemplateEseTrialLoaded = payload;
      if (publish) {
        minTemplateEseTrialLoaded = 'publish';
      }

      return { ...state, minTemplateEseTrialLoaded }
    },
    [minTemplateEseTrial.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [minTemplateEsePublish.REQUEST]: (state, { payload }) => ({ ...state, payload, minTemplateEsePublishLoaded: false }),
    [minTemplateEsePublish.SUCCESS]: (state, { payload }) => ({ ...state, minTemplateEsePublishLoaded: payload }),
    [minTemplateEsePublish.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [minTemplateEseDelete.REQUEST]: (state, { payload }) => ({ ...state, payload, minTemplateEseDeleteLoaded: false }),
    [minTemplateEseDelete.SUCCESS]: (state, { payload }) => ({ ...state, minTemplateEseDeleteLoaded: payload }),
    [minTemplateEseDelete.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [minTemplateEseCancel.REQUEST]: (state, { payload }) => ({ ...state, payload, minTemplateEseCancelLoaded: false }),
    [minTemplateEseCancel.SUCCESS]: (state, { payload }) => ({ ...state, minTemplateEseCancelLoaded: payload }),
    [minTemplateEseCancel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



    [minTemplateEseById.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [minTemplateEseById.SUCCESS]: (state, { payload }) => ({ ...state, minTemplateEseDetail: payload, loading: false }),
    [minTemplateEseById.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [minTemplateEseDistribution.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [minTemplateEseDistribution.SUCCESS]: (state, { payload }) => ({ ...state, minTemplateEseDistribution: payload, loading: false }),
    [minTemplateEseDistribution.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [minTemplateEseModify.REQUEST]: (state, { payload }) => ({ ...state, payload, }),
    [minTemplateEseModify.SUCCESS]: (state, { payload }) => ({ ...state, minTemplateEseModifyLoaded: payload }),
    [minTemplateEseModify.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [minTemplateEseEditJson.REQUEST]: (state, { payload }) => ({ ...state, payload, minTemplateEseEditJsonLoaded: false }),
    [minTemplateEseEditJson.SUCCESS]: (state, { payload }) => ({ ...state, minTemplateEseEditJsonLoaded: payload }),
    [minTemplateEseEditJson.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [getSaveData.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [getSaveData.SUCCESS]: (state, { payload }) => {
      return { ...state, saveData: payload, sourceSaveData: JSON.parse(JSON.stringify(payload)) }
    },
    [getSaveData.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [getTestSaveData]: (state, { payload }) => {
      return { ...state, saveData: payload, sourceSaveData: JSON.parse(JSON.stringify(payload)) }
    },

    [updateSaveData]: (state, { payload: { value = '', path, key, all } }) => {
      let { saveData } = state;

      _set(saveData, `${path}.${key}`, value)

      // 对象路径包含 common 应用到全部
      if (all) {
        for (const p in saveData.page) {
          delete saveData.page[p][key];
        }
      }

      return { ...state, saveData: Object.assign({}, { ...saveData }) }
    },
  },

  initialState
);

export default reducer;
