import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './wechatPay';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 微信管理-支付管理
 */
// 红包祝福语下拉列表
export const loadBlessingAllEpic = actions$ => actions$.pipe(
  ofType(actions.loadBlessingAll.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.wechat.pay.blessing}all`,
      }).pipe(
        map(res => actions.loadBlessingAll.SUCCESS(res.response.content)),
        catchError(error => of (actions.loadBlessingAll.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 详情
export const loadBlessingIdDetailEpic = actions$ => actions$.pipe(
  ofType(actions.loadBlessingIdDetail.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { id, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.wechat.pay.blessing}${id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.loadBlessingIdDetail.SUCCESS(res.response);
        }),
        catchError(error => of (actions.loadBlessingIdDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 创建
export const blessingCreateEpic = actions$ => actions$.pipe(
  ofType(actions.blessingCreate.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.wechat.pay.blessing }`,
        method: 'POST',
        body: JSON.stringify(action.payload)
      }).pipe(
        concatMap(res => [actions.blessingCreate.SUCCESS(res.response),  actions.loadBlessingAll.REQUEST(action.payload), actions.blessingDialogVisibale({ isBlessingDialogVisibale: false, blessingId: 0 })]),
        catchError(error => of (actions.blessingCreate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 修改
export const blessingEditEpic = actions$ => actions$.pipe(
  ofType(actions.blessingEdit.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.wechat.pay.blessing }`,
        method: 'PUT',
        body: JSON.stringify(action.payload)
      }).pipe(
        concatMap(res => [actions.blessingEdit.SUCCESS(res.response), actions.loadBlessingAll.REQUEST(action.payload), actions.blessingDialogVisibale({ isBlessingDialogVisibale: false, blessingId: 0 })]),
        catchError(error => of (actions.blessingEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

