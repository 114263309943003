import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './scancodeDetail';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// 宴会扫码
export const banquetListEpic = actions$ => actions$.pipe(
    ofType(actions.banquetList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20,startTime='',endTime='',sort = 'lotteryTime,desc',franchiserId={},shopId={},productId=[],prizeId={},officePost={},cityManagerPost={},seqConfId={},seqCode='',banquetCode=''} = action.payload;
        let franchiserIdValue = franchiserId.value||'';
        let shopIdValue = shopId.value||'';
        let productIdValue = productId.value||'';
        let prizeIdValue = prizeId.value||'';
        let officePostValue = officePost.value||'';
        let cityManagerPostValue = cityManagerPost.value||'';
        let seqConfIdValue = seqConfId.value||'';

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.scan.banquet}?page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&franchiserId=${franchiserIdValue}&shopId=${shopIdValue}&productId=${productIdValue}&prizeId=${prizeIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&seqConfId=${seqConfIdValue}&seqCode=${seqCode}&banquetCode=${banquetCode}`
        }).pipe(
            map(res => actions.banquetList.SUCCESS(res.response)),
            catchError(error => of(actions.banquetList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 消费者扫码
export const consumerListEpic = actions$ => actions$.pipe(
    ofType(actions.consumerList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20,startTime='',endTime='',sort = 'lotteryTime,desc',franchiserId={},shopId={},productId=[],prizeId={},officePost={},cityManagerPost={},seqCode=''} = action.payload;
        let franchiserIdValue = franchiserId.value||'';
        let shopIdValue = shopId.value||'';
        let productIdValue = productId.value||'';
        let prizeIdValue = prizeId.value||'';
        let officePostValue = officePost.value||'';
        let cityManagerPostValue = cityManagerPost.value||'';

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.scan.consumer}?page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&franchiserId=${franchiserIdValue}&shopId=${shopIdValue}&productId=${productIdValue}&prizeId=${prizeIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&seqCode=${seqCode}`
        }).pipe(
            map(res => actions.consumerList.SUCCESS(res.response)),
            catchError(error => of(actions.consumerList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//办事处下拉框
export const officePostSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.officePostSelection.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        return ajax({
            ...ajaxBaseConfig,
            url:`${urls.scan.officePost}`
        }).pipe(
            map(res => actions.officePostSelection.SUCCESS(res.response.content)),
            catchError(error => of(actions.officePostSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//城市经理下拉框
export const cityManagerPostSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.cityManagerPostSelection.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        return ajax({
            ...ajaxBaseConfig,
            url:`${urls.scan.cityManagerPost}`
        }).pipe(
            map(res => actions.cityManagerPostSelection.SUCCESS(res.response.content)),
            catchError(error => of(actions.cityManagerPostSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//码类型下拉框
export const tagTypeSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.tagTypeSelection.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        return ajax({
            ...ajaxBaseConfig,
            url:`${urls.scan.tagType}`
        }).pipe(
            map(res => actions.tagTypeSelection.SUCCESS(res.response.content)),
            catchError(error => of(actions.tagTypeSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);





