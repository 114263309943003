import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './scancodeStatistics';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// 扫码统计列表
export const statisticsListEpic = actions$ => actions$.pipe(
    ofType(actions.statisticsList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20,startTime='',endTime='',sort = 'tagdate,desc',franchiserId={},shopId={},productId=[],prizeId={},officePost={},cityManagerPost={}} = action.payload;
        let franchiserIdValue = franchiserId.value||'';
        let shopIdValue = shopId.value||'';
        let productIdValue = productId.value||'';
        let prizeIdValue = prizeId.value||'';
        let officePostValue = officePost.value||'';
        let cityManagerPostValue = cityManagerPost.value||'';

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.scan.statistics}?page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&franchiserId=${franchiserIdValue}&shopId=${shopIdValue}&productId=${productIdValue}&prizeId=${prizeIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}`
        }).pipe(
            map(res => actions.statisticsList.SUCCESS(res.response)),
            catchError(error => of(actions.statisticsList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 扫码统计汇总列表
export const statisticSumEpic = actions$ => actions$.pipe(
    ofType(actions.statisticSum.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime='',endTime='',dataVersion={},franchiserId=[],shopId=[],poolId={},productId=[],prizeId={},officePost={},cityManagerPost={}} = action.payload;
        let franchiserIdValue = franchiserId||'';
        let shopIdValue = shopId||'';
        let productIdValue = productId||'';
        let prizeIdValue = prizeId.value||'';
        let officePostValue = officePost.value||'';
        let cityManagerPostValue = cityManagerPost.value||'';
        let poolIdValue=poolId.value || '';
        let dataVersionValue=dataVersion.value || 'NORMAL';
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.scan.sumData}?dataVersion=${dataVersionValue}&poolId=${poolIdValue}&startTime=${startTime}&endTime=${endTime}&franchiserIds=${franchiserIdValue}&shopIds=${shopIdValue}&productIds=${productIdValue}&prizeId=${prizeIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}`
        }).pipe(
            map(res => actions.statisticSum.SUCCESS(res.response)),
            catchError(error => of(actions.statisticSum.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//筛选下载类表
export const fixedDownloadEpic = actions$ => actions$.pipe(
    ofType(actions.fixedDownload.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        const  {type = 'scan-report'} = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.scan.fixedDownload}?type=${type}`
        }).pipe(
            map(res => actions.fixedDownload.SUCCESS(res.response)),
            catchError(error => of(actions.fixedDownload.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 筛选选下载
export const selectLoadEpic = actions$ => actions$.pipe(
    ofType(actions.selectLoad.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        const {type=1,json}=action.payload;
        let url = '';
        if(type == 1){
            url = `${urls.scan.downloadStatistics}`;
        }else if(type==2){
            url = `${urls.scan.downloadBanquet}`;
        }else if(type==3){
            url = `${urls.scan.downloadConsumer}`;
        }
        return ajax({
            ...ajaxBaseConfig,
            url: url,
            method:'POST',
            body:JSON.stringify(json)
        }).pipe(
            map(res => actions.selectLoad.SUCCESS(res.response)),
            catchError(error => of(actions.selectLoad.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);




