import React from 'react';
import { connect } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { notification as Notification, PageLoading } from 'tyb';

import { AppMain, Navbar, Tabbar, Sidebar } from './components/index';
import NoPermission from '../NoPermission';
import UcodeLogin from '../Ucode/UcodeLogin';
import ExtractionCode from '../Ucode/ExtractionCode';
import queryString from 'query-string';

import ArrowRight from '@/icons/ArrowRight';
import { toggleSidebar, loadPermissions } from '@/redux/modules/ui';
import { checkOpenId, selfInfo, systemLogin } from '@/redux/modules/users/users';
import { getNowTabs } from 'ucode-utils';
import { routes } from '../../routes';
import NoMatch from '../NoMatch'
import SystemLogin from '../SystemLogin/SystemLogin';
import { all } from 'q';
import JSEncrypt from '@/libs/encrypt.js';

@connect(
  (state) => ({
    sidebarStatus: state.ui.sidebarStatus,
    backend: state.backend,
    user: state.users,
    lvThreeMenuOfLvTwo: state.ui.lvThreeMenuOfLvTwo,
    nowRouter: state.ui.nowRouter,
    notification: state.ui.notification,
    allMenuUrls: state.ui.allMenuUrls,
    userType: state.users.userType
  }),
  {
    toggleSidebar,
    loadPermissions,
    selfInfo: selfInfo.REQUEST,
    checkOpenId: checkOpenId.REQUEST,
    systemLogin: systemLogin.REQUEST
  }
)
class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extractionCode: false,
    }
  }
  componentDidMount() {
    console.log(window.localStorage.getItem('YJG_ISLOGIN'), 'window----YJG');
    const win_backend = window.__INITIAL_STATE__.backend || {};
    const password = win_backend['YJG-PWD'], account = win_backend['YJG-USER'];
    if(queryString.parse(window.location.search)?.data && password && account && !window.localStorage.getItem('YJG_ISLOGIN') && !window.location.hash.split('/')[1]) {
      var encrypt  = new JSEncrypt();
      encrypt.setPublicKey(`MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQqJ4SyujGCKwQvNzsKVMcoSQnoE60zKSPoUen7c+FRqJqineEFNhk7Hc8qWfZDbzMtIAMeg78+SgiOJ1es+KOIUOncaN0dae34QP5AdmORAm1AUXG8osmCe54SU0cnnIwS6L6RE6OIiFZzf//Eo3blf1x3sBLc6WZ/KDSqRLKCwIDAQAB`);
      var encrypted = encrypt.encrypt(password);
      let pwdObj = {account,password:encrypted};
      this.props.systemLogin({ body: pwdObj, callback: () => {
        window.localStorage.setItem('YJG_ISLOGIN', true);
      } });
      return;
    }

    const { checkOpenId, backend } = this.props;
    // 优码包提取 拼参数在url后面
    const query = queryString.parse(window.location.search);
    const { extraction_url, extractionCode } = query;
    if (extraction_url || extractionCode) {
      this.setState({ extractionCode: true });
      return;
    }

    // 查询openid
    if (process.env.NODE_ENV === 'development') {

      // checkOpenId('yan');
      // checkOpenId('123456');
      // checkOpenId('12345X');

      //五粮液单点登录逻辑
      if (backend.system || backend.system == undefined) {
        if (this.props.userType !== 'SYSTEM' && window.location.hash.split('/')[1] !== 'SystemLogin') {
          this.systemEntry();
        }
      }
      else {
        checkOpenId({ openId: backend.open_id || '' });
      }
      // if (this.props.userType !== 'SYSTEM' && window.location.hash.split('/')[1] !== 'SystemLogin') {
      //   this.systemEntry();
      // }



    } else {
      if (backend.system || !backend.open_id) {
        // 
        if (window.location.hash.split('/')[1] !== 'SystemLogin') {
          this.systemEntry();
        }
      } else {
        checkOpenId({ openId: backend.open_id || '', enterpriseId: backend.enterpriseId || '' });
      }
    }


  }

  componentDidUpdate() {

  }

  systemEntry = () => {
    const { loadPermissions, selfInfo } = this.props;

    selfInfo({
      callback: () => {
        // loadPermissions(window.location.hash.split('/')[1]); //  原来的
        loadPermissions(window.location.hash.split('/')[2]);
      }
    });
  }

  renderUcodeLogin = () => {
    return (
      <section className="app-main withtab">
        <UcodeLogin hideButton={true}></UcodeLogin>
      </section>
    )
  }

  renderAppMain = (isMenuUrl, nowUrl, allMenuUrls, routesPathArray) => {
    if (isMenuUrl) {
      if (!routesPathArray.includes(nowUrl)) {
        return this.renderUcodeLogin()
      }


      if (!allMenuUrls.includes('mallManagement')) {
        allMenuUrls.push('mallManagement')
        allMenuUrls.push('editmallinfo')
      }


      //五粮液单点登录
      if (!this.props.backend.system && this.props.backend.open_id) {
        allMenuUrls.push('dataorganization/datauseredit')
      }
      //|| window.location.host==='https://ucode-test.aax6.cn'
      if (allMenuUrls.includes(nowUrl) || nowUrl === '' || window.location.hostname == 'localhost') {
        return <AppMain></AppMain>
      }
      return this.renderUcodeLogin()
    }
    return <AppMain></AppMain>
  }

  _renderPage = () => {
    const { backend: backEnd, nowRouter, lvThreeMenuOfLvTwo, sidebarStatus, toggleSidebar, user: { openIds, registration, checkOpenIdLoaded, checkOpenIdError }, allMenuUrls, userType = 'SYSTEM' } = this.props;
    const hashUrl = window.location.hash;
    // console.log(nowRouter,'nowRouter')
    let backend;
    // if (process.env.NODE_ENV === 'development') {
    //   backend = {}
    //   backend = { system: true }
    // } else {
    backend = backEnd;
    // }
    const isMenuUrl = hashUrl.split('/').length === 2;
    // const nowUrl = hashUrl.split('/')[1].split('?')[0];
    const nowUrl = hashUrl.substring(hashUrl.lastIndexOf('/') + 1, hashUrl.includes('?') ? hashUrl.indexOf('?') : hashUrl.length);
    const tabList = getNowTabs(nowRouter, lvThreeMenuOfLvTwo)
    const tabbarStatus = tabList.length > 0;
    const routesPathArray = routes.map(v => v.path && v.path.split('/')[1]);
    switch (true) {
      // 优码包提取链接，直接显示优码包提取页面
      case window.location.pathname !== '/index-system.html' && window.location.pathname !== '/index-system-redirect.html' && window.parent === window && process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'production':
        return (<UcodeLogin />)
      case this.state.extractionCode:
        return (<ExtractionCode />);
      // 内部登录
      case (backend.system || !backend.open_id) && nowUrl === 'SystemLogin':
        //
        return (<SystemLogin />)
      // 查询到openid用下，正常显示
      case (!!openIds || !!registration) && !backend.system || (backend.system && !!openIds):
        return (<div className={`app-wrapper ${sidebarStatus ? '' : 'hideSidebar'}`} >
          <div className="sidebar-container">
            <Sidebar></Sidebar>
          </div>
          <div className="main-sidebar-button">
            {
              sidebarStatus ? '' :
                <div className="qc-menu-fold qc-menu-fold-right" onClick={() => { toggleSidebar(); }}>
                  <ArrowRight className="menu-arrow" width="16" height="16" />
                </div>
            }
          </div>
          <div className={`main-container ${tabbarStatus ? 'with-tab' : 'without-tab'}`}>
            <Navbar></Navbar>
            <Tabbar></Tabbar>
            {
              allMenuUrls ?
                this.renderAppMain(isMenuUrl, nowUrl, allMenuUrls, routesPathArray)
                : <PageLoading />
            }
            {/* <iframe
              name="showHere"
              scrolling="auto"
              width="100%"
              height="800px"
              src="https://www.baidu.com/"
            ></iframe> */}
          </div>
        </div>)
      case !!checkOpenIdError:    // 查询openid用户报错
        // return <div>{checkOpenIdError}</div>
        return <UcodeLogin hideButton={true}></UcodeLogin>
      // 没有查询到openid用下，显示平台登陆
      case checkOpenIdLoaded && !openIds && !registration:
        return (<UcodeLogin />);
    }
  }

  render() {
    return (
      <HashRouter>
        {this._renderPage()}
      </HashRouter>

    )
  }
}

export default MainLayout;

