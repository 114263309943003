import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, map, catchError, merge } from 'rxjs/operators';
import { ajax } from '../ajax';
import urls from '../../api/urls';
import { notification as Notification } from 'tyb';
import { getErrorMessageByCode } from 'ucode-utils';
import { ajaxBaseConfig, actionApi } from '../utils';
import { hasChildrenMenu, getChildrenMenu } from 'ucode-utils';
import wechatError from '@/assets/wechatError.json';
import { createHashHistory } from 'history';
import { store } from '../../index'

import queryString from 'query-string';

const moduleName = 'ui';
const initialState = {
  navbarBottomStatus: false,
  tabbarStatus: true,
  sidebarStatus: true,
  loading: false,
  loaded: false,
  permissions: [],
  nowRouter: '',
  //历史路由
  oldRouter: '',
  lvTwoMenuOfLvOne: {},
  lvThreeMenuOfLvTwo: {},
  selectedKeysOfRouter: {},
  openKeys: [],
  notification: {
    type: 'error',
    message: ''
  },
  downloadCodeDialogStatus: false,
  publishDialogStatus: false,
  consumptionDialogStatus: false,
  priorityDialogStatus: false,
  publishDialogStatusInStep: false,
  sendAgainDialogStatus: false,
  auditPassDialogStatus: false,
  notPassDialogStatus: false,
  codeParamsDetailDialogStatus: false,
  scrapDialogStatus: false,
  addOrEditTagMaterialDialogStatus: false,
  sendWayDialogStatus: false,
  permissionsSelection: [],
};

// Actions
const TOGGLE_CONSUMPTION_DIALOG = `${moduleName}/TOGGLE_CONSUMPTION_DIALOG`;
const TOGGLE_SIDEBAR = `${moduleName}/TOGGLE_SIDEBAR`;
const CHANGE_NAVBAR_BOTTOM_STATUS = `${moduleName}/CHANGE_NAVBAR_BOTTOM_STATUS`;
const CHANGE_TABBAR_STATUS = `${moduleName}/CHANGE_TABBAR_STATUS`;
const NOTIFICATION = `${moduleName}/NOTIFICATION`;

const LOAD_PERMISSIONS = `${moduleName}/LOAD_PERMISSIONS`;
const LOAD_PERMISSIONS_SUCCESS = `${moduleName}/LOAD_PERMISSIONS_SUCCESS`;
const LOAD_PERMISSIONS_FAIL = `${moduleName}/LOAD_PERMISSIONS_FAIL`;

const LOGOUTANDDISABLEDCOOKIE = `${moduleName}/LOGOUTANDDISABLEDCOOKIE`;
const LOGOUTANDDISABLEDCOOKIE_SUCCESS = `${moduleName}/LOGOUTANDDISABLEDCOOKIE_SUCCESS`;
const LOGOUTANDDISABLEDCOOKIE_FAIL = `${moduleName}/LOGOUTANDDISABLEDCOOKIE_FAIL`;

const SET_MENU_MAP = `${moduleName}/SET_MENU_MAP`;
const SET_OPEN_KEYS = `${moduleName}/SET_OPEN_KEYS`;
const SET_INIT_OPEN_KEYS_BY_ROUTER = `${moduleName}/SET_INIT_OPEN_KEYS_BY_ROUTER`;
const UPDATE_NOW_ROUTER = `${moduleName}/UPDATE_NOW_ROUTER`;

const UPDATE_DOWNLOADCODEDIALOG_STATUS = `${moduleName}/UPDATE_DOWNLOADCODEDIALOG_STATUS`;
const UPDATE_PUBLISHDIALOG_STATUS = `${moduleName}/UPDATE_PUBLISHDIALOG_STATUS`;
const UPDATE_PRIORITYDIALOG_STATUS = `${moduleName}/UPDATE_PRIORITYDIALOG_STATUS`;
const UPDATE_PUBLISHDIALOGINSTEP_STATUS = `${moduleName}/UPDATE_PUBLISHDIALOGINSTEP_STATUS`;
const SET_NAV_BAR = `${moduleName}/SET_NAV_BAR`;
const SET_lvThreeMenuOfLvTwo = `${moduleName}/SET_lvThreeMenuOfLvTwo`;  // 修改二级路由下三级路由的名字（未开始（100））
const UPDATE_SENDAGAINDIALOG_STATUS = `${moduleName}/UPDATE_SENDAGAINDIALOG_STATUS`;
const UPDATE_AUDITPASSDIALOG_STATUS = `${moduleName}/UPDATE_AUDITPASSDIALOG_STATUS`;
const UPDATE_NOTPASSDIALOG_STATUS = `${moduleName}/UPDATE_NOTPASSDIALOG_STATUS`;
const UPDATE_CODEPARAMSDETAILDIALOG_STATUS = `${moduleName}/UPDATE_CODEPARAMSDETAILDIALOG_STATUS`;
const UPDATE_SCRAPDIALOG_STATUS = `${moduleName}/UPDATE_SCRAPDIALOG_STATUS`;
const UPDATE_ADDOREDITTAGMATERIALDIALOG_STATUS = `${moduleName}/UPDATE_ADDOREDITTAGMATERIALDIALOG_STATUS`;
const UPDATE_SENDWAYDIALOG_STATUS = `${moduleName}/UPDATE_SENDWAYDIALOG_STATUS`;

const LOAD_PERMISSIONS_SELECTION = `${moduleName}/LOAD_PERMISSIONS_SELECTION`;
const LOAD_PERMISSIONS_SELECTION_SUCCESS = `${moduleName}/LOAD_PERMISSIONS_SELECTION_SUCCESS`;
const LOAD_PERMISSIONS_SELECTION_FAIL = `${moduleName}/LOAD_PERMISSIONS_SELECTION_FAIL`;




// Reducer

export default function reducer(state = initialState, action = {}) {
  let permissions, lvTwoMenuOfLvOne, lvThreeMenuOfLvTwo, lvOneMenuUrls, lvTwoMenuUrls, allMenuUrls,
    selectedKeysOfRouter, nowRouter, openKeysByRouter;
  switch (action.type) {
    case TOGGLE_CONSUMPTION_DIALOG:
      return { ...state, consumptionDialogStatus: !state.consumptionDialogStatus };
    case TOGGLE_SIDEBAR:
      return { ...state, sidebarStatus: !state.sidebarStatus };
    case CHANGE_NAVBAR_BOTTOM_STATUS:
      return { ...state, navbarBottomStatus: action.navbarBottomStatus };
    case CHANGE_TABBAR_STATUS:
      return { ...state, tabbarStatus: action.tabbarStatus };
    case LOAD_PERMISSIONS:
      return { ...state, loading: true, nowRouter: action.payload }
    case LOAD_PERMISSIONS_SUCCESS:
      return { ...state, loading: false, loaded: true, permissions: action.payload.content || [] }
    case LOAD_PERMISSIONS_FAIL:
      return { ...state, loading: false, loaded: false, error: action.payload }
    case NOTIFICATION:
      if (action.payload.type === 'success') {
        Notification[action.payload.type](action.payload.message);
      }
      if (action.payload.message) {
        if (action.payload.message.thirdCode) {
          Notification[action.payload.type](wechatError[action.payload.message.thirdCode] || action.payload.message.emsg);
        } else if (action.payload.message.ecode !== undefined && action.payload.message.ecode !== '') {
          Notification[action.payload.type](getErrorMessageByCode(action.payload.message.ecode, action.payload.message.emsg));
          const history = createHashHistory();
          if (action.payload.message.ecode === 10001 && history.location.pathname != '/SystemLogin') {
            console.log(window.location, 'location---123');
            if(queryString.parse(window.location.search)?.data){
              window.localStorage.removeItem('YJG_ISLOGIN');
            }
            history.push('/SystemLogin');
          }
        }
      }
      return { ...state }
    case SET_MENU_MAP:
      permissions = action.payload.content || [];
      lvTwoMenuOfLvOne = {};
      lvOneMenuUrls = [];
      lvTwoMenuUrls = [];
      lvThreeMenuOfLvTwo = {};
      selectedKeysOfRouter = {};
      allMenuUrls = [];
      permissions.forEach(v => {
        if (v.children && v.children.length > 0 && hasChildrenMenu(v.children)) {
          lvTwoMenuOfLvOne[v.permission] = getChildrenMenu(v.children);
        } else if (!hasChildrenMenu(v.children)) {
          lvOneMenuUrls.push(v.url);
        }
        if (v.url === '') {
          const children = v.children;
          children.forEach(x => {
            lvTwoMenuUrls.push(x.url);
            if (x.children && x.children.length > 0 && hasChildrenMenu(x.children)) {
              lvThreeMenuOfLvTwo[x.url] = getChildrenMenu(x.children);
              x.children.forEach(element => {
                allMenuUrls.push(element.url);
                selectedKeysOfRouter[element.url] = { selectedKey: x.url, selectedOpenKey: v.permission, name: JSON.parse(x.name), }  // 三级显示二级name
              });
            } else {
              allMenuUrls.push(x.url);
            }
            selectedKeysOfRouter[x.url] = { selectedKey: x.url, selectedOpenKey: v.permission, name: JSON.parse(x.name), }
          });
        } else {
          allMenuUrls.push(v.url);
          selectedKeysOfRouter[v.url] = {
            selectedOpenKey: '',
            selectedKey: v.url,
            name: JSON.parse(v.name),
          }
        }
      });

      return { ...state, lvTwoMenuOfLvOne, lvOneMenuUrls, lvTwoMenuUrls, lvThreeMenuOfLvTwo, selectedKeysOfRouter, allMenuUrls };
    case 'logout':
      return { ...state, allMenuUrls: null }
      case LOGOUTANDDISABLEDCOOKIE_SUCCESS:
        return { ...state, allMenuUrls: null }
      case LOGOUTANDDISABLEDCOOKIE:
        return { ...state }
      case LOGOUTANDDISABLEDCOOKIE_FAIL:
        return { ...state }

    case SET_OPEN_KEYS:
      return { ...state, openKeys: action.payload }
    case SET_INIT_OPEN_KEYS_BY_ROUTER:
      nowRouter = action.payload !== '' ? action.payload : state.nowRouter;
      openKeysByRouter = state.selectedKeysOfRouter[nowRouter] ? [state.selectedKeysOfRouter[nowRouter].selectedOpenKey] : [];

      return { ...state, nowRouter: nowRouter, oldRouter: state.nowRouter, openKeys: Array.from(new Set(state.openKeys.concat(openKeysByRouter))) }
    case UPDATE_NOW_ROUTER:
      return { ...state, nowRouter: action.payload, oldRouter: state.nowRouter }
    case UPDATE_DOWNLOADCODEDIALOG_STATUS:
      return { ...state, downloadCodeDialogStatus: action.payload }
    case UPDATE_PUBLISHDIALOG_STATUS:
      return { ...state, publishDialogStatus: action.payload }
    case UPDATE_PRIORITYDIALOG_STATUS:
      return { ...state, priorityDialogStatus: action.payload }
    case UPDATE_PUBLISHDIALOGINSTEP_STATUS:
      return { ...state, publishDialogStatusInStep: action.payload }
    case SET_NAV_BAR:
      window.localStorage.setItem('_navbar', action.payload);
      return { ...state, navbar: action.payload }
    case SET_lvThreeMenuOfLvTwo:
      return { ...state, lvThreeMenuOfLvTwo: action.payload }
    case UPDATE_SENDAGAINDIALOG_STATUS:
      return { ...state, sendAgainDialogStatus: action.payload }
    case UPDATE_AUDITPASSDIALOG_STATUS:
      return { ...state, auditPassDialogStatus: action.payload }
    case UPDATE_NOTPASSDIALOG_STATUS:
      return { ...state, notPassDialogStatus: action.payload }
    case UPDATE_CODEPARAMSDETAILDIALOG_STATUS:
      return { ...state, codeParamsDetailDialogStatus: action.payload }
    case UPDATE_SCRAPDIALOG_STATUS:
      return { ...state, scrapDialogStatus: action.payload }
    case UPDATE_ADDOREDITTAGMATERIALDIALOG_STATUS:
      return { ...state, addOrEditTagMaterialDialogStatus: action.payload }
    case UPDATE_SENDWAYDIALOG_STATUS:
      return { ...state, sendWayDialogStatus: action.payload }
    case LOAD_PERMISSIONS_SELECTION:
      return { ...state, selectionloading: true }
    case LOAD_PERMISSIONS_SELECTION_SUCCESS:
      const { content: children = [] } = action.payload;
      let contentJson = [], obj = {
        permission: ".*",
        name: "{\"en_US\":\"Check All\",\"zh_CN\":\"选择全部权限\"}",
        children,
      };
      contentJson.push(obj);
      const arrFormatDep = (data) => {
        let arr = [];
        data.forEach((val, index) => {
          let obj = {};
          obj = val;
          obj.title = JSON.parse(val.name).zh_CN;
          val.children && val.children.length > 0 ? obj.children = arrFormatDep(val.children) : '';
          arr.push(obj);
        })
        return arr;
      }
      // let newContent = arrFormatDep(content);
      let newContent = arrFormatDep(contentJson);
      return { ...state, selectionloading: false, permissionsSelection: newContent }
    case LOAD_PERMISSIONS_SELECTION_FAIL:
      return { ...state, selectionloading: false }
    default:
      return state;
  }
}

// Action Creators
export function toggleConsumptionDialogStatus() {
  return { type: TOGGLE_CONSUMPTION_DIALOG };
}
export function toggleSidebar() {
  return { type: TOGGLE_SIDEBAR };
}
export function changeNavbarBottomStatus(status) {
  return { type: CHANGE_NAVBAR_BOTTOM_STATUS, status };
}
export function changeTabbarStatus(status) {
  return { type: CHANGE_TABBAR_STATUS, status };
}

export function loadPermissions(nowRouter = '') {
  return { type: LOAD_PERMISSIONS, payload: nowRouter };
}

export function loadPermissionsSuccess(payload) {
  return { type: LOAD_PERMISSIONS_SUCCESS, payload };
}

export function loadPermissionsFail(payload) {
  return { type: LOAD_PERMISSIONS_FAIL, payload };
}

export function notification(message, type = 'error') {
  return { type: NOTIFICATION, payload: { type, message } }
}

export function setMenuMap(payload) {
  // davinci 特殊处理
  // 需要要求将davinci放在一级菜单，但是一级菜单需要单独的服务，无法提供，因此前端特殊处理，将davinci手动放到一级菜单
  // if (Array.isArray(payload.content)) {
  //   let toolsTree = payload.content.find(item => item.permission === 'query-tools.*');
  //   if (toolsTree) {
  //     let toolsTreeChildren = toolsTree.children;
  //     let davinciTree = toolsTreeChildren.find(item => item.permission === 'query-tools.davinci.*');
  //     if (davinciTree) {
  //       payload.content.find(item => item.permission === 'query-tools.*').children = toolsTreeChildren.filter(item => item.permission !== 'query-tools.davinci.*')
  //       payload.content.push(davinciTree);
  //     }
  //   }
  // }

  const state = store.getState();
  const users = state.users;
  const openIds = users.openIds || {};
  const id = openIds.id;

  // 再此可以做一些处理，前端根据菜单接口返回值再进行一步处理
  // todos...
  if (id === 1000074 && process.env.NODE_ENV === 'production') {
    if (Array.isArray(payload.content)) {
      payload.content = payload.content.filter(item => !(item.permission.includes('codeDataCollect.*') || item.permission.includes("codeDataApp.*") || item.permission.includes("tm.*")));
    }
  }
  return { type: SET_MENU_MAP, payload }
}

export function logout() {
  return { type: 'logout' }
}

export function logoutAndDisabledCookie(payload) {
  return { type: LOGOUTANDDISABLEDCOOKIE, payload };
}
export function logoutAndDisabledCookieSuccess(payload) {
  return { type: LOGOUTANDDISABLEDCOOKIE_SUCCESS, payload };
}
export function logoutAndDisabledCookieFail(payload) {
  return { type: LOGOUTANDDISABLEDCOOKIE_FAIL, payload };
}

export function setInitOpenKeysByRouter(nowRouter = '') {
  return { type: SET_INIT_OPEN_KEYS_BY_ROUTER, payload: nowRouter }
}

export function setOpenKeys(openKeys) {
  return { type: SET_OPEN_KEYS, payload: openKeys }
}

export function updateNowRouter(nowRouter) {
  return { type: UPDATE_NOW_ROUTER, payload: nowRouter }
}

export function updateDownloadCodeDialogStatus(status) {
  return { type: UPDATE_DOWNLOADCODEDIALOG_STATUS, payload: status }
}

export function updatePublishDialogStatus(status) {
  return { type: UPDATE_PUBLISHDIALOG_STATUS, payload: status }
}

export function updatePriorityDialogStatus(status) {
  return { type: UPDATE_PRIORITYDIALOG_STATUS, payload: status }
}

export function updatePublishDialogInStepStatus(status) {
  return { type: UPDATE_PUBLISHDIALOGINSTEP_STATUS, payload: status }
}

export function updateSendAgainDialogStatus(status) {
  return { type: UPDATE_SENDAGAINDIALOG_STATUS, payload: status }
}

export function updateAuditPassDialogStatus(status) {
  return { type: UPDATE_AUDITPASSDIALOG_STATUS, payload: status }
}

export function updateNotPassDialogStatus(status) {
  return { type: UPDATE_NOTPASSDIALOG_STATUS, payload: status }
}

export function updateCodeParamsDetailDialogStatus(status) {
  return { type: UPDATE_CODEPARAMSDETAILDIALOG_STATUS, payload: status }
}

export function updateScrapDialogStatus(status) {
  return { type: UPDATE_SCRAPDIALOG_STATUS, payload: status }
}

export function updateAddOrEditTagMaterialDialogStatus(status) {

  return { type: UPDATE_ADDOREDITTAGMATERIALDIALOG_STATUS, payload: status }
}

export function updateSendWayDialogStatus(status) {
  return { type: UPDATE_SENDWAYDIALOG_STATUS, payload: status }
}


export function setNavBar(payload) {
  return { type: SET_NAV_BAR, payload }
}

export function setlvThreeMenuOfLvTwo(payload) {
  return { type: SET_lvThreeMenuOfLvTwo, payload }
}

export function loadPermissionsSelection(payload) {
  return { type: LOAD_PERMISSIONS_SELECTION, roleType: payload || '' };
}

export function loadPermissionsSelectionSuccess(payload) {
  return { type: LOAD_PERMISSIONS_SELECTION_SUCCESS, payload };
}

export function loadPermissionsSelectionFail(payload) {
  return { type: LOAD_PERMISSIONS_SELECTION_FAIL, payload };
}




// Epics
export const loadPermissionsEpic = actions$ => actions$.pipe(
  ofType(LOAD_PERMISSIONS),
  mergeMap(action => {
    actionApi()
    const { openIds } = action;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.permissions.tree}`
    }).pipe(
      mergeMap(res => [loadPermissionsSuccess(res.response), setMenuMap(res.response), setInitOpenKeysByRouter()]),
      catchError(error => {
        console.log(error)
        return of(loadPermissionsFail(error.xhr.response), notification(error.xhr.response))
      })
    )
  }
  )
);


// 角色操作权限树
export const loadPermissionsSelectionEpic = actions$ => actions$.pipe(
  ofType(LOAD_PERMISSIONS_SELECTION),
  mergeMap(action => {
    actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.permissions.selections}?roleType=${action.roleType}`
      }).pipe(
        map(res => {
          return loadPermissionsSelectionSuccess(res.response)
        }),
        catchError(error => of(loadPermissionsSelectionFail(error.xhr.response), notification(error.xhr.response)))
      )
    )
  })
);

// 退出登录并且使cookie失效
export const logoutAndDisabledCookieEpic = actions$ => actions$.pipe(
  ofType(LOGOUTANDDISABLEDCOOKIE),
  mergeMap(action => {
    actionApi()

    const { callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.users.logoutAndDisabledCookie}`,
      method: 'DELETE'
    }).pipe(
      mergeMap(res => {
        callback && callback()
        return [logoutAndDisabledCookieSuccess()]
      }),
      catchError(error => of(logoutAndDisabledCookieFail(error.xhr.response), notification(error.xhr.response)))
    )
  }
  )
);








