import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import * as actions from './order';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// 生产订单列表
export const productionOrderListEpic = actions$ => actions$.pipe(
    ofType(actions.productionOrderList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        const { page = 0, size = 20, startTime = '', sort = 'tagdate,desc', endTime = '', category = '', factoryId = {}, termName = '', deptId = {}, productIds = [], type = 0 } = action.payload;

        let factoryIdValue = [factoryId.value] || [];
        let deptIdValue = [deptId.value] || [];
        let categoryValue = [category.value] || [];
        let productIdsValue = productIds || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/production?page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&factoryId=${factoryIdValue}&deptId=${deptIdValue}&productIds=${productIdsValue}&termName=${termName}&category=${categoryValue}&type=${type}`
        }).pipe(
            map(res => actions.productionOrderList.SUCCESS(res.response)),
            catchError(error => of(actions.productionOrderList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 生产订单指标统计
export const productionOrderStaticEpic = actions$ => actions$.pipe(
    ofType(actions.productionOrderStatic.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime = '', endTime = '', category = '', factoryId = {}, termName = '', deptId = {}, productIds = [] } = action.payload;
        let factoryIdValue = [factoryId.value] || [];
        let deptIdValue = [deptId.value] || [];
        let categoryValue = [category.value] || [];
        let productIdsValue = productIds || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/production-statistics?startTime=${startTime}&endTime=${endTime}&factoryId=${factoryIdValue}&deptId=${deptIdValue}&productIds=${productIdsValue}&termName=${termName}&category=${categoryValue}`
        }).pipe(
            map(res => actions.productionOrderStatic.SUCCESS(res.response)),
            catchError(error => of(actions.productionOrderStatic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 生产订单趋势图
export const productionOrderTendencyEpic = actions$ => actions$.pipe(
    ofType(actions.productionOrderTendency.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        let { startTime = '', endTime = '', category = '', factoryId = {}, termName = '', deptId = {}, productIds = [], type = 0, indexName = "orderNum" } = action.payload;
        let factoryIdValue = [factoryId.value] || [];
        let deptIdValue = [deptId.value] || [];
        let categoryValue = [category.value] || [];
        let productIdsValue = productIds || [];

        if (endTime - startTime > 1000 * 24 * 3600 * 30) {
            startTime = endTime - 1000 * 24 * 3600 * 30;
        } else {
            startTime = startTime;
        }

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/production-tendency?startTime=${startTime}&endTime=${endTime}&factoryId=${factoryIdValue}&deptId=${deptIdValue}&productIds=${productIdsValue}&termName=${termName}&category=${categoryValue}&type=${type}&indexName=${indexName}`
        }).pipe(
            map((res) => {
                return actions.productionOrderTendency.SUCCESS(res.response)
            }),
            catchError(error => of(actions.productionOrderTendency.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 销售订单列表
export const saleOrderListEpic = actions$ => actions$.pipe(
    ofType(actions.saleOrderList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        const { page = 0, size = 20, startTime = '', sort = 'tagdate,desc', endTime = '', category = '', deptId = {}, productIds = [], type = 0, deliveryIds = [], areaCode = '', channelCode = '', saleDeptCode = '', groupCode = '', soldIds = '' } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let categoryValue = [category.value] || [];
        let saleDeptCodeValue = [saleDeptCode.value] || [];
        let groupCodeValue = [groupCode.value] || [];
        let channelCodeValue = [channelCode.value] || [];
        let productIdsValue = productIds || [];
        let deliveryIdsValue = deliveryIds || [];
        let soldIdsValue = soldIds || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/sale?page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&deptId=${deptIdValue}&productIds=${productIdsValue}&category=${categoryValue}&type=${type}&deliveryIds=${deliveryIdsValue}&areaCode=${areaCode}&channelCode=${channelCodeValue}&saleDeptCode=${saleDeptCodeValue}&groupCode=${groupCodeValue}&soldIds=${soldIdsValue}`
        }).pipe(
            map(res => actions.saleOrderList.SUCCESS(res.response)),
            catchError(error => of(actions.saleOrderList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 销售订单指标统计
export const saleOrderStaticEpic = actions$ => actions$.pipe(
    ofType(actions.saleOrderStatic.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime = '', endTime = '', category = '', deptId = {}, productIds = [], deliveryIds = [], areaCode = '', channelCode = '', saleDeptCode = '', groupCode = '', soldIds = [] } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let categoryValue = [category.value] || [];
        let saleDeptCodeValue = [saleDeptCode.value] || [];
        let groupCodeValue = [groupCode.value] || [];
        let channelCodeValue = [channelCode.value] || [];
        let productIdsValue = productIds || [];
        let deliveryIdsValue = deliveryIds || [];
        let soldIdsValue = soldIds || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/sale-statistics?startTime=${startTime}&endTime=${endTime}&deptId=${deptIdValue}&productIds=${productIdsValue}&category=${categoryValue}&deliveryIds=${deliveryIdsValue}&areaCode=${areaCode}&channelCode=${channelCodeValue}&saleDeptCode=${saleDeptCodeValue}&groupCode=${groupCodeValue}&soldIds=${soldIdsValue}`
        }).pipe(
            map(res => actions.saleOrderStatic.SUCCESS(res.response)),
            catchError(error => of(actions.saleOrderStatic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 销售订单趋势图
export const saleOrderTendencyEpic = actions$ => actions$.pipe(
    ofType(actions.saleOrderTendency.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        let { startTime = '', endTime = '', category = '', deptId = {}, productIds = [], type = 0, indexName = "orderNum", deliveryIds = [], areaCode = '', channelCode = '', saleDeptCode = '', groupCode = '', soldIds = [] } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let categoryValue = [category.value] || [];
        let saleDeptCodeValue = [saleDeptCode.value] || [];
        let groupCodeValue = [groupCode.value] || [];
        let channelCodeValue = [channelCode.value] || [];
        let productIdsValue = productIds || [];
        let deliveryIdsValue = deliveryIds || [];
        let soldIdsValue = soldIds || [];

        if (endTime - startTime > 1000 * 24 * 3600 * 30) {
            startTime = endTime - 1000 * 24 * 3600 * 30;
        } else {
            startTime = startTime;
        }

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/sale-tendency?startTime=${startTime}&endTime=${endTime}&deptId=${deptIdValue}&productIds=${productIdsValue}&category=${categoryValue}&type=${type}&indexName=${indexName}&deliveryIds=${deliveryIdsValue}&areaCode=${areaCode}&channelCode=${channelCodeValue}&saleDeptCode=${saleDeptCodeValue}&groupCode=${groupCodeValue}&soldIds=${soldIdsValue}`
        }).pipe(
            map((res) => {
                return actions.saleOrderTendency.SUCCESS(res.response)
            }),
            catchError(error => of(actions.saleOrderTendency.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 交货单列表
export const deliveryListEpic = actions$ => actions$.pipe(
    ofType(actions.deliveryList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, startTime = '', endTime = '', sort = 'tagdate,desc', deptId = {}, category = '', productIds = [], type = 0, deliveryIds = [], channelCode = '', batchId = {}, warehouseId = {}, soldIds = '' } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let batchIdValue = [batchId.value] || [];
        let warehouseIdValue = [warehouseId.value] || [];
        let categoryValue = [category.value] || [];
        let channelCodeValue = [channelCode.value] || [];
        let productIdsValue = productIds || [];
        let deliveryIdsValue = deliveryIds || [];
        let soldIdsValue = soldIds || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/delivery?page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&deptId=${deptIdValue}&productIds=${productIdsValue}&category=${categoryValue}&type=${type}&deliveryIds=${deliveryIdsValue}&channelCode=${channelCodeValue}&batchId=${batchIdValue}&warehouseId=${warehouseIdValue}&soldIds=${soldIdsValue}`
        }).pipe(
            map(res => actions.deliveryList.SUCCESS(res.response)),
            catchError(error => of(actions.deliveryList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 交货单指标统计
export const deliveryStaticEpic = actions$ => actions$.pipe(
    ofType(actions.deliveryStatic.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime = '', endTime = '', category = '', deptId = {}, productIds = [], deliveryIds = [], channelCode = '', batchId = {}, warehouseId = {}, soldIds = '' } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let batchIdValue = [batchId.value] || [];
        let warehouseIdValue = [warehouseId.value] || [];
        let categoryValue = [category.value] || [];
        let channelCodeValue = [channelCode.value] || [];
        let productIdsValue = productIds || [];
        let deliveryIdsValue = deliveryIds || [];
        let soldIdsValue = soldIds || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/delivery-statistics?startTime=${startTime}&endTime=${endTime}&deptId=${deptIdValue}&productIds=${productIdsValue}&category=${categoryValue}&deliveryIds=${deliveryIdsValue}&channelCode=${channelCodeValue}&batchId=${batchIdValue}&warehouseId=${warehouseIdValue}&soldIds=${soldIdsValue}`
        }).pipe(
            map(res => actions.deliveryStatic.SUCCESS(res.response)),
            catchError(error => of(actions.deliveryStatic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 交货单趋势图
export const deliveryTendencyEpic = actions$ => actions$.pipe(
    ofType(actions.deliveryTendency.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        let { startTime = '', endTime = '', category = '', deptId = {}, type = 0, productIds = [], deliveryIds = [], channelCode = '', batchId = {}, warehouseId = {}, soldIds = '', indexName = 'orderNum' } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let batchIdValue = [batchId.value] || [];
        let warehouseIdValue = [warehouseId.value] || [];
        let categoryValue = [category.value] || [];
        let channelCodeValue = [channelCode.value] || [];
        let productIdsValue = productIds || [];
        let deliveryIdsValue = deliveryIds || [];
        let soldIdsValue = soldIds || [];

        if (endTime - startTime > 1000 * 24 * 3600 * 30) {
            startTime = endTime - 1000 * 24 * 3600 * 30;
        } else {
            startTime = startTime;
        }

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/delivery-tendency?startTime=${startTime}&endTime=${endTime}&type=${type}&deptId=${deptIdValue}&productIds=${productIdsValue}&category=${categoryValue}&deliveryIds=${deliveryIdsValue}&channelCode=${channelCodeValue}&batchId=${batchIdValue}&warehouseId=${warehouseIdValue}&soldIds=${soldIdsValue}&indexName=${indexName}`
        }).pipe(
            map((res) => {
                return actions.deliveryTendency.SUCCESS(res.response)
            }),
            catchError(error => of(actions.deliveryTendency.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 配送单列表
export const distributionListEpic = actions$ => actions$.pipe(
    ofType(actions.distributionList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, startTime = '', endTime = '', sort = 'tagdate,desc', deptId = {}, category = '', productIds = [], type = 0, deliveryIds = [], batchId = {}, customerCode = '', soldIds = [] } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let batchIdValue = [batchId.value] || [];
        let categoryValue = [category.value] || [];
        let productIdsValue = productIds || [];
        let deliveryIdsValue = deliveryIds || [];
        let soldIdsValue = soldIds || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/distribution?page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&deptId=${deptIdValue}&productIds=${productIdsValue}&category=${categoryValue}&type=${type}&deliveryIds=${deliveryIdsValue}&batchId=${batchIdValue}&customerCode=${customerCode}&soldIds=${soldIdsValue}`
        }).pipe(
            map(res => actions.distributionList.SUCCESS(res.response)),
            catchError(error => of(actions.distributionList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 配送单指标统计
export const distributionStaticEpic = actions$ => actions$.pipe(
    ofType(actions.distributionStatic.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime = '', endTime = '', deptId = {}, category = '', productIds = [], deliveryIds = [], batchId = {}, customerCode = '', soldIds = [] } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let batchIdValue = [batchId.value] || [];
        let categoryValue = [category.value] || [];
        let productIdsValue = productIds || [];
        let deliveryIdsValue = deliveryIds || [];
        let soldIdsValue = soldIds || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/distribution-statistics?startTime=${startTime}&endTime=${endTime}&deptId=${deptIdValue}&productIds=${productIdsValue}&category=${categoryValue}&deliveryIds=${deliveryIdsValue}&batchId=${batchIdValue}&customerCode=${customerCode}&soldIds=${soldIdsValue}`
        }).pipe(
            map(res => actions.distributionStatic.SUCCESS(res.response)),
            catchError(error => of(actions.distributionStatic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 配送单趋势图
export const distributionTendencyEpic = actions$ => actions$.pipe(
    ofType(actions.distributionTendency.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        let { startTime = '', endTime = '', deptId = {}, category = '', productIds = [], type = 0, deliveryIds = [], batchId = {}, customerCode = '', indexName = 'orderNum', soldIds = [] } = action.payload;
        let deptIdValue = [deptId.value] || [];
        let batchIdValue = [batchId.value] || [];
        let categoryValue = [category.value] || [];
        let productIdsValue = productIds || [];
        let deliveryIdsValue = deliveryIds || [];
        let soldIdsValue = soldIds || [];

        if (endTime - startTime > 1000 * 24 * 3600 * 30) {
            startTime = endTime - 1000 * 24 * 3600 * 30;
        } else {
            startTime = startTime;
        }

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/distribution-tendency?startTime=${startTime}&endTime=${endTime}&deptId=${deptIdValue}&productIds=${productIdsValue}&category=${categoryValue}&deliveryIds=${deliveryIdsValue}&batchId=${batchIdValue}&customerCode=${customerCode}&indexName=${indexName}&type=${type}&soldIds=${soldIdsValue}`
        }).pipe(
            map((res) => {
                return actions.distributionTendency.SUCCESS(res.response)
            }),
            catchError(error => of(actions.distributionTendency.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 分销单列表
export const distributorListEpic = actions$ => actions$.pipe(
    ofType(actions.distributorList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, startTime = '', endTime = '', sort = 'tagdate,desc', category = '', productIds = [], type = 0, deliveryIds = [], franchiserId = [], productType = '', soldIds = [] } = action.payload;
        let franchiserIdValue = franchiserId || [];
        let categoryValue = [category.value] || [];
        let productTypeValue = [productType.value] || [];
        let productIdsValue = productIds || [];
        let deliveryIdsValue = deliveryIds || [];
        let soldIdsValue = soldIds || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/distributor?page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&productIds=${productIdsValue}&category=${categoryValue}&type=${type}&deliveryIds=${deliveryIdsValue}&franchiserIds=${franchiserIdValue}&productType=${productTypeValue}&soldIds=${soldIdsValue}`
        }).pipe(
            map(res => actions.distributorList.SUCCESS(res.response)),
            catchError(error => of(actions.distributorList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 分销单指标统计
export const distributorStaticEpic = actions$ => actions$.pipe(
    ofType(actions.distributorStatic.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime = '', endTime = '', category = '', productIds = [], deliveryIds = [], franchiserId = [], productType = '',soldIds = [] } = action.payload;
        let franchiserIdValue = franchiserId || [];
        let categoryValue = [category.value] || [];
        let productTypeValue = [productType.value] || [];
        let productIdsValue = productIds || [];
        let deliveryIdsValue = deliveryIds || [];
        let soldIdsValue = soldIds || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/distributor-statistics?startTime=${startTime}&endTime=${endTime}&productIds=${productIdsValue}&category=${categoryValue}&deliveryIds=${deliveryIdsValue}&franchiserIds=${franchiserIdValue}&productType=${productTypeValue}&soldIds=${soldIdsValue}`
        }).pipe(
            map(res => actions.distributorStatic.SUCCESS(res.response)),
            catchError(error => of(actions.distributorStatic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 分销单趋势图
export const distributorTendencyEpic = actions$ => actions$.pipe(
    ofType(actions.distributorTendency.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        let { startTime = '', endTime = '', type = 0, category = '', productIds = [], deliveryIds = [], franchiserId = [], productType = '', indexName = 'orderNum',soldIds = [] } = action.payload;
        let franchiserIdValue = franchiserId || [];
        let categoryValue = [category.value] || [];
        let productTypeValue = [productType.value] || [];
        let productIdsValue = productIds || [];
        let deliveryIdsValue = deliveryIds || [];
        let soldIdsValue = soldIds || [];

        if (endTime - startTime > 1000 * 24 * 3600 * 30) {
            startTime = endTime - 1000 * 24 * 3600 * 30;
        } else {
            startTime = startTime;
        }

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/distributor-tendency?startTime=${startTime}&endTime=${endTime}&productIds=${productIdsValue}&category=${categoryValue}&deliveryIds=${deliveryIdsValue}&franchiserIds=${franchiserIdValue}&productType=${productTypeValue}&type=${type}&indexName=${indexName}&soldIds=${soldIdsValue}`
        }).pipe(
            map((res) => {
                return actions.distributorTendency.SUCCESS(res.response)
            }),
            catchError(error => of(actions.distributorTendency.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 宴会列表
export const banquetListEpic = actions$ => actions$.pipe(
    ofType(actions.banquetList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, startTime = '', endTime = '', sort = 'tagdate,desc', brandNames = [], type = 0, shopId = [], areaCode = '', areaType = '', banquetType = '', franchiserId = [] } = action.payload;
        let shopIdValue = shopId || [];
        let banquetTypeValue = [banquetType.value] || [];
        let franchiserIdValue = franchiserId || [];
        let brandNamesValue = brandNames || [];
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/banquet?page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&brandNames=${brandNamesValue}&type=${type}&shopIds=${shopIdValue}&areaCode=${areaCode}&areaType=${areaType}&banquetType=${banquetTypeValue}&franchiserIds=${franchiserIdValue}`
        }).pipe(
            map(res => actions.banquetList.SUCCESS(res.response)),
            catchError(error => of(actions.banquetList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 宴会指标统计
export const banquetStaticEpic = actions$ => actions$.pipe(
    ofType(actions.banquetStatic.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime = '', endTime = '', brandNames = [], shopId = [], areaCode = '', areaType = '', banquetType = '', franchiserId = [] } = action.payload;
        let shopIdValue = shopId || [];
        let banquetTypeValue = [banquetType.value] || [];
        let franchiserIdValue = franchiserId || [];
        let brandNamesValue = brandNames || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/banquet-statistics?startTime=${startTime}&endTime=${endTime}&brandNames=${brandNamesValue}&shopIds=${shopIdValue}&areaCode=${areaCode}&areaType=${areaType}&banquetType=${banquetTypeValue}&franchiserIds=${franchiserIdValue}`
        }).pipe(
            map(res => actions.banquetStatic.SUCCESS(res.response)),
            catchError(error => of(actions.banquetStatic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 宴会趋势图
export const banquetTendencyEpic = actions$ => actions$.pipe(
    ofType(actions.banquetTendency.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        let { startTime = '', endTime = '', brandNames = [], shopId = [], areaCode = '', areaType = '', banquetType = '', indexName = 'deskNum', type = 0, franchiserId = [] } = action.payload;
        let shopIdValue = shopId || [];
        let banquetTypeValue = [banquetType.value] || [];
        let franchiserIdValue = franchiserId || [];
        let brandNamesValue = brandNames || [];

        if (endTime - startTime > 1000 * 24 * 3600 * 30) {
            startTime = endTime - 1000 * 24 * 3600 * 30;
        } else {
            startTime = startTime;
        }

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/banquet-tendency?startTime=${startTime}&endTime=${endTime}&brandNames=${brandNamesValue}&shopIds=${shopIdValue}&areaCode=${areaCode}&areaType=${areaType}&banquetType=${banquetTypeValue}&indexName=${indexName}&type=${type}&franchiserIds=${franchiserIdValue}`
        }).pipe(
            map((res) => {
                return actions.banquetTendency.SUCCESS(res.response)
            }),
            catchError(error => of(actions.banquetTendency.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 其他列表
export const otherListEpic = actions$ => actions$.pipe(
    ofType(actions.otherList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, startTime = '', endTime = '', sort = 'tagdate,desc', factoryId = {}, batchId = {}, warehouseId = {}, franchiserId = [], productIds = [], type = 0, category = '' } = action.payload;
        let factoryIdValue = [factoryId.value] || [];
        let batchIdValue = [batchId.value] || [];
        let warehouseIdValue = [warehouseId.value] || [];
        let franchiserIdValue = franchiserId || [];
        let categoryValue = [category.value] || [];
        let productIdsValue = productIds || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/other?page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&productIds=${productIdsValue}&category=${categoryValue}&type=${type}&franchiserIds=${franchiserIdValue}&factoryId=${factoryIdValue}&warehouseId=${warehouseIdValue}&batchId=${batchIdValue}`
        }).pipe(
            map(res => actions.otherList.SUCCESS(res.response)),
            catchError(error => of(actions.otherList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 其他指标统计
export const otherStaticEpic = actions$ => actions$.pipe(
    ofType(actions.otherStatic.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime = '', endTime = '', factoryId = {}, batchId = {}, warehouseId = {}, franchiserId = [], productIds = [], category = '', } = action.payload;
        let factoryIdValue = [factoryId.value] || [];
        let batchIdValue = [batchId.value] || [];
        let warehouseIdValue = [warehouseId.value] || [];
        let franchiserIdValue = franchiserId || [];
        let categoryValue = [category.value] || [];
        let productIdsValue = productIds || [];

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/other-statistics?startTime=${startTime}&endTime=${endTime}&category=${categoryValue}&productIds=${productIdsValue}&franchiserIds=${franchiserIdValue}&factoryId=${factoryIdValue}&warehouseId=${warehouseIdValue}&batchId=${batchIdValue}`
        }).pipe(
            map(res => actions.otherStatic.SUCCESS(res.response)),
            catchError(error => of(actions.otherStatic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 其他趋势图
export const otherTendencyEpic = actions$ => actions$.pipe(
    ofType(actions.otherTendency.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        let { startTime = '', endTime = '', factoryId = {}, batchId = {}, warehouseId = {}, franchiserId = [], productIds = [], indexName = 'orderNum', type = 0, category = '' } = action.payload;
        let factoryIdValue = [factoryId.value] || [];
        let batchIdValue = [batchId.value] || [];
        let warehouseIdValue = [warehouseId.value] || [];
        let franchiserIdValue = franchiserId || [];
        let categoryValue = [category.value] || [];
        let productIdsValue = productIds || [];
        if (endTime - startTime > 1000 * 24 * 3600 * 30) {
            startTime = endTime - 1000 * 24 * 3600 * 30;
        } else {
            startTime = startTime;
        }

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.commonOrder}/other-tendency?startTime=${startTime}&endTime=${endTime}&productIds=${productIdsValue}&category=${categoryValue}&franchiserIds=${franchiserIdValue}&factoryId=${factoryIdValue}&warehouseId=${warehouseIdValue}&batchId=${batchIdValue}&indexName=${indexName}&type=${type}`
        }).pipe(
            map((res) => {
                return actions.otherTendency.SUCCESS(res.response)
            }),
            catchError(error => of(actions.otherTendency.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 销售部门下拉
export const saleDeptCodeListEpic = actions$ => actions$.pipe(
    ofType(actions.saleDeptCodeList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.reportOrder}/salesDept`
        }).pipe(
            map(res => actions.saleDeptCodeList.SUCCESS(res.response)),
            catchError(error => of(actions.saleDeptCodeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 销售组下拉
export const saleTeamCodeListEpic = actions$ => actions$.pipe(
    ofType(actions.saleTeamCodeList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.reportOrder}/salesTeam`
        }).pipe(
            map(res => actions.saleTeamCodeList.SUCCESS(res.response)),
            catchError(error => of(actions.saleTeamCodeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 售达方下拉
export const getDeliveryIdsSelectListEpic = actions$ => actions$.pipe(
    ofType(actions.getDeliveryIdsSelectList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        let { name = ''} = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.deliveryIds}?name=${name}`
        }).pipe(
            map(res => actions.getDeliveryIdsSelectList.SUCCESS(res.response.content)),
            catchError(error => of(actions.getDeliveryIdsSelectList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 送达方下拉
export const getSoldIdsSelectListEpic = actions$ => actions$.pipe(
    ofType(actions.getSoldIdsSelectList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        let { name = ''} = action.payload;
        
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.soldIds}?name=${name}`
        }).pipe(
            map(res => actions.getSoldIdsSelectList.SUCCESS(res.response.content)),
            catchError(error => of(actions.getSoldIdsSelectList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 品牌下拉
export const brandsEpic = actions$ => actions$.pipe(
    ofType(actions.brands.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        let { name = ''} = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.order.brands}?name=${name}`
        }).pipe(
            map(res => actions.brands.SUCCESS(res.response.content)),
            catchError(error => of(actions.brands.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);