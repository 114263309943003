import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './batchInformation';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const loadApplyUnauditedsEpic = actions$ => actions$.pipe(
  ofType(actions.loadApplyUnauditeds.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { idOrName = '', applyStart = '', applyEnd = '', factoryIds = '', page = 0, size = 20, sort = 'id,desc', eseSeqnumStart = '', eseSeqnumEnd = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.batchInformations.applyUnauditeds}?query=${idOrName}&eseSeqnumStart=${eseSeqnumStart}&eseSeqnumEnd=${eseSeqnumEnd}&startTime=${applyStart}&endTime=${applyEnd}&registerStatus=${factoryIds ? factoryIds.length != 0 ? factoryIds : 'UNREGISTERED,REGISTERED,REVOCATION_ACTIVATION' : 'UNREGISTERED,REGISTERED,REVOCATION_ACTIVATION'}&page=${page}&size=${size}&sort=${sort}` //
    }).pipe(
      map(res => actions.loadApplyUnauditeds.SUCCESS(res.response)),
      catchError(error => of(actions.loadApplyUnauditeds.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const loadApplyPassesEpic = actions$ => actions$.pipe(
  ofType(actions.loadApplyPasses.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { idOrName = '', applyStart = '', applyEnd = '', factoryIds = '', page = 0, size = 20, sort = 'id,desc', eseSeqnumStart = '', eseSeqnumEnd = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.batchInformations.applyUnauditeds}?query=${idOrName}&eseSeqnumStart=${eseSeqnumStart}&eseSeqnumEnd=${eseSeqnumEnd}&startTime=${applyStart}&endTime=${applyEnd}&registerStatus=${factoryIds ? factoryIds.length != 0 ? factoryIds : 'TO_BE_ACTIVATED,ACTIVATED' : 'TO_BE_ACTIVATED,ACTIVATED'}&page=${page}&size=${size}&sort=${sort}` //
    }).pipe(
      map(res => actions.loadApplyPasses.SUCCESS(res.response)),
      catchError(error => of(actions.loadApplyPasses.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const loadInfoEpic = actions$ => actions$.pipe(
  ofType(actions.loadInfo.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.batchInformations.info(action.payload),
    }).pipe(
      map(res => actions.loadInfo.SUCCESS(res.response)),
      catchError(error => of(actions.loadInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


export const loadHistoryEpic = actions$ => actions$.pipe(
  ofType(actions.loadHistory.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.batchInformations.history(action.payload),
    }).pipe(
      map(res => actions.loadHistory.SUCCESS(res.response)),
      catchError(error => of(actions.loadHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
export const batchEnterEpic = actions$ => actions$.pipe(
  ofType(actions.batchEnter.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      body: data,
      url: `${urls.code.batchInformations.applyUnauditeds}`
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.batchEnter.SUCCESS(res)
      }),
      catchError(error => of(actions.batchEnter.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


export const batchActiveEpic = actions$ => actions$.pipe(
  ofType(actions.batchActive.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      body: data,
      url: `${urls.code.batchInformations.active}`
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.batchActive.SUCCESS(res)
      }),
      catchError(error => of(actions.batchActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const revocationEpic = actions$ => actions$.pipe(
  ofType(actions.revocation.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      body: data,
      url: `${urls.code.batchInformations.revocation}`
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.revocation.SUCCESS(res)
      }),
      catchError(error => of(actions.revocation.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const checkEpic = actions$ => actions$.pipe(
  ofType(actions.check.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'GET',
      body: data,
      url: urls.code.batchInformations.check(data)
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.check.SUCCESS(res)
      }),
      catchError(error => of(actions.check.FAIL(error.xhr.response)) //, uiActions.notification(error.xhr.response)
      )
    )
  }
  )
);

export const applet_settingsEpic = actions$ => actions$.pipe(
  ofType(actions.applet_settings.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      body: data,
      url: `${urls.code.batchInformations.applet_settings}`
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.applet_settings.SUCCESS(res)
      }),
      catchError(error => of(actions.applet_settings.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const query_settingsEpic = actions$ => actions$.pipe(
  ofType(actions.query_settings.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback, failCallback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      // method: 'PUT',
      body: data,
      url: `${urls.code.batchInformations.query_settings}/${data.id}`
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback(res)
        return actions.query_settings.SUCCESS(res)
      }),
      catchError(error => {
        failCallback && failCallback();
        return of(actions.query_settings.FAIL(error.xhr.response), uiActions.notification(error.xhr.response.thirdCode && error.xhr.response.thirdCode == 80304 ? '' : error.xhr.response))
      }
      )
    )
  }
  )
);


// export const loadApplyReturnsEpic = actions$ => actions$.pipe(
//   ofType(actions.loadApplyReturns.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     const { idOrName = '', applyStart = '', applyEnd = '', factoryIds = '', page = 0, size = 20, sort = '' } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       url: `${urls.code.batchApplys.applyReturns}?idOrName=${idOrName}&applyStart=${applyStart}&applyEnd=${applyEnd}&factoryIds=${factoryIds}&page=${page}&size=${size}&sort=${sort}`
//     }).pipe(
//       map(res => actions.loadApplyReturns.SUCCESS(res.response)),
//       catchError(error => of(actions.loadApplyReturns.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )}
//   )
// );

// export const sendBatchsEpic = actions$ => actions$.pipe(
//   ofType(actions.sendBatchs.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     return ajax({
//       ...ajaxBaseConfig,
//       url: urls.code.batchApplys.sendBatchs(action.payload),
//     }).pipe(
//       mergeMap(res => actions.sendBatchs.SUCCESS(res.response)),
//       catchError(error => of(actions.sendBatchs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )}
//   )
// );

// export const loadAuditUnauditedsEpic = actions$ => actions$.pipe(
//   ofType(actions.loadAuditUnauditeds.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     const { idOrName = '', applyStart = '', applyEnd = '', auditStart = '', auditEnd = '', factoryIds = '', page = 0, size = 20, sort = '' } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       url: `${urls.code.batchApplys.auditUnauditeds}?idOrName=${idOrName}&applyStart=${applyStart}&applyEnd=${applyEnd}&auditStart=${auditStart}&auditEnd=${auditEnd}&factoryIds=${factoryIds}&page=${page}&size=${size}&sort=${sort}` //
//     }).pipe(
//       map(res => actions.loadAuditUnauditeds.SUCCESS(res.response)),
//       catchError(error => of(actions.loadAuditUnauditeds.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )}
//   )
// );

// export const loadAuditPassesEpic = actions$ => actions$.pipe(
//   ofType(actions.loadAuditPasses.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     const { idOrName = '', applyStart = '', applyEnd = '', factoryIds = '', page = 0, size = 20, sort = '' } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       url: `${urls.code.batchApplys.auditPasses}?idOrName=${idOrName}&applyStart=${applyStart}&applyEnd=${applyEnd}&factoryIds=${factoryIds}&page=${page}&size=${size}&sort=${sort}` //
//     }).pipe(
//       map(res => actions.loadAuditPasses.SUCCESS(res.response)),
//       catchError(error => of(actions.loadAuditPasses.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )}
//   )
// );

// export const auditEpic = actions$ => actions$.pipe(
//   ofType(actions.audit.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     return ajax({
//       ...ajaxBaseConfig,
//       method: 'POST',
//       url: urls.code.batchApplys.audit,
//       body: action.payload
//     }).pipe(
//       mergeMap(res => [actions.audit.SUCCESS(res.response), uiActions.updateNotPassDialogStatus(false)]),
//       catchError(error => of(actions.audit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )}
//   )
// );

