import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import { debug } from 'util';


const moduleName = 'miniProgramEseRelations';

/**
 * 小程序用户: 
 */
export const loadMpuser = fasterActions(moduleName, 'loadMpuser');
export const editMpuser = fasterActions(moduleName, 'editMpuser');
export const enableOrDisabledMpuser = fasterActions(moduleName, 'enableOrDisabledMpuser');
export const deleteMpuser = fasterActions(moduleName, 'deleteMpuser');
export const getTokenMpuser = fasterActions(moduleName, 'getTokenMpuser');

export const updateCurrentAppId = createAction(`${moduleName}/updateCurrentAppId`);

const initialState = {
    mpuserList: [],
    perPageCountList: [20, 50, 100],
    currentAppid: '',
    mpuserParam: {
        word: '',
        page: 0,
        size: 20,
        sort: 'createdTime,desc',
        totalElements: 0
    }
}

const reducer = handleActions(
    {

        // 小程序用户列表
        [loadMpuser.REQUEST]: (state, { payload }) => ({ ...state, ...payload, loaddingMpuser: true }),
        [loadMpuser.SUCCESS]: (state, { payload }) => ({ ...state, mpuserList: payload.content, loaddingMpuser: false, mpuserParam: {...state.mpuserParam, totalElements: payload.totalElements || 0}}),
        [loadMpuser.FAIL]: (state, { payload }) => ({ ...state, error: payload, loaddingMpuser: false }),
        // 修改小程序用户
        [editMpuser.REQUEST]: (state, { payload }) => ({ ...state }),
        [editMpuser.SUCCESS]: (state, { payload }) => ({ ...state }),
        [editMpuser.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        // 修改小程序用户
        [enableOrDisabledMpuser.REQUEST]: (state, { payload }) => ({ ...state }),
        [enableOrDisabledMpuser.SUCCESS]: (state, { payload }) => ({ ...state }),
        [enableOrDisabledMpuser.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        
        // 解绑，删除小程序用户
        [deleteMpuser.REQUEST]: (state, { payload }) => ({ ...state }),
        [deleteMpuser.SUCCESS]: (state, { payload }) => ({ ...state }),
        [deleteMpuser.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
       
        // 绑定小程序用户
        [getTokenMpuser.REQUEST]: (state, { payload }) => ({ ...state, updateTokenLoading: true }),
        [getTokenMpuser.SUCCESS]: (state, { payload }) => ({ ...state, token: payload,  updateTokenLoading: false }),
        [getTokenMpuser.FAIL]: (state, { payload }) => ({ ...state, error: payload, updateTokenLoading: false }),

        [updateCurrentAppId]: (state, { payload }) => ({...state, ...payload}),

    },

    initialState
);

export default reducer;
