import React from 'react';

import { AppRouter } from '../../../routes';
import { notification as Notification } from 'tyb';
import FrameworkLayout from '@/layout/FrameworkLayout';

// 微应用列表
const { apps: mockAppsConfig = [] } = require('../../../mock/apps'); // 本地调试用
const mockAppsPath = mockAppsConfig.map(v => v.activePath && v.activePath.split('/')[1]);

class AppMain extends React.Component {

  componentDidUpdate() {

  }

  render() {
    const isDavinci = location.hash.includes('davinci')
    const isMockApp = mockAppsPath.includes(location.hash.split("/")[1]);
    return (
      <section className={`app-main withtab ${isDavinci ? 'davinci-main' : ''}`}>
        <div className="main ice-main">
          {isMockApp ? <FrameworkLayout /> : AppRouter()}

          {/* {AppRouter()}
          <FrameworkLayout /> */}
        </div>
      </section>
    );
  }
}

export default AppMain;

