import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'workOrder';

/**
 * TODO: 工单服务
 */

export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);

// 数据源下拉
export const dataSourceList = fasterActions(moduleName, 'dataSourceList');
// 业务字段列表
export const businessField = fasterActions(moduleName, 'businessField');
// 业务维度列表
export const businessDimension = fasterActions(moduleName, 'businessDimension');
// 提交用单
export const work = fasterActions(moduleName, 'work');
// 工单管理统计数据
export const indexStatistics = fasterActions(moduleName, 'indexStatistics');
// 工单管理列表
export const list = fasterActions(moduleName, 'list');
// 工单管理查看
export const detail = fasterActions(moduleName, 'detail');
//工单管理
export const action = fasterActions(moduleName, 'action');
//工单管理结果
export const result = fasterActions(moduleName, 'result');
//工单状态查询
export const orderStatus = fasterActions(moduleName, 'orderStatus');
//草稿工单信息
export const draft = fasterActions(moduleName, 'draft');

const initialState = {
  businessType: [],
  businessFieldData: [],
  businessDimensionData: [],
  indexStatisticsData:{},
  listData:[],
  listLoading:true,
  detailData:{},
  draftData:{},
  resultData:{},
  actionData:{},
  pageParams: {
    page: 1,
    pageSize: 20,
    startTime: '',
    endTime: ''
  },
}

const reducer = handleActions(
  {
    [changeDictPaging]: (state, { payload }) => {
      return { ...state, pageParams: { ...payload } }
    },

    [dataSourceList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [dataSourceList.SUCCESS]: (state, { payload }) => ({ ...state, businessType: payload, loading: false }),
    [dataSourceList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [businessField.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [businessField.SUCCESS]: (state, { payload }) => ({ ...state, businessFieldData: payload, loading: false }),
    [businessField.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [businessDimension.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [businessDimension.SUCCESS]: (state, { payload }) => ({ ...state, businessDimensionData: payload, loading: false }),
    [businessDimension.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [work.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [work.SUCCESS]: (state, { payload }) => ({ ...state, payload, loading: false }),
    [work.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [indexStatistics.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [indexStatistics.SUCCESS]: (state, { payload }) => ({ ...state, indexStatisticsData: payload}),
    [indexStatistics.FAIL]: (state, { payload }) => ({ ...state}),

    [list.REQUEST]: (state, { payload }) => ({ ...state, payload,listLoading:true }),
    [list.SUCCESS]: (state, { payload }) => ({ ...state, listData: payload,listLoading:false}),
    [list.FAIL]: (state, { payload }) => ({ ...state,listLoading:false}),

    [detail.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [detail.SUCCESS]: (state, { payload }) => ({ ...state, detailData: payload}),
    [detail.FAIL]: (state, { payload }) => ({ ...state}),

    [result.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [result.SUCCESS]: (state, { payload }) => ({ ...state, resultData: payload}),
    [result.FAIL]: (state, { payload }) => ({ ...state}),

    [action.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [action.SUCCESS]: (state, { payload }) => ({ ...state, actionData: payload}),
    [action.FAIL]: (state, { payload }) => ({ ...state}),

    [draft.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [draft.SUCCESS]: (state, { payload }) => ({ ...state, draftData: payload}),
    [draft.FAIL]: (state, { payload }) => ({ ...state}),

    [orderStatus.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [orderStatus.SUCCESS]: (state, { payload }) => ({ ...state, payload}),
    [orderStatus.FAIL]: (state, { payload }) => ({ ...state}),
  },
  initialState
);

export default reducer;
