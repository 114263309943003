import { store as layoutStore } from '@ice/stark-data';

import * as ui from './ui';
import * as promotions from './promotions/promotions';
import * as pools from './pools/pools';
import * as prizeRegister from './prizeRegister/prizeRegister';
import * as pointCash from './pointCash/pointCash';


import * as prizeManager from './prizeManager/prizeManager';

// 页面管理
import * as liveManageMent from './liveManageMent/liveManageMent';

// import wechat, { authorizerConfigsEpic } from './wechat';

import * as dataOverview from './dataOverview/dataOverview';
import * as realTime from './realTime/realTime';
import * as amountBill from './amountBill/amountBill';
import * as marketing from './marketing/marketing';
import * as reportResource from './reportResource/reportResource';


import * as products from './products/products';
import * as enterprises from './enterprises/enterprises';
//图片库
import * as uploadLibrary from './uploadLibrary/uploadLibrary';


import * as tagbatchConfigs from './tagbatchConfigs/tagbatchConfigs';
import * as prizePointTypes from './prizePointTypes/prizePointTypes';
import * as prizeCardTypes from './prizeCardTypes/prizeCardTypes';
import * as coupon from './coupon/coupon';
import * as wechatPay from './wechatPay/wechatPay';
import * as wechat from './wechat/wechat';
import backend from './backend/backend';
import * as consumptions from './consumptions/consumptions';
import * as plan from './plan/plan';
import * as award from './award/award';
import * as zone from './zone/zone';
//防伪明细
import * as security from './security/security';
import * as whiteOff from './whiteOff/whiteOff';


import * as targetAll from './targetAll/targetAll';



import * as dataScreen from './dataScreen/dataScreen';


import * as member from './member/member';
import * as membershipSystem from './membershipSystem/membershipSystem';
import * as memberManage from './memberManage/memberManage';
import * as memberTask from './memberTask/memberTask';

import * as batchInformation from './batchInformation/batchInformation';
import * as seqnum from './seqnum/seqnum';

import * as accessories from './accessories/accessories';

import * as links from './links/links';
import * as fileds from './fileds/fileds';
//

import * as addressBooks from './addressBooks/addressBooks';

import * as domainConfigs from './domainConfigs/domainConfigs';
import * as miniProgramConfig from './miniProgramConfig/miniProgramConfig';
import * as miniProgramDevelopers from './miniProgramDevelopers/miniProgramDevelopers';
import * as miniProgramMaterials from './miniProgramMaterials/miniProgramMaterials';
import * as minProgramTemplate from './minProgramTemplate/minProgramTemplate';
import * as merchant from './merchant/merchant';

import * as miniProgramParameter from './miniProgramParameter/miniProgramParameter';



import * as users from './users/users';
import * as factory from './factory/factory';
// 单据
import * as certificate from './certificate/certificate';
import * as brand from './brand/brand';
import * as h5CodePackage from './h5CodePackage/h5CodePackage';
import * as batchApply from './batchApply/batchApply';
import * as enterpriseinfo from './enterpriseinfo/enterpriseinfo';
import * as datadealers from './datadealers/datadealers';

import * as billInfomation from './billInfomation/billInfomation';

import * as contractInfomation from './contractInfomation/contractInfomation';

import * as activityInfomation from './activityInfomation/activityInfomation';

import * as terminalManagement from './terminalManagement/terminalManagement';

// 预警服务
import * as warnEvents from './warnEvents/warnEvents';
import * as warnRecipients from './warnRecipients/warnRecipients';
import * as warnRecords from './warnRecords/warnRecords';



// 工具箱
import * as queryTools from './queryTools/queryTools';
import * as codeQueryTool from './codeQueryTool/codeQueryTool';

import * as pinCodeRecord from './pinCodeRecord/pinCodeRecord';

import * as miniProgramRelease from './miniProgramRelease/miniProgramRelease';
import * as miniProgramTesters from './miniProgramTesters/miniProgramTesters';

import * as operationTools from './operationTools/operationTools';

import * as codePrintingConfig from './codePrintingConfig/codePrintingConfig';

import * as codeSource from './codeSource/codeSource';
import * as thirdPartCodeHandle from './thirdPartCodeHandle/thirdPartCodeHandle';

import * as batchScrap from './batchScrap/batchScrap';

import * as customFields from './customFields/customFields';

import * as codingRegister from './codingRegister/codingRegister';
// 组织架构  角色
import * as role from './role/role';
import * as categories from './categories/categories';
import * as tagbatch from './tagbatch/tagbatch';
import * as departments from './departments/departments';
import * as dataAllots from './dataAllots/dataAllots';

import * as codeLabel from './codeLabel/codeLabel';
// 内部
import * as codeTempConfig from './codeTempConfig/codeTempConfig';
import * as codedb from './codedb/codedb';
//码包上传
import * as codeUpload from './codeUpload/codeUpload';

// 小程序开通工具
import * as miniProgramEseRelations from './miniProgramEseRelations/miniProgramEseRelations';
import * as mpUsers from './mpUsers/mpUsers';

import * as isvUser from './isvUser/isvUser';

import * as sendWay from './sendWay/sendWay';

// 企业管理平台-码数据管理
import * as codeDataManagement from './codeDataManagement/codeDataManagement';
// 数据字典
import * as dictionary from './dictionary/dictionary';

//字段分配
import * as fieldAssign from './fieldassign/fieldassign';

//工单服务
import * as workOrder from './workOrder/workOrder';


//经销商分组管理
import * as dealerGroup from './dealerGroup/dealerGroup';

//环节流转
import * as linkCirculate from './linkCirculate/linkCirculate';

//异常监控
import * as anticounterfeiting from './anticounterfeiting/anticounterfeiting';


//订单统计
import * as order from './order/order';

//扫码明细
import * as scancodeDetail from './scancodeDetail/scancodeDetail';

//扫码统计
import * as scancodeStatistics from './scancodeStatistics/scancodeStatistics';

//窜货明细
import * as flowDetail from './flowDetail/flowDetail';

//仓库
import * as warehouse from './warehouse/warehouse';

//水井坊单码查询工具类型
import * as singleCodeQueryToolType from './singleCodeQueryToolType/singleCodeQueryToolType';

//渠道
import * as channel from './channel/channel';

import * as channelBill from './channelBill/channelBill';

//渠道
import * as precinct from './precinct/precinct';

// 积分商城
import * as pointMall from './pointMall/pointMall';
// import * as pointMall from './mallCreate/create';
//
import * as pointMallOrder from './pointMallOrder/pointMallOrder';
import * as pointMallPresents from './pointMallPresents/pointMallPresents';

// 返利
import * as rebate from './rebate/rebate';
// 移动账号
import * as identification from './identification/identification';
// 移动端管理
import * as mobileManagement from './mobileManagement/mobileManagement';

//码标签统计
import * as tagStatistics from './tagStatistics/tagStatistics';

//码明细
import * as codeDetail from './codeDetail/codeDetail';

//返利统计
import * as reportRebate from './reportRebate/reportRebate';
// 防黑管理
import * as antiblack from './antiblack/antiblack';


// 实物物流管理
import * as physicalDelivery from './physicalDelivery/physicalDelivery';

// 消息模板
import * as messageTemplate from './messageTemplate/messageTemplate'

// 消息管理
import * as newsManage from './newsManage/newsManage'

// 订阅消息
import * as subscriber from './subscriber/subscriber'


import * as cityRank from './cityRank/cityRank';

// 批货审批
import * as whPurchaseApproval from "./whPurchaseApproval/whPurchaseApproval"

import * as codeCodeverify from './codeCodeverify/codeCodeverify';

// 特色活动
import * as characteristic from './characteristic/characteristic'
// 品牌红包配置
import * as brandRedpackConfig from './brandRedpackConfig/brandRedpackConfig';


layoutStore.set('commonAction', {
  promotions,
  products,
  enterprises,
  uploadLibrary,

  tagbatchConfigs,
  ui,
  pools,
  prizeRegister,
  pointCash,
  prizeManager,
  liveManageMent,
  wechat,
  backend,
  prizePointTypes,
  prizeCardTypes,
  scancodeStatistics,
  coupon,
  wechatPay,
  consumptions,
  reportRebate,
  plan,
  award,
  zone,
  security,
  whiteOff,
  flowDetail,
  targetAll,
  dataScreen,
  member,
  membershipSystem,
  memberManage,
  memberTask,
  addressBooks,
  domainConfigs,
  miniProgramConfig,
  miniProgramDevelopers,
  miniProgramMaterials,
  minProgramTemplate,
  miniProgramParameter,
  merchant,
  users,
  queryTools,
  codeQueryTool,
  pinCodeRecord,
  operationTools,
  factory,
  certificate,
  brand,
  batchApply,
  h5CodePackage,
  reportRebate,
  batchInformation,
  seqnum,
  accessories,
  enterpriseinfo,
  datadealers,
  codePrintingConfig,
  miniProgramRelease,
  batchScrap,
  customFields,
  codingRegister,
  role,
  categories,
  dataOverview,
  realTime,
  miniProgramTesters,
  tagbatch,
  departments,
  dataAllots,
  amountBill,
  marketing,
  reportResource,
  codeTempConfig,
  codedb,
  codeLabel,
  codeUpload,
  miniProgramEseRelations,
  mpUsers,
  links,
  fileds,
  codeSource,
  warnEvents,
  warnRecipients,
  warnRecords,
  thirdPartCodeHandle,
  isvUser,
  sendWay,
  codeDataManagement,
  dictionary,
  fieldAssign,
  workOrder,
  dealerGroup,
  linkCirculate,
  order,
  scancodeDetail,
  scancodeStatistics,
  warehouse,
  channel,
  precinct,
  channelBill,
  billInfomation,
  contractInfomation,
  activityInfomation,
  terminalManagement,
  pointMall,
  pointMallOrder,
  pointMallPresents,
  anticounterfeiting,
  scancodeDetail,
  codeDetail,
  tagStatistics,
  rebate,
  identification,
  mobileManagement,
  antiblack,
  physicalDelivery,
  subscriber,
  newsManage,
  messageTemplate,
  singleCodeQueryToolType,
  cityRank,
  whPurchaseApproval,
  codeCodeverify,
  characteristic,
  brandRedpackConfig,
});
