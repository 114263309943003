import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'reportRebate';

/**
 * TODO: 
 */

export const getDealerPage = fasterActions(moduleName, 'getDealerPage');
export const getShopPage = fasterActions(moduleName, 'getShopPage');
export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const getMechanicPage = fasterActions(moduleName, 'getMechanicPage');
export const getAllRebateActive = fasterActions(moduleName, 'getAllRebateActive');
export const getAllRebateAccount = fasterActions(moduleName, 'getAllRebateAccount');
export const getAllCase = fasterActions(moduleName, 'getAllCase');
const initialState = {
    pageData:[],
    pageParams:{
      page:0,
      size:20,
      startTime:'',
      endTime:''
    },
    officePostData:[],
    cityManagerPostData:[],
    tagTypeData:[],
    loading:false,
    tableData:{},
    getAllRebateActivelist:[],
    getAllRebateAccountlist:[],
    getAllCaselist:[]
}
const merchantSelect = ( obj={} ) => {
  console.log(obj)
  obj.content.map((item) => {
    item.mechanicName=item.mechanicName?(item.mechanicName+(item.eseUserId?'('+item.eseUserId+')':'')):(item.eseUserId?item.eseUserId:'');
  });
  return obj;
};

const reducer = handleActions(
  { 
    [changeDictPaging]: (state, { payload }) => {
        return { ...state, pageParams: { ...payload} }
    },

    [getDealerPage.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [getDealerPage.SUCCESS]: (state, { payload }) => ({ ...state, tableData: payload, loading: false }),
    [getDealerPage.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [getShopPage.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [getShopPage.SUCCESS]: (state, { payload }) => ({ ...state, tableData: payload, loading: false }),
    [getShopPage.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [getMechanicPage.REQUEST]: (state, { payload }) => ({ ...state,loading: true}),
    [getMechanicPage.SUCCESS]: (state, { payload }) => ({ ...state, tableData:merchantSelect(payload), loading: false }),
    [getMechanicPage.FAIL]: (state, { payload }) => ({ ...state}),

    [getAllRebateActive.REQUEST]: (state, { payload }) => ({ ...state,}),
    [getAllRebateActive.SUCCESS]: (state, { payload }) => ({ ...state, getAllRebateActivelist: payload, loading: false }),
    [getAllRebateActive.FAIL]: (state, { payload }) => ({ ...state}),

    [getAllRebateAccount.REQUEST]: (state, { payload }) => ({ ...state,}),
    [getAllRebateAccount.SUCCESS]: (state, { payload }) => ({ ...state, getAllRebateAccountlist: payload, loading: false }),
    [getAllRebateAccount.FAIL]: (state, { payload }) => ({ ...state}),

    [getAllCase.REQUEST]: (state, { payload }) => ({ ...state,}),
    [getAllCase.SUCCESS]: (state, { payload }) => ({ ...state, getAllCaselist: payload, loading: false }),
    [getAllCase.FAIL]: (state, { payload }) => ({ ...state}),

  },
  initialState
);

export default reducer;
