import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
//不要encode
import { ajax as noecAjax } from 'rxjs/ajax';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './pointCash';
import { ajaxBaseConfig, actionApi } from '../../utils';

// Epics
export const getPrizeInfoTableDataEpic = actions$ => actions$.pipe(
    ofType(actions.getPointCashTable.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, sort = '', keyWords = '', userId = '', pointType = '', exportStatus = [], withdrawlTime = { from: '', to: '' }, status = [] } = action.payload;
        let theStatus = status;
        if (status.length >= 2) {
            theStatus = [];
        }
        return noecAjax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.withdrawl.withdrawl}?page=${page}&size=${size}&sort=${sort}&userId=${userId}&phonenumber=${keyWords ? encodeURIComponent(keyWords) : ''}&pointType=${pointType}&withdrawlTime=${withdrawlTime.to ? withdrawlTime.from + ',' + withdrawlTime.to : ''}&exportStatus=${exportStatus}&status=${theStatus}`
        }).pipe(
            map(res => actions.getPointCashTable.SUCCESS(res.response)),
            catchError(error => of(actions.getPointCashTable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 奖品登记 */
export const patchRegisterEpic = actions$ => actions$.pipe(
    ofType(actions.patchRegister.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, updateExpress, callback } = action.payload;
        const { id, expressName = '', expressNo = '', adminRemark = '' } = data;
        return ajax({
            ...ajaxBaseConfig,
            method: 'GET',
            body: data,
            url: `${urls.promotion.withdrawl.withdrawl}/${id}/remark?id=${id}&remark=${adminRemark}&expressName=${expressName}&expressNo=${expressNo}&updateExpress=${updateExpress}`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.patchRegister.SUCCESS(res.response)
            }),
            catchError(error => of(actions.patchRegister.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


/* 导出 */
export const exportRegisterEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.exportRegister.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        const { page = 0, size = 20, sort = '', keyWords = '', userId = '', pointType = '', exportStatus = [], status = [], withdrawlTime = '', exportRemark = '', } = data;
        let theStatus = status;
        if (status.length >= 2) {
            theStatus = [];
        }
        return ajax({
            ...ajaxBaseConfig,
            // url: `${urls.promotion.withdrawl.export}?page=${page}&size=${size}&sort=${sort}&keyWords=${keyWords}&prizeType=${prizeType}&exportStatus=${exportStatus}&status=${status}&withdrawlTime=${withdrawlTime}&email=602019979@qq.com`
            url: `${urls.promotion.withdrawl.withdrawl}/export?page=${page}&size=${size}&sort=${sort}&userId=${userId}&phonenumber=${keyWords ? encodeURIComponent(keyWords) : ''}&pointType=${pointType}&exportStatus=${exportStatus}&status=${theStatus}&withdrawlTime=${withdrawlTime}&exportRemark=${exportRemark}`
        }).pipe(
            mergeMap(res => {
                callback && callback(res.status);
                return [actions.exportRegister.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.exportRegister.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
