import React from 'react';
import { Dialog, Button, DataTable, Pager, Tip, TabList, BubbleWrapper } from 'tyb';
import { connect } from 'react-redux';
import { getFileList, delFile, downFile } from '@/redux/modules/users/users';
import { formatDate } from 'ucode-utils';
import './FileCenter.scss';
import enums from '@/assets/enum.json';
import urls from '@/api/urls';
@connect(
    state => {
        return {
            loadingFile: state.users.loadingFile,
            fileList: state.users.fileList,
        }
    },
    {
        getFileList: getFileList.REQUEST,
        delFile: delFile.REQUEST,
        downFile: downFile.REQUEST,
    }
)
class FileCenter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            params: {
                taskEvents: '',
                taskStatus: '',
                page: 0,
                size: 20,
                sort: 'id,desc',
                sort2: 'taskStatus,desc',
            },
            tableColumns: this.getTableColumns(),
            perPageCountList: [20, 50, 100],

            listType: {
                list: [
                    //     {
                    //     text: '文件上传',
                    //     value: 'IMPORT'
                    // },
                    {
                        text: '文件下载',
                        value: 'EXPORT'
                    },],

            },
            listTypeValue: 'EXPORT',
            filterType: [],
            filterStatus: [],
        }
    }

    componentDidMount() {
        this.props.getFileList({
            data: {
                ...this.state.params,
                taskType: this.state.listTypeValue
            }
        })
    }

    tableHeadInit = (list) => {
        return list.map(column => {

            if (column.key === 'taskEvent') {
                return {
                    ...column,
                    headerRender: this.renderTypeFilterHeader
                };
            }
            else if (column.key === 'taskStatus') {
                return {
                    ...column,
                    headerRender: this.renderStatusFilterHeader
                };
            }
            else {
                return column;
            }
        });
    };

    renderTypeFilterHeader = (col) => {
        const list = Object.keys(enums.taskEvent).map(item => { return { value: item, text: enums.taskEvent[item].name } }).filter(key => key.text.indexOf('导出') >= 0)

        return (
            <DataTable.FilterableColumnHeader
                list={list}
                value={this.state.filterType}
                onFilter={this.handleTypeFilter}
            >
                {col.title}
            </DataTable.FilterableColumnHeader>
        )
    }
    handleTypeFilter = (value) => {
        const taskEvents = value.toString();
        const params = this.state.params;
        params.taskEvents = taskEvents;
        params.page = 0;
        this.setState({
            params,
            filterType: value
        });

        this.props.getFileList({
            data: {
                ...this.state.params,
                taskType: this.state.listTypeValue
            }
        })
    }

    renderStatusFilterHeader = (col) => {
        const list = [
            {
                text: '导出中',
                value: 'INIT'
            },
            {
                text: '未下载',
                value: 'SUCCESS'
            },
            {
                text: '已下载',
                value: 'COMPLETE'
            },
            {
                text: '导出超时',
                value: 'FAIL'
            }
        ];

        // const list = Object.keys(enums.taskStatus).map(item => { return { value: item, text: enums.taskStatus[item].name } })
        return (
            <DataTable.FilterableColumnHeader
                list={list}
                value={this.state.filterStatus}
                onFilter={this.handleStatusFilter}
            >
                {col.title}
            </DataTable.FilterableColumnHeader>
        )
    }
    handleStatusFilter = (value) => {
        const taskStatus = value.toString();
        const params = this.state.params;
        params.taskStatus = taskStatus;
        params.page = 0;
        this.setState({
            params,
            filterStatus: value
        });

        this.props.getFileList({
            data: {
                ...this.state.params,
                taskType: this.state.listTypeValue
            }
        })
    }


    getTableColumns = () => {
        const tableHeadArr = [
            {
                key: 'fileName',
                dataIndex: 'fileName',
                title: '文件名称',
            },
            {
                key: 'fileSize',
                dataIndex: 'fileSize',
                title: '文件大小',
                render: (value, record) => <div> {value ? value / 1024 >= 1024 ? value / 1024 / 1024 >= 1024 ? (value / 1024 / 1024 / 1024).toFixed(2) + 'GB' : (value / 1024 / 1024).toFixed(2) + 'MB' : (value / 1024).toFixed(2) + 'KB' : '0KB'}</div>

            },
            {
                key: 'createdTime',
                dataIndex: 'createdTime',
                title: '导出时间',
                render: (value, record) => <div className="moreline-text-ellipsis" title={value}>{formatDate(value)}</div>
            },
            {
                key: 'taskEvent',
                dataIndex: 'taskEvent',
                title: '导出事件',
                render: (value, record) => <div> {value ? enums.taskEvent[value] ? enums.taskEvent[value].name : "" : ""}</div>

            },
            {
                key: 'remark',
                dataIndex: 'remark',
                title: '备注',

            },
            {
                key: 'taskStatus',
                dataIndex: 'taskStatus',
                title: '状态',
                render: (value, record) => <div>
                    {value == "INIT" ?
                        <span style={{ color: '#FF7800' }}>导出中</span>
                        : value == "SUCCESS" ? <span style={{ color: '#00C156' }}>未下载</span>
                            : value == "COMPLETE" ? <span >已下载</span>
                                : value == "FAIL" ? <span style={{ color: '#E1504A' }}>导出超时</span> : ''}



                </div>
                // {value ? enums.taskStatus[value].name : ""}
            },
            {
                key: 'option',
                dataIndex: 'option',
                title: '操作',
                render: (value, record) => <div>
                    {/* {record.taskStatus == "FAIL" ?
                        <React.Fragment>
                            <BubbleWrapper position="top" content={record.taskLog}>
                                <span style={{ color: '#006EFF', cursor: 'pointer', marginRight: 10 }} >原因</span>
                            </BubbleWrapper>

                            <span style={{ color: '#006EFF', cursor: 'pointer', verticalAlign: 'middle' }} onClick={() => { window.location.href = record.fileUrl }}>原文件</span>
                        </React.Fragment>
                        : ''} */}
                    {record.taskStatus == "SUCCESS" || record.taskStatus == "COMPLETE" || record.taskStatus == "INIT" ?
                        <React.Fragment>
                            <span style={{ color: '#006EFF', cursor: 'pointer', marginRight: 10 }} onClick={() => { this.todownload(record) }}>下载</span>
                            <span style={{ color: '#006EFF', cursor: 'pointer' }} onClick={() => {
                                this.props.delFile({
                                    data: { id: record.id }, callback: () => {
                                        this.props.getFileList({
                                            data: {
                                                ...this.state.params,
                                                taskType: this.state.listTypeValue
                                            }
                                        })
                                    }
                                })
                            }}>删除</span>
                        </React.Fragment>
                        : ''}
                    {record.taskStatus == "FAIL" ?
                        <React.Fragment>
                            <span style={{ color: '#006EFF', cursor: 'pointer' }} onClick={() => {
                                this.props.delFile({
                                    data: { id: record.id }, callback: () => {
                                        this.props.getFileList({
                                            data: {
                                                ...this.state.params,
                                                taskType: this.state.listTypeValue
                                            }
                                        })
                                    }
                                })
                            }}>删除</span>
                        </React.Fragment>
                        : ''}
                </div>
            },
        ]
        return tableHeadArr;
    }

    getColumns = () => {
        return this.state.tableColumns.map(column => {
            return column;
        });
    }

    listTypeChange = (val) => {
        if (val != this.state.listTypeValue) {
            this.setState({ listTypeValue: val })
            this.props.getFileList({
                data: {
                    ...this.state.params,
                    taskType: val
                }
            })
        }
    }

    onPageChange = (val) => {
        const { params } = this.state;
        params.page = val;
        this.setState({ params });
        this.props.getFileList({
            data: {
                ...this.state.params,
                taskType: this.state.listTypeValue
            }
        })
    }

    onPerPageChange = (val) => {
        const params = this.state.params;
        params.size = val;
        params.page = 0;
        this.setState({
            params
        });
        this.props.getFileList({
            data: {
                ...this.state.params,
                taskType: this.state.listTypeValue
            }
        })
    }


    todownload = (record) => {
        this.props.downFile({
            data: {
                id: record.id,
            },
            callback: (res) => {
                // console.log(res.result)
                top.location.href = res.result
            }
        })
        // const { protectUserType = '', user = '', protectType, protectStatus = '', blackReason = '', page = 0, size = 20, sort = 'id,desc', dataRange: { from = '', to = '' } = {}, } = this.state.params;
        //  let url = `${urls.data.users.files}/${record.id}/file`;
        //  location.href = url;
    }

    render() {
        const { listTypeValue, listType, params } = this.state;
        const { visible, handleCancel, fileList = {}, loadingFile } = this.props;

        let listStyle = fileList.content && fileList.content.length > 0 && !loadingFile;

        return (
            <Dialog
                className="file-center"
                style={{ width: '1000px' }}
                title="文件中心"
                visible={visible}
                onOk={() => { handleCancel() }}
                onCancel={() => { handleCancel() }}
            >
                <Tip visible hasClose={false} type="warning">{listTypeValue == 'EXPORT' ? '文件将在导出后90天自动清除，请尽快下载！' : '文件将在导出后90天自动清除！'}</Tip>
                <TabList
                    value={listTypeValue}
                    list={listType.list}
                    onChange={(val) => { this.listTypeChange(val) }}
                />
                <div className="searchbar-box">
                    <Button onClick={() => {
                        this.props.getFileList({
                            data: {
                                ...this.state.params,
                                taskType: this.state.listTypeValue
                            }
                        })

                    }}>刷新</Button>
                </div>
                <DataTable
                    data={fileList.content}
                    columns={this.tableHeadInit(this.getColumns())}
                    rowKey="id"
                    loading={loadingFile}
                    messageRender={({ data, error }) => {
                        if (error != null) { return error.msg; }
                        if (data == null || data.length === 0) {
                            return <span className="text-weak">暂无数据</span>;
                        }
                    }}
                />


                {listStyle ? <div className="page-padding20">
                    <Pager
                        total={fileList.totalElements}
                        current={params.page}
                        perPageCount={params.size}
                        perPageCountList={this.state.perPageCountList}
                        onPageChange={this.onPageChange}
                        onPerPageChange={this.onPerPageChange}
                    />
                </div> : ''}
            </Dialog>
        )
    }
}

export default FileCenter;
