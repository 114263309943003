import React from "react";

const SvgHint = props => (
  <svg width={15} height={15} {...props}>
    <g fill="#888" fillRule="nonzero">
      <path d="M7.042.917a7.041 7.041 0 1 0 0 14.083 7.042 7.042 0 0 0 0-14.083zm0 12.583A5.548 5.548 0 0 1 1.5 7.958a5.548 5.548 0 0 1 5.542-5.542 5.548 5.548 0 0 1 5.542 5.542A5.55 5.55 0 0 1 7.042 13.5z" />
      <path d="M8 7H5v1h1v4h2l1-1H8zM6 4h2v2H6z" />
    </g>
  </svg>
);

export default SvgHint;
