import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'tagStatistics';

/**
 * TODO: 
 */

export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const tagTeamList = fasterActions(moduleName, 'tagTeamList');
export const tagList= fasterActions(moduleName, 'tagList');
export const tagInitSum = fasterActions(moduleName, 'tagInitSum');
export const tagUseSum = fasterActions(moduleName, 'tagUseSum');

const initialState = {
  pageData:[],
  tagUseData:[],
  pageParams:{
    page:0,
    size:20,
    startTime:'',
    endTime:'',
    
  },
  tagList:[],
  loading:false,
  tagTeamList:[]
}

const reducer = handleActions(
  { 
    [changeDictPaging]: (state, { payload }) => {
        return { ...state, pageParams: { ...payload} }
    },

    [tagList.REQUEST]: (state, { payload }) => ({ ...state, payload, }),
    [tagList.SUCCESS]: (state, { payload }) => {
        let tagList= [];
        for (let elem of payload.content) {
            const obj = { ...elem, value: elem.type, text: elem.type_name }
            tagList.push(obj);
        }
        return ({ ...state, tagList})
    },
    [tagList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [tagInitSum.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [tagInitSum.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [tagInitSum.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [tagUseSum.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [tagUseSum.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [tagUseSum.FAIL]: (state, { payload }) => ({ ...state, pageData: false }),

    [tagTeamList.REQUEST]: (state, { payload }) => ({ ...state, tagTeamList: [], loading: true }),
    [tagTeamList.SUCCESS]: (state, { payload }) => ({ ...state, tagTeamList: payload, loading: false }),
    [tagTeamList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

  },
  initialState
);

export default reducer;
