import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
//不要encode
import { ajax as noecAjax } from 'rxjs/ajax';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './pools';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// Epics
export const getPoolTableDataEpic = actions$ => actions$.pipe(
    ofType(actions.getPoolTable.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page, size, word, poolStatus, poolUseStatus, promotionCategories = '', promotionKeyWords = '' } = action.payload;
        let poolUseStatuses = '', poolStatuses = '';
        poolUseStatus.map(val => { if (val != null) poolUseStatuses += `poolUseStatuses=${val}&`; })
        poolStatus.map(val => { if (val != null) poolStatuses += `poolStatuses=${val}&` })
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.pools.pools}?page=${page}&size=${size}&${poolUseStatuses}${poolStatuses}word=${word}&promotionIds=${promotionKeyWords}&promotionCategories=${promotionCategories == 'no' ? '' : promotionCategories}`
        }).pipe(
            map(res => actions.getPoolTable.SUCCESS(res.response)),
            catchError(error => of(actions.getPoolTable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const deletePoolIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.deletePool.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            method: 'DELETE',
            url: `${urls.promotion.pools.delete}/${action.payload}`
        }).pipe(
            mergeMap(res => [actions.deletePool.SUCCESS(res), actions.getPoolTable.REQUEST(state$.value.pools.poolTableSearch)]),
            catchError(error => of(actions.deletePool.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
)

/* pool-edit */
export const getPrizeListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPrizeList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            // url: `${urls.promotion.prizes.prizes}?page=${542}&size=${20}&type=&keyWords=&sort=asc` // 479  542
            url: `${urls.promotion.prizes.prizes}`
        }).pipe(
            map(res => actions.getPrizeList.SUCCESS(res.response)),
            catchError(error => of(actions.getPrizeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
)
// 奖品复制
export const poolCopyEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.poolCopy.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, remark, nextParam = {}, callback } = action.payload;
        return ajax({
            url: `${urls.promotion.pools.pools}/copy`,
            method: 'POST',
            ...ajaxBaseConfig,
            body: { poolId: id, name: remark },
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.poolCopy.SUCCESS(res.response), actions.getPoolTable.REQUEST(state$.value.pools.poolTableSearch)];
            }),
            catchError(error => of(actions.poolCopy.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )

    })
);



//多奖池
export const getPoolGroupEpic = actions$ => actions$.pipe(
    ofType(actions.getPoolGroup.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page, size, word, poolStatus, poolUseStatus, sort } = action.payload;
        let poolUseStatuses = '', poolStatuses = '';
        poolUseStatus.map(val => { if (val != null) poolUseStatuses += `poolUseStatuses=${val}&`; })
        poolStatus.map(val => { if (val != null) poolStatuses += `poolStatuses=${val}&` })
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.pools.poolGroup}?page=${page}&size=${size}&sort=${sort}&${poolUseStatuses}${poolStatuses}word=${word}`
        }).pipe(
            map(res => actions.getPoolGroup.SUCCESS(res.response)),
            catchError(error => of(actions.getPoolGroup.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
//多奖池停用启用
export const enableOrDisabledEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableOrDisabled.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id = '', status = '' } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.promotion.pools.poolGroup}/${status}/${id}`,
                method: 'PATCH',
            }).pipe(
                concatMap(res => [actions.enableOrDisabled.SUCCESS(res.response), actions.getPoolGroup.REQUEST(state$.value.pools.poolGroupTableSearch)]),
                catchError(error => of(actions.enableOrDisabled.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
//多奖池删除
export const deletePoolGroupEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.deletePoolGroup.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            method: 'DELETE',
            url: `${urls.promotion.pools.poolGroup}/${action.payload}`
        }).pipe(
            mergeMap(res => [actions.deletePoolGroup.SUCCESS(res), actions.getPoolGroup.REQUEST(state$.value.pools.poolGroupTableSearch)]),
            catchError(error => of(actions.deletePoolGroup.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
)
//多奖池新增
export const postPoolGroupEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.postPoolGroup.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: data,
            url: `${urls.promotion.pools.poolGroup}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.postPoolGroup.SUCCESS(res.response), actions.getPoolGroup.REQUEST(state$.value.pools.poolGroupTableSearch)]
            }),
            catchError(error => of(actions.postPoolGroup.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const patchPoolGroupEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.patchPoolGroup.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            body: data,
            url: `${urls.promotion.pools.poolGroup}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.patchPoolGroup.SUCCESS(res.response), actions.getPoolGroup.REQUEST(state$.value.pools.poolGroupTableSearch)]
            }),
            catchError(error => of(actions.patchPoolGroup.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)



//预算


export const budgetEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.budget.REQUEST.toString()),
    mergeMap(action => {
        actionApi()

        const { poolData, id, merchantBudget } = action.payload;
        const { totalBudget, cashBudget } = poolData;

        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            body: {},
            // url: `${urls.promotion.pools.budget}/${id}?totalBudget=${poolData.totalBudget}`
            url: `${urls.promotion.pools.budget}/${id}?totalBudget=${totalBudget}&cashBudget=${cashBudget}`
        }).pipe(
            concatMap(res => {
                return [actions.budget.SUCCESS(res.response), actions.postPoolPrize.REQUEST({ ...action.payload, merchantBudget })]
                //
            }),
            catchError(error => of(actions.budget.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const putBudgetEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putBudget.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolData, callback } = action.payload;
        const { totalBudget, cashBudget } = poolData;

        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            body: {},
            // url: `${urls.promotion.pools.budget}/${poolData.id}?totalBudget=${poolData.totalBudget}`
            url: `${urls.promotion.pools.budget}/${poolData.id}?totalBudget=${totalBudget}&cashBudget=${cashBudget}`,
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.putBudget.SUCCESS(res.response)
            }),
            catchError(error => of(actions.putBudget.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


/* 商户号充值预算 */
export const rechargeBudgetEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.rechargeBudget.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, merchantBudget, callback, remark = '' } = action.payload;
        const { merchantRechargeBudget, exceedChargeOptionType = 'SEND_PRIZE' } = merchantBudget;

        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            body: {},
            url: `${urls.promotion.pools.recharge}/${id}?merchantRechargeBudget=${merchantRechargeBudget}&exceedChargeOptionType=${exceedChargeOptionType}`,
        }).pipe(
            // map(res => {
            //     callback && callback(res.response);
            //     return actions.rechargeBudget.SUCCESS(res.response)
            // }),
            concatMap(res => {
                if (remark) {
                    return [actions.rechargeBudget.SUCCESS(res.response), actions.postPoolPrize.REQUEST({ ...action.payload })]
                } else {
                    return [actions.rechargeBudget.SUCCESS(res.response)]
                }
            }),
            catchError(error => of(actions.rechargeBudget.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)






/* 
** 创建修改奖池奖品
*/
export const postPoolEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.postPool.REQUEST.toString()),
    mergeMap(action => {
        actionApi()

        const { poolData, prizeList = [], merchantBudget, history, quanxian = false } = action.payload;
        const { merchantRechargeBudget, exceedChargeOptionType = 'SEND_PRIZE', warningValue } = merchantBudget;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: { ...poolData, merchantRechargeBudget, exceedChargeOptionType, warningValue },
            url: `${urls.promotion.pools.pools}`
        }).pipe(
            concatMap(res => {
                if (quanxian) {
                    return [actions.postPool.SUCCESS(res.response), actions.budget.REQUEST({ ...res.response, prizeList, history, poolData, merchantBudget })]
                }
                else {
                    return [actions.postPool.SUCCESS(res.response), actions.postPoolPrize.REQUEST({ ...res.response, prizeList, history, merchantBudget })]
                }
            }),
            catchError(error => of(actions.postPool.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const putPoolEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putPoolName.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolData, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            body: poolData,
            url: `${urls.promotion.pools.pools}`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.putPoolName.SUCCESS(res.response)
            }),
            catchError(error => of(actions.putPoolName.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 
** 批量创建、修改奖池
*/
export const postPoolPrizeEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.postPoolPrize.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, eseId, prizeList, remark = '', history, appId, merchantNumber, merchantId, merchantBudget } = action.payload;
        const { merchantRechargeBudget, exceedChargeOptionType = 'SEND_PRIZE', warningValue } = merchantBudget;
        let myPrizeList = JSON.parse(JSON.stringify(prizeList));
        const reform = (obj, keyArr = []) => {
            let newObj = {};
            Object.keys(obj).forEach(key => {
                const val = obj[key];
                if (!keyArr.includes(key)) {
                    newObj[key] = val;
                }
            })
            return newObj;
        }
        if (prizeList.length) {
            myPrizeList.map((item, index, self) => {
                item.poolId = id; item.eseId = eseId; item.requireWarning = !item.requireWarning; item.putCount = Number(item.putCount);
                let obj = {};
                if (item.prizeId) {
                    obj = reform(item, ['text', 'prizeCoupon', 'disabled', 'codePutCount', 'prizeRedPacket', 'prizeChangeOfMoney']);
                } else {
                    item.prizeId = item.id;
                    obj = reform(item, ['text', 'id']);
                }
                return self[index] = obj;
            })
        }
        let myPoolPrizes = { poolId: id, poolPrizes: myPrizeList };
        if (remark) {
            myPoolPrizes = { ...myPoolPrizes, remark, appId, merchantNumber, merchantId, merchantRechargeBudget, exceedChargeOptionType, warningValue };
        }

        const myUrl = remark ? `${urls.promotion.poolPrizes.edit}` : `${urls.promotion.poolPrizes.create}`;
        return ajax({
            ...ajaxBaseConfig,
            method: remark ? 'PUT' : 'POST',
            body: myPoolPrizes,
            url: myUrl
        }).pipe(
            // map(res => {
            //     history.push('/promotionpool');
            //     return actions.postPoolPrize.SUCCESS(res);
            // }),
            concatMap(res => {
                history.push('/promotionpool');
                return [actions.postPoolPrize.SUCCESS(res)]
                // if (remark) {
                //     return [actions.postPoolPrize.SUCCESS(res)]
                // } else {
                //     return [actions.postPoolPrize.SUCCESS(res), actions.rechargeBudget.REQUEST({ id, merchantBudget })]
                // }
            }),
            catchError(error => of(actions.postPoolPrize.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 
**  获取奖池奖品详情
 */
export const getPoolIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolDataId.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolId, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.pools.pools}/${poolId}`
        }).pipe(
            mergeMap(res => {
                return [actions.getPoolDataId.SUCCESS(res.response), actions.getPoolPrizeDataId.REQUEST({ poolData: res.response, poolId, callback })]
            }),
            catchError(error => of(actions.getPoolDataId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getPoolPrizeDataIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolPrizeDataId.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolId, poolData, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.poolPrizes.poolPrizes}/${poolId}`
        }).pipe(
            map(res => {
                const { content: prizeList = [] } = res.response;
                callback && callback({ poolData, prizeList, remark: poolData.remark || '' });
                return actions.getPoolPrizeDataId.SUCCESS(res.response);
            }),
            catchError(error => of(actions.getPoolPrizeDataId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 
** 奖池历史版本
*/
export const getPoolHistoryTableEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolHistoryTable.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolId, page, size } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.poolHistorys.pool}/${poolId}?page=${page}&size=${size}`
        }).pipe(
            map(res => {
                return actions.getPoolHistoryTable.SUCCESS(res.response);
            }),
            catchError(error => of(actions.getPoolHistoryTable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getPoolHistoryInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolHistoryInfo.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolId, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.poolHistorys.poolHistorys}/${poolId}`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.getPoolHistoryInfo.SUCCESS(res.response);
            }),
            // catchError(error => of(actions.getPoolHistoryInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 
** 校验奖品是否可被删除
*/
export const checkPrizeDeleteEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.checkPrizeDelete.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.poolPrizes.check}/${id}`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.checkPrizeDelete.SUCCESS(res.response);
            }),
            catchError(error => of(actions.checkPrizeDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
/* 
** 奖池树，用于会员中心，自定义标签
*/
export const getPoolTreeEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolTree.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { promotionIds = '', callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.pools.pools}/selections${promotionIds ? '?promotionIds=' + promotionIds : ''}`
        }).pipe(
            map(res => {

                callback && callback(res.response.content || []);
                return actions.getPoolTree.SUCCESS(res.response.content || []);
            }),
            catchError(error => of(actions.getPoolTree.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const getPoolTreeMoreEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolTreeMore.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { promotionIds = '', callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.pools.pools}/selections${promotionIds ? '?promotionIds=' + promotionIds : ''}`
        }).pipe(
            map(res => {
                callback && callback(res.response.content || []);
                return actions.getPoolTreeMore.SUCCESS(res.response.content || []);
            }),
            catchError(error => of(actions.getPoolTreeMore.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
/* 
**  不统计奖池奖品列表
 */
export const poolPageByEseIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.poolPageByEseId.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { eseId, page, size } = action.payload;
        return ajax({
            ...apiBaseConfig,
            url: `${urls.promotion.poolPrizes.poolPrizes}/${eseId}/page?page=${page}&size=${size}`
        }).pipe(
            map(res => actions.poolPageByEseId.SUCCESS(res.response)),
            catchError(error => of(actions.poolPageByEseId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 
** 数据统计标记
*/
export const poolPrizeNotNeedStatisticsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.poolPrizeNotNeedStatistics.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { poolPrizeId, notNeedStatistics } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            body: action.payload,
            url: `${urls.promotion.poolPrizes.poolPrizes}/${poolPrizeId}/notNeedStatistics?notNeedStatistics=${notNeedStatistics}`
        }).pipe(
            map(res => actions.poolPrizeNotNeedStatistics.SUCCESS(res.status)),
            catchError(error => of(actions.poolPrizeNotNeedStatistics.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 
**  获取奖池奖品详情
 */
export const getPoolDataByEseIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolDataByEseId.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { eseId } = action.payload;
        return ajax({
            ...apiBaseConfig,
            url: `${urls.promotion.pools.pools}?eseId=${eseId}`
        }).pipe(
            map(res => actions.getPoolDataByEseId.SUCCESS(res.response)),
            catchError(error => of(actions.getPoolDataByEseId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)






//获取列表V2
export const getPoolTableDataV2Epic = actions$ => actions$.pipe(
    ofType(actions.getPoolTableV2.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page, size, word,prizeWord, poolStatus, poolUseStatus, poolType, promotionCategories = '', promotionKeyWords = '', sort = '', vaild } = action.payload;

        let poolUseStatuses = '', poolStatuses = '', poolTypes = '';
        poolUseStatus.map(val => { if (val != null) poolUseStatuses += `poolUseStatuses=${val}&`; })
        poolStatus.map(val => { if (val != null) poolStatuses += `poolStatuses=${val}&` })
        poolType.map(val => { if (val != null) poolTypes += `poolType=${val}&` })

        let url = `${urls.promotion.poolsV2.poolsV2}`;
        if (vaild == 'PUBLISH') {
            url = `${url}/valid?page=${page}&size=${size}&sort=${sort}&${poolUseStatuses}${poolStatuses}${poolTypes}word=${word ? encodeURIComponent(word) : ''}&prizeWord=${prizeWord ? encodeURIComponent(prizeWord) : ''}&promotionIds=${promotionKeyWords}&promotionCategories=${promotionCategories == 'no' ? '' : promotionCategories}`;
        }
        else {
            url = `${url}?page=${page}&status=${vaild}&size=${size}&sort=${sort}&${poolUseStatuses}${poolStatuses}${poolTypes}word=${word ? encodeURIComponent(word) : ''}&prizeWord=${prizeWord ? encodeURIComponent(prizeWord) : ''}&promotionIds=${promotionKeyWords}&promotionCategories=${promotionCategories == 'no' ? '' : promotionCategories}`;
        }

        return noecAjax({
            ...ajaxBaseConfig,
            url
        }).pipe(
            map(res => actions.getPoolTableV2.SUCCESS(res.response)),
            catchError(error => of(actions.getPoolTableV2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

//奖池删除V2
export const deletePoolV2Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.deletePoolV2.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'DELETE',

            url: `${urls.promotion.poolsV2.poolsV2}/${data.id}`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.deletePoolV2.SUCCESS(res.response)

            }),
            catchError(error => of(actions.deletePoolV2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
)
//奖池删除草稿V2
export const deletePoolDraftV2Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.deletePoolDraftV2.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'DELETE',

            url: `${urls.promotion.poolsV2.poolsV2}/${data.id}/deleteDraft`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.deletePoolDraftV2.SUCCESS(res.response)

            }),
            catchError(error => of(actions.deletePoolDraftV2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
)
//奖池归档
export const poolRegulateEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.poolRegulate.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',

            url: `${urls.promotion.poolsV2.poolsV2}/regulate/${data.id}`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.poolRegulate.SUCCESS(res.response)

            }),
            catchError(error => {
                let newerror = error.xhr.response;
                if (newerror.frontTips) {
                    for (let x in newerror.frontTips) {
                        // newerror.ecode = x;
                        newerror.emsg = newerror.frontTips[x];
                    }
                }
                return of(actions.poolRegulate.FAIL(newerror), uiActions.notification(newerror))

            })
        )
    }
    )
)






/* 
** 创建修改奖池奖品V2
*/


export const postPoolV2Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.postPoolV2.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolData, merchantBudget, callback } = action.payload;
        // const { merchantRechargeBudget, exceedChargeOptionType = 'SEND_PRIZE', warningValue } = merchantBudget;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: { ...poolData, ...merchantBudget },
            url: `${urls.promotion.poolsV2.poolsV2}`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.postPoolV2.SUCCESS(res.response)
                // if (quanxian) {
                //     return [actions.postPoolV2.SUCCESS(res.response), actions.budget.REQUEST({ ...res.response, prizeList, history, poolData, merchantBudget })]
                // }
                // else {
                //     return [actions.postPoolV2.SUCCESS(res.response), actions.patchPoolV2.REQUEST({ ...res.response, prizeList, history,poolData, merchantBudget })]
                // }
            }),
            catchError(error => of(actions.postPoolV2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
/* 
** 批量创建、修改奖池V2
*/
export const patchPoolV2Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.patchPoolV2.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolData, merchantBudget, prizeList, remark = '', callback } = action.payload;
        // const { merchantRechargeBudget, exceedChargeOptionType = 'SEND_PRIZE', warningValue } = merchantBudget;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            body: { ...poolData, poolPrizes: prizeList, ...merchantBudget },
            url: `${urls.promotion.poolsV2.poolsV2}/editAndBudget`
        }).pipe(
            map(res => {
                // history.push('/promotionpoolDraft');
                callback && callback(res.response);
                return actions.patchPoolV2.SUCCESS(res.response)

                // if (remark) {
                //     return [actions.postPoolPrize.SUCCESS(res)]
                // } else {
                //     return [actions.postPoolPrize.SUCCESS(res), actions.rechargeBudget.REQUEST({ id, merchantBudget })]
                // }
            }),
            catchError(error => of(actions.patchPoolV2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))


                // let myPrizeList = JSON.parse(JSON.stringify(prizeList));
                // const reform = (obj, keyArr = []) => {
                //     let newObj = {};
                //     Object.keys(obj).forEach(key => {
                //         const val = obj[key];
                //         if (!keyArr.includes(key)) {
                //             newObj[key] = val;
                //         }
                //     })
                //     return newObj;
                // }
                // if (prizeList.length) {
                //     myPrizeList.map((item, index, self) => {
                //         console.log(item)
                //         item.poolId = id;
                //         item.eseId = eseId;
                //         item.requireWarning = !item.requireWarning; item.putCount = Number(item.putCount);

                //         let obj = {};
                //         if (item.prizeId) {
                //             obj = reform(item, ['text', 'prizeCoupon', 'disabled', 'codePutCount', 'prizeRedPacket', 'prizeChangeOfMoney']);
                //         } else {
                //             item.prizeId = item.id;
                //             obj = reform(item, ['text', 'id']);
                //         }
                //         console.log(obj)
                //         return item;
                //     })
                // }
                // let myPoolPrizes = { version, totalBudget, cashBudget, merchantRechargeBudget, name, id, poolPrizes: myPrizeList };
                // if (remark) {
                //     myPoolPrizes = { ...myPoolPrizes, name, remark, appId, merchantNumber, merchantId, merchantRechargeBudget, exceedChargeOptionType, warningValue };
                // }


                // map(res => {
                //     history.push('/promotionpool');
                //     return actions.postPoolPrize.SUCCESS(res);
                // }),
            )
        )
    })
)


export const patchPoolNoBudgetV2Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.patchPoolNoBudgetV2.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolData, merchantBudget, prizeList, remark = '', callback } = action.payload;
        // const { merchantRechargeBudget, exceedChargeOptionType = 'SEND_PRIZE', warningValue } = merchantBudget;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            body: { ...poolData, poolPrizes: prizeList, ...merchantBudget },
            url: `${urls.promotion.poolsV2.poolsV2}`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.patchPoolNoBudgetV2.SUCCESS(res.response)

            }),
            catchError(error => of(actions.patchPoolNoBudgetV2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    })
)






/* 
**  获取奖池奖品详情V2
 */
export const getPoolIdV2Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolDataIdV2.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolId, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.poolsV2.poolsV2}/${poolId}`
        }).pipe(
            mergeMap(res => {
                callback && callback({ poolData: res.response, prizeList: res.response.poolPrizes, remark: res.response.remark || '' });
                // , actions.getPoolPrizeDataId.REQUEST({ poolData: res.response, poolId, callback })
                return [actions.getPoolDataIdV2.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getPoolDataIdV2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


/* 
**  奖池发布v2
 */
export const publishPoolEpic = (actions$, store) => actions$.pipe(
    ofType(actions.publishPool.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            url: `${urls.promotion.poolsV2.poolsV2}/${data.id}/publish`,
            // url: `${urls.promotion.promotions.publish(action.payload.id)}?&authType=${action.payload.authType}`,
            body: data
        }).pipe(
            map(res => {
                callback && callback(res.response)
                return actions.publishPool.SUCCESS(res.response)
            }),
            catchError(error => of(actions.publishPool.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);


/* 
**  奖池发布定时v2
 */
export const publishPoolTimeEpic = (actions$, store) => actions$.pipe(
    ofType(actions.publishPoolTime.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            url: `${urls.promotion.poolsV2.poolsV2}/${data.id}/toBeReleased`,
            body: data
        }).pipe(
            map(res => {
                callback && callback(res.response)
                return actions.publishPoolTime.SUCCESS(res.response)
            }),
            catchError(error => of(actions.publishPoolTime.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);



/* 
**  取消发布v2
 */

export const deletePublishTimeEpic = (actions$, store) => actions$.pipe(
    ofType(actions.deletePublishTime.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            url: `${urls.promotion.poolsV2.poolsV2}/${data.id}/cancelPublish`,
            body: {
                remark: '',
            }
        }).pipe(
            map(res => {
                callback && callback(res.response)
                return actions.deletePublishTime.SUCCESS(res.response)
            }),
            catchError(error => of(actions.deletePublishTime.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    }
    )
);


/* 
**  预算v2
 */


export const putBudgetV2Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.putBudgetV2.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolData, callback } = action.payload;
        const { totalBudget, cashBudget } = poolData;

        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            body: {},
            // url: `${urls.promotion.pools.budget}/${poolData.id}?totalBudget=${poolData.totalBudget}`
            url: `${urls.promotion.poolsV2.poolsV2}/budget/${poolData.id}?totalBudget=${totalBudget}&cashBudget=${cashBudget}`,
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.putBudgetV2.SUCCESS(res.response)
            }),
            catchError(error => of(actions.putBudgetV2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)





//导出step2方案
export const exportPoolEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.exportPool.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: data.version ? `${urls.promotion.poolsV2.poolsV2}/exportHis/${data.id}` : `${urls.promotion.poolsV2.poolsV2}/export/${data.id}`,
        }).pipe(
            concatMap(res => {
                callback && callback()
                return [actions.exportPool.SUCCESS(res)]
            }),
            catchError(error => of(actions.exportPool.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    }
    )
);
//导出step2方案
export const exportPoolFirstEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.exportPoolFirst.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: data.version ? `${urls.promotion.pools.pools}/exportHis/${data.id}` : `${urls.promotion.pools.pools}/export/${data.id}`,
        }).pipe(
            concatMap(res => {
                callback && callback()
                return [actions.exportPoolFirst.SUCCESS(res)]
            }),
            catchError(error => of(actions.exportPoolFirst.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    }
    )
);

//导入
export const importPoolEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.importPool.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, id, callback } = action.payload;
        return ajax({
            ...apiBaseConfig,
            url: `${urls.promotion.poolsV2.nestPool}/import?id=${id}`,
            method: 'POST',
            body: data,
        }).pipe(
            concatMap(res => {
                callback && callback()
                return [actions.importPool.SUCCESS(res)]
            }),
            catchError(error => of(actions.importPool.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    }
    )
);



//嵌套奖池逻辑
export const getPoolPlanEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolPlan.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;

        return noecAjax({
            ...ajaxBaseConfig,
            body: {},
            // url: `${urls.promotion.pools.budget}/${poolData.id}?totalBudget=${poolData.totalBudget}`
            url: `${urls.promotion.poolsV2.nestPool}/page?poolId=${data.id}&size=${data.size}&page=${data.page}&keywords=${data.keywords ? encodeURIComponent(data.keywords) : ''}&name=${data.name ? encodeURIComponent(data.name) : ''}`,
        }).pipe(
            map(res => {
                callback && callback(res.response);
                console.log(res.response)
                return actions.getPoolPlan.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getPoolPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const getPoolPlanIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolPlanId.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            body: {},
            // url: `${urls.promotion.pools.budget}/${poolData.id}?totalBudget=${poolData.totalBudget}`
            url: `${urls.promotion.poolsV2.nestPool}/${data.id}`,
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.getPoolPlanId.SUCCESS(res.response)
            }),
            // catchError(error => of(actions.getPoolPlanId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)




export const addPoolPlanEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.addPoolPlan.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: data,
            // url: `${urls.promotion.pools.budget}/${poolData.id}?totalBudget=${poolData.totalBudget}`
            url: `${urls.promotion.poolsV2.nestPool}`,
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.addPoolPlan.SUCCESS(res.response)
            }),
            catchError(error => of(actions.addPoolPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)



export const patchPoolPlanV2Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.patchPoolPlanV2.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolData, merchantBudget, prizeList, remark = '', callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            body: { ...poolData, nestPoolPrizes: prizeList, ...merchantBudget },
            url: `${urls.promotion.poolsV2.nestPool}/editAndBudget`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.patchPoolPlanV2.SUCCESS(res.response)
            }),
            catchError(error => of(actions.patchPoolPlanV2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))

            )
        )
    })
)


export const patchPoolPlanNoBudgetV2Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.patchPoolPlanNoBudgetV2.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolData, merchantBudget, prizeList, remark = '', callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            body: { ...poolData, nestPoolPrizes: prizeList, ...merchantBudget },
            url: `${urls.promotion.poolsV2.nestPool}/update`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.patchPoolPlanNoBudgetV2.SUCCESS(res.response)

            }),
            catchError(error => of(actions.patchPoolPlanNoBudgetV2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    })
)



export const statePoolPlanEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.statePoolPlan.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            body: data,
            url: `${urls.promotion.poolsV2.nestPool}/${data.id}/${data.state}`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.statePoolPlan.SUCCESS(res.response)

            }),
            catchError(error => of(actions.statePoolPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    })
)
export const deletePoolPlanEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.deletePoolPlan.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            body: data,
            url: `${urls.promotion.poolsV2.nestPool}/${data.id}/${data.state}`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.deletePoolPlan.SUCCESS(res.response)

            }),
            catchError(error => of(actions.deletePoolPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    })
)


export const putPoolPlanBudgetEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putPoolPlanBudget.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolData, callback } = action.payload;
        const { totalBudget, cashBudget } = poolData;

        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            body: poolData,
            // url: `${urls.promotion.pools.budget}/${poolData.id}?totalBudget=${poolData.totalBudget}`
            url: `${urls.promotion.poolsV2.nestPool}/budget`,
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.putPoolPlanBudget.SUCCESS(res.response)
            }),
            catchError(error => of(actions.putPoolPlanBudget.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getPoolThirdOrgSelectEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolThirdOrgSelect.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
    

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.poolsV2.poolsV2}/thirdOrgs`,
        }).pipe(
            map(res => {
          
                return actions.getPoolThirdOrgSelect.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getPoolThirdOrgSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

