import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'order';

/**
 * TODO: 
 */

export const productionList = fasterActions(moduleName, 'productionList');
export const productionOrderList = fasterActions(moduleName, 'productionOrderList');
export const productionOrderStatic = fasterActions(moduleName, 'productionOrderStatic');
export const productionOrderTendency = fasterActions(moduleName, 'productionOrderTendency');

export const saleOrderList = fasterActions(moduleName, 'saleOrderList');
export const saleOrderStatic = fasterActions(moduleName, 'saleOrderStatic');
export const saleOrderTendency = fasterActions(moduleName, 'saleOrderTendency');

export const deliveryList = fasterActions(moduleName, 'deliveryList');
export const deliveryStatic = fasterActions(moduleName, 'deliveryStatic');
export const deliveryTendency = fasterActions(moduleName, 'deliveryTendency');

export const distributionList = fasterActions(moduleName, 'distributionList');
export const distributionStatic = fasterActions(moduleName, 'distributionStatic');
export const distributionTendency = fasterActions(moduleName, 'distributionTendency');

export const distributorList = fasterActions(moduleName, 'distributorList');
export const distributorStatic = fasterActions(moduleName, 'distributorStatic');
export const distributorTendency = fasterActions(moduleName, 'distributorTendency');

export const banquetList = fasterActions(moduleName, 'banquetList');
export const banquetStatic = fasterActions(moduleName, 'banquetStatic');
export const banquetTendency = fasterActions(moduleName, 'banquetTendency');

export const otherList = fasterActions(moduleName, 'otherList');
export const otherStatic = fasterActions(moduleName, 'otherStatic');
export const otherTendency = fasterActions(moduleName, 'otherTendency');

export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);

export const saleDeptCodeList = fasterActions(moduleName, 'saleDeptCodeList');
export const saleTeamCodeList = fasterActions(moduleName, 'saleTeamCodeList');

export const  getDeliveryIdsSelectList = fasterActions(moduleName, 'getDeliveryIdsSelectList');
export const  getSoldIdsSelectList = fasterActions(moduleName, 'getSoldIdsSelectList');

export const brands = fasterActions(moduleName, 'brands');

const initialState = {
    pageData:[],
    pageStaticData:{},
    tendencyData:{},
    echartsData:[],
    saleDeptCodeData:[],
    saleTeamCodeData:[],
    deliveryIdsSelectList:[],
    soldIdsSelectList:[],
    brandsSelector:[],
    pageParams:{
      page:0,
      size:20,
      startTime:'',
      endTime:''
    },
    loading:false,
    tendencyLoading:false
}

const reducer = handleActions(
  { 
    [changeDictPaging]: (state, { payload }) => {
        return { ...state, pageParams: { ...payload} }
    },

    [brands.REQUEST]: (state, { payload }) => ({ ...state,}),
    [brands.SUCCESS]: (state, { payload }) => ({ ...state, brandsSelector: payload}),
    [brands.FAIL]: (state, { payload }) => ({ ...state}),

    [getDeliveryIdsSelectList.REQUEST]: (state, { payload }) => ({ ...state}),
    [getDeliveryIdsSelectList.SUCCESS]: (state, { payload }) => ({ ...state, deliveryIdsSelectList: payload}),
    [getDeliveryIdsSelectList.FAIL]: (state, { payload }) => ({ ...state}),

    [getSoldIdsSelectList.REQUEST]: (state, { payload }) => ({ ...state}),
    [getSoldIdsSelectList.SUCCESS]: (state, { payload }) => ({ ...state, soldIdsSelectList: payload}),
    [getSoldIdsSelectList.FAIL]: (state, { payload }) => ({ ...state}),

    [productionOrderList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [productionOrderList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [productionOrderList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [productionOrderStatic.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [productionOrderStatic.SUCCESS]: (state, { payload }) => ({ ...state, pageStaticData: payload,}),
    [productionOrderStatic.FAIL]: (state, { payload }) => ({ ...state}),

    [productionOrderTendency.REQUEST]: (state, { payload }) => ({ ...state, echartsData:[], tendencyLoading: true }),
    [productionOrderTendency.SUCCESS]: (state, { payload }) => ({ ...state, echartsData: payload, tendencyLoading: false }),
    [productionOrderTendency.FAIL]: (state, { payload }) => ({ ...state, tendencyLoading: false }),

    [saleOrderList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [saleOrderList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [saleOrderList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [saleOrderStatic.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [saleOrderStatic.SUCCESS]: (state, { payload }) => ({ ...state, pageStaticData: payload}),
    [saleOrderStatic.FAIL]: (state, { payload }) => ({ ...state}),

    [saleOrderTendency.REQUEST]: (state, { payload }) => ({ ...state, echartsData:[], tendencyLoading: true }),
    [saleOrderTendency.SUCCESS]: (state, { payload }) => ({ ...state, echartsData: payload, tendencyLoading: false }),
    [saleOrderTendency.FAIL]: (state, { payload }) => ({ ...state, tendencyLoading: false }),

    [deliveryList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [deliveryList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [deliveryList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [deliveryStatic.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [deliveryStatic.SUCCESS]: (state, { payload }) => ({ ...state, pageStaticData: payload}),
    [deliveryStatic.FAIL]: (state, { payload }) => ({ ...state}),

    [deliveryTendency.REQUEST]: (state, { payload }) => ({ ...state, echartsData:[], tendencyLoading: true }),
    [deliveryTendency.SUCCESS]: (state, { payload }) => ({ ...state, echartsData: payload, tendencyLoading: false }),
    [deliveryTendency.FAIL]: (state, { payload }) => ({ ...state, tendencyLoading: false }),

    [distributionList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [distributionList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [distributionList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [distributionStatic.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [distributionStatic.SUCCESS]: (state, { payload }) => ({ ...state, pageStaticData: payload}),
    [distributionStatic.FAIL]: (state, { payload }) => ({ ...state}),

    [distributionTendency.REQUEST]: (state, { payload }) => ({ ...state, echartsData:[], tendencyLoading: true }),
    [distributionTendency.SUCCESS]: (state, { payload }) => ({ ...state, echartsData: payload, tendencyLoading: false }),
    [distributionTendency.FAIL]: (state, { payload }) => ({ ...state, tendencyLoading: false }),

    [distributorList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [distributorList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [distributorList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [distributorStatic.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [distributorStatic.SUCCESS]: (state, { payload }) => ({ ...state, pageStaticData: payload}),
    [distributorStatic.FAIL]: (state, { payload }) => ({ ...state}),

    [distributorTendency.REQUEST]: (state, { payload }) => ({ ...state, echartsData:[], tendencyLoading: true }),
    [distributorTendency.SUCCESS]: (state, { payload }) => ({ ...state, echartsData: payload, tendencyLoading: false }),
    [distributorTendency.FAIL]: (state, { payload }) => ({ ...state, tendencyLoading: false }),

    [banquetList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [banquetList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [banquetList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [banquetStatic.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [banquetStatic.SUCCESS]: (state, { payload }) => ({ ...state, pageStaticData: payload}),
    [banquetStatic.FAIL]: (state, { payload }) => ({ ...state}),

    [banquetTendency.REQUEST]: (state, { payload }) => ({ ...state, echartsData:[], tendencyLoading: true }),
    [banquetTendency.SUCCESS]: (state, { payload }) => ({ ...state, echartsData: payload, tendencyLoading: false }),
    [banquetTendency.FAIL]: (state, { payload }) => ({ ...state, tendencyLoading: false }),

    [otherList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [otherList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [otherList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [otherStatic.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [otherStatic.SUCCESS]: (state, { payload }) => ({ ...state, pageStaticData: payload}),
    [otherStatic.FAIL]: (state, { payload }) => ({ ...state}),

    [otherTendency.REQUEST]: (state, { payload }) => ({ ...state, echartsData:[], tendencyLoading: true }),
    [otherTendency.SUCCESS]: (state, { payload }) => ({ ...state, echartsData: payload, tendencyLoading: false }),
    [otherTendency.FAIL]: (state, { payload }) => ({ ...state, tendencyLoading: false }),

    [saleDeptCodeList.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [saleDeptCodeList.SUCCESS]: (state, { payload }) => ({ ...state, saleDeptCodeData: payload.content}),
    [saleDeptCodeList.FAIL]: (state, { payload }) => ({ ...state}),

    [saleTeamCodeList.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [saleTeamCodeList.SUCCESS]: (state, { payload }) => ({ ...state, saleTeamCodeData: payload.content}),
    [saleTeamCodeList.FAIL]: (state, { payload }) => ({ ...state}),
  },
  initialState
);

export default reducer;
