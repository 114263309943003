import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './whPurchaseApproval';
import { ajaxBaseConfig, actionApi } from '../../utils';
import { trims } from 'ucode-utils';


export const loadWhPurchaseApprovalListEpic = actions$ => actions$.pipe(
  ofType(actions.loadWhPurchaseApprovalList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    // const { isVaild = true, promotionCategory, keywords = '', productKeywords = '', promotionType = '', promotionPublishStatus = '', page = 0, size = 20, sort = '' } = action.payload;
    const {  page = 0, size = 20, sort = '', examineStatus = "",account="",terminal="", dealerTypes="" } = action.payload;
    const url = urls.memberShip.whPurchaseApproval.terminal
    return ajax({
      ...ajaxBaseConfig,
      url: `${url}?page=${page}&size=${size}&sort=${sort}&examineStatus=${examineStatus}&account=${account}&terminal=${terminal}&dealerTypes=${dealerTypes}`
    }).pipe(
      map(res => actions.loadWhPurchaseApprovalList.SUCCESS(res.response)),
      catchError(error => of(actions.loadWhPurchaseApprovalList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


export const editWhPurchaseApprovalEpic = actions$ => actions$.pipe(
  ofType(actions.editWhPurchaseApproval.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: urls.memberShip.whPurchaseApproval.terminalExamine,
      method: "POST",
      body: data
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.editWhPurchaseApproval.SUCCESS(res.response)
      }),
      catchError(error => of(actions.editWhPurchaseApproval.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
