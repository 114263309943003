import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './consumptions';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const loadListEpic = actions$ => actions$.pipe(
  ofType(actions.loadList.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.consumptions.consumptions}?${queryString.stringify(action.payload)}`,
    }).pipe(
      map(res => actions.loadList.SUCCESS(res.response)),
      catchError(error => of(actions.loadList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const updateConsumptionEpic = (actions$, store) => actions$.pipe(
  ofType(actions.updateConsumption.REQUEST.toString()),
  mergeMap(action => { actionApi()
    // const { sendData } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.consumptions.consumptions}`,
      method: 'PUT',
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.updateConsumption.SUCCESS(res.response), uiActions.notification('修改成功', 'success'), actions.loadList.REQUEST({ promotionId: store.value.promotions.byId.id }), actions.updateSelected({})]),
      catchError(error => of(actions.updateConsumption.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  })
);

export const addConsumptionEpic = (actions$, store) => actions$.pipe(
  ofType(actions.addConsumption.REQUEST.toString()),
  mergeMap(action => { actionApi()
    // const { sendData } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.consumptions.consumptions}`,
      method: 'POST',
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.addConsumption.SUCCESS(res.response), uiActions.notification('新增成功', 'success'), actions.loadList.REQUEST({ promotionId: store.value.promotions.byId.id })]), //actions.updateSelected({})
      catchError(error => of(actions.addConsumption.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  })
);

export const delConsumptionEpic = (actions$, store) => actions$.pipe(
  ofType(actions.delConsumption.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.consumptions.consumptions}/${action.payload}`,
      method: 'DELETE',
    }).pipe(
      mergeMap(res => [actions.delConsumption.SUCCESS(res.response), uiActions.notification('删除成功', 'success'), actions.loadList.REQUEST({ promotionId: store.value.promotions.byId.id }), actions.updateSelected({})]),
      catchError(error => of(actions.delConsumption.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  })
);

export const loadListEpic1 = actions$ => actions$.pipe(
  ofType(actions.loadList1.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.consumptions.consumptions}?${queryString.stringify(action.payload)}&returnStandard=true`,
    }).pipe(
      map(res => actions.loadList1.SUCCESS(res.response)),
      catchError(error => of(actions.loadList1.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const updateConsumptionEpic1 = (actions$, store) => actions$.pipe(
  ofType(actions.updateConsumption1.REQUEST.toString()),
  mergeMap(action => { actionApi()
    // const { sendData } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.consumptions.consumptions}`,
      method: 'PUT',
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.updateConsumption1.SUCCESS(res.response), uiActions.notification('修改成功', 'success'), actions.loadList1.REQUEST({ promotionId: store.value.promotions.byId.id }), actions.updateSelected({})]),
      catchError(error => of(actions.updateConsumption1.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  })
);

export const addConsumptionEpic1 = (actions$, store) => actions$.pipe(
  ofType(actions.addConsumption1.REQUEST.toString()),
  mergeMap(action => { actionApi()
    // const { sendData } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.consumptions.consumptions}`,
      method: 'POST',
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.addConsumption1.SUCCESS(res.response), uiActions.notification('新增成功', 'success'), actions.loadList1.REQUEST({ promotionId: store.value.promotions.byId.id })]), //actions.updateSelected({})
      catchError(error => of(actions.addConsumption1.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  })
);

export const delConsumptionEpic1 = (actions$, store) => actions$.pipe(
  ofType(actions.delConsumption1.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.consumptions.consumptions}/${action.payload}`,
      method: 'DELETE',
    }).pipe(
      mergeMap(res => [actions.delConsumption1.SUCCESS(res.response), uiActions.notification('删除成功', 'success'), actions.loadList1.REQUEST({ promotionId: store.value.promotions.byId.id }), actions.updateSelected({})]),
      catchError(error => of(actions.delConsumption1.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  })
);