import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';

import * as actions from './tagStatistics';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// 码标签生产列表
export const tagInitSumEpic = actions$ => actions$.pipe(
    ofType(actions.tagInitSum.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20,startTime='',endTime='',productIds=[],tagGroupIds = [],tagGroupName='',tagName='',tagCodes=[],sort = 'tagdate,desc'} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.tagStatistics.tagInit}?page=${page}&size=${size}&startTime=${startTime}&endTime=${endTime}&productIds=${productIds}&tagName=${tagName}&tagGroupName=${tagGroupName}&sort=${sort}`
        }).pipe(
            map(res => actions.tagInitSum.SUCCESS(res.response)),
            catchError(error => of(actions.tagInitSum.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    } 
    )
);

// 码标签使用列表
export const tagUseSumEpic = actions$ => actions$.pipe(
  ofType(actions.tagUseSum.REQUEST.toString()),
  mergeMap(action => {
      actionApi();
      const {  page = 0, size = 20,startTime='',endTime='',productIds=[],tagGroupIds = [],tagCodes=[],tagGroupName='',tagName='',promotionId='',planId='',sort = 'tagdate,desc'} = action.payload;
      let planIdValue = [planId.value] || [];
      let promotionIdValue = [promotionId.value] || [];
      return ajax({
          ...ajaxBaseConfig,
          url: `${urls.tagStatistics.tagUse}?page=${page}&size=${size}&startTime=${startTime}&endTime=${endTime}&productIds=${productIds}&promotionId=${promotionIdValue}&planId=${planIdValue}&tagName=${tagName}&tagGroupName=${tagGroupName}&sort=${sort}`
      }).pipe(
          map(res => actions.tagUseSum.SUCCESS(res.response)),
          catchError(error => of(actions.tagUseSum.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
  } 
  )
);

// 订单类型
export const tagListEpic = actions$ => actions$.pipe(
    ofType(actions.tagList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        // const  {type = 'scan-report'} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.codeDetail.orderType}?type=1&childType=1,2,4,5,6`
        }).pipe(
            map(res => actions.tagList.SUCCESS(res.response)),
            catchError(error => of(actions.tagList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//订单号list
export const tagTeamListEpic = actions$ => actions$.pipe(
    ofType(actions.tagTeamList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime='',endTime=''} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.codeDetail.cordeOrder}?startTime=${startTime}&endTime=${endTime}`
        }).pipe(
            map(res => actions.tagTeamList.SUCCESS(res.response)),
            catchError(error => of(actions.tagTeamList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    } 
    )
);

// // 筛选选下载
// export const selectLoadEpic = actions$ => actions$.pipe(
//     ofType(actions.selectLoad.REQUEST.toString()),
//     mergeMap(action => {
//         actionApi();

//         const {type=1,json}=action.payload;
//         let url = '';
//         if(type == 1){
//             url = `${urls.scan.downloadStatistics}`;
//         }else if(type==2){
//             url = `${urls.scan.downloadBanquet}`;
//         }else if(type==3){
//             url = `${urls.scan.downloadConsumer}`;
//         }
//         return ajax({
//             ...ajaxBaseConfig,
//             url: url,
//             method:'POST',
//             body:JSON.stringify(json)
//         }).pipe(
//             map(res => actions.selectLoad.SUCCESS(res.response)),
//             catchError(error => of(actions.selectLoad.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//         )
//     }
//     )
// );




