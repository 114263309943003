import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './addressBooks';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 通讯录管理
 */

// 通讯录分页列表
export const loadAddressBooksPageListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadAddressBooksPageList.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { addressBookPageParam = {}, addressBookSearchKey } = state$.value.addressBooks;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.addressBooks.addressBooks}?page=${addressBookPageParam.page}&size=${addressBookPageParam.size}&sort=${addressBookPageParam.sort}&word=${addressBookSearchKey}&deptIds=${addressBookPageParam.deptIds}`,
      }).pipe(
        map(res => actions.loadAddressBooksPageList.SUCCESS(res.response)),
        catchError(error => of(actions.loadAddressBooksPageList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//通信录查询by id
export const loadAddressBookByIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadAddressBookById.REQUEST.toString()),
  mergeMap(action => { 
    actionApi()
    const { id, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.addressBooks.addressBooks}/${id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response || {});
          return actions.loadAddressBookById.SUCCESS(res.response || {});
        }),
        catchError(error => of(actions.loadAddressBookById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//通讯录选框
export const loadAddressBookSelectionsEpic = actions$ => actions$.pipe(
  ofType(actions.loadAddressBookSelections.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { status = '' } = action.payload || {};
    const newUrl = urls.data.addressBooks.addressBooksSelections;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: !status ? newUrl : `${newUrl}?status=${status}`,
      }).pipe(
        map(res => actions.loadAddressBookSelections.SUCCESS(res.response)),
        catchError(error => of(actions.loadAddressBookSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//禁用，启用
export const enableOrDisableAddressBooksEpic = (actions$,state$) => actions$.pipe(
  ofType(actions.enableOrDisableAddressBooks.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id = '', status = '' } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.addressBooks.addressBooks}/${id}/status?status=${status}`,
        method: 'PATCH'
      }).pipe(
        concatMap(res => [actions.enableOrDisableAddressBooks.SUCCESS(res.response), actions.loadAddressBooksPageList.REQUEST({ ...state$.value.addressBooks.addressBookPageParam, page: 0})]),
        catchError(error => of (actions.enableOrDisableAddressBooks.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//创建
export const createAddressbookEpic = (actions$,state$) => actions$.pipe(
  ofType(actions.createAddressbook.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.addressBooks.addressBooks}`,
        method: 'POST',
        body: action.payload.data
      }).pipe(
        concatMap(res => {
          action.payload.callback && action.payload.callback();
          return[actions.createAddressbook.SUCCESS(res.response), actions.loadAddressBooksPageList.REQUEST( { ...state$.value.addressBooks.addressBookPageParam, page: 0})]
        }),
        catchError(error => of(actions.createAddressbook.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//编辑
export const editAddressbookEpic = (actions$,state$) => actions$.pipe(
  ofType(actions.editAddressbook.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.addressBooks.addressBooks}`,
        method: 'PUT',
        body: action.payload.data
      }).pipe(
        concatMap(res => {
          action.payload.callback && action.payload.callback();
          return [actions.editAddressbook.SUCCESS(res.response), actions.loadAddressBooksPageList.REQUEST({ ...state$.value.addressBooks.addressBookPageParam})];
        }),
        catchError(error => of(actions.editAddressbook.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
