import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions, setScrollTop } from '../../utils';

const moduleName = 'role';

export const changeRoleSearch = createAction(`${moduleName}/changeRoleSearch`);
export const getRolesList = fasterActions(moduleName, 'getRolesList');
export const enableOrDisabledRole = fasterActions(moduleName, 'enableOrDisabledRole');
export const createRole = fasterActions(moduleName, 'createRole');
export const editRole = fasterActions(moduleName, 'editRole');
export const getRoleData = fasterActions(moduleName, 'getRoleData');
export const getRoleScope = fasterActions(moduleName, 'getRoleScope');
export const getRoleSelections = fasterActions(moduleName, 'getRoleSelections');
export const deleteRole = fasterActions(moduleName, 'deleteRole');

const initialState = {
    roleSearch: {
        page: 0,
        size: 20, // 每页大小
    },
    roleTableData: [],
    roleScope: [],
    totalElements: 0, /* 总数据条数 */
    perPageCountList: [20, 50, 100], // 每页大小候选
}

const reducer = handleActions(
    {
        [changeRoleSearch]: (state, { payload }) => {
            return { ...state, roleSearch: { ...state.roleSearch, ...payload } }
        },
        // 获取角色列表
        [getRolesList.REQUEST]: (state, { payload }) => {
            return { ...state, roleLoading: true, roleSearch: payload }
        },
        [getRolesList.SUCCESS]: (state, { payload }) => {
            const { content = [], totalElements = 0 } = payload;
            return { ...state, roleLoading: false, roleTableData: content, totalElements }
        },
        [getRolesList.FAIL]: (state, { payload }) => ({ ...state, error: payload, roleLoading: false }),
        // 禁用、启用角色
        [enableOrDisabledRole.REQUEST]: (state, { payload }) => {
            return { ...state }
        },
        [enableOrDisabledRole.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [enableOrDisabledRole.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
        // 新建角色
        [createRole.REQUEST]: (state, { payload }) => {
            return { ...state }
        },
        [createRole.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [createRole.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
        // 编辑角色
        [editRole.REQUEST]: (state, { payload }) => {
            return { ...state }
        },
        [editRole.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [editRole.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
        // 根据id查角色详情
        [getRoleData.REQUEST]: (state, { payload }) => {
            return { ...state }
        },
        [getRoleData.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [getRoleData.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
        // 查询角色影响范围  内部
        [getRoleScope.REQUEST]: (state, { payload }) => {
            return { ...state }
        },
        [getRoleScope.SUCCESS]: (state, { payload }) => ({ ...state, roleScope: payload.content || [] }),
        [getRoleScope.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
        // 角色选框
        [getRoleSelections.REQUEST]: (state, { payload }) => {
            return { ...state }
        },
        [getRoleSelections.SUCCESS]: (state, { payload }) => {
            const { content = [] } = payload;
            let roleSelections = [];
            content && content.forEach(item => { roleSelections.push({ text: item.name, value: Number(item.id) }) });
            return { ...state, roleSelections }
        },
        [getRoleSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
        /* 删除 */
        [deleteRole.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [deleteRole.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [deleteRole.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    },
    initialState
);
export default reducer;