import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'uploadLibrary';

/**
 * TODO: 图片库
 */

export const loadLibraryList = fasterActions(moduleName, 'loadLibraryList');

export const uploadLibrary = fasterActions(moduleName, 'uploadLibrary');

export const delLibrary = fasterActions(moduleName, 'delLibrary');

// 优惠券导出
export const couponCodePacketExport = fasterActions(moduleName, 'couponCodePacketExport');

const initialState = {
  libraryList: {},

  couponLoaded: false,
  couponConfigsAllList: [],
  couponConfigDetailsAllList: [],
}

const reducer = handleActions(
  {

    [loadLibraryList.REQUEST]: (state, { payload }) => ({ ...state, loadingList: true }),
    [loadLibraryList.SUCCESS]: (state, { payload }) => {
      return ({ ...state, loadingList: false, libraryList: payload })
    },
    [loadLibraryList.FAIL]: (state, { payload }) => ({ ...state, loadingList: false, error: payload }),


    [uploadLibrary.REQUEST]: (state, { payload }) => ({ ...state }),
    [uploadLibrary.SUCCESS]: (state, { payload }) => ({ ...state }),
    [uploadLibrary.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [delLibrary.REQUEST]: (state, { payload }) => ({ ...state }),
    [delLibrary.SUCCESS]: (state, { payload }) => ({ ...state }),
    [delLibrary.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

  },
  initialState
);

export default reducer;
