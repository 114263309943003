import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'links';

/**
 * TODO: 
 */
export const loadLinksList = fasterActions(moduleName, 'loadLinksList');
export const loadLinkSelections = fasterActions(moduleName, 'loadLinkSelections');
export const createLink = fasterActions(moduleName, 'createLink');


export const loadTencentBsp = fasterActions(moduleName, 'loadTencentBsp');
export const createTencentBsp = fasterActions(moduleName, 'createTencentBsp');
export const editTencentBsp = fasterActions(moduleName, 'editTencentBsp');

export const loadEseConfigs = fasterActions(moduleName, 'loadEseConfigs');
export const editEseConfigs = fasterActions(moduleName, 'editEseConfigs');

export const umit = fasterActions(moduleName, 'umit');

// 字段管理
export const linksFields = fasterActions(moduleName, 'linksFields');
export const linksFieldsSelections = fasterActions(moduleName, 'linksFieldsSelections');
export const linksFieldsStatus = fasterActions(moduleName, 'linksFieldsStatus');
export const linksFieldsModify = fasterActions(moduleName, 'linksFieldsModify');

// 环节管理
export const links = fasterActions(moduleName, 'links');
export const linksStatus = fasterActions(moduleName, 'linksStatus');
export const linksModify = fasterActions(moduleName, 'linksModify');

// 物流节点
export const logisticsQuery = fasterActions(moduleName, 'logisticsQuery');
export const logisticsOperate = fasterActions(moduleName, 'logisticsOperate');
export const allLinks = fasterActions(moduleName, 'allLinks');

export const loadCodeLinksList = fasterActions(moduleName, 'loadCodeLinksList');
export const loadDiEnterpirsesLink = fasterActions(moduleName, 'loadDiEnterpirsesLink');




export const linksenterprise = fasterActions(moduleName, 'linksenterprise');
export const linksenterpriseId = fasterActions(moduleName, 'linksenterpriseId');
export const createenterpriseLink = fasterActions(moduleName, 'createenterpriseLink');


export const tagstatusSelections = fasterActions(moduleName, 'tagstatusSelections');


export const isvLinkGet = fasterActions(moduleName, 'isvLinkGet');
export const isvLinkSave = fasterActions(moduleName, 'isvLinkSave');
export const isvLinkDelete = fasterActions(moduleName, 'isvLinkDelete');


export const codeLabelSelections = fasterActions(moduleName, 'codeLabelSelections');
// 环节管理新版

//新增方案
export const addLinkPlan = fasterActions(moduleName, 'addLinkPlan');
//排序方案
export const sortLinkPlan = fasterActions(moduleName, 'sortLinkPlan');
//方案不分页
export const linkPlanSelect = fasterActions(moduleName, 'linkPlanSelect');
//搜索特定分组
export const linkPlanTypeValue = fasterActions(moduleName, 'linkPlanTypeValue');

export const linkPlanCheckRule = fasterActions(moduleName, 'linkPlanCheckRule');
//清空数据
export const unmount = fasterActions(moduleName, 'unmount');

const initialState = {

  LinksList: [],
  loadLinksListLoading: false,
  loadLinksListLoaded: false,
  linksSelectionsList: [],
  linksSelectionsList2: [],
  tencentBspContent: '',

  CodeLinksList: [],


  isvLinkList: [],


  linkPlanRule: [],

  linkPlanfilterValue: '',
}

const reducer = handleActions(
  {

    //企业列表
    [loadLinksList.REQUEST]: (state, { payload }) => ({ ...state, loadLinksListLoading: true }),
    [loadLinksList.SUCCESS]: (state, { payload }) => ({ ...state, loadLinksListLoading: false, loadLinksListLoaded: true, LinksList: payload }),
    [loadLinksList.FAIL]: (state, { payload }) => ({ ...state, loadLinksListLoading: false, loadLinksListLoaded: false, error: payload }),

    // select
    [loadLinkSelections.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadLinkSelections.SUCCESS]: (state, { payload }) => {
      const selector = [];
      const selector2 = [];
      if (payload) {
        for (let elem of payload.values()) {
          selector.push({ ...elem, id: elem.id, code: elem.code, text: elem.name + '_' + elem.code, fields: elem.fields });
          selector2.push({ ...elem, id: elem.id, code: elem.code, value: elem.id, text: elem.name, linkType: elem.linkType });
        }
      }
      return ({ ...state, linksSelectionsList: selector, linksSelectionsList2: selector2 });
    },
    [loadLinkSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [createLink.REQUEST]: (state, { payload }) => ({ ...state }),
    [createLink.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createLink.FAIL]: (state, { payload }) => ({ ...state }),

    [createenterpriseLink.REQUEST]: (state, { payload }) => ({ ...state }),
    [createenterpriseLink.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createenterpriseLink.FAIL]: (state, { payload }) => ({ ...state }),


    [loadTencentBsp.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadTencentBsp.SUCCESS]: (state, { payload }) => ({ ...state, tencentBspContent: payload ? payload : [] }),
    [loadTencentBsp.FAIL]: (state, { payload }) => ({ ...state }),
    [createTencentBsp.REQUEST]: (state, { payload }) => ({ ...state }),
    [createTencentBsp.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createTencentBsp.FAIL]: (state, { payload }) => ({ ...state }),
    [editTencentBsp.REQUEST]: (state, { payload }) => ({ ...state }),
    [editTencentBsp.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editTencentBsp.FAIL]: (state, { payload }) => ({ ...state }),


    [loadEseConfigs.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadEseConfigs.SUCCESS]: (state, { payload }) => ({ ...state, eseConfigs: payload ? payload : [] }),
    [loadEseConfigs.FAIL]: (state, { payload }) => ({ ...state }),
    [editEseConfigs.REQUEST]: (state, { payload }) => ({ ...state }),
    [editEseConfigs.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editEseConfigs.FAIL]: (state, { payload }) => ({ ...state }),


    [linksFields.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [linksFields.SUCCESS]: (state, { payload }) => ({ ...state, linksFields: payload, loading: false }),
    [linksFields.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [linksFieldsSelections.REQUEST]: (state, { payload }) => ({ ...state, payload, loadlinksFieldsSelections: true, }),
    [linksFieldsSelections.SUCCESS]: (state, { payload }) => {
      if (payload.content) {
        payload.content.forEach(v => { v.text = v.name, v.value = v.id });
      }
      return ({ ...state, linksFieldsSelections: payload, loadlinksFieldsSelections: false });
    },
    [linksFieldsSelections.FAIL]: (state, { payload }) => ({ ...state, loadlinksFieldsSelections: false }),

    [linksFieldsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, linksFieldsModifyLoaded: false }),
    [linksFieldsModify.SUCCESS]: (state, { payload }) => ({ ...state, linksFieldsModifyLoaded: payload, }),
    [linksFieldsModify.FAIL]: (state, { payload }) => ({ ...state }),

    [linksFieldsStatus.REQUEST]: (state, { payload }) => ({ ...state, payload, linksFieldsStatusLoaded: false }),
    [linksFieldsStatus.SUCCESS]: (state, { payload }) => ({ ...state, linksFieldsStatusLoaded: payload, }),
    [linksFieldsStatus.FAIL]: (state, { payload }) => ({ ...state }),


    [links.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [links.SUCCESS]: (state, { payload }) => ({ ...state, links: payload, loading: false }),
    [links.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [linksenterprise.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [linksenterprise.SUCCESS]: (state, { payload }) => ({ ...state, linksenterprise: payload, loading: false }),
    [linksenterprise.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [linksenterpriseId.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [linksenterpriseId.SUCCESS]: (state, { payload }) => ({ ...state, linksenterpriseId: payload }),
    [linksenterpriseId.FAIL]: (state, { payload }) => ({ ...state }),



    [linksModify.REQUEST]: (state, { payload }) => ({ ...state, payload, linksModifyLoaded: false }),
    [linksModify.SUCCESS]: (state, { payload }) => ({ ...state, linksModifyLoaded: payload, }),
    [linksModify.FAIL]: (state, { payload }) => ({ ...state }),

    [linksStatus.REQUEST]: (state, { payload }) => ({ ...state, payload, linksStatusLoaded: false }),
    [linksStatus.SUCCESS]: (state, { payload }) => ({ ...state, linksStatusLoaded: payload, }),
    [linksStatus.FAIL]: (state, { payload }) => ({ ...state }),

    [umit.REQUEST]: (state, { payload }) => ({ ...state, payload, tencentBspContent: '', eseConfigs: '' }),




    [loadCodeLinksList.REQUEST]: (state, { payload }) => ({ ...state, }),
    [loadCodeLinksList.SUCCESS]: (state, { payload }) => ({ ...state, CodeLinksList: payload }),
    [loadCodeLinksList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),







    [tagstatusSelections.REQUEST]: (state, { payload }) => ({ ...state, }),
    [tagstatusSelections.SUCCESS]: (state, { payload }) => {
      const selector = [];
      for (let elem of (payload.content || []).values()) {
        const obj = { ...elem, value: elem.code, text: elem.name }
        selector.push(obj);
      }
      let tagstatusSelections = [];
      let tagstatusSelections1 = [];
      for (let elem of payload.content) {
        const obj = { ...elem, value: elem.code, text: elem.name, id: elem.code }
        tagstatusSelections.push(obj);
        tagstatusSelections1.push(obj)
      }
      let obj1 = { text: '无初始状态', value: 'default', id: 9999 }
      tagstatusSelections1.unshift(obj1)
      return { ...state, tagstatusSelections, tagstatusSelections1 }
    },

    [tagstatusSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [loadDiEnterpirsesLink.REQUEST]: (state, { payload }) => ({ ...state, }),
    [loadDiEnterpirsesLink.SUCCESS]: (state, { payload }) => {
      let selector = [];
      if (payload.content) {
        payload.content.forEach(v => { if (v.link.id) { selector.push({ ...v, text: v.link.name, value: v.link.code }) } });
      }
      return ({ ...state, diEnterpirsesLinkList: selector })
    },
    [loadDiEnterpirsesLink.FAIL]: (state, { payload }) => ({ ...state, error: payload }),










    [isvLinkGet.REQUEST]: (state, { payload }) => ({ ...state, }),
    [isvLinkGet.SUCCESS]: (state, { payload }) => ({ ...state, isvLinkList: payload }),
    [isvLinkGet.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



    [isvLinkSave.REQUEST]: (state, { payload }) => ({ ...state, }),
    [isvLinkSave.SUCCESS]: (state, { payload }) => ({ ...state, isvLinkSave: payload }),
    [isvLinkSave.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [isvLinkDelete.REQUEST]: (state, { payload }) => ({ ...state, }),
    [isvLinkDelete.SUCCESS]: (state, { payload }) => ({ ...state, isvLinkDelete: payload }),
    [isvLinkDelete.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



    [codeLabelSelections.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [codeLabelSelections.SUCCESS]: (state, { payload }) => {
      return ({ ...state, codeLabelSelection: payload.records || [], loading: false })
    },
    [codeLabelSelections.FAIL]: (state, { payload }) => ({ ...state, loading: false }),





    // 环节管理新版
    [addLinkPlan.REQUEST]: (state, { payload }) => ({ ...state, }),
    [addLinkPlan.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [addLinkPlan.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    //获取环境方案
    [linkPlanSelect.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [linkPlanSelect.SUCCESS]: (state, { payload }) => {
      let linkPlanTypeValueList = payload.filter(key => { return (key.name || '').indexOf(state.linkPlanfilterValue) >= 0 || (key.id.toString()).indexOf(state.linkPlanfilterValue) >= 0 });
      return { ...state, linkPlanSelection: linkPlanTypeValueList, linkPlanAllSelection: payload, loading: false }

    },

    [linkPlanTypeValue.REQUEST]: (state, { payload }) => {
      let linkPlanTypeValueList = state.linkPlanAllSelection.filter(key => { return (key.name || '').indexOf(payload) >= 0 || (key.id.toString()).indexOf(payload) >= 0 });
      return { ...state, linkPlanfilterValue: payload, linkPlanSelection: linkPlanTypeValueList }
      // return { ...state, PlanfilterValue: payload }
    },


    [linkPlanSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),


    //排序环节方案
    [sortLinkPlan.REQUEST]: (state, { payload }) => ({ ...state }),
    [sortLinkPlan.SUCCESS]: (state, { payload }) => ({ ...state }),
    [sortLinkPlan.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    //RULE
    [linkPlanCheckRule.REQUEST]: (state, { payload }) => ({ ...state, }),
    [linkPlanCheckRule.SUCCESS]: (state, { payload }) => ({ ...state, linkPlanRule: payload }),
    [linkPlanCheckRule.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [unmount.REQUEST]: (state, { payload }) => ({
      linkPlanSelection: [],
      linkPlanfilterValue: '',

    }),

    [logisticsQuery.REQUEST]: (state, { payload }) => ({ ...state, logisticsLoading: true }),
    [logisticsQuery.SUCCESS]: (state, { payload }) => ({ ...state, logisticsLoading: false,  logisticsPageData: payload }),
    [logisticsQuery.FAIL]: (state, { payload }) => ({ ...state, logisticsLoading: false,  error: payload }),

    [logisticsOperate.REQUEST]: (state, { payload }) => ({ ...state }),
    [logisticsOperate.SUCCESS]: (state, { payload }) => ({ ...state}),
    [logisticsOperate.FAIL]: (state, { payload }) => ({ ...state,error: payload }),

    [allLinks.REQUEST]: (state, { payload }) => ({ ...state }),
    [allLinks.SUCCESS]: (state, { payload }) => ({ ...state,allLinksList:payload}),
    [allLinks.FAIL]: (state, { payload }) => ({ ...state,error: payload }),

  },
  initialState
);

export default reducer;
