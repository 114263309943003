import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './isvUser';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: isv user
 */


// isv账号分页查询
export const isvUserEpic = actions$ => actions$.pipe(
  ofType(actions.isvUser.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { keywords = '', status = '', page, size } = action.payload;
    let url = `${urls.data.isvUser.isvUser}?page=${page}&size=${size}&sort=createdTime,desc&word=${keywords}`;
    if (status) {
      status.forEach(val => val && (url += `&statuses=${val}`))
    }
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.isvUser.SUCCESS(res.response)),
      catchError(error => of(actions.isvUser.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// isv用户查询（userId查询）
export const isvUserByIdEpic = actions$ => actions$.pipe(
  ofType(actions.isvUserById.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { userId } = action.payload;
    return ajax({
      ...apiBaseConfig,
      url: `${urls.data.isvUser.isvUser}/${userId}`,
    }).pipe(
      map(res => actions.isvUserById.SUCCESS(res.response)),
      catchError(error => of(actions.isvUserById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 禁用/启用isv账号
export const isvUserStatusEpic = actions$ => actions$.pipe(
  ofType(actions.isvUserStatus.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { userId, status } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.data.isvUser.isvUser}/${userId}/status?status=${status}`,
      body: action.payload
    }).pipe(
      map(res => actions.isvUserStatus.SUCCESS(res.status)),
      catchError(error => of(actions.isvUserStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 创建/编辑 isv账号
export const isvUserModifyEpic = actions$ => actions$.pipe(
  ofType(actions.isvUserModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      method: action.payload.userId ? 'PUT' : 'POST',
      url: `${urls.data.isvUser.isvUser}`,
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.isvUserModify.SUCCESS(res.status), uiActions.notification(action.payload.userId ? '修改成功' : '创建成功', 'success')]),
      catchError(error => of(actions.isvUserModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 重置密码
export const isvUserModifyPwdEpic = actions$ => actions$.pipe(
  ofType(actions.isvUserModifyPwd.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { userId, password } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.data.isvUser.isvUser}/${userId}/password`,
      body: { password: password },
      // body: action.payload
    }).pipe(
      mergeMap(res => [actions.isvUserModifyPwd.SUCCESS(res.status), uiActions.notification('修改成功', 'success')]),
      catchError(error => of(actions.isvUserModifyPwd.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// isv用户白名单查询（分页查询）
export const isvUserWhitelistsEpic = actions$ => actions$.pipe(
  ofType(actions.isvUserWhitelists.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { userId, keywords = '', status = '', page, size } = action.payload;
    let url = `${urls.data.isvUser.isvUser}/${userId}/whitelists?page=${page}&size=${size}&sort=createdTime,desc&ip=${keywords}`;
    if (status) {
      status.forEach(val => val && (url += `&statuses=${val}`))
    }
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.isvUserWhitelists.SUCCESS(res.response)),
      catchError(error => of(actions.isvUserWhitelists.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// isv用户白名单启用/禁用
export const isvUserWhitelistsStatusEpic = actions$ => actions$.pipe(
  ofType(actions.isvUserWhitelistsStatus.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id, status } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.data.isvUser.isvUser}/whitelists/${id}/status?status=${status}`,
      body: action.payload
    }).pipe(
      map(res => actions.isvUserWhitelistsStatus.SUCCESS(res.status)),
      catchError(error => of(actions.isvUserWhitelistsStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 创建/编辑 isv白名单
export const isvUserWhitelistsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.isvUserWhitelistsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id, userId, whitelists } = action.payload;
    let url = `${urls.data.isvUser.isvUser}/whitelists`;
    if (!id) {
      url = `${urls.data.isvUser.isvUser}/${userId}/whitelists`;
    }

    return ajax({
      ...ajaxBaseConfig,
      method: id ? 'PUT' : 'POST',
      url,
      body: id ? action.payload : whitelists
    }).pipe(
      mergeMap(res => [actions.isvUserWhitelistsModify.SUCCESS(res.status), uiActions.notification(id ? '修改成功' : '创建成功', 'success')]),
      catchError(error => of(actions.isvUserWhitelistsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// isv用户分配企业查询（分页查询）
export const isvUserEnterprisesEpic = actions$ => actions$.pipe(
  ofType(actions.isvUserEnterprises.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { userId, keywords = '', page, size } = action.payload;
    let url = `${urls.data.isvUser.isvUser}/${userId}/enterprises?page=${page}&size=${size}`;
    if (keywords) {
      url += `&word=${keywords}`;
    }
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.isvUserEnterprises.SUCCESS(res.response)),
      catchError(error => of(actions.isvUserEnterprises.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// isv用户分配企业
export const isvUserAllocationEpic = actions$ => actions$.pipe(
  ofType(actions.isvUserAllocation.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { userId, eseIds } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.data.isvUser.isvUser}/${userId}/allocation`,
      body: eseIds
    }).pipe(
      mergeMap(res => [actions.isvUserAllocation.SUCCESS(res.status), uiActions.notification('操作成功', 'success')]),
      catchError(error => of(actions.isvUserAllocation.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// isv移除用户分配企业
export const isvUserEnterprisesRemoveEpic = actions$ => actions$.pipe(
  ofType(actions.isvUserEnterprisesRemove.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { userId, eseIds } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'DELETE',
      url: `${urls.data.isvUser.isvUser}/${userId}/enterprises/ids`,
      body: eseIds
    }).pipe(
      mergeMap(res => [actions.isvUserEnterprisesRemove.SUCCESS(res.status), uiActions.notification('移除成功', 'success')]),
      catchError(error => of(actions.isvUserEnterprisesRemove.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// isv企业分配角色
export const isvUserAssignRolesEpic = actions$ => actions$.pipe(
  ofType(actions.isvUserAssignRoles.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { userId, eseId, roleIds } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.data.isvUser.isvUser}/${userId}/enterprises/${eseId}/roles`,
      body: roleIds
    }).pipe(
      mergeMap(res => [actions.isvUserAssignRoles.SUCCESS(res.status), uiActions.notification('分配成功', 'success')]),
      catchError(error => of(actions.isvUserAssignRoles.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// isv用户分配企业id查询
export const isvUserEnterprisesListEpic = actions$ => actions$.pipe(
  ofType(actions.isvUserEnterprisesList.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { userId } = action.payload;
    return ajax({
      ...apiBaseConfig,
      url: `${urls.data.isvUser.isvUser}/${userId}/enterprises/ids`,
    }).pipe(
      map(res => actions.isvUserEnterprisesList.SUCCESS(res.response)),
      catchError(error => of(actions.isvUserEnterprisesList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// isv用户分配角色id查询
export const isvUserAssignRolesListEpic = actions$ => actions$.pipe(
  ofType(actions.isvUserAssignRolesList.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { userId, eseId } = action.payload;
    return ajax({
      ...apiBaseConfig,
      url: `${urls.data.isvUser.isvUser}/${userId}/enterprises/${eseId}/roles/ids`,
    }).pipe(
      map(res => actions.isvUserAssignRolesList.SUCCESS(res.response)),
      catchError(error => of(actions.isvUserAssignRolesList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);