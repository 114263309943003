import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './reportRebate';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

const parseParam = function params(obj) {
    let result = '';
    let item;
    for (item in obj) {
        if (obj[item] && String(obj[item])) {
            result += `&${item}=${obj[item]}`;
        }
    }
    if (result) {
        result = '?' + result.slice(1);
    }
    return result;
}


// 分页查询终端返利统计报表
export const getDealerPageEpic = actions$ => actions$.pipe(
    ofType(actions.getDealerPage.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, startTime = '', endTime = '', dealerLevels = [], dealerIds = [], sort = 'tagdate,desc', assistantIds = [], rebateSourceTypes = [], rebatePromotionIds = '', rebatePlanIds = [], productIds = [], provinceCode = '', cityCode = '', areaType = '' } = action.payload;
        let data = { page, size, startTime, endTime, dealerLevels, dealerIds, sort, assistantIds, rebateSourceTypes, rebatePromotionIds, rebatePlanIds, productIds, provinceCode, cityCode, areaType }
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.rebate.getDealerPage}${parseParam(data)}`
        }).pipe(
            map(res => actions.getDealerPage.SUCCESS(res.response)),
            catchError(error => of(actions.getDealerPage.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
// 分页查询终端返利统计报表
export const getShopPageEpic = actions$ => actions$.pipe(
    ofType(actions.getShopPage.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, startTime = '', endTime = '', dealerLevels = [], dealerIds = [], sort = 'tagdate,desc', assistantIds = [], rebateSourceTypes = [], rebatePromotionIds = '', rebatePlanIds = [], productIds = [], provinceCode = '', cityCode = '', areaType = '' } = action.payload;
        let data = { page, size, startTime, endTime, dealerLevels, dealerIds, sort, assistantIds, rebateSourceTypes, rebatePromotionIds, rebatePlanIds, productIds, provinceCode, cityCode, areaType }
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.rebate.getShopPage}${parseParam(data)}`
        }).pipe(
            map(res => actions.getShopPage.SUCCESS(res.response)),
            catchError(error => of(actions.getShopPage.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
// 分页查询机修工返利统计报表
export const getMechanicPageEpic = actions$ => actions$.pipe(
    ofType(actions.getMechanicPage.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        
        const { page = 0, size = 20, startTime = '', endTime = '', mechanicInfo = '', promotionType = '', promotionId = '', deptId = '', planId = '', productIds = '', sort = 'tagdate,desc', provinceCode = '', cityCode = '', areaType = '' } = action.payload;
        
        let data = { page, size, startTime, endTime, mechanicInfo, promotionType, promotionId, deptId, planId, productIds, sort, provinceCode, cityCode, areaType }
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.rebate.getMechanicPage}${parseParam(data)}`
        }).pipe(
            map(res => actions.getMechanicPage.SUCCESS(res.response)),
            catchError(error => of(actions.getMechanicPage.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
// 
//下拉框
export const getAllRebateActiveEpic = actions$ => actions$.pipe(
    ofType(actions.getAllRebateActive.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        let url = `${urls.rebate.getAllRebateActive}`;
        if (action.payload && action.payload.page) {
            url += `?isPage=${action.payload.page}`;
        }
        else {
            url += `?isPage=false`;
        }

        if (action.payload && action.payload.status) {
            url += `&status=${action.payload.status}`;
        }
        if (action.payload && action.payload.size) {
            url += `&size=${action.payload.size}`;
        }

        if (action.payload && action.payload.word) {
            url += `&word=${action.payload.word}`;
        }

        // const { status} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url,
            //  url: `${urls.data.dealers.dealers}/selections?status=${status}`,
        }).pipe(
            map(res => actions.getAllRebateActive.SUCCESS(res.response)),
            catchError(error => of(actions.getAllRebateActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
//下拉框
export const getAllRebateAccountEpic = actions$ => actions$.pipe(
    ofType(actions.getAllRebateAccount.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        let url = `${urls.rebate.getAllRebateAccount}`;
        if (action.payload && action.payload.page) {
            url += `?isPage=${action.payload.page}`;
        }
        else {
            url += `?isPage=false`;
        }

        if (action.payload && action.payload.status) {
            url += `&status=${action.payload.status}`;
        }
        if (action.payload && action.payload.size) {
            url += `&size=${action.payload.size}`;
        }

        if (action.payload && action.payload.word) {
            url += `&word=${action.payload.word}`;
        }

        // const { status} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url,
            //  url: `${urls.data.dealers.dealers}/selections?status=${status}`,
        }).pipe(
            map(res => actions.getAllRebateAccount.SUCCESS(res.response)),
            catchError(error => of(actions.getAllRebateAccount.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const getAllCaseEpic = actions$ => actions$.pipe(
    ofType(actions.getAllCase.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { activityId } = action.payload;
        let url = `${urls.rebate.getAllCase}?activityId=${activityId}`
        if (action.payload && action.payload.page) {
            url += `&isPage=${action.payload.page}`;
        } else {
            url += `&isPage=false`;
        }

        if (action.payload && action.payload.status) {
            url += `&status=${action.payload.status}`;
        }
        if (action.payload && action.payload.size) {
            url += `&size=${action.payload.size}`;
        }

        if (action.payload && action.payload.word) {
            url += `&word=${action.payload.word}`;
        }
        return ajax({
            ...ajaxBaseConfig,
            url,
        }).pipe(
            map(res => actions.getAllCase.SUCCESS(res.response)),
            catchError(error => of(actions.getAllCase.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
