import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';


const moduleName = 'h5CodePackage';

/**
 * TODO: h5代码包管理
 */

export const h5CodePackage = fasterActions(moduleName, 'h5CodePackage');
export const h5CodePackageadd = fasterActions(moduleName, 'h5CodePackageadd');
export const h5CodePackageDel = fasterActions(moduleName, 'h5CodePackageDel');
export const h5PackageAll = fasterActions(moduleName, 'h5PackageAll');
export const h5LinkTable = fasterActions(moduleName, 'h5LinkTable');
export const h5Linkadd = fasterActions(moduleName, 'h5Linkadd');

export const h5ruleTable = fasterActions(moduleName, 'h5ruleTable');
export const h5ruleAdd = fasterActions(moduleName, 'h5ruleAdd');
export const h5ruleDel = fasterActions(moduleName, 'h5ruleDel');
export const domainAll = fasterActions(moduleName, 'domainAll');
export const h5PathAdd = fasterActions(moduleName, 'h5PathAdd');
export const h5PathEdit = fasterActions(moduleName, 'h5PathEdit');
export const h5PathDel = fasterActions(moduleName, 'h5PathDel');


const initialState = {
  h5CodePackageloading:false,
  h5CodePackageloaded:false,
  h5CodePackageList:[],
  loading:false,
  loaded:false,
  h5CodePackageaddPackage:[],
  h5CodePackageDelete:[],
  h5linkList:[],
  h5Linkaddlink:[],
  selectLoading:false,
  selectloaded:false,
  h5PackageAllList:[],
  h5ruleTableRule:[],
  domainAllloading:false,
  domainAllloaded:false,
  domainAllList:[],
  h5PathEditList:[],
  h5PathAddList:[],
  h5PathDelList:[],
  h5ruleAddloading:false,
  h5ruleAddloaded:false,
  h5ruleAddList:[],
}

const reducer = handleActions(
  {
    [h5CodePackage.REQUEST]: (state, { payload }) => ({ ...state, payload, h5CodePackageloading: true }),
    [h5CodePackage.SUCCESS]: (state, { payload }) => ({ ...state, h5CodePackageList: payload, h5CodePackageloading: false, h5CodePackageloaded:true }),
    [h5CodePackage.FAIL]: (state, { payload }) => ({ ...state, error: payload, h5CodePackageloading: false, h5CodePackageloaded:false }),
    [h5CodePackageadd.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [h5CodePackageadd.SUCCESS]: (state, { payload }) => ({ ...state, h5CodePackageaddPackage: payload, loading: false, loaded:true }),
    [h5CodePackageadd.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded:false }),
    [h5CodePackageDel.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [h5CodePackageDel.SUCCESS]: (state, { payload }) => ({ ...state, h5CodePackageDelete: payload, loading: false, loaded:true }),
    [h5CodePackageDel.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded:false }),
    [h5PackageAll.REQUEST]: (state, { payload }) => ({ ...state, payload, selectLoading: true }),
    [h5PackageAll.SUCCESS]: (state, { payload }) => ({ ...state, h5PackageAllList: payload, selectLoading: false, selectloaded:true }),
    [h5PackageAll.FAIL]: (state, { payload }) => ({ ...state, error: payload, selectLoading: false, selectloaded:false }),

    [h5LinkTable.REQUEST]: (state, { payload }) => ({ ...state, payload, h5CodePackageloading: true }),
    [h5LinkTable.SUCCESS]: (state, { payload }) => ({ ...state, h5linkList: payload, h5CodePackageloading: false, h5CodePackageloaded:true }),
    [h5LinkTable.FAIL]: (state, { payload }) => ({ ...state, error: payload, h5CodePackageloading: false, h5CodePackageloaded:false }),
    [h5Linkadd.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [h5Linkadd.SUCCESS]: (state, { payload }) => ({ ...state, h5Linkaddlink: payload, loading: false, loaded:true }),
    [h5Linkadd.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded:false }),

    [h5ruleTable.REQUEST]: (state, { payload }) => ({ ...state, payload, h5CodePackageloading: true }),
    [h5ruleTable.SUCCESS]: (state, { payload }) => ({ ...state, h5ruleTableRule: payload, h5CodePackageloading: false, h5CodePackageloaded:true }),
    [h5ruleTable.FAIL]: (state, { payload }) => ({ ...state, error: payload, h5CodePackageloading: false, h5CodePackageloaded:false }),
    [h5ruleAdd.REQUEST]: (state, { payload }) => ({ ...state, payload, h5ruleAddloading: true }),
    [h5ruleAdd.SUCCESS]: (state, { payload }) => ({ ...state, h5ruleDelRule: payload, h5ruleAddloading: false, h5ruleAddloaded:true }),
    [h5ruleAdd.FAIL]: (state, { payload }) => ({ ...state, error: payload, h5ruleAddloading: false, h5ruleAddloaded:false }),
    [h5ruleDel.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [h5ruleDel.SUCCESS]: (state, { payload }) => ({ ...state, h5ruleAddList: payload, loading: false, loaded:true }),
    [h5ruleDel.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded:false }),

    [domainAll.REQUEST]: (state, { payload }) => ({ ...state, payload, domainAllloading: true }),
    [domainAll.SUCCESS]: (state, { payload }) => ({ ...state, domainAllList: payload, domainAllloading: false, domainAllloaded:true }),
    [domainAll.FAIL]: (state, { payload }) => ({ ...state, error: payload, domainAllloading: false, domainAllloaded:false }),

    [h5PathAdd.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [h5PathAdd.SUCCESS]: (state, { payload }) => ({ ...state, h5PathAddList: payload, loading: false, loaded:true }),
    [h5PathAdd.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded:false }),
    [h5PathEdit.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [h5PathEdit.SUCCESS]: (state, { payload }) => ({ ...state, h5PathEditList: payload, loading: false, loaded:true }),
    [h5PathEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded:false }),
    [h5PathDel.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [h5PathDel.SUCCESS]: (state, { payload }) => ({ ...state, h5PathDelList: payload, loading: false, loaded:true }),
    [h5PathDel.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded:false }),
  },

  initialState
);

export default reducer;
