import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './dictionary';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 主数据字典管理
 */

// 单据列表
export const dictoinaryListEpic = actions$ => actions$.pipe(
    ofType(actions.dictoinaryList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page, size, dictDataType, code = '', dictTypeListVal = [] } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.dictionary.dict}?page=${page}&size=${size}&dictDataType=${dictDataType}&code=${code}&dictTypeList=${dictTypeListVal}`
        }).pipe(
            map(res => actions.dictoinaryList.SUCCESS(res.response)),
            catchError(error => of(actions.dictoinaryList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 创建单据
export const createBillEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.createDictionary.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                method: data.id ? 'PUT' : 'POST',
                url: `${urls.data.dictionary.dict}`,
                body: action.payload.data,
            }).pipe(
                concatMap(res => [actions.createDictionary.SUCCESS(res.status), actions.dictoinaryList.REQUEST(state$.value.dictionary.dictPaging), uiActions.notification(data.id ? '修改成功' : '创建成功', 'success')]),
                catchError(error => of(actions.createDictionary.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 禁用、启用
export const enableOrDisabledDictEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableOrDisabledDict.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id = '', status = '' } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.dictionary.dict}/${id}/status?status=${status}`,
                method: 'PATCH',
            }).pipe(
                concatMap(res => [actions.enableOrDisabledDict.SUCCESS(res.response), actions.dictoinaryList.REQUEST(state$.value.dictionary.dictPaging)]),
                catchError(error => of(actions.enableOrDisabledDict.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 新增枚举
export const addEnumsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.addEnums.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id = '', dictItems } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.dictionary.dict}/${id}/enums`,
                method: 'PATCH',
                body: dictItems
            }).pipe(
                concatMap(res => [actions.addEnums.SUCCESS(res.status), actions.dictoinaryList.REQUEST(state$.value.dictionary.dictPaging)]),
                catchError(error => of(actions.addEnums.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 字段查询
export const queryDictListEpic = actions$ => actions$.pipe(
    ofType(actions.queryDictList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { dictDataType = '' } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.dictionary.query}?dictDataType=${dictDataType}`
        }).pipe(
            map(res => actions.queryDictList.SUCCESS(res.response)),
            catchError(error => of(actions.queryDictList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);