import { createAction, handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'certificate';
// 证书类型
export const loadCertificateSelections = fasterActions(moduleName, 'loadCertificateSelections');

export const factories = fasterActions(moduleName, 'factories');

// 查看
export const loadCertificateId = fasterActions(moduleName, 'loadCertificateId');
// export const createFactory = fasterActions(moduleName, 'createFactory');
// 启用禁用
export const enableOrDisabledCertificate = fasterActions(moduleName, 'enableOrDisabledCertificate');

export const uploadFactorySearchKey = createAction(`${moduleName}/uploadFactorySearchKey`);

// 列表
export const loadCertificateList = fasterActions(moduleName, 'loadCertificateList');
//创建
export const loadCertificateAdd = fasterActions(moduleName, 'loadCertificateAdd');
// 编辑
export const loadCertificateEdit = fasterActions(moduleName, 'loadCertificateEdit');

// 工控机
// 列表
export const loadIpcList = fasterActions(moduleName, 'loadIpcList');
// 启用禁用
export const enableOrDisabledIpc = fasterActions(moduleName, 'enableOrDisabledIpc');
// 编辑
export const ipcEdit = fasterActions(moduleName, 'ipcEdit');
//创建
// 工控机列表
export const loadIpcSelections = fasterActions(moduleName, 'loadIpcSelections');
// 下载
export const exportCertificate = fasterActions(moduleName, 'exportCertificate');


const initialState = {
  factorySelectionsLoading: false,
  factorySelectionsLoaded: false,
  printFactorySelector: [],
  productFactorySelector: [],
  ipcList: [],
  certificateList: [],
  factoryDetail: {},
  factorySearchKey: '',
  couponConfigsAllList: [],
  ipcSelectionsList: [],
  certificateListParam: {
    page: 0,
    size: 20,
    sort: 'id,desc',
    factoryType: ''
  },
  ipcParam: {
    page: 0,
    size: 20,
    sort: 'id,desc',
    factoryType: ''
  }

}

const reducer = handleActions(
  {
    [loadCertificateSelections.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadCertificateSelections.SUCCESS]: (state, { payload }) => {
      console.log(payload)
      const selector = [];
      if (payload) {
        for (let elem of payload.values()) {
          selector.push({ value: elem.code, text: elem.name })
        }
      }
      console.log(selector)
      return ({ ...state, couponConfigsAllList: selector })
    },
    [loadCertificateSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 工控机列表
    [loadIpcSelections.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadIpcSelections.SUCCESS]: (state, { payload }) => {
      console.log(payload)
      const selector1 = [];
      if (payload) {
        for (let elem of payload.values()) {
          selector1.push({ value: elem.id, text: elem.name })
        }
      }
     
      return ({ ...state, ipcSelectionsList: selector1 })
    },
    [loadIpcSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



    [loadCertificateList.REQUEST]: (state, { payload }) => {
      return ({ ...state, ...payload, factoryPageListLoading: true })
    },
    [loadCertificateList.SUCCESS]: (state, { payload }) => {
      const { content = [], pageable = {}, totalElements = 0 } = payload;
      return ({ ...state, certificateListParam: { ...state.certificateListParam, totalElements: totalElements || 0 }, certificateList: content, factoryPageListLoading: false, })
    },
    [loadCertificateList.FAIL]: (state, { payload }) => ({ ...state, factoryPageListLoading: false, error: payload }),


    [loadIpcList.REQUEST]: (state, { payload }) => {
      return ({ ...state, ...payload, factoryPageListLoading: true })
    },
    [loadIpcList.SUCCESS]: (state, { payload }) => {
      const { content = [], pageable = {}, totalElements = 0 } = payload;
      return ({ ...state, ipcParam: { ...state.ipcParam, totalElements: totalElements || 0 }, ipcList: content, factoryPageListLoading: false, })
    },
    [loadIpcList.FAIL]: (state, { payload }) => ({ ...state, factoryPageListLoading: false, error: payload }),

    [loadCertificateId.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [loadCertificateId.SUCCESS]: (state, { payload }) => ({ ...state, factoryDetail: payload }),
    [loadCertificateId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [enableOrDisabledCertificate.REQUEST]: (state, { payload }) => ({ ...state }),
    [enableOrDisabledCertificate.SUCCESS]: (state, { payload }) => ({ ...state }),
    [enableOrDisabledCertificate.FAIL]: (state, { payload }) => ({ ...state }),

    [enableOrDisabledIpc.REQUEST]: (state, { payload }) => ({ ...state }),
    [enableOrDisabledIpc.SUCCESS]: (state, { payload }) => ({ ...state }),
    [enableOrDisabledIpc.FAIL]: (state, { payload }) => ({ ...state }),


    [loadCertificateAdd.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadCertificateAdd.SUCCESS]: (state, { payload }) => ({ ...state }),
    [loadCertificateAdd.FAIL]: (state, { payload }) => ({ ...state }),

    [loadCertificateEdit.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadCertificateEdit.SUCCESS]: (state, { payload }) => ({ ...state }),
    [loadCertificateEdit.FAIL]: (state, { payload }) => ({ ...state }),

    // 存储state
    [uploadFactorySearchKey]: (state, { payload }) => ({ ...state, ...payload }),

    [exportCertificate.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [exportCertificate.SUCCESS]: (state, { payload }) => ({ ...state, payload }),
    [exportCertificate.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // [factories.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    // [factories.SUCCESS]: (state, { payload }) => {
    //   const factory = [{ value: '', text: '请选择包材印刷工厂' }];
    //   const productFactory = [{ value: '', text: '请选择成品灌装厂' }];

    //   if (payload.content) {
    //     payload.content.forEach(val => {
    //       val.text = val.name;
    //       val.value = val.id;
    //       if (val.factoryType == 'PRINT') {
    //         factory.push(val);
    //       } else if (val.factoryType == 'PACKAGE') {
    //         productFactory.push(val);
    //       }
    //     });
    //   }

    //   return ({ ...state, factories: { factory, productFactory } })
    // },
    // [factories.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

  },
  initialState
)

export default reducer;