import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'singleCodeQueryToolType';
export const singleCodeQueryToolTypeList = fasterActions(moduleName, 'singleCodeQueryToolTypeList');
export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const singleCodeQueryToolTypeStatus = fasterActions(moduleName, 'singleCodeQueryToolTypeStatus');
export const singleCodeQueryToolTypeUpdate = fasterActions(moduleName, 'singleCodeQueryToolTypeUpdate');
export const singleCodeQueryToolTypeDelete = fasterActions(moduleName, 'singleCodeQueryToolTypeDelete');

const initialState = {
  dictPaging: {
    page: 0,
    size: 20,
  },
  singleCodeQueryToolTypeData: {},
}

const reducer = handleActions(

  {
    [changeDictPaging]: (state, { payload }) => {
      return { ...state, dictPaging: { ...payload } }
    },

    [singleCodeQueryToolTypeList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true,singleCodeQueryToolTypeData:{} }),
    [singleCodeQueryToolTypeList.SUCCESS]: (state, { payload }) => ({ ...state, singleCodeQueryToolTypeData: payload, loading: false }),
    [singleCodeQueryToolTypeList.FAIL]: (state, { payload }) => ({ ...state, loading: false,singleCodeQueryToolTypeData:{} }),

    [singleCodeQueryToolTypeStatus.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [singleCodeQueryToolTypeStatus.SUCCESS]: (state, { payload }) => ({ ...state, singleCodeQueryToolTypeData: payload, loading: false }),
    [singleCodeQueryToolTypeStatus.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [singleCodeQueryToolTypeUpdate.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }), 
    [singleCodeQueryToolTypeUpdate.SUCCESS]: (state, { payload }) => ({ ...state, singleCodeQueryToolTypeData: payload, loading: false }),
    [singleCodeQueryToolTypeUpdate.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [singleCodeQueryToolTypeDelete.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }), 
    [singleCodeQueryToolTypeDelete.SUCCESS]: (state, { payload }) => ({ ...state, singleCodeQueryToolTypeData: payload, loading: false }),
    [singleCodeQueryToolTypeDelete.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

  },
  initialState
);

export default reducer;