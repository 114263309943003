import React from 'react';
import { CodeRouter } from '../../../routes';

class ExtractionCode extends React.Component {

  render() {
    return (
      <React.Fragment>
        {CodeRouter()}
      </React.Fragment>
    );
  }
}

export default ExtractionCode;

