import { createAction, handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'identification';

/**
 * TODO: 移动端账号
 */
// 列表分页
export const loadIdentificationList = fasterActions(moduleName, 'loadIdentificationList');
export const loadIdentificationList1 = fasterActions(moduleName, 'loadIdentificationList1');
// 导出
export const loadIdentificationExport = fasterActions(moduleName, 'loadIdentificationExport');
// id查询
export const loadIdentificationId = fasterActions(moduleName, 'loadIdentificationId');
export const loadAddressBookById = fasterActions(moduleName, 'loadAddressBookById');
export const loadAddressBookSelections = fasterActions(moduleName, 'loadAddressBookSelections');
export const enableOrDisableAddressBooks = fasterActions(moduleName, 'enableOrDisableAddressBooks');
export const createAddressbook = fasterActions(moduleName, 'createAddressbook');
export const editAddressbook = fasterActions(moduleName, 'editAddressbook');
// 密码
export const loadpassword = fasterActions(moduleName, 'loadpassword');



export const updateAddressBookSearchKey = createAction(`${moduleName}/updateAddressBookSearchKey`);
export const loadwarehouseSelections = fasterActions(moduleName, 'loadwarehouseSelections');
// 角色
export const loadrolesSelections = fasterActions(moduleName, 'loadrolesSelections');


const initialState = {
    identificationList: [],
    perPageCountList: [20, 50, 100],
    addressBookSearchKey: '',
    addressBookDetail: {},
    warehouseName: '',
    wordName:"",
    addressBookPageParam: {
        size: 20,
        page: 0,
        sort: 'id,desc',
        totalElements: 0,
        deptIds: '',
        type: '',
        status: ''
    }
}

const reducer = handleActions({
    // 分页列表
    [loadIdentificationList.REQUEST]: (state, { payload }) => ({ ...state, addressBookPageParam: payload, addressBookPageListLoading: true }),
    [loadIdentificationList.SUCCESS]: (state, { payload }) => ({ ...state, identificationList: payload.content || [], addressBookPageParam: { ...state.addressBookPageParam, totalElements: payload.totalElements }, addressBookPageListLoading: false }),
    // [loadIdentificationList.SUCCESS]: (state, { payload }) => {
    //     let promotionCodeArr = [];
    //     if (payload&&payload.content&&payload.content.length>0) {
    //         payload.content.forEach(item => { promotionCodeArr.push({ value: item.promotionCode, text: item.account+' '+ item.promotionCode }) });
    //     }
    //     debugger
    //     console.log(promotionCodeArr,"promotionCodeArr")
    //     let identificationList = payload.content || [];
    //     return { ...state, state['addressBookPageParam']['account']?promotionCodeArr:identificationList }
    // },
    [loadIdentificationList.FAIL]: (state, { payload }) => ({ ...state, error: payload, addressBookPageListLoading: false }),
    [loadIdentificationList1.REQUEST]: (state, { payload }) => ({ ...state, addressBookPageParam: payload, addressBookPageListLoading: true }),
    [loadIdentificationList1.SUCCESS]: (state, { payload }) => ({ ...state, promotionCodeArr: payload.content || [], addressBookPageParam: { ...state.addressBookPageParam, totalElements: payload.totalElements }, addressBookPageListLoading: false }),
    [loadIdentificationList1.FAIL]: (state, { payload }) => ({ ...state, error: payload, addressBookPageListLoading: false }),
    
    [loadIdentificationExport.REQUEST]: (state, { payload }) => ({ ...state, }),
    [loadIdentificationExport.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [loadIdentificationExport.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
    // id 详情
    [loadIdentificationId.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadIdentificationId.SUCCESS]: (state, { payload }) => ({ ...state, addressBookDetail: payload }),
    [loadIdentificationId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 下拉选项
    [loadAddressBookSelections.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadAddressBookSelections.SUCCESS]: (state, { payload }) => {
        let addressBookSelections = [];
        if (payload.content) {
            payload.content.forEach(v => { addressBookSelections.push({ text: v.name + (v.mobile ? v.mobile : '') + (v.deptName ? v.deptName : ''), value: v.id, id: v.id }) });
        }
        return { ...state, addressBookSelections }
    },
    [loadAddressBookSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 仓库下拉选项
    [loadwarehouseSelections.REQUEST]: (state, { payload }) => ({ ...state, warehouseName: payload, addressBookPageListLoading: true }),
    [loadwarehouseSelections.SUCCESS]: (state, { payload }) => {
        let warehouseSelections = [];
        
        if (payload) {
            if((state.warehouseName === "ENTERPRISE"||state.warehouseName.sourceType==="ENTERPRISE")&&payload.content){
                payload.content.forEach(v => { warehouseSelections.push({ text: v.name , value: v.id.toString()}) });
            }else {
                payload.forEach(v => { warehouseSelections.push({ text: v.name , value: v.id.toString()}) });
            }
        }
        return { ...state, warehouseSelections }
    },
    [loadwarehouseSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    
    // 角色下拉选项
    [loadrolesSelections.REQUEST]: (state, { payload }) => ({ ...state, wordName: payload, addressBookPageListLoading: true }),
    [loadrolesSelections.SUCCESS]: (state, { payload }) => {
        let getlistList = [];
       
        if (payload) {
            payload.forEach(item => { getlistList.push({ id: item.id, value: item.id, text: item.name }) });
        }
 console.log(getlistList,"getlistList")
        return { ...state, getlistList }
    },
    [loadrolesSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 禁用/启用
    [enableOrDisableAddressBooks.REQUEST]: (state, { payload }) => ({ ...state }),
    [enableOrDisableAddressBooks.SUCCESS]: (state, { payload }) => ({ ...state }),
    [enableOrDisableAddressBooks.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 创建
    [createAddressbook.REQUEST]: (state, { payload }) => ({ ...state }),
    [createAddressbook.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createAddressbook.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 编辑
    [editAddressbook.REQUEST]: (state, { payload }) => ({ ...state }),
    [editAddressbook.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editAddressbook.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
     // 修改密码
     [loadpassword.REQUEST]: (state, { payload }) => ({ ...state}),
     [loadpassword.SUCCESS]: (state, { payload }) => ({ ...state }),
     [loadpassword.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [updateAddressBookSearchKey]: (state, { payload }) => ({ ...state, ...payload })
},
    initialState
);

export default reducer;