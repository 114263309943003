import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';
import { elementAt } from 'rxjs/operators';

const moduleName = 'queryTools';

/**
 * TODO: 查询工具
 */
// 查询用户
export const loadQueryUserMetadata = fasterActions(moduleName, 'loadQueryUserMetadata');
// 用户中奖记录
export const loadlotteryRecordList = fasterActions(moduleName, 'loadlotteryRecordList');
// 导出用户中奖记录
export const exportLotteryRecordList = fasterActions(moduleName, 'exportLotteryRecordList');
// 用户积分记录
export const loadpointRecordList = fasterActions(moduleName, 'loadpointRecordList');
export const loadpointRecordDetailList = fasterActions(moduleName, 'loadpointRecordDetailList');
// 用户卡片记录
export const loadcardRecordList = fasterActions(moduleName, 'loadcardRecordList');
export const loadcardRecordDetailList = fasterActions(moduleName, 'loadcardRecordDetailList');
// 用户扫码记录
export const loadscanRecordList = fasterActions(moduleName, 'loadscanRecordList');
// 导出用户扫码记录
export const exportscanRecordList = fasterActions(moduleName, 'exportscanRecordList');
// 归档中奖记录
export const loadlotteryBackRecordList = fasterActions(moduleName, 'loadlotteryBackRecordList');
// 导出归档中奖记录
export const exportLotteryBackRecordList = fasterActions(moduleName, 'exportLotteryBackRecordList');

// 存储state
export const updateTabListCurrentTab = createAction(`${moduleName}/updateTabListCurrentTab`);
export const updateUserMetadataSearchkey = createAction(`${moduleName}/updateUserMetadataSearchkey`);
export const updateEnterprisesSelectdId = createAction(`${moduleName}/updateEnterprisesSelectdId`);
export const updateLotteryRecoredSearchUuid = createAction(`${moduleName}/updateLotteryRecoredSearchUuid`);
export const updateScanRecoredSearchUuid = createAction(`${moduleName}/updateScanRecoredSearchUuid`);

/* 二维码信息查询 */
export const changeCodeSearch = createAction(`${moduleName}/changeCodeSearch`);
export const changeTabList = createAction(`${moduleName}/changeTabList`);
export const resetTagInfo = createAction(`${moduleName}/resetTagInfo`);
export const changeDrawSearch = createAction(`${moduleName}/changeDrawSearch`);
export const changeScanSearch = createAction(`${moduleName}/changeScanSearch`);
export const changeQuery = createAction(`${moduleName}/changeQuery`);
// 二维码信息
export const getCodeInfo = fasterActions(moduleName, 'getCodeInfo');
//二维码信息更多
export const getCodeInfoMore = fasterActions(moduleName, 'getCodeInfoMore');
// 获取抽奖信息
export const getDrawRecord = fasterActions(moduleName, 'getDrawRecord');
// 导出抽奖信息
export const drawRecordExport = fasterActions(moduleName, 'drawRecordExport');
// 扫码信息
export const getScanRecord = fasterActions(moduleName, 'getScanRecord');
// 导出扫码信息
export const scanRecordExport = fasterActions(moduleName, 'scanRecordExport');
//激励信息
export const getIncentiveRecords = fasterActions(moduleName, 'getIncentiveRecords');

//根据ids获取更多
export const getCodeIdsInfoMore = fasterActions(moduleName, 'getCodeIdsInfoMore');
// linkLogIds


//清空数据
export const removeinfo = createAction(`${moduleName}/removeinfo`);


//活动匹配
export const getActiveCodeInfo = fasterActions(moduleName, 'getActiveCodeInfo');
export const getActiveMatch = fasterActions(moduleName, 'getActiveMatch');

const initialState = {
  queryUserMetadataList: [],
  lotteryRecordList: [],
  scanRecordList: [],
  pointRecordList: [],
  pointRecordDetailList: [],
  cardRecordList: [],
  cardRecordDetailList: [],
  // userMetadataSearchkey: 'oY9Er5HtZyzJmbZS5qJ0qQkO6puw',
  userMetadataSearchkey: '',
  tabListCurrentTab: 'lotteryRecord',

  // 记录当前查询用户记录的openID和appid
  currentOpenId: '',
  currentAppid: '',
  lotteryRecoredSearchUuid: '',
  scanRecoredSearchUuid: '',
  loadlotteryRecordPage: {
    uuid: '',
    prizeType: '',
    promotionType: '',
    page: 0,
    size: 20,
    sort: ''
  },
  loadscanRecordPage: {
    page: 0,
    size: 20,
    sort: ''
  },


  loadpointRecordDetailListPage: {},
  loadcardRecordDetailListPage: {},

  /* 二维码信息查询 */
  enterprisesSelectionsId: '',
  codeQuerySearch: {
    // query: 'HTTP://UT.AAX6.CN/A/5BJ+E568DVY93$FQ178',
    query: '',
    type: 1
  },
  savePrevquery: { drawQuery: '', scanQuery: '' },
  tabVal: 'code',
  codeInfo: {}, // 二维码信息
  codeInfoMore: {},
  drawRecords: [], // 抽奖信息
  incentiveRecords: [],//激励信息
  scanRecord: [], // 扫码信息
  drawPaging: {
    page: 0,
    size: 20,
  },
  incentivePaging: {
    page: 0,
    size: 20,
  },
  scanPaging: {
    page: 0,
    size: 20,
    type: '',
  },
  tagSearch: {
    codeUri: '', // 域名
    senNum: '', // eseSeqnum
  },
  perPageCountList: [20, 50, 100],
  drawTotalElements: 0,
  incentiveTotalElements: 0,
  scanTotalElements: 0,

}

const reducer = handleActions(
  {
    // 查询用户
    [loadQueryUserMetadata.REQUEST]: (state, { payload }) => ({ ...state, ...payload, userQueryMetadataLoading: true }),
    [loadQueryUserMetadata.SUCCESS]: (state, { payload }) => ({ ...state, queryUserMetadataList: payload, userQueryMetadataLoading: false }),
    [loadQueryUserMetadata.FAIL]: (state, { payload }) => ({ ...state, error: payload, userQueryMetadataLoading: false }),

    // 用户中奖记录
    [loadlotteryRecordList.REQUEST]: (state, { payload }) => ({ ...state, lotteryRecordLoad: true, ...payload }),
    [loadlotteryRecordList.SUCCESS]: (state, { payload }) => {
      const { content = [], pageable = {}, totalElements = 0 } = payload;
      return ({ ...state, lotteryRecordLoad: false, lotteryRecordList: content || [], loadlotteryRecordPage: { ...state.loadlotteryRecordPage, page: pageable.pageNumber || 0, size: pageable.pageSize || 20, totalElements: totalElements || 0 } })
    },
    [loadlotteryRecordList.FAIL]: (state, { payload }) => ({ ...state, error: payload, lotteryRecordLoad: false }),
    //导出用户中奖记录
    [exportLotteryRecordList.REQUEST]: (state, { payload }) => ({ ...state, }),
    [exportLotteryRecordList.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [exportLotteryRecordList.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    //用户扫码记录
    [loadscanRecordList.REQUEST]: (state, { payload }) => ({ ...state, scanRecordLoad: true, ...payload }),
    [loadscanRecordList.SUCCESS]: (state, { payload }) => {
      const { content = [], pageable = {}, totalElements = 0 } = payload;
      return ({ ...state, scanRecordLoad: false, scanRecordList: content || [], loadscanRecordPage: { ...state.loadscanRecordPage, page: pageable.pageNumber || 0, size: pageable.pageSize || 20, totalElements: totalElements || 0 } })
    },
    [loadscanRecordList.FAIL]: (state, { payload }) => ({ ...state, error: payload, scanRecordLoad: false }),
  //导出用户扫码记录
    [exportscanRecordList.REQUEST]: (state, { payload }) => ({ ...state, }),
    [exportscanRecordList.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [exportscanRecordList.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
    
    // 用户积分记录
    [loadpointRecordList.REQUEST]: (state, { payload }) => ({ ...state, pointRecordLoad: true }),
    [loadpointRecordList.SUCCESS]: (state, { payload }) => ({ ...state, pointRecordList: payload, pointRecordLoad: false }),
    [loadpointRecordList.FAIL]: (state, { payload }) => ({ ...state, error: payload, pointRecordLoad: false }),
    // 用户积分记录
    [loadpointRecordDetailList.REQUEST]: (state, { payload }) => ({ ...state, loadpointRecordDetailListPage: payload, userQuerypointDetailLoading: true }),
    [loadpointRecordDetailList.SUCCESS]: (state, { payload }) => {
      const { content = [], pageable = { pageNumber: 0, pageSize: 20 }, totalElements = '' } = payload;
      return ({ ...state, pointRecordDetailList: content, loadpointRecordDetailListPage: { ...state.loadpointRecordDetailListPage, page: pageable.pageNumber, size: pageable.pageSize, totalElements }, userQuerypointDetailLoading: false });
    },
    [loadpointRecordDetailList.FAIL]: (state, { payload }) => ({ ...state, error: payload, userQuerypointDetailLoading: false }),

    // 用户卡片记录
    [loadcardRecordList.REQUEST]: (state, { payload }) => ({ ...state, cardRecordLoad: true }),
    [loadcardRecordList.SUCCESS]: (state, { payload }) => {
      const list = [];
      if (payload) {
        payload.map((elem, index) => {
          const flag = list.filter(v => elem.typeId == v.typeId).length < 1;
          if (flag) {
            list.push({ ...elem, itemkey: index });
          } else {
            list.map(val => {
              if (val.typeId == elem.typeId) {
                val.totalConsumeNumber += elem.totalConsumeNumber;
                val.validNumber += elem.validNumber;
                val.totalIncomeNumber += elem.totalIncomeNumber;
              }
            })
          }
        })
      }
      return ({ ...state, cardRecordList: list, cardRecordLoad: false })
    },
    [loadcardRecordList.FAIL]: (state, { payload }) => ({ ...state, cardRecordLoad: false, error: payload }),
    // 用户卡片明细
    [loadcardRecordDetailList.REQUEST]: (state, { payload }) => ({ ...state, loadcardRecordDetailListPage: payload, userQueryCardDetailLoading: true }),
    [loadcardRecordDetailList.SUCCESS]: (state, { payload }) => {
      const { content = [], pageable = { pageNumber: 0, pageSize: 20 }, totalElements = '' } = payload;
      return ({ ...state, cardRecordDetailList: content, loadcardRecordDetailListPage: { ...state.loadcardRecordDetailListPage, page: pageable.pageNumber, size: pageable.pageSize, totalElements }, userQueryCardDetailLoading: false });
    },
    [loadcardRecordDetailList.FAIL]: (state, { payload }) => ({ ...state, error: payload, userQueryCardDetailLoading: false }),
    // 归档中奖记录
    [loadlotteryBackRecordList.REQUEST]: (state, { payload }) => ({ ...state, lotteryRecordLoad: true, ...payload }),
    [loadlotteryBackRecordList.SUCCESS]: (state, { payload }) => {
      const { content = [], pageable = {}, totalElements = 0 } = payload;
      return ({ ...state, lotteryRecordLoad: false, lotteryRecordList: content || [], loadlotteryRecordPage: { ...state.loadlotteryRecordPage, page: pageable.pageNumber || 0, size: pageable.pageSize || 20, totalElements: totalElements || 0 } })
    },
    [loadlotteryBackRecordList.FAIL]: (state, { payload }) => ({ ...state, error: payload, lotteryRecordLoad: false }),
    //导出归档中奖记录
    [exportLotteryBackRecordList.REQUEST]: (state, { payload }) => ({ ...state, }),
    [exportLotteryBackRecordList.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [exportLotteryBackRecordList.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),


    // 存储state
    [updateUserMetadataSearchkey]: (state, { payload }) => ({ ...state, ...payload }),
    [updateTabListCurrentTab]: (state, { payload }) => ({ ...state, ...payload }),
    [updateEnterprisesSelectdId]: (state, { payload }) => ({ ...state, enterprisesSelectionsId: payload || "" }),
    [updateLotteryRecoredSearchUuid]: (state, { payload }) => ({ ...state, ...payload }),
    [updateScanRecoredSearchUuid]: (state, { payload }) => ({ ...state, ...payload }),

    /* 
    ** 二维码信息查询
    */
    [changeCodeSearch]: (state, { payload }) => {
      return { ...state, codeQuerySearch: { ...state.codeQuerySearch, ...payload, page: 0 } }
    },
    [changeTabList]: (state, { payload }) => {
      return { ...state, tabVal: payload }
    },
    [resetTagInfo]: (state, { payload }) => {
      const drawPaging = { ...state.drawPaging, page: 0 };
      const scanPaging = { ...state.scanPaging, page: 0 };
      const incentivePaging = { ...state.incentivePaging, page: 0 };
      // const drawPaging = { page: 0, size: 20 };
      // const scanPaging = { page: 0, size: 20 };
      return { ...state, codeInfo: {}, drawRecords: [], incentiveRecords: [], scanRecord: [], drawPaging, incentivePaging, scanPaging }
    },
    [changeDrawSearch]: (state, { payload }) => {
      return { ...state, drawPaging: { ...state.drawPaging, ...payload, page: 0 } }
    },
    [changeScanSearch]: (state, { payload }) => {
      return { ...state, scanPaging: { ...state.scanPaging, ...payload, page: 0 } }
    },
    [changeQuery]: (state, { payload }) => {
      return { ...state, savePrevquery: { ...state.savePrevquery, ...payload } };
    },
    // 二维码基本信息
    [getCodeInfo.REQUEST]: (state, { payload }) => {
      return { ...state, loading: true, codeQuerySearch: payload }
    },
    [getCodeInfo.SUCCESS]: (state, { payload }) => {
      const { tagUrl, tagDomain = '', eseSeqnum, sysSeqnum } = payload; // sysSeqnum系统序号
      // let codeUri = '';
      // if (tagUrl) {
      //   const urlArr = tagUrl.split('/');
      //   codeUri = `${urlArr[2]}/${urlArr[3]}`;
      // }
      let tagSearch = { codeUri: tagDomain, senNum: sysSeqnum };

      return { ...state, loading: false, codeInfo: payload, tagSearch, enterprisesSelectionsId: payload.eseId }
    },
    [getCodeInfo.FAIL]: (state, { payload }) => {
      return { ...state, loading: false, tagSearch: { codeUri: '', senNum: '' }, error: payload }
    },

    // 二维码基本信息
    [getCodeInfoMore.REQUEST]: (state, { payload }) => {
      return { ...state }
    },
    [getCodeInfoMore.SUCCESS]: (state, { payload }) => {
      return { ...state, codeInfoMore: payload }
    },
    [getCodeInfoMore.FAIL]: (state, { payload }) => {
      return { ...state, error: payload }
    },

    //激励信息

    [getIncentiveRecords.REQUEST]: (state, { payload }) => {
      return { ...state, iloading: true, incentivePaging: payload }
    },
    [getIncentiveRecords.SUCCESS]: (state, { payload }) => {
      const { content = [], totalElements } = payload || {};
      return { ...state, iloading: false, incentiveRecords: content, incentiveTotalElements: totalElements }
    },
    [getIncentiveRecords.FAIL]: (state, { payload }) => ({ ...state, iloading: false, error: payload }),
    // 抽奖信息
    [getDrawRecord.REQUEST]: (state, { payload }) => {
      return { ...state, dloading: true, drawPaging: payload }
    },
    [getDrawRecord.SUCCESS]: (state, { payload }) => {
      const { content = [], totalElements } = payload || {};
      return { ...state, dloading: false, drawRecords: content, drawTotalElements: totalElements }
    },
    [getDrawRecord.FAIL]: (state, { payload }) => ({ ...state, dloading: false, error: payload }),

    //导出抽奖信息
    [drawRecordExport.REQUEST]: (state, { payload }) => ({ ...state, }),
    [drawRecordExport.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [drawRecordExport.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    // 扫码信息
    [getScanRecord.REQUEST]: (state, { payload }) => {
      return { ...state, sloading: true, scanPaging: payload }
    },
    [getScanRecord.SUCCESS]: (state, { payload }) => {
      const { content = [], totalElements } = payload || {};
      return { ...state, sloading: false, scanRecord: content, scanTotalElements: totalElements }
    },
    [getScanRecord.FAIL]: (state, { payload }) => ({ ...state, sloading: false, error: payload }),
  
  
    [getCodeIdsInfoMore.REQUEST]: (state, { payload }) => {
      return { ...state }
    },
    [getCodeIdsInfoMore.SUCCESS]: (state, { payload }) => {
      return { ...state, codeIdsInfoMore: payload }
    },
    [getCodeIdsInfoMore.FAIL]: (state, { payload }) => {
      return { ...state, error: payload }
    },

    
  

    //导出扫码信息
    [scanRecordExport.REQUEST]: (state, { payload }) => ({ ...state, }),
    [scanRecordExport.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [scanRecordExport.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),



    [removeinfo]: (state, { payload }) => ({
      ...state,
      tabVal: 'code',
      drawPaging: { page: 0, size: 20, },
      incentivePaging: { page: 0, size: 20, },
      scanPaging: { page: 0, size: 20, type: '', },
      scanRecord: [],
      drawRecords: [],
      incentiveRecords: [],
      tagSearch: { codeUri: '', senNum: '', },
      codeInfo: {},
      codeInfoMore: {},
      userMetadataSearchkey: '',
      queryUserMetadataList: [],
      currentOpenId: "",
      lotteryRecoredSearchUuid: '',
      scanRecoredSearchUuid: "",
    }),


    // 活动匹配
    [getActiveCodeInfo.REQUEST]: (state, { payload }) => { return { ...state, } },
    [getActiveCodeInfo.SUCCESS]: (state, { payload }) => { return { ...state, codeInfo: payload, } },
    [getActiveCodeInfo.FAIL]: (state, { payload }) => { return { ...state, error: payload } },

    [getActiveMatch.REQUEST]: (state, { payload }) => { return { ...state, } },
    [getActiveMatch.SUCCESS]: (state, { payload }) => { return { ...state, codeInfo: payload, } },
    [getActiveMatch.FAIL]: (state, { payload }) => { return { ...state, error: payload } },


  },
  initialState
);

export default reducer;
