import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './links';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 企业管理
 */


//list
export const loadLinksListEpic = actions$ => actions$.pipe(
  ofType(actions.loadLinksList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.dataManage.links.links}/enterprises/${data.id}`,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.loadLinksList.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.loadLinksList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//create
export const createLinkEpic = actions$ => actions$.pipe(
  ofType(actions.createLink.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.dataManage.links.links}/enterprises/${data.id}`,
        body: data.list,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.createLink.SUCCESS(res)
        }),
        catchError(error => of(actions.createLink.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// //able

// export const ableEnterpriseEpic = actions$ => actions$.pipe(
//   ofType(actions.ableEnterprise.REQUEST.toString()),

//   mergeMap(action => { actionApi()
//     const { data, callback } = action.payload;
//     return (

//       ajax({
//         ...ajaxBaseConfig,
//         method: 'PATCH',
//         url: `${urls.data.enterprises.enterprises}/${data.id}/status?status=${data.status}`,
//         //  body: action.payload.data,
//       }).pipe(
//         map(res => {
//           callback && callback()
//           return actions.ableEnterprise.SUCCESS(res)
//         }),
//         catchError(error => of(actions.ableEnterprise.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//       )
//     )
//   })
// );

//select list
export const loadLinkSelectionsEpic = actions$ => actions$.pipe(
  ofType(actions.loadLinkSelections.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, status, callback } = action.payload;
    let url = `${urls.dataManage.links.links}/selections?isPage=false`;
    // let url = `${urls.dataManage.links.links}/allots?isPage=false`;
    if (action.payload && action.payload.status) {
      url += `&status=${action.payload.status}`;
    }
    return (
      ajax({
        ...apiBaseConfig,
        url,
        //  url: `${urls.dataManage.links.selections}?status=${status}`,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.loadLinkSelections.SUCCESS(res.response.content || [])
        }),
        catchError(error => of(actions.loadLinkSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);



//天域
export const loadTencentBspEpic = actions$ => actions$.pipe(
  ofType(actions.loadTencentBsp.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...apiBaseConfig,
        url: `${urls.promotion.tencentBsp.tencentBsp}?eseId=${data.id}`,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.loadTencentBsp.SUCCESS(res.response)
        }),
        catchError(error => of(actions.loadTencentBsp.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//create
export const createTencentBspEpic = actions$ => actions$.pipe(
  ofType(actions.createTencentBsp.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.promotion.tencentBsp.tencentBsp}`,
        body: data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.createTencentBsp.SUCCESS(res)
        }),
        catchError(error => of(actions.createTencentBsp.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//edit
export const editTencentBspEpic = actions$ => actions$.pipe(
  ofType(actions.editTencentBsp.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.promotion.tencentBsp.tencentBsp}`,
        body: data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.editTencentBsp.SUCCESS(res)
        }),
        catchError(error => of(actions.editTencentBsp.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//活动配置
export const loadEseConfigsEpic = actions$ => actions$.pipe(
  ofType(actions.loadEseConfigs.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...apiBaseConfig,
        url: `${urls.promotion.eseConfigs.eseConfigs}/${data.id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.loadEseConfigs.SUCCESS(res.response)
        }),
        catchError(error => of(actions.loadEseConfigs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//edit
export const editEseConfigsEpic = actions$ => actions$.pipe(
  ofType(actions.editEseConfigs.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.promotion.eseConfigs.eseConfigs}`,
        body: data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.editEseConfigs.SUCCESS(res)
        }),
        // catchError(error => of(actions.editEseConfigs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);




// 字段分页查询
export const linksFieldsEpic = actions$ => actions$.pipe(
  ofType(actions.linksFields.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { keywords = '', page, size, fieldType = '' } = action.payload;
    let url = `${urls.dataManage.links.links}/fields?page=${page}&size=${size}&sort=id,desc&name=${keywords}&code=${keywords}`;
    if (fieldType) {
      fieldType.forEach(val => val && (url += `&fieldType=${val}`))
      // url += `&fieldType=${fieldType}`;
    }
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.linksFields.SUCCESS(res.response)),
      catchError(error => of(actions.linksFields.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 创建/编辑 环节字段
export const linksFieldsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.linksFieldsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      method: action.payload.id ? 'PUT' : 'POST',
      url: `${urls.dataManage.links.links}/fields`,
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.linksFieldsModify.SUCCESS(res.status), uiActions.notification(action.payload.id ? '修改成功' : '创建成功', 'success')]),
      catchError(error => of(actions.linksFieldsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 禁用/启用字段
export const linksFieldsStatusEpic = actions$ => actions$.pipe(
  ofType(actions.linksFieldsStatus.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { code, status } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.dataManage.links.links}/fields/${code}/status?status=${status}`,
      body: action.payload
    }).pipe(
      map(res => actions.linksFieldsStatus.SUCCESS(res.status)),
      catchError(error => of(actions.linksFieldsStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 字段所有数据
export const linksFieldsSelectionsEpic = actions$ => actions$.pipe(
  ofType(actions.linksFieldsSelections.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { status, callback } = action.payload || {};
    return ajax({
      ...apiBaseConfig,
      url: `${urls.dataManage.links.links}/fields/selections?status=${status}`,
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.linksFieldsSelections.SUCCESS(res.response)
      }),
      catchError(error => of(actions.linksFieldsSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// 环节分页查询
export const linksEpic = actions$ => actions$.pipe(
  ofType(actions.links.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { keywords = '', page, size, linkType = '' } = action.payload;
    let url = `${urls.dataManage.links.links}?page=${page}&size=${size}&sort=id,desc&name=${keywords}&code=${keywords}`;
    if (linkType) {
      // url += `&linkType=${linkType}`;
      linkType.forEach(val => val && (url += `&linkType=${val}`))
    }
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.links.SUCCESS(res.response)),
      catchError(error => of(actions.links.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 创建/编辑 环节
export const linksModifyEpic = actions$ => actions$.pipe(
  ofType(actions.linksModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      method: action.payload.id ? 'PUT' : 'POST',
      url: `${urls.dataManage.links.links}`,
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.linksModify.SUCCESS(res.status), uiActions.notification(action.payload.id ? '修改成功' : '创建成功', 'success')]),
      catchError(error => of(actions.linksModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 禁用/启用环节
export const linksStatusEpic = actions$ => actions$.pipe(
  ofType(actions.linksStatus.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { code, status } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.dataManage.links.links}/${code}/status?status=${status}`,
      body: action.payload
    }).pipe(
      map(res => actions.linksStatus.SUCCESS(res.status)),
      catchError(error => of(actions.linksStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

//list
export const loadCodeLinksListEpic = actions$ => actions$.pipe(
  ofType(actions.loadCodeLinksList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.dataManage.links.links}/${data.code}?eseId=${data.eseId}`,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.loadCodeLinksList.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.loadCodeLinksList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const createenterpriseLinkEpic = actions$ => actions$.pipe(
  ofType(actions.createenterpriseLink.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: data.id ? 'PUT' : 'POST',
        url: `${urls.dataManage.links.linksenterprise}`,
        body: data,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.createenterpriseLink.SUCCESS(res)
        }),
         catchError(error => of(actions.createenterpriseLink.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);



//企业环节管理
export const linksenterpriseEpic = actions$ => actions$.pipe(
  ofType(actions.linksenterprise.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { keywords = '', page, size, linkType = '' } = action.payload;
    let url = `${urls.dataManage.links.linksenterprise}?page=${page}&size=${size}&sort=id,desc&name=${keywords}&code=${keywords}&linkType=${linkType}`;

    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.linksenterprise.SUCCESS(res.response)),
      catchError(error => of(actions.linksenterprise.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
//企业环节管理
export const linksenterpriseIdEpic = actions$ => actions$.pipe(
  ofType(actions.linksenterpriseId.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload;
    let url = `${urls.dataManage.links.linksenterprise}/${data.id}`;

    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.linksenterpriseId.SUCCESS(res.response)
      }),
       catchError(error => of(actions.linksenterpriseId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);



//企业环节管理
export const tagstatusSelectionsEpic = actions$ => actions$.pipe(
  ofType(actions.tagstatusSelections.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, status, callback } = action.payload;
    let url = `${urls.dataManage.tagStatus.selections}?status=${status}`;
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.tagstatusSelections.SUCCESS(res.response)
      }),
      catchError(error => of(actions.tagstatusSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const loadDiEnterpirsesLinksEpic = actions$ => actions$.pipe(
  ofType(actions.loadDiEnterpirsesLink.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;

    // let eseId = urls.baseUrl.includes('jnc')||urls.baseUrl.includes('digitalshell') ? '' : `/${data.eseId}`
    return (

      ajax({
        ...ajaxBaseConfig,
        // url: `${urls.dataManage.links.diEnterpirsesLinks}${eseId}`,
        url: `${urls.dataManage.links.diEnterpirsesLinks}`,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.loadDiEnterpirsesLink.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.loadDiEnterpirsesLink.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);




export const isvLinkGetEpic = actions$ => actions$.pipe(
  ofType(actions.isvLinkGet.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.isvLink.isvLink}?userId=${data.userId}&eseId=${data.eseId}`,

      }).pipe(
        map(res => {
          callback && callback()
          return actions.isvLinkGet.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.isvLinkGet.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);



export const isvLinkSaveEpic = actions$ => actions$.pipe(
  ofType(actions.isvLinkSave.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.isvLink.isvLink}?linkIdList=${data.code}`,
        method: 'POST',
        body: data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.isvLinkSave.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.isvLinkSave.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const isvLinkDeleteEpic = actions$ => actions$.pipe(
  ofType(actions.isvLinkDelete.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.isvLink.isvLink}/${data.id}`,
        method: 'delete',
      }).pipe(
        map(res => {
          callback && callback()
          return actions.isvLinkDelete.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.isvLinkDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);


// 下拉
export const codeLabelSelectionsEpic = actions$ => actions$.pipe(
  ofType(actions.codeLabelSelections.REQUEST.toString()), 
  mergeMap(action => {
      actionApi();
      const {status} = action.payload;
      return ajax({
          ...ajaxBaseConfig,
          url: `${urls.data.codeLabel.select}?isPage=false&status=${status}`
      }).pipe(
          map(res => actions.codeLabelSelections.SUCCESS(res.response)),
          catchError(error => of(actions.codeLabelSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
  }
  )
);

// 环节管理新版



//保存环节方案
export const addLinkPlanEpic = actions$ => actions$.pipe(
  ofType(actions.addLinkPlan.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: data.id ? 'PUT' : 'POST',
      body: data,
      url: `${urls.dataManage.links.linkplan}`
    }).pipe(
      map(res => {

        callback && callback()
        return actions.addLinkPlan.SUCCESS(res.response);

      }),
       catchError(error => of(actions.addLinkPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
       )
    )
  }
  )
);


//环节方案不分页
export const linkPlanSelectEpic = actions$ => actions$.pipe(
  ofType(actions.linkPlanSelect.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback, linkCode } = action.payload;
    let url = `${urls.dataManage.links.linkplan}?linkCode=${linkCode}`;
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => {
        // res.response.reverse()
        callback && callback(res.response);

        return actions.linkPlanSelect.SUCCESS(res.response);
      }),
       catchError(error => of(actions.linkPlanSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);



//排序方案
export const sortLinkPlanEpic = actions$ => actions$.pipe(
  ofType(actions.sortLinkPlan.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback, data,linkCode } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.links.linkplan}/sort/${linkCode}`,
      body: data,
      method: 'PUT'
    }).pipe(
      map(res => {
        callback && callback();

        return actions.sortLinkPlan.SUCCESS(res.response);
      }),
      catchError(error => of(actions.sortLinkPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);




//保存环节方案
export const linkPlanCheckRuleEpic = actions$ => actions$.pipe(
  ofType(actions.linkPlanCheckRule.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: data,
      url: `${urls.dataManage.links.linkRule}`
    }).pipe(
      map(res => {

        callback && callback()
        return actions.linkPlanCheckRule.SUCCESS(res.response);

      }),
       catchError(error => of(actions.linkPlanCheckRule.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
       )
    )
  }
  )
);

// 物流节点 - 查询
export const logisticsQueryEpic = actions$ => actions$.pipe(
  ofType(actions.logisticsQuery.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { page=0,size=20,keyword='' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'GET',
      url: `${urls.dataManage.links.logisticsQuery}?page=${page}&size=${size}&keyword=${keyword}&sort=id,desc`
    }).pipe(
      map(res => {
        return actions.logisticsQuery.SUCCESS(res.response);
      }),
      catchError(error => of(actions.logisticsQuery.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

// 物流节点 - 新增,编辑,删除
export const logisticsOperateEpic = actions$ => actions$.pipe(
  ofType(actions.logisticsOperate.REQUEST.toString()),
  mergeMap(action => {
    actionApi()

    const { id, name, code, fieldCode, linkOwnerIds,version, callback = () => { }, idDel = false } = action.payload;

    let data = {
      id,
      name,
      code,
      fieldCode,
      linkOwnerIds,
      version
    }
    let method = id ? 'PUT' : 'POST';

    if (idDel) {
      data = undefined;
      method = 'DELETE';
    }

    return ajax({
      ...ajaxBaseConfig,
      method,
      body: data,
      url: `${urls.dataManage.links.logisticsOperate}${idDel ? ('/' + id) : ''}`
    }).pipe(
      map(res => {
        callback && callback();
        return actions.logisticsOperate.SUCCESS(res.response);
      }),
      catchError(error => of(actions.logisticsOperate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

// 物流节点 - 查询企业下所有环节
export const allLinksEpic = actions$ => actions$.pipe(
  ofType(actions.allLinks.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.links.allLinks}`
    }).pipe(
      map(res => {
        return actions.allLinks.SUCCESS(res.response);
      }),
      catchError(error => of(actions.allLinks.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

