import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions, setScrollTop } from '../../utils';

const moduleName = 'prizeInfo';

export const getPrizeRegisterTable = fasterActions(moduleName, 'getPrizeRegisterTable');
export const changeKewWords = createAction(`${moduleName}/changeKewWords`);
/* 
** 登记信息
*/
export const patchRegister = fasterActions(moduleName, 'patchRegister');
export const unmountRegister = fasterActions(moduleName, 'unmountRegister');

/* 
** 导出
*/
export const exportRegister = fasterActions(moduleName, 'exportRegister');

/* 
** 确认和驳回
*/
export const prizeConfirm = fasterActions(moduleName, 'prizeConfirm');



const initialPrizeInfoState = {
    prizeRegisterSearch: {
        page: 0,
        size: 20, // 每页大小
        sort: '',
        keyWords: '',
        prizeKeyWords:'',
        promotionKeyWords:'',
        promotionCategory:'NORMAL',
        prizeType: [],
        status: [],
        status2: [],
        exportStatus: []
    },
    prizeInfoTableData: [],
    totalElements: '', /* 总数据条数 */
    perPageCountList: [20, 50, 100], // 每页大小候选
}


const reducer = handleActions(
    {
        /* 获取奖品登记列表  搜索 */
        [getPrizeRegisterTable.REQUEST]: (state, { payload }) => {
            setScrollTop();
            return { ...state, loading: true, prizeRegisterSearch: payload };
        },
        [getPrizeRegisterTable.SUCCESS]: (state, { payload }) => {
            const { content = [], totalElements } = payload || {};
            return { ...state, loading: false, prizeInfoTableData: content, totalElements }
        },
        [getPrizeRegisterTable.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
        [changeKewWords]: (state, { payload }) => {
            return { ...state, prizeRegisterSearch: { ...state.prizeRegisterSearch, ...payload, page: 0 } }
        },
        /* 登记信息 */
        [patchRegister.REQUEST]: (state, { payload }) => ({ ...state, disabled: true, payload }),
        [patchRegister.SUCCESS]: (state, { payload }) => ({ ...state, disabled: false }),
        [patchRegister.FAIL]: (state, { payload }) => ({ ...state, disabled: false, error: payload }),
        /* 导出 */
        [exportRegister.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [exportRegister.SUCCESS]: (state, { payload }) => ({ ...state, payload }),
        [exportRegister.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    
    
        [prizeConfirm.REQUEST]: (state, { payload }) => ({ ...state, payload, pointMallOrderConfirmLoaded: false }),
        [prizeConfirm.SUCCESS]: (state, { payload }) => ({ ...state, pointMallOrderConfirmLoaded: payload }),
        [prizeConfirm.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [unmountRegister.REQUEST]: (state, { payload }) => ({
            prizeRegisterSearch: {
                page: 0,
                size: 20, // 每页大小
                sort: '',
                keyWords: '',
                prizeKeyWords:'',
                promotionKeyWords:'',
                promotionCategory:'NORMAL',
                prizeType: [],
                status: [],
                status2: [],
                exportStatus: []
            },
            prizeInfoTableData: [],
            totalElements: '', /* 总数据条数 */
            perPageCountList: [20, 50, 100], // 每页大小候选
          }),
      
    },
    initialPrizeInfoState
)

export default reducer;