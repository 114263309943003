import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'codeUpload';

export const loadCodeUpload = fasterActions(moduleName, 'loadCodeUpload');
export const loadCodeUploadSelect = fasterActions(moduleName, 'loadCodeUploadSelect');
export const createCodeUpload = fasterActions(moduleName, 'createCodeUpload');
export const editCodedb = fasterActions(moduleName, 'editCodedb');


const initialState = {
  codeUploadList: {},
  codeUploadListSelect: {},
  loadCodeUploadLoading: false,
  loadCodeUploadLoaded: false,
}



const reducer = handleActions(
  {
    [loadCodeUpload.REQUEST]: (state, { payload }) => ({ ...state, loadCodeUploadLoading: true }),
    [loadCodeUpload.SUCCESS]: (state, { payload }) => ({ ...state, loadCodeUploadLoading: false, loadCodeUploadLoaded: true, codeUploadList: payload }),
    [loadCodeUpload.FAIL]: (state, { payload }) => ({ ...state, loadCodeUploadLoading: false, loadCodeUploadLoaded: false, error: payload }),


    [loadCodeUploadSelect.REQUEST]: (state, { payload }) => ({ ...state, loadCodeUploadLoading: true }),
    [loadCodeUploadSelect.SUCCESS]: (state, { payload }) => ({ ...state, loadCodeUploadLoading: false, loadCodeUploadLoaded: true, codeUploadListSelect: payload }),
    [loadCodeUploadSelect.FAIL]: (state, { payload }) => ({ ...state, loadCodeUploadLoading: false, loadCodeUploadLoaded: false, error: payload }),


    [createCodeUpload.REQUEST]: (state, { payload }) => ({ ...state, }),
    [createCodeUpload.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [createCodeUpload.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [editCodedb.REQUEST]: (state, { payload }) => ({ ...state, }),
    [editCodedb.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [editCodedb.FAIL]: (state, { payload }) => ({ ...state, error: payload }),




  },
  initialState
)

export default reducer;