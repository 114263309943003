import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';


const moduleName = 'warnEvents';

/**
 * TODO: 微信管理
 */

export const warnEvents = fasterActions(moduleName, 'warnEvents');
export const warnEventsModify = fasterActions(moduleName, 'warnEventsModify');
export const warnEventsStatus = fasterActions(moduleName, 'warnEventsStatus');






const initialState = {

}

const reducer = handleActions(
  {

    [warnEvents.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [warnEvents.SUCCESS]: (state, { payload }) => ({ ...state, warnEventsList: payload, loading: false }),
    [warnEvents.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [warnEventsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, warnEventsModifyLoaded: false }),
    [warnEventsModify.SUCCESS]: (state, { payload }) => ({ ...state, warnEventsModifyLoaded: payload }),
    [warnEventsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [warnEventsStatus.REQUEST]: (state, { payload }) => ({ ...state, payload, warnEventsStatusLoaded: false }),
    [warnEventsStatus.SUCCESS]: (state, { payload }) => ({ ...state, warnEventsStatusLoaded: payload }),
    [warnEventsStatus.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
  },

  initialState
);

export default reducer;
