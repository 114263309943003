import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './anticounterfeiting';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// 防伪数量区域分布
export const securityAreaDistributionEpic = actions$ => actions$.pipe(
    ofType(actions.securityAreaDistribution.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime, endTime, securityPromotionId = '', securityPlanIds = '', dealerIds,dealerName='', shopIds, productIds, provinceCode = '', cityCode = '' ,securityLevel=''} = action.payload;

        let securityPromotionIdValue =  (securityPromotionId == 'all') ? '' : securityPromotionId;
        let securityPlanIdsValue = (securityPlanIds == 'all') ? '' : securityPlanIds;
        let dealerIdsValue = dealerIds || '';
        let shopIdsValue = shopIds || '';
        let productIdsValue = productIds || '';
        let securityLevelValue = securityLevel || [];

        let paramsStr = `startTime=${startTime}&endTime=${endTime}&securityPromotionId=${securityPromotionIdValue}&securityPlanIds=${securityPlanIdsValue}&dealerIds=${dealerIdsValue}&shopIds=${shopIdsValue}&productIds=${productIdsValue}&provinceCode=${provinceCode}&cityCode=${cityCode}&dealerName=${dealerName}&securityLevel=${securityLevelValue}`

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.anticounterfeiting.monitor}/v1/securityAreaDistribution?${paramsStr}`,
        }).pipe(
            map(res => actions.securityAreaDistribution.SUCCESS(res.response)),
            catchError(error => of(actions.securityAreaDistribution.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 窜货区域分布
export const flowAreaDistributionEpic = actions$ => actions$.pipe(
    ofType(actions.flowAreaDistribution.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime, endTime, fleeingGoodsType = {}, dealerIds,dealerName='', shopIds, productIds, provinceCode = '', cityCode = '' } = action.payload;

        let fleeingGoodsTypeValue = fleeingGoodsType.value || '';
        let dealerIdsValue = dealerIds || '';
        let shopIdsValue = shopIds || '';
        let productIdsValue = productIds || '';

        let paramsStr = `startTime=${startTime}&endTime=${endTime}&fleeingGoodsType=${fleeingGoodsTypeValue}&dealerIds=${dealerIdsValue}&shopIds=${shopIdsValue}&productIds=${productIdsValue}&provinceCode=${provinceCode}&cityCode=${cityCode}&dealerName=${dealerName}`

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.anticounterfeiting.monitor}/v1/flowAreaDistribution?${paramsStr}`,
        }).pipe(
            map(res => actions.flowAreaDistribution.SUCCESS(res.response)),
            catchError(error => of(actions.flowAreaDistribution.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 防伪省排行
export const securityProDistributionEpic = actions$ => actions$.pipe(
    ofType(actions.securityProDistribution.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime, endTime, securityPromotionId = '', securityPlanIds = '', dealerIds,dealerName='', shopIds, productIds, provinceCode = '', cityCode = '', condition = 'rank',securityLevel='' } = action.payload;

        let securityPromotionIdValue =  (securityPromotionId == 'all') ? '' : securityPromotionId;
        let securityPlanIdsValue = (securityPlanIds == 'all') ? '' : securityPlanIds;
        let dealerIdsValue = dealerIds || '';
        let shopIdsValue = shopIds || '';
        let productIdsValue = productIds || '';
        let securityLevelValue = securityLevel || [];

        let paramsStr = `startTime=${startTime}&endTime=${endTime}&securityPromotionId=${securityPromotionIdValue}&securityPlanIds=${securityPlanIdsValue}&dealerIds=${dealerIdsValue}&shopIds=${shopIdsValue}&productIds=${productIdsValue}&provinceCode=${provinceCode}&cityCode=${cityCode}&condition=${condition}&dealerName=${dealerName}&&securityLevel=${securityLevelValue}`

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.anticounterfeiting.monitor}/v1/securityProDistribution?${paramsStr}`,
        }).pipe(
            map(res => actions.securityProDistribution.SUCCESS(res.response)),
            catchError(error => of(actions.securityProDistribution.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 防伪市排行
export const securityCityRankEpic = actions$ => actions$.pipe(
    ofType(actions.securityCityRank.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime, endTime, securityPromotionId = '', securityPlanIds = '', dealerIds,dealerName='', shopIds, productIds, provinceCode = '', cityCode = '' } = action.payload;

        let securityPromotionIdValue =  (securityPromotionId == 'all') ? '' : securityPromotionId;
        let securityPlanIdsValue = (securityPlanIds == 'all') ? '' : securityPlanIds;
        let dealerIdsValue = dealerIds || '';
        let shopIdsValue = shopIds || '';
        let productIdsValue = productIds || '';

        let paramsStr = `startTime=${startTime}&endTime=${endTime}&securityPromotionId=${securityPromotionIdValue}&securityPlanIds=${securityPlanIdsValue}&dealerIds=${dealerIdsValue}&shopIds=${shopIdsValue}&productIds=${productIdsValue}&provinceCode=${provinceCode}&cityCode=${cityCode}&dealerName=${dealerName}`

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.anticounterfeiting.monitor}/v1/securityCityRank?${paramsStr}`,
        }).pipe(
            map(res => actions.securityCityRank.SUCCESS(res.response)),
            catchError(error => of(actions.securityCityRank.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 窜货省排行
export const flowProDistributionEpic = actions$ => actions$.pipe(
    ofType(actions.flowProDistribution.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime, endTime, fleeingGoodsType = {}, dealerIds,dealerName='', shopIds, productIds, provinceCode = '', cityCode = '',condition = 'rank' } = action.payload;

        let fleeingGoodsTypeValue = fleeingGoodsType.value || '';
        let dealerIdsValue = dealerIds || '';
        let shopIdsValue = shopIds || '';
        let productIdsValue = productIds || '';

        let paramsStr = `startTime=${startTime}&endTime=${endTime}&fleeingGoodsType=${fleeingGoodsTypeValue}&dealerIds=${dealerIdsValue}&shopIds=${shopIdsValue}&productIds=${productIdsValue}&provinceCode=${provinceCode}&cityCode=${cityCode}&condition=${condition}&dealerName=${dealerName}`

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.anticounterfeiting.monitor}/v1/flowProDistribution?${paramsStr}`,
        }).pipe(
            map(res => actions.flowProDistribution.SUCCESS(res.response)),
            catchError(error => of(actions.flowProDistribution.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 窜货市排行
export const flowCityRankEpic = actions$ => actions$.pipe(
    ofType(actions.flowCityRank.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime, endTime, fleeingGoodsType = {}, dealerIds,dealerName='', shopIds, productIds, provinceCode = '', cityCode = '' } = action.payload;

        let fleeingGoodsTypeValue = fleeingGoodsType.value || '';
        let dealerIdsValue = dealerIds || '';
        let shopIdsValue = shopIds || '';
        let productIdsValue = productIds || '';

        let paramsStr = `startTime=${startTime}&endTime=${endTime}&fleeingGoodsType=${fleeingGoodsTypeValue}&dealerIds=${dealerIdsValue}&shopIds=${shopIdsValue}&productIds=${productIdsValue}&provinceCode=${provinceCode}&cityCode=${cityCode}&dealerName=${dealerName}`

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.anticounterfeiting.monitor}/v1/flowCityRank?${paramsStr}`,
        }).pipe(
            map(res => actions.flowCityRank.SUCCESS(res.response)),
            catchError(error => of(actions.flowCityRank.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 窜货趋势
export const flowTrendEpic = actions$ => actions$.pipe(
    ofType(actions.flowTrend.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime, endTime, fleeingGoodsType = {}, dealerIds,dealerName='', shopIds, productIds, provinceCode = '', cityCode = '' } = action.payload;

        let fleeingGoodsTypeValue = fleeingGoodsType.value || '';
        let dealerIdsValue = dealerIds || '';
        let shopIdsValue = shopIds || '';
        let productIdsValue = productIds || '';

        let paramsStr = `startTime=${startTime}&endTime=${endTime}&fleeingGoodsType=${fleeingGoodsTypeValue}&dealerIds=${dealerIdsValue}&shopIds=${shopIdsValue}&productIds=${productIdsValue}&provinceCode=${provinceCode}&cityCode=${cityCode}&dealerName=${dealerName}`

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.anticounterfeiting.monitor}/v1/flowTrend?${paramsStr}`,
        }).pipe(
            map(res => actions.flowTrend.SUCCESS(res.response)),
            catchError(error => of(actions.flowTrend.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 防伪数量列表
export const securityPageEpic = actions$ => actions$.pipe(
    ofType(actions.securityPage.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, sort = 'tagdate,desc', startTime, endTime, securityPromotionId = '', securityPlanIds = '', dealerIds,dealerName='', shopIds, productIds, provinceCode = '', cityCode = '', securityLevel='' } = action.payload;

        let securityPromotionIdValue =  (securityPromotionId == 'all') ? '' : securityPromotionId;
        let securityPlanIdsValue = (securityPlanIds == 'all') ? '' : securityPlanIds;
        let dealerIdsValue = dealerIds || '';
        let shopIdsValue = shopIds || '';
        let productIdsValue = productIds || '';
        let securityLevelValue = securityLevel || [];

        let paramsStr = `page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&securityPromotionId=${securityPromotionIdValue}&securityPlanIds=${securityPlanIdsValue}&dealerIds=${dealerIdsValue}&shopIds=${shopIdsValue}&productIds=${productIdsValue}&provinceCode=${provinceCode}&cityCode=${cityCode}&dealerName=${dealerName}&securityLevel=${securityLevelValue}`

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.anticounterfeiting.monitor}/v1/securityPage?${paramsStr}`,
        }).pipe(
            map(res => actions.securityPage.SUCCESS(res.response)),
            catchError(error => of(actions.securityPage.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 窜货数量列表
export const flowPageEpic = actions$ => actions$.pipe(
    ofType(actions.flowPage.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, sort = 'tagdate,desc',startTime, endTime, fleeingGoodsType = {}, dealerIds,dealerName='', shopIds, productIds, provinceCode = '', cityCode = '' } = action.payload;

        let fleeingGoodsTypeValue = fleeingGoodsType.value || '';
        let dealerIdsValue = dealerIds || '';
        let shopIdsValue = shopIds || '';
        let productIdsValue = productIds || '';

        let paramsStr = `page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&fleeingGoodsType=${fleeingGoodsTypeValue}&dealerIds=${dealerIdsValue}&shopIds=${shopIdsValue}&productIds=${productIdsValue}&provinceCode=${provinceCode}&cityCode=${cityCode}&dealerName=${dealerName}`

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.anticounterfeiting.monitor}/v1/flowPage?${paramsStr}`,
        }).pipe(
            map(res => actions.flowPage.SUCCESS(res.response)),
            catchError(error => of(actions.flowPage.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 窜货统计
export const flowStatisticsEpic = actions$ => actions$.pipe(
    ofType(actions.flowStatistics.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime, endTime, fleeingGoodsType = {}, dealerIds,dealerName='', shopIds, productIds, provinceCode = '', cityCode = '' } = action.payload;

        let fleeingGoodsTypeValue = fleeingGoodsType.value || '';
        let dealerIdsValue = dealerIds || '';
        let shopIdsValue = shopIds || '';
        let productIdsValue = productIds || '';

        let paramsStr = `startTime=${startTime}&endTime=${endTime}&fleeingGoodsType=${fleeingGoodsTypeValue}&dealerIds=${dealerIdsValue}&shopIds=${shopIdsValue}&productIds=${productIdsValue}&provinceCode=${provinceCode}&cityCode=${cityCode}&dealerName=${dealerName}`

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.anticounterfeiting.monitor}/v1/flowStatistics?${paramsStr}`,
        }).pipe(
            map(res => actions.flowStatistics.SUCCESS(res.response)),
            catchError(error => of(actions.flowStatistics.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
