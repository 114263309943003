import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'whiteOff';


export const loadList = fasterActions(moduleName, 'loadList');
export const loadById = fasterActions(moduleName, 'loadById');
export const editWhiteOff = fasterActions(moduleName, 'editWhiteOff');
export const deleteWhiteOff = fasterActions(moduleName, 'deleteWhiteOff');
export const whiteOffPause = fasterActions(moduleName, 'whiteOffPause');
export const whiteOffResume = fasterActions(moduleName, 'whiteOffResume');

export const publishWhiteOff = fasterActions(moduleName, 'publishWhiteOff');
export const loadPromotionHistoriesById = fasterActions(moduleName, 'loadPromotionHistoriesById');
//新增方案
export const addLinkPlan = fasterActions(moduleName, 'addLinkPlan');
export const copyPlan = fasterActions(moduleName, 'copyPlan');
//排序方案

export const sortLinkPlan = fasterActions(moduleName, 'sortLinkPlan');
//方案不分页
export const linkPlanSelect = fasterActions(moduleName, 'linkPlanSelect');
export const getPlanDetail = fasterActions(moduleName, 'getPlanDetail');

//搜索特定分组
export const linkPlanTypeValue = fasterActions(moduleName, 'linkPlanTypeValue');

//清空数据
export const unmount = fasterActions(moduleName, 'unmount');


//优先级列表
export const loadPriorities = fasterActions(moduleName, 'loadPriorities');
export const updatePriorities = fasterActions(moduleName, 'updatePriorities');



//第三步配置
export const loadwhiteOffConfigsById = fasterActions(moduleName, 'loadwhiteOffConfigsById');
export const addwhiteOffConfigs = fasterActions(moduleName, 'addwhiteOffConfigs');
export const editwhiteOffConfigs = fasterActions(moduleName, 'editwhiteOffConfigs');


// 统计草稿数量
export const draftCount = fasterActions(moduleName, 'draftCount');




//核销政策页面
export const loadWriteOffPolicyList = fasterActions(moduleName, 'loadWriteOffPolicyList');
export const loadWriteOffPolicyId = fasterActions(moduleName, 'loadWriteOffPolicyId');
export const createWriteOffPolicy = fasterActions(moduleName, 'createWriteOffPolicy');
export const editWriteOffPolicy = fasterActions(moduleName, 'editWriteOffPolicy');
export const ableWriteOffPolicy = fasterActions(moduleName, 'ableWriteOffPolicy');
export const deleteWriteOffPolicy = fasterActions(moduleName, 'deleteWriteOffPolicy');

export const getWriteOffPolicySelection = fasterActions(moduleName, 'getWriteOffPolicySelection');




const INIT_BYID = `${moduleName}/INIT_BYID`;

export function initByid() {
    return { type: INIT_BYID };
}


const initialPoolState = {
    loadinglist: false,
    byId: {},
    byIdLoaded: false,

    linkPlanRule: [],
    linkPlanfilterValue: '',
    pageParams: {
        page: 0,
        size: 20,
        startTime: '',
        endTime: ''
    },
    loading: false,
    secActivitySelectorData: [],
    pageData: {},
    codeList: {},



    //核销政策页面
    writeOffPolicyList: [],
    writeOffPolicySelection: [],
    loadWriteOffPolicyListLoading: false,
    loadWriteOffPolicyListLoaded: false,

}

// Reducer
const reducer = handleActions(
    {
        [INIT_BYID]: (state, { payload }) => ({ ...state, byId: {}, history: {}, historyStep0: '', historyStep1: '', historyStep2: '', historyStep3: '' }),

        [loadList.REQUEST]: (state, { payload }) => ({ ...state, loadinglist: true }),
        [loadList.SUCCESS]: (state, { payload }) => ({ ...state, loadinglist: false, listLoaded: true, list: payload }),
        [loadList.FAIL]: (state, { payload }) => ({ ...state, loadinglist: false, listLoaded: false, error: payload }),



        [loadById.REQUEST]: (state, { payload }) => ({ ...state, byIdLoaded: true }),
        [loadById.SUCCESS]: (state, { payload }) => {
            let data = {
                PRODUCT: [],
                PARTICIPATING_DEALERS: [],
                PARTICIPATING_SHOP: [],
                PARTICIPATING_SALES_AREA: [],
                MARKETING_CHANNEL: [],
                CODE_STATUS: [],
                CODE_LABEL: [],
                VERIFY_STYLE: [],

                likeProduct: '-1',
                likeDealers: '-1',
                likeShop: '-1',
                likeChannel: '-1',
            }
            let conditions = {};
            conditions = payload.conditions;
            if (conditions && conditions.PRODUCT) {
                for (let elem of conditions.PRODUCT.values()) {
                    data.PRODUCT.push(elem.value)
                    if (elem.notContain) {
                        data.likeProduct = 'true'
                    }
                }
            }

            if (conditions && conditions.PARTICIPATING_DEALERS) {
                for (let elem of conditions.PARTICIPATING_DEALERS.values()) {
                    data.PARTICIPATING_DEALERS.push(elem.value)
                    if (elem.notContain) {
                        data.likeDealers = 'true'
                    }
                }
            }
            if (conditions && conditions.PARTICIPATING_SHOP) {
                for (let elem of conditions.PARTICIPATING_SHOP.values()) {
                    data.PARTICIPATING_SHOP.push(elem.value)
                    if (elem.notContain) {
                        data.likeDealers = 'true'
                    }
                }
            }
            if (conditions && conditions.PARTICIPATING_SALES_AREA) {
                for (let elem of conditions.PARTICIPATING_SALES_AREA.values()) {
                    data.PARTICIPATING_SALES_AREA.push(elem.value)

                }
            }
            if (conditions && conditions.MARKETING_CHANNEL) {
                for (let elem of conditions.MARKETING_CHANNEL.values()) {
                    data.MARKETING_CHANNEL.push(elem.value)
                    if (elem.notContain) {
                        data.likeChannel = 'true'
                    }
                }
            }
            if (conditions && conditions.CODE_STATUS) {
                for (let elem of conditions.CODE_STATUS.values()) {
                    data.CODE_STATUS.push(elem.value)
                }
            }
            if (conditions && conditions.CODE_LABEL) {
                for (let elem of conditions.CODE_LABEL.values()) {
                    data.CODE_LABEL.push(elem.value)
                }
            }


            if (conditions && conditions.VERIFY_STYLE) {
                for (let elem of conditions.VERIFY_STYLE.values()) {
                    data.VERIFY_STYLE.push(elem.value)
                }
            }




            return {
                ...state, byId: payload, byIdLoaded: true,
                selectProductValues: data.PRODUCT || [],
                likeProduct: data.likeProduct,
                selectDealersValues: data.PARTICIPATING_DEALERS || [],
                likeDealers: data.likeDealers,
                selectShopValues: data.PARTICIPATING_SHOP || [],
                likeShop: data.likeShop,
                selectSalesAreaValues: data.PARTICIPATING_SALES_AREA || [],
                selectChannelValues: data.MARKETING_CHANNEL || [],
                likeChannel: data.likeChannel,
                selectCodeStatusValues: data.CODE_STATUS || [],
                selectCodeLabelValues: data.CODE_LABEL || [],
                selectCodeCodeverifyValues: data.VERIFY_STYLE || [],
            };
        },

        [loadById.FAIL]: (state, { payload }) => ({ ...state, byIdLoaded: false, error: payload }),


        [editWhiteOff.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [editWhiteOff.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, }),
        [editWhiteOff.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),


        [deleteWhiteOff.REQUEST]: (state, { payload }) => ({ ...state, }),
        [deleteWhiteOff.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [deleteWhiteOff.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [whiteOffPause.REQUEST]: (state, { payload }) => ({ ...state, }),
        [whiteOffPause.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [whiteOffPause.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [whiteOffResume.REQUEST]: (state, { payload }) => ({ ...state, }),
        [whiteOffResume.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [whiteOffResume.FAIL]: (state, { payload }) => ({ ...state, perror: payload }),

        [publishWhiteOff.REQUEST]: (state, { payload }) => ({ ...state, }),
        [publishWhiteOff.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [publishWhiteOff.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [loadPromotionHistoriesById.REQUEST]: (state, { payload }) => ({ ...state, loadPromotionHistoriesByIdLoading: true }),
        [loadPromotionHistoriesById.SUCCESS]: (state, { payload }) => ({ ...state, loadPromotionHistoriesByIdLoading: false, loadPromotionHistoriesByIdLoaded: true, promotionHistories: payload }),
        [loadPromotionHistoriesById.FAIL]: (state, { payload }) => ({ ...state, loadPromotionHistoriesByIdLoading: false, loadPromotionHistoriesByIdLoaded: false, error: payload }),


        // 环节管理新版
        [addLinkPlan.REQUEST]: (state, { payload }) => ({ ...state, }),
        [addLinkPlan.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [addLinkPlan.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        //获取环境方案
        [linkPlanSelect.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [linkPlanSelect.SUCCESS]: (state, { payload }) => {
            let linkPlanTypeValueList = payload.filter(key => { return (key.name || '').indexOf(state.linkPlanfilterValue) >= 0 || (key.id.toString()).indexOf(state.linkPlanfilterValue) >= 0 });
            return { ...state, linkPlanSelection: linkPlanTypeValueList, linkPlanAllSelection: payload, loading: false }

        },

        [linkPlanTypeValue.REQUEST]: (state, { payload }) => {
            let linkPlanTypeValueList = state.linkPlanAllSelection.filter(key => { return (key.name || '').indexOf(payload) >= 0 || (key.id.toString()).indexOf(payload) >= 0 });
            return { ...state, linkPlanfilterValue: payload, linkPlanSelection: linkPlanTypeValueList }
        },


        [linkPlanSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),


        [getPlanDetail.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [getPlanDetail.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, }),
        [getPlanDetail.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),


        //排序环节方案
        [sortLinkPlan.REQUEST]: (state, { payload }) => ({ ...state }),
        [sortLinkPlan.SUCCESS]: (state, { payload }) => ({ ...state }),
        [sortLinkPlan.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [copyPlan.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [copyPlan.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
        [copyPlan.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

        [unmount.REQUEST]: (state, { payload }) => ({
            ...state,
            linkPlanSelection: [],
            linkPlanAllSelection: [],
            linkPlanfilterValue: '',

        }),





        [loadPriorities.REQUEST]: (state, { payload }) => ({ ...state, loadPrioritiesLoading: true }),
        [loadPriorities.SUCCESS]: (state, { payload }) => ({ ...state, loadPrioritiesLoading: false, loadPrioritiesLoaded: true, priorities: payload }),
        [loadPriorities.FAIL]: (state, { payload }) => ({ ...state, loadPrioritiesLoading: false, loadPrioritiesLoaded: false, error: payload }),
        [updatePriorities.REQUEST]: (state, { payload }) => ({ ...state, updatePrioritiesLoading: true, loadPrioritiesParams: payload.params }),
        [updatePriorities.SUCCESS]: (state, { payload }) => ({ ...state, updatePrioritiesLoading: false, updatePrioritiesLoaded: true }),
        [updatePriorities.FAIL]: (state, { payload }) => ({ ...state, updatePrioritiesLoading: false, updatePrioritiesLoaded: false, error: payload }),




        [loadwhiteOffConfigsById.REQUEST]: (state, { payload }) => ({ ...state, }),
        [loadwhiteOffConfigsById.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [loadwhiteOffConfigsById.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [addwhiteOffConfigs.REQUEST]: (state, { payload }) => ({ ...state, }),
        [addwhiteOffConfigs.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [addwhiteOffConfigs.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [editwhiteOffConfigs.REQUEST]: (state, { payload }) => ({ ...state, }),
        [editwhiteOffConfigs.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [editwhiteOffConfigs.FAIL]: (state, { payload }) => ({ ...state, error: payload }),




        [draftCount.REQUEST]: (state, { payload }) => ({ ...state, draftCountLoading: true }),
        [draftCount.SUCCESS]: (state, { payload }) => ({ ...state, draftCountLoading: false, draftCountLoaded: true, draftCount: payload }),
        [draftCount.FAIL]: (state, { payload }) => ({ ...state, draftCountLoading: false, draftCountLoaded: false, error: payload }),







        //核销政策页面
        [loadWriteOffPolicyList.REQUEST]: (state, { payload }) => ({ ...state, loadWriteOffPolicyListLoading: true }),
        [loadWriteOffPolicyList.SUCCESS]: (state, { payload }) => ({ ...state, loadWriteOffPolicyListLoading: false, loadWriteOffPolicyListLoaded: true, writeOffPolicyList: payload }),
        [loadWriteOffPolicyList.FAIL]: (state, { payload }) => ({ ...state, loadWriteOffPolicyListLoading: false, loadWriteOffPolicyListLoaded: false, error: payload }),

        [loadWriteOffPolicyId.REQUEST]: (state, { payload }) => ({ ...state }),
        [loadWriteOffPolicyId.SUCCESS]: (state, { payload }) => ({ ...state }),
        [loadWriteOffPolicyId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [createWriteOffPolicy.REQUEST]: (state, { payload }) => ({ ...state, cOrEWriteOffPolicyLoading: true }),
        [createWriteOffPolicy.SUCCESS]: (state, { payload }) => ({ ...state, cOrEWriteOffPolicyLoading: false }),
        [createWriteOffPolicy.FAIL]: (state, { payload }) => ({ ...state, cOrEWriteOffPolicyLoading: false }),
        [editWriteOffPolicy.REQUEST]: (state, { payload }) => ({ ...state, cOrEWriteOffPolicyLoading: true }),
        [editWriteOffPolicy.SUCCESS]: (state, { payload }) => ({ ...state, cOrEWriteOffPolicyLoading: false }),
        [editWriteOffPolicy.FAIL]: (state, { payload }) => ({ ...state, cOrEWriteOffPolicyLoading: false }),
        [ableWriteOffPolicy.REQUEST]: (state, { payload }) => ({ ...state }),
        [ableWriteOffPolicy.SUCCESS]: (state, { payload }) => ({ ...state }),
        [ableWriteOffPolicy.FAIL]: (state, { payload }) => ({ ...state }),

        [deleteWriteOffPolicy.REQUEST]: (state, { payload }) => ({ ...state }),
        [deleteWriteOffPolicy.SUCCESS]: (state, { payload }) => ({ ...state }),
        [deleteWriteOffPolicy.FAIL]: (state, { payload }) => ({ ...state }),

        [getWriteOffPolicySelection.REQUEST]: (state, { payload }) => ({ ...state }),
        [getWriteOffPolicySelection.SUCCESS]: (state, { payload }) => {
            let selector = []
            if (payload) {
                for (let elem of payload.values()) {
                    selector.push({ ...elem, id: elem.id, value: elem.id, text: elem.name })
                }
            }
            return ({ ...state, writeOffPolicySelection: selector, })
        },
        [getWriteOffPolicySelection.FAIL]: (state, { payload }) => ({ ...state }),

    },
    initialPoolState
)
export default reducer;