import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './dealerGroup';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 字段分配
 */

// 列表
export const loadPageListEpic = actions$ => actions$.pipe(
    ofType(actions.loadPageList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20,code='',sort='',status='' } = action.payload;
        console.log(code)
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.dealerGroup.list}?page=${page}&size=${size}&code=${code}&sort=${sort}&status=${status}`
        }).pipe(
            map(res => actions.loadPageList.SUCCESS(res.response)),
            catchError(error => of(actions.loadPageList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 新增
export const pageAddEpic = actions$ => actions$.pipe(
    ofType(actions.pageAdd.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20,name='',code='',mainId=[],viceIds=[],callback={},pageParams } = action.payload;
        console.log(action.payload)
        return ajax({
            ...ajaxBaseConfig,
            method:'POST',
            url: `${urls.data.dealerGroup.list}`,
            body:{
                name,code,
                mainId:mainId.value,
                viceIds:viceIds
            }
        }).pipe(
            concatMap((res) =>{ 
                callback && callback();
                return [actions.loadPageList.REQUEST({...pageParams,page:0})]
            }),
            catchError(error => {
                console.log(error)
                return of(actions.pageAdd.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))})
        )
    }
    )
);

// 编辑
export const pageEditEpic = actions$ => actions$.pipe(
    ofType(actions.pageEdit.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20,name='',code='',mainId=[],viceIds=[],id='',viceId=[],callback={},version='',pageParams } = action.payload;
        console.log(action.payload)
        return ajax({
            ...ajaxBaseConfig,
            method:'PUT',
            url: `${urls.data.dealerGroup.list}`,
            body:{
                name,code,
                mainId:mainId.value,
                viceId:viceId.value,
                viceIds:viceIds,
                id:id,
                version:version
            }
        }).pipe(
            concatMap((res) =>{ 
                callback && callback();
                return [actions.loadPageList.REQUEST({...pageParams})]
            }),
            catchError(error => {
                console.log(error)
                return of(actions.pageEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))})
        )
    }
    )
);

//启禁用
export const enableChangeEpic = actions$ => actions$.pipe(
    ofType(actions.enableChange.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const {id='',status='',pageParams } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.dealerGroup.list}/${id}/status?status=${status}`,
            method: 'PATCH'
        }).pipe(
            // map(res => actions.enableChange.SUCCESS(res.response)),
            concatMap((res) =>{ 
                
                return [actions.loadPageList.REQUEST({...pageParams})]
            }),
            catchError(error => of(actions.enableChange.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

export const findByDealerIdEpic = actions$ => actions$.pipe(
    ofType(actions.findByDealerId.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const {dealerId=''} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.dealerGroup.list}/findByDealerId?dealerId=${dealerId}`,
        }).pipe(
            map(res => actions.findByDealerId.SUCCESS(res.response)),
            catchError(error => of(actions.findByDealerId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

export const dealerQueryEpic = actions$ => actions$.pipe(
    ofType(actions.dealerQuery.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const {name='',cate='DEALER',number=20,callback={}} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.dealerGroup.query}?word=${name}&size=${number}`,
        }).pipe(
            map(res => {callback&&callback(res.response.content);return actions.dealerQuery.SUCCESS(res.response)}),
            catchError(error => {
                console.log(error)
                return of(actions.dealerQuery.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    }
    )
);

// 下载
export const downloadEpic = actions$ => actions$.pipe(
    ofType(actions.download.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        
        let {callback=()=>{}} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.dealerGroup.download}`
        }).pipe(
            
            map(res => {
                callback && callback(res.response);
                return actions.download.SUCCESS(res.response)}),
            catchError(error => {
                return of(actions.download.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))})
        )
    }
    )
);


