import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';


const moduleName = 'wechat';

/**
 * TODO: 微信管理-支付管理
 */
// 红包祝福语select列表
export const loadBlessingAll = fasterActions(moduleName, 'loadBlessingAll');
export const loadBlessingIdDetail = fasterActions(moduleName, 'loadBlessingIdDetail');
export const blessingCreate = fasterActions(moduleName, 'blessingCreate');
export const blessingEdit = fasterActions(moduleName, 'blessingEdit');
export const blessingDialogVisibale = createAction(`${moduleName}/blessingDialogVisibale`);
export const authorizerConfigsLoad = fasterActions(`${moduleName}/authorizerConfigsLoad`);


const initialState = {
  wechatPayLoad: false,
  blessingSubmitLoad: false,
  blessingAllList: [],
  blessingIdDetailObj: {},
  isBlessingDialogVisibale: false,
  blessingId: '',
  authorizerConfigsList: [],
  cardInfoObj: {}
}

const reducer = handleActions(
  {
    // 红包祝福语
    // 下拉列表
    [loadBlessingAll.REQUEST]: (state, { payload }) => ({ ...state}),
    [loadBlessingAll.SUCCESS]: (state, { payload }) => {
      const selector = [];
      if (payload) {
        for (let elem of payload.values()) {
          selector.push({ value: elem.id, text: elem.wishing })
        }
      }
      return ({ ...state, blessingAllList: selector })
    },
    [loadBlessingAll.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 详情
    [loadBlessingIdDetail.REQUEST]: (state, { payload }) => ({ ...state}),
    [loadBlessingIdDetail.SUCCESS]: (state, { payload }) => ({ ...state, blessingIdDetailObj: payload }),
    [loadBlessingIdDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 创建
    [blessingCreate.REQUEST]: (state, { payload }) => ({ ...state, blessingSubmitLoad: true}),
    [blessingCreate.SUCCESS]: (state, { payload }) => ({ ...state, blessingSubmitLoad: false }),
    [blessingCreate.FAIL]: (state, { payload }) => ({ ...state, blessingSubmitLoad: false, error: payload }),
    // 编辑
    [blessingEdit.REQUEST]: (state, { payload }) => ({ ...state, blessingSubmitLoad: true}),
    [blessingEdit.SUCCESS]: (state, { payload }) => ({ ...state, blessingSubmitLoad: false }),
    [blessingEdit.FAIL]: (state, { payload }) => ({ ...state, blessingSubmitLoad: false, error: payload }),
    // 弹窗
    [blessingDialogVisibale]: (state, { payload }) => ({ ...state, ...payload }),
    
  },
  initialState
);

export default reducer;
