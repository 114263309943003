import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Menu } from "antd"
import {
  setOpenKeys,
  setInitOpenKeysByRouter,
  loadPermissions,
  setNavBar
} from "@/redux/modules/ui"
import { routes } from "../../../../routes"
import { integal } from "./integer.js"

const { apps: mockAppsConfig = [], appNameMap } = require('../../../../mock/apps'); // 本地调试用
const mockAppsPath = mockAppsConfig.map(v => v.activePath && v.activePath.split('/')[1]);

const { SubMenu, Item: MenuItem } = Menu

@withRouter
@connect(
  state => {
    return {
      permissions: state.ui.permissions,
      lvTwoMenuOfLvOne: state.ui.lvTwoMenuOfLvOne,
      lvThreeMenuOfLvTwo: state.ui.lvThreeMenuOfLvTwo,
      selectedKeysOfRouter: state.ui.selectedKeysOfRouter,
      openKeys: state.ui.openKeys,
      nowRouter: state.ui.nowRouter,
      navbar: state.ui.navbar,
      allMenuUrls: state.ui.allMenuUrls,
      lvTwoMenuUrls: state.ui.lvTwoMenuUrls
    }
  },
  { setOpenKeys, setInitOpenKeysByRouter, loadPermissions, setNavBar }
)
class SideMenu extends React.Component {
  constructor(props) {
    super(props)
    this.routesEnum = {} // 路由枚举
  }

  componentDidMount() {
    const {
      setInitOpenKeysByRouter,
      loadPermissions,
      location,
      allMenuUrls,
      lvTwoMenuUrls,
      history
    } = this.props
    // loadPermissions(location.pathname.split('/')[1]);
    // 带自定义名字的路由
    routes.forEach(({ name, path }) => {
      if (name) {
        this.routesEnum[path] = name
      }
    })

    if (window.localStorage.getItem("_navbar")) {
      this.props.setNavBar(window.localStorage.getItem("_navbar"))
    }

    this.props.history.listen((location, action) => {
      const isMockApp = mockAppsPath.includes(location.pathname.split("/")[1]);
      const index = isMockApp ? location.pathname.indexOf('/', 1) + 1 : 1;
      setInitOpenKeysByRouter(location.pathname.split("/")[isMockApp ? 2 : 1]);
      // setInitOpenKeysByRouter(location.pathname.split("/")[1])   // 原来的
      // if (!(allMenuUrls.includes(location.pathname.split('/')[1]) || lvTwoMenuUrls.includes(location.pathname.split('/')[1]))) {
      //   history.push('/root');
      // }
      const { selectedKeysOfRouter } = this.props
      // 监听路由是否是，2，3级路由/自定义路由，设置navbar
      // const currentRouter = location.pathname.substr(1);   // 原来的
      const currentRouter = location.pathname.substr(index);
      let navbar
      if (currentRouter) {
        if (selectedKeysOfRouter[currentRouter]) {
          navbar = selectedKeysOfRouter[currentRouter].name.zh_CN
        } else if (this.routesEnum[`/${currentRouter}`]) {
          navbar = this.routesEnum[`/${currentRouter}`]
        }
      }

      if (navbar) {
        this.props.setNavBar(navbar)
      }
    })
  }

  componentDidUpdate(prevProps) {
    // 第一次进来，赋值navbar
    if (this.props.selectedKeysOfRouter !== prevProps.selectedKeysOfRouter) {
      const { location, selectedKeysOfRouter } = this.props
      const isMockApp = mockAppsPath.includes(location.pathname.split("/")[1]);
      const index = isMockApp ? location.pathname.indexOf('/',1)+1: 1;
      const currentRouter = location.pathname.substr(index);
      // const currentRouter = location.pathname.substr(1);   // 原来的
      // 路由列表有 用路由列表,没有用缓存里的
      let navbar
      if (selectedKeysOfRouter[currentRouter]) {
        navbar = selectedKeysOfRouter[currentRouter].name.zh_CN
      } else if (this.routesEnum[`/${currentRouter}`]) {
        navbar = this.routesEnum[`/${currentRouter}`]
      } else {
        navbar = window.localStorage.getItem("_navbar") || ""
      }

      if (navbar) {
        this.props.setNavBar(navbar)
      }
    }
  }

  onOpenChange = openKeys => {
    this.props.setOpenKeys(openKeys)
  }

  handleLevelOneItem = () => {
    const { permissions = [] } = this.props
    let permissionsTest = JSON.parse(JSON.stringify(permissions))
    // console.log("permissionsTest", permissions)

   /*  if (!permissions.includes("积分商城")) {
      permissionsTest.splice(2, 0, integal)
    } */
    
   // console.log("permissionsTest", permissionsTest)

    const subMenu = permissionsTest.map((v, i) => {
      if (v.url !== "") {
        return (
          <MenuItem key={v.url} item={v}>
            <span>{JSON.parse(v.name).zh_CN}</span>
          </MenuItem>
        )
      }
      return (
        <SubMenu key={v.permission} title={JSON.parse(v.name).zh_CN}>
          {this.handleLevelTwoItem(v.children)}
        </SubMenu>
      )
    })
    /* const subMenu = permissions.map((v, i) => {
      if (v.url !== '') {
        return (
          <MenuItem key={v.url}>
            <span>{JSON.parse(v.name).zh_CN}</span>
          </MenuItem>
        )
      }
      return (
        <SubMenu
          key={v.permission}
          title={JSON.parse(v.name).zh_CN}
        >
          {this.handleLevelTwoItem(v.children)}
        </SubMenu>
      )
    }); */
    return subMenu
  }

  handleLevelTwoItem = levelTwoItems => {
    const menuItem = levelTwoItems.map((v, k) => {
      return <MenuItem key={v.url} item={v}>{JSON.parse(v.name).zh_CN}</MenuItem>
    })
    return menuItem
  }

  handleItemClick = ({ item, key, keyPath }) => {
    const { permission } = item.props?.item || {};
    const { history, location, lvThreeMenuOfLvTwo } = this.props
    const levelThreeRouters = lvThreeMenuOfLvTwo[key]

    let url = key
    if (levelThreeRouters && levelThreeRouters.length > 0) {
      url = levelThreeRouters[0].url
    }
    
    // 原来的
    // if (location.pathname === `/${url}`) return
    // history.push(`/${url}`)
    // if(url.includes('davinci')){
    //   return ;
    // }
    // this.props.menuClick({ requestUrl: `/${url}`, menu: this.props.tabMap.get(url) })

    let theAppName = `${permission.split('.')[0]}`;
    for (const k in appNameMap) {
      if (k.split(',').includes(theAppName)) {
        theAppName = appNameMap[k];
      }
    }
    let the_url = `${theAppName}/${url}`;
    if (location.pathname === `/${the_url}`) return
    history.push(`/${the_url}`, true);
    if(url.includes('davinci')){
      return ;
    }
    // this.props.menuClick({ requestUrl: `/${the_url}`, menu: this.props.tabMap.get(the_url) })
  }

  render() {
    const { selectedKeysOfRouter, openKeys, nowRouter } = this.props
    const main = this.handleLevelOneItem()
    const keyObj = selectedKeysOfRouter[nowRouter]

    return (
      <div>
        <Menu
          mode='inline'
          onClick={this.handleItemClick}
          selectedKeys={[keyObj ? keyObj.selectedKey : ""]}
          openKeys={openKeys}
          onOpenChange={this.onOpenChange}
        >
          {main}
        </Menu>
      </div>
    )
  }
}

export default withRouter(SideMenu)
