import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './datadealers';
import { ajaxBaseConfig, actionApi } from '../../utils';

// 分页查询经销商
export const loadDealerListEpic = actions$ => actions$.pipe(
    ofType(actions.loadDealerList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { page = 0, size = 20, sort='id,desc', keyword = '', levels = [], ...rest } = action.payload;
        const params = {
            page,
            size,
            sort,
            word: keyword || undefined,
            levels: levels.length ? levels.join(',') : undefined,
            ...rest,
            totalElements: undefined
        }
        const str = queryString.stringify(params, { encode: false })
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.dealers.dealers}/?${str}`,
        }).pipe(
            map(res => actions.loadDealerList.SUCCESS(res.response)),
            catchError(error => of(actions.loadDealerList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

// 查询经销商详情
export const dealerByIdEpic = actions$ => actions$.pipe(
    ofType(actions.dealerById.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const url = urls.statistics.dataOverview.statistics;
        const {id, callback} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.dealers.dealers}/${id}`,
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.dealerById.SUCCESS(res.response)
            }),
            catchError(error => of(actions.dealerById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

// 禁用启用经销商
export const enableOrDisableDealerEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableOrDisableDealer.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const {id, status, version} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            // url: `${urls.data.dealers.dealers}/status/${id}/${status}/${version}`,
            url: `${urls.data.dealers.dealers}/${id}/status?status=${status}`,
            body: action.payload,
            method: 'PATCH'
        }).pipe(
            concatMap(res => [actions.enableOrDisableDealer.SUCCESS(res.response), actions.loadDealerList.REQUEST(state$.value.datadealers.paging)]),
            catchError(error => of(actions.enableOrDisableDealer.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

// 新增经销商
export const postDealerEpic = actions$ => actions$.pipe(
    ofType(actions.postDealer.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const {data, callback} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            // url: `${urls.data.dealers.dealers}/new`,
            url: `${urls.data.dealers.dealers}`,
            body: data,
            method: 'POST'
        }).pipe(
            map(res =>  {
                callback && callback(res.response);
            return actions.postDealer.SUCCESS(res.response)}),
            catchError(error => of(actions.postDealer.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

// 修改经销商
export const putDealerEpic = actions$ => actions$.pipe(
    ofType(actions.putDealer.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const {data, callback} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            // url: `${urls.data.dealers.dealers}/update`,
            url: `${urls.data.dealers.dealers}`,
            body: data,
            method: 'PUT'
        }).pipe(
            map(res => {
                callback && callback();
                return actions.putDealer.SUCCESS(res.response)}),
            catchError(error => of(actions.putDealer.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);


//下拉框
export const loadDealerSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.loadDealerSelection.REQUEST.toString()),
    mergeMap(action => { actionApi()
        let url = `${urls.data.dealers.dealers}/allots`;
        if(action.payload && action.payload.page){
          url+=`?isPage=${action.payload.page}`;    
        }
        else{
          url+=`?isPage=false`;    
        }
    
        if(action.payload && action.payload.status){
          url+=`&status=${action.payload.status}`;    
        }
        if(action.payload && action.payload.size){
          url+=`&size=${action.payload.size}`;    
        }
    
        if(action.payload && action.payload.word){
          url+=`&word=${action.payload.word}`;    
        }
    
        const {callback = null} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url,
        //  url: `${urls.data.dealers.dealers}/selections?status=${status}`,
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.loadDealerSelection.SUCCESS({...res.response,showMaterTYpe:action.payload.showMaterTYpe})
            }),
            catchError(error => of(actions.loadDealerSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);


// 服务人员//下拉框
export const LoadServiceStaffIdsEpic = actions$ => actions$.pipe(
    ofType(actions.LoadServiceStaffIds.REQUEST.toString()),
    mergeMap(action => { actionApi()
        let url = `${urls.data.dealers.LoadServiceStaffIds}`;
        return ajax({
            ...ajaxBaseConfig,
            url,
            method: 'post',
            body:{},
        }).pipe(
            map(res => actions.LoadServiceStaffIds.SUCCESS(res.response)),
            catchError(error => of(actions.LoadServiceStaffIds.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);