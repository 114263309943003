import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './sendWay';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const loadListEpic = actions$ => actions$.pipe(
  ofType(actions.loadList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.sendWay.sendWay}?poolId=${action.payload.poolId}`,
    }).pipe(
      map(res => actions.loadList.SUCCESS(res.response)),
      catchError(error => of(actions.loadList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const updateSendWayEpic = (actions$, store) => actions$.pipe(
  ofType(actions.updateSendWay.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.sendWay.sendWay}`,
      method: 'PUT',
      body: data
    }).pipe(
      mergeMap(res => {
        callback && callback()
        return [actions.updateSendWay.SUCCESS(res.response), uiActions.notification('修改成功', 'success'), actions.updateSelected({})]
      }), //  actions.loadList.REQUEST({ promotionId: store.value.promotions.byId.id }), actions.updateSelected({})

      catchError(error => of(actions.updateSendWay.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  })
);

export const addSendWayEpic = (actions$, store) => actions$.pipe(
  ofType(actions.addSendWay.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.sendWay.sendWay}`,
      method: 'POST',
      body: data
    }).pipe(
      mergeMap(res => {
        callback && callback()
        return [actions.addSendWay.SUCCESS(res.response), uiActions.notification('创建成功', 'success'), actions.updateSelected({})]
      }), //actions.updateSelected({}) , actions.loadList.REQUEST({ promotionId: store.value.promotions.byId.id })
      catchError(error => of(actions.addSendWay.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  })
);

export const delSendWayEpic = (actions$, store) => actions$.pipe(
  ofType(actions.delSendWay.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.sendWay.sendWay}/${data}`,
      method: 'DELETE',
    }).pipe(
      mergeMap(res => {
        callback && callback()
        return [actions.delSendWay.SUCCESS(res.response), uiActions.notification('删除成功', 'success'), actions.updateSelected({})]
      }),
      catchError(error => of(actions.delSendWay.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  })
);