import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'batchApply';

export const loadApplyUnauditeds = fasterActions(moduleName, 'loadApplyUnauditeds');
export const loadApplyPasses = fasterActions(moduleName, 'loadApplyPasses');
export const loadApplyReturns = fasterActions(moduleName, 'loadApplyReturns');
export const sendBatchs = fasterActions(moduleName, 'sendBatchs');
export const loadAuditUnauditeds = fasterActions(moduleName, 'loadAuditUnauditeds');
export const loadAuditPasses = fasterActions(moduleName, 'loadAuditPasses');
export const audit = fasterActions(moduleName, 'audit');
export const auditPass = fasterActions(moduleName, 'auditPass');
export const viewAudit = fasterActions(moduleName, 'viewAudit');
export const viewAuditLog = fasterActions(moduleName, 'viewAuditLog');
export const wechatviewAudit = fasterActions(moduleName, 'wechatviewAudit');
export const wechatSaveSetting = fasterActions(moduleName, 'wechatSaveSetting');
export const wechatloadApplyUnauditeds = fasterActions(moduleName, 'wechatloadApplyUnauditeds');



export const batchApplys = fasterActions(moduleName, 'batchApplys');


const initialState = {
  loadApplyUnauditedsLoading: false,
  loadApplyUnauditedsLoaded: false,
  loadApplyPassesLoading: false,
  loadApplyPassesLoaded: false,
  loadApplyReturnsLoading: false,
  loadApplyReturnsLoaded: false,
  loadAuditPassesParams: {},
  wechatloadApplyUnauditedsLoading: false,
  wechatloadApplyUnauditedsLoaded: false,
  wechatapplyUnauditeds:{},
  wechatSettingsLoading: false,
  wechatSettingsLoaded: false,
  weChatViewapplicationsettings:{},
  weChatViewapplicationsettingsParams:{},
  wechatSaveSettingReturn:{},
  wechatSaveSettingParams:{}
}

const reducer = handleActions(
  {
    [loadApplyUnauditeds.REQUEST]: (state, { payload }) => ({ ...state, loadApplyUnauditedsLoading: true }),
    [loadApplyUnauditeds.SUCCESS]: (state, { payload }) => ({ ...state, loadApplyUnauditedsLoading: false, loadApplyUnauditedsLoaded: true, applyUnauditeds: payload }),
    [loadApplyUnauditeds.FAIL]: (state, { payload }) => ({ ...state, loadApplyUnauditedsLoading: false, loadApplyUnauditedsLoaded: false, error: payload }),
    [loadApplyPasses.REQUEST]: (state, { payload }) => ({ ...state, loadApplyPassesLoading: true }),
    [loadApplyPasses.SUCCESS]: (state, { payload }) => ({ ...state, loadApplyPassesLoading: false, loadApplyPassesLoaded: true, applyPasses: payload }),
    [loadApplyPasses.FAIL]: (state, { payload }) => ({ ...state, loadApplyPassesLoading: false, loadApplyPassesLoaded: false, error: payload }),
    [loadApplyReturns.REQUEST]: (state, { payload }) => ({ ...state, loadApplyReturnsLoading: true }),
    [loadApplyReturns.SUCCESS]: (state, { payload }) => ({ ...state, loadApplyReturnsLoading: false, loadApplyReturnsLoaded: true, applyReturns: payload }),
    [loadApplyReturns.FAIL]: (state, { payload }) => ({ ...state, loadApplyReturnsLoading: false, loadApplyReturnsLoaded: false, error: payload }),
    [sendBatchs.REQUEST]: (state, { payload }) => ({ ...state, sendBatchsLoading: true }),
    [sendBatchs.SUCCESS]: (state, { payload }) => ({ ...state, sendBatchsLoading: false, sendBatchsLoaded: true, sendBatchs: payload }),
    [sendBatchs.FAIL]: (state, { payload }) => ({ ...state, sendBatchsLoading: false, sendBatchsLoaded: false, error: payload }),
    [loadAuditUnauditeds.REQUEST]: (state, { payload }) => ({ ...state, loadAuditUnauditedsLoading: true }),
    [loadAuditUnauditeds.SUCCESS]: (state, { payload }) => ({ ...state, loadAuditUnauditedsLoading: false, loadAuditUnauditedsLoaded: true, auditUnauditeds: payload }),
    [loadAuditUnauditeds.FAIL]: (state, { payload }) => ({ ...state, loadAuditUnauditedsLoading: false, loadAuditUnauditedsLoaded: false, error: payload }),
    [loadAuditPasses.REQUEST]: (state, { payload }) => ({ ...state, loadAuditPassesLoading: true, loadAuditPassesParams: payload }),
    [loadAuditPasses.SUCCESS]: (state, { payload }) => ({ ...state, loadAuditPassesLoading: false, loadAuditPassesLoaded: true, auditPasses: payload }),
    [loadAuditPasses.FAIL]: (state, { payload }) => ({ ...state, loadAuditPassesLoading: false, loadAuditPassesLoaded: false, error: payload }),
    [audit.REQUEST]: (state, { payload }) => ({ ...state, auditLoading: true }),
    [audit.SUCCESS]: (state, { payload }) => ({ ...state, auditLoading: false, auditLoaded: true, audit: payload }),
    [audit.FAIL]: (state, { payload }) => ({ ...state, auditLoading: false, auditLoaded: false, error: payload }),
    [auditPass.REQUEST]: (state, { payload }) => ({ ...state, auditPassLoading: true }),
    [auditPass.SUCCESS]: (state, { payload }) => ({ ...state, auditPassLoading: false, auditPassLoaded: true, auditPass: payload }),
    [auditPass.FAIL]: (state, { payload }) => ({ ...state, auditPassLoading: false, auditPassLoaded: false, error: payload }),
    [viewAudit.REQUEST]: (state, { payload }) => ({ ...state, viewAuditLoading: true, viewAudit: undefined }),
    [viewAudit.SUCCESS]: (state, { payload }) => ({ ...state, viewAuditLoading: false, viewAuditLoaded: true, viewAudit: payload }),
    [viewAudit.FAIL]: (state, { payload }) => ({ ...state, viewAuditLoading: false, viewAuditLoaded: false, error: payload }),
    [wechatviewAudit.REQUEST]: (state, { payload }) => ({ ...state, wechatSettingsLoading: true,weChatViewapplicationsettingsParams:payload}),
    [wechatviewAudit.SUCCESS]: (state, { payload }) => ({ ...state, wechatSettingsLoading: false, wechatSettingsLoaded: true, weChatViewapplicationsettings: payload }),
    [wechatviewAudit.FAIL]: (state, { payload }) => ({ ...state, wechatSettingsLoading: false, wechatSettingsLoaded: false, error: payload }),
    [batchApplys.REQUEST]: (state, { payload }) => ({ ...state, payload, batchApplysLoading: true, batchApplysRes: '' }),
    [batchApplys.SUCCESS]: (state, { payload }) => ({ ...state, batchApplysRes: payload, batchApplysLoading: false }),
    [batchApplys.FAIL]: (state, { payload }) => ({ ...state, error: payload, batchApplysLoading: false }),
    [viewAuditLog.REQUEST]: (state, { payload }) => ({ ...state, viewAuditLogLoading: true, viewAuditLog: undefined }),
    [viewAuditLog.SUCCESS]: (state, { payload }) => ({ ...state, viewAuditLogLoading: false, viewAuditLogLoaded: true, viewAuditLog: payload }),
    [viewAuditLog.FAIL]: (state, { payload }) => ({ ...state, viewAuditLogLoading: false, viewAuditLogLoaded: false, error: payload }),
    [wechatloadApplyUnauditeds.REQUEST]: (state, { payload }) => ({ ...state, wechatloadApplyUnauditedsLoading: true }),
    [wechatloadApplyUnauditeds.SUCCESS]: (state, { payload }) => ({ ...state, wechatloadApplyUnauditedsLoading: false, wechatloadApplyUnauditedsLoaded: true, wechatapplyUnauditeds: payload }),
    [wechatloadApplyUnauditeds.FAIL]: (state, { payload }) => ({ ...state, wechatloadApplyUnauditedsLoading: false, wechatloadApplyUnauditedsLoaded: false, error: payload }),
    [wechatSaveSetting.REQUEST]: (state, { payload }) => ({ ...state, wechatSettingsLoading: true,wechatSaveSettingParams:payload}),
    [wechatSaveSetting.SUCCESS]: (state, { payload }) => ({ ...state, wechatSettingsLoading: false, wechatSettingsLoaded: true, wechatSaveSettingReturn: payload }),
    [wechatSaveSetting.FAIL]: (state, { payload }) => ({ ...state, wechatSettingsLoading: false, wechatSettingsLoaded: false, error: payload }),
  },
  initialState
)

export default reducer;