import React from "react";

const SvgIconAdvisory = props => (
  <svg width={10} height={10} {...props}>
    <g fill="#006EFF" fillRule="nonzero">
      <path d="M5 0l1.793 1.793L3 5.586 4.414 7l3.793-3.793L10 5V0z" />
      <path d="M8 8H2V2h2V0H0v10h10V6H8z" />
    </g>
  </svg>
);

export default SvgIconAdvisory;
