import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'characteristic';

/**
 * TODO: 宴会类型
 */
export const banquetTypeTableList = fasterActions(moduleName, 'banquetTypeTableList'); // 宴会类型
export const banquetTypeEdit = fasterActions(moduleName, 'banquetTypeEdit'); // 宴会类型编辑
export const banquetTypeCreate = fasterActions(moduleName, 'banquetTypeCreate'); // 宴会类型创建
export const banquetTypeDelete = fasterActions(moduleName, 'banquetTypeDelete'); // 宴会类型删除
export const banquetTypeStatus = fasterActions(moduleName, 'banquetTypeStatus'); // 宴会类型状态

/**
 * 宴会政策
 */
export const banquetPolicyOnlineTableList = fasterActions(moduleName, 'banquetPolicyOnlineTableList'); // 政策列表
export const banquetPolicyTestTableList = fasterActions(moduleName, 'banquetPolicyTestTableList'); // 政策草稿列表
export const banquetPolicyTestCreate = fasterActions(moduleName, 'banquetPolicyTestCreate'); // 政策创建
export const banquetPolicyTestEdit = fasterActions(moduleName, 'banquetPolicyTestEdit'); // 政策编辑
export const banquetPolicyTestView = fasterActions(moduleName, 'banquetPolicyTestView'); // 政策查看
export const banquetPolicyHistoryView = fasterActions(moduleName, 'banquetPolicyHistoryView'); // 宴会政策历史详情
export const banquetPolicyTestRelease = fasterActions(moduleName, 'banquetPolicyTestRelease'); // 政策发布
export const banquetPolicyTestDelete = fasterActions(moduleName, 'banquetPolicyTestDelete'); // 政策删除
export const banquetPolicyTestStatus = fasterActions(moduleName, 'banquetPolicyTestStatus'); // 政策状态更改
export const banquetPolicyHistory = fasterActions(moduleName, 'banquetPolicyHistory'); // 政策状态历史

export const banquetOrderTypeList = fasterActions(moduleName, 'banquetOrderTypeList')
export const banquetOrderTypeInfo = fasterActions(moduleName, 'banquetOrderTypeInfo')
export const banquetOrderDataList = fasterActions(moduleName, 'banquetOrderDataList')
export const banquetOrderDataInfo = fasterActions(moduleName, 'banquetOrderDataInfo')
export const banquetOrderSelection = fasterActions(moduleName, 'banquetOrderSelection')
export const banquetTypeSelection = fasterActions(moduleName, 'banquetTypeSelection')
export const banquetPolicySelection = fasterActions(moduleName, 'banquetPolicySelection')
export const banquetOrderTypeCreate = fasterActions(moduleName, 'banquetOrderTypeCreate')
export const banquetOrderTypeEdit = fasterActions(moduleName, 'banquetOrderTypeEdit')
export const banquetOrderTypeStatus = fasterActions(moduleName, 'banquetOrderTypeStatus')

/**
 * 执行奖励
 */
export const banquetRewardOnlineTableList = fasterActions(moduleName, 'banquetRewardOnlineTableList'); // 执行奖励正式列表
export const banquetRewardTestTableList = fasterActions(moduleName, 'banquetRewardTestTableList'); // 执行奖励草稿列表
export const banquetRewardTestStatus = fasterActions(moduleName, 'banquetRewardTestStatus'); // 执行奖励状态更改
export const banquetRewardHistory = fasterActions(moduleName, 'banquetRewardHistory'); // 执行奖励历史列表
export const banquetRewardHistoryView = fasterActions(moduleName, 'banquetRewardHistoryView'); // 执行奖励历史详情
export const banquetRewardCreate = fasterActions(moduleName, 'banquetRewardCreate'); // 执行奖励创建
export const banquetRewardEdit = fasterActions(moduleName, 'banquetRewardEdit'); // 执行奖励编辑
export const banquetRewardAddProgramme = fasterActions(moduleName, 'banquetRewardAddProgramme'); // 添加方案
export const banquetRewardEditProgramme = fasterActions(moduleName, 'banquetRewardEditProgramme'); // 修改方案
export const banquetRewardListProgramme = fasterActions(moduleName, 'banquetRewardListProgramme'); // 方案列表
export const banquetRewardListRule = fasterActions(moduleName, 'banquetRewardListRule'); // 方案准则数据列表
export const banquetRewardListOnlineRule = fasterActions(moduleName, 'banquetRewardListOnlineRule'); // 方案准则数据正式列表
export const banquetRewardSortRule = fasterActions(moduleName, 'banquetRewardSortRule'); // 方案顺序调整
export const banquetRewardActivityHistory = fasterActions(moduleName, 'banquetRewardActivityHistory'); // 活动详情
export const rewardPolicyList = fasterActions(moduleName, 'rewardPolicyList'); // 核销政策
export const banquetLevel = fasterActions(moduleName, 'banquetLevel'); // 投放奖品
export const banquetRewardThrow = fasterActions(moduleName, 'banquetRewardThrow'); // 优先级
export const banquetRewardOnlineView = fasterActions(moduleName, 'banquetRewardOnlineView'); // 正式版详情
export const banquetRewardOnlineStatus = fasterActions(moduleName, 'banquetRewardOnlineStatus'); // 调整状态
export const releaseBanquetReward = fasterActions(moduleName, 'releaseBanquetReward'); // 发布
export const banquetRewardDelete = fasterActions(moduleName, 'banquetRewardDelete'); // 删除草稿
export const banquetRewardPrizeList = fasterActions(moduleName, 'banquetRewardPrizeList'); // 奖品列表
export const banquetRewardOnlineEdit = fasterActions(moduleName, 'banquetRewardOnlineEdit'); // 正式版编辑
export const banquetRewardFactory = fasterActions(moduleName, 'banquetRewardFactory'); // 工厂

export const banquetRewardWaitReleaseList = fasterActions(moduleName, 'banquetRewardWaitReleaseList'); // 待发布列表
export const timeReleaseBanquetReward = fasterActions(moduleName, 'timeReleaseBanquetReward'); // 定时发布
export const cancelReleaseBanquetReward = fasterActions(moduleName, 'cancelReleaseBanquetReward'); // 取消发布
export const banquetRewardWaitReleaseView = fasterActions(moduleName, 'banquetRewardWaitReleaseView'); // 活动详情

export const orderActivityRecordList = fasterActions(moduleName, 'orderActivityRecordList'); // 订单活动记录
export const orderActivityRecordExport = fasterActions(moduleName, 'orderActivityRecordExport'); // 订单活动导出


/**
 * 终端门店
 */
 export const writeOffStore = fasterActions(moduleName, 'writeOffStore'); // 终端列表
 export const writeOffEmptyAll = fasterActions(moduleName, 'writeOffEmptyAll'); // 清空终端列表
 export const writeOffUpload = fasterActions(moduleName, 'writeOffUpload'); // 上传终端
 export const writeOffEdit = fasterActions(moduleName, 'writeOffEdit'); // 选择终端 -- 终端编辑确认
 export const writeOffStoreAll = fasterActions(moduleName, 'writeOffStoreAll'); // 选择终端 -- 终端编辑确认
 export const writeOffDelete = fasterActions(moduleName, 'writeOffDelete'); // 删除终端
 export const writeOffPolicy = fasterActions(moduleName, 'writeOffPolicy'); // 政策/批量政策

/***
 * 终端核销记录
 */
 export const writeOffDetail = fasterActions(moduleName, 'writeOffDetail'); // 核销明细列表
 export const writeOffDetailExport = fasterActions(moduleName, 'writeOffDetailExport'); // 核销明细导出
 export const writeOffLog = fasterActions(moduleName, ' writeOffLog'); // 核销执行奖励明细列表
 export const writeOffLogExport = fasterActions(moduleName, 'writeOffLogExport'); // 核销执行奖励明细导出

export const levelRulesSelections = fasterActions(moduleName, 'levelRulesSelections')

const initialState = {
  loading: false, // 列表loading
  createLoading: false,
  banquetPolicyTestForm: {}, // 表单信息
}

const reducer = handleActions(
  {

    [banquetTypeTableList.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [banquetTypeTableList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, banquetType: payload }),
    [banquetTypeTableList.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),

    [banquetTypeEdit.REQUEST]: (state, { payload }) => ({ ...state, }),
    [banquetTypeEdit.SUCCESS]: (state, { payload }) => ({ ...state, banquetTypeEdit: payload }),
    [banquetTypeEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [banquetTypeCreate.REQUEST]: (state, { payload }) => ({ ...state, }),
    [banquetTypeCreate.SUCCESS]: (state, { payload }) => ({ ...state }),
    [banquetTypeCreate.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [banquetTypeDelete.REQUEST]: (state, { payload }) => ({ ...state, }),
    [banquetTypeDelete.SUCCESS]: (state, { payload }) => ({ ...state }),
    [banquetTypeDelete.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [banquetTypeStatus.REQUEST]: (state, { payload }) => ({ ...state, }),
    [banquetTypeStatus.SUCCESS]: (state, { payload }) => ({ ...state }),
    [banquetTypeStatus.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [banquetPolicyOnlineTableList.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [banquetPolicyOnlineTableList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, banquetPolicy: payload }),
    [banquetPolicyOnlineTableList.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),

    [banquetPolicyTestTableList.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [banquetPolicyTestTableList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, banquetPolicy: payload }),
    [banquetPolicyTestTableList.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),

    [banquetPolicyTestCreate.REQUEST]: (state, { payload }) => ({ ...state, createLoading: true }),
    [banquetPolicyTestCreate.SUCCESS]: (state, { payload }) => ({ ...state, createLoading: false, banquetPolicyTestForm: payload }),
    [banquetPolicyTestCreate.FAIL]: (state, { payload }) => ({ ...state, createLoading: false, error: payload }),

    [banquetPolicyTestEdit.REQUEST]: (state, { payload }) => ({ ...state, createLoading: true }),
    [banquetPolicyTestEdit.SUCCESS]: (state, { payload }) => ({ ...state, createLoading: false, banquetPolicyTestForm: payload }),
    [banquetPolicyTestEdit.FAIL]: (state, { payload }) => ({ ...state, createLoading: false, error: payload }),

    [banquetPolicyTestView.REQUEST]: (state, { payload }) => ({ ...state, createLoading: true }),
    [banquetPolicyTestView.SUCCESS]: (state, { payload }) => ({ ...state, createLoading: false, banquetPolicyTestForm: payload }),
    [banquetPolicyTestView.FAIL]: (state, { payload }) => ({ ...state, createLoading: false, error: payload }),

    [banquetPolicyHistoryView.REQUEST]: (state, { payload }) => ({ ...state, createLoading: true }),
    [banquetPolicyHistoryView.SUCCESS]: (state, { payload }) => ({ ...state, createLoading: false, banquetPolicyTestForm: payload }),
    [banquetPolicyHistoryView.FAIL]: (state, { payload }) => ({ ...state, createLoading: false, error: payload }),

    [banquetPolicyTestRelease.REQUEST]: (state, { payload }) => ({ ...state, createLoading: true }),
    [banquetPolicyTestRelease.SUCCESS]: (state, { payload }) => ({ ...state, createLoading: false, }),
    [banquetPolicyTestRelease.FAIL]: (state, { payload }) => ({ ...state, createLoading: false, error: payload }),

    [banquetPolicyTestDelete.REQUEST]: (state, { payload }) => ({ ...state, createLoading: true }),
    [banquetPolicyTestDelete.SUCCESS]: (state, { payload }) => ({ ...state, createLoading: false, }),
    [banquetPolicyTestDelete.FAIL]: (state, { payload }) => ({ ...state, createLoading: false, error: payload }),

    [banquetPolicyTestStatus.REQUEST]: (state, { payload }) => ({ ...state, }),
    [banquetPolicyTestStatus.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [banquetPolicyTestStatus.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [banquetPolicyHistory.REQUEST]: (state, { payload }) => ({ ...state, historyLoading: true }),
    [banquetPolicyHistory.SUCCESS]: (state, { payload }) => ({ ...state, banquetPolicyHistory: payload, historyLoading: false }),
    [banquetPolicyHistory.FAIL]: (state, { payload }) => ({ ...state, error: payload, historyLoading: false }),

    [banquetRewardOnlineTableList.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [banquetRewardOnlineTableList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, banquetReward: payload }),
    [banquetRewardOnlineTableList.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),

    [banquetRewardTestTableList.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [banquetRewardTestTableList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, banquetReward: payload }),
    [banquetRewardTestTableList.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),

    [banquetRewardTestStatus.REQUEST]: (state, { payload }) => ({ ...state, }),
    [banquetRewardTestStatus.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [banquetRewardTestStatus.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [banquetRewardHistory.REQUEST]: (state, { payload }) => ({ ...state, historyLoading: true }),
    [banquetRewardHistory.SUCCESS]: (state, { payload }) => ({ ...state, banquetRewardHistory: payload, historyLoading: false }),
    [banquetRewardHistory.FAIL]: (state, { payload }) => ({ ...state, error: payload, historyLoading: false }),

    [banquetRewardHistoryView.REQUEST]: (state, { payload }) => ({ ...state, }),
    [banquetRewardHistoryView.SUCCESS]: (state, { payload }) => ({ ...state, banquetRewardHistoryView: payload,banquetRewardForm: payload, }),
    [banquetRewardHistoryView.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    [banquetRewardCreate.REQUEST]: (state, { payload }) => ({ ...state, createLoading: true}),
    [banquetRewardCreate.SUCCESS]: (state, { payload }) => ({ ...state, banquetRewardForm: payload,createLoading: false }),
    [banquetRewardCreate.FAIL]: (state, { payload }) => ({ ...state, error: payload,createLoading: false }),

    [banquetRewardEdit.REQUEST]: (state, { payload }) => ({ ...state, createLoading: true}),
    [banquetRewardEdit.SUCCESS]: (state, { payload }) => ({ ...state, createLoading: false }),
    [banquetRewardEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload,createLoading: false }),

    [banquetRewardOnlineEdit.REQUEST]: (state, { payload }) => ({ ...state, createLoading: true}),
    [banquetRewardOnlineEdit.SUCCESS]: (state, { payload }) => ({ ...state, createLoading: false }),
    [banquetRewardOnlineEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload,createLoading: false }),

    [banquetRewardAddProgramme.REQUEST]: (state, { payload }) => ({ ...state, }),
    [banquetRewardAddProgramme.SUCCESS]: (state, { payload }) => ({ ...state,  }),
    [banquetRewardAddProgramme.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    [banquetRewardEditProgramme.REQUEST]: (state, { payload }) => ({ ...state, banquetRewardEditProgrammeLoading: true}),
    [banquetRewardEditProgramme.SUCCESS]: (state, { payload }) => ({ ...state, banquetRewardEditProgramme: payload,banquetRewardEditProgrammeLoading: false }),
    [banquetRewardEditProgramme.FAIL]: (state, { payload }) => ({ ...state, error: payload, banquetRewardEditProgrammeLoading: false}),

    [banquetRewardListProgramme.REQUEST]: (state, { payload }) => ({ ...state, }),
    [banquetRewardListProgramme.SUCCESS]: (state, { payload }) => ({ ...state, banquetRewardListProgramme: payload, }),
    [banquetRewardListProgramme.FAIL]: (state, { payload }) => ({ ...state, error: payload,}),

    [banquetRewardListRule.REQUEST]: (state, { payload }) => ({ ...state, banquetRewardListRuleLoading: true}),
    [banquetRewardListRule.SUCCESS]: (state, { payload }) => ({ ...state, banquetRewardListRule: payload,banquetRewardListRuleLoading: false }),
    [banquetRewardListRule.FAIL]: (state, { payload }) => ({ ...state, error: payload,banquetRewardListRuleLoading: false}),

    [banquetRewardListOnlineRule.REQUEST]: (state, { payload }) => ({ ...state, banquetRewardListRuleLoading: true}),
    [banquetRewardListOnlineRule.SUCCESS]: (state, { payload }) => ({ ...state, banquetRewardListRule: payload,banquetRewardListRuleLoading: false }),
    [banquetRewardListOnlineRule.FAIL]: (state, { payload }) => ({ ...state, error: payload,banquetRewardListRuleLoading: false}),

    [banquetRewardSortRule.REQUEST]: (state, { payload }) => ({ ...state, }),
    [banquetRewardSortRule.SUCCESS]: (state, { payload }) => ({ ...state, banquetRewardSortRule: payload, }),
    [banquetRewardSortRule.FAIL]: (state, { payload }) => ({ ...state, error: payload,}),
    
    [banquetRewardActivityHistory.REQUEST]: (state, { payload }) => ({ ...state,banquetRewardActivityLoading: true }),
    [banquetRewardActivityHistory.SUCCESS]: (state, { payload }) => ({ ...state, banquetRewardForm: payload,banquetRewardActivityLoading: false }),
    [banquetRewardActivityHistory.FAIL]: (state, { payload }) => ({ ...state, error: payload,banquetRewardActivityLoading: false}),

    [rewardPolicyList.REQUEST]: (state, { payload }) => ({ ...state,rewardPolicyListLoading: true }),
    [rewardPolicyList.SUCCESS]: (state, { payload }) => ({ ...state, rewardPolicyList: payload,rewardPolicyListLoading: false }),
    [rewardPolicyList.FAIL]: (state, { payload }) => ({ ...state, error: payload,rewardPolicyListLoading: false}),
    
    [banquetRewardThrow.REQUEST]: (state, { payload }) => ({ ...state,banquetRewardThrowLoading: true }),
    [banquetRewardThrow.SUCCESS]: (state, { payload }) => ({ ...state, banquetRewardThrowLoading: false }),
    [banquetRewardThrow.FAIL]: (state, { payload }) => ({ ...state, error: payload,banquetRewardThrowLoading: false}),

    [banquetLevel.REQUEST]: (state, { payload }) => ({ ...state, }),
    [banquetLevel.SUCCESS]: (state, { payload }) => ({ ...state,  }),
    [banquetLevel.FAIL]: (state, { payload }) => ({ ...state, error: payload,}),

    [banquetRewardOnlineView.REQUEST]: (state, { payload }) => ({ ...state, banquetRewardActivityLoading: true}),
    [banquetRewardOnlineView.SUCCESS]: (state, { payload }) => ({ ...state, banquetRewardForm: payload,banquetRewardActivityLoading: false }),
    [banquetRewardOnlineView.FAIL]: (state, { payload }) => ({ ...state, error: payload,banquetRewardActivityLoading: false }),

    [banquetRewardOnlineStatus.REQUEST]: (state, { payload }) => ({ ...state, }),
    [banquetRewardOnlineStatus.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [banquetRewardOnlineStatus.FAIL]: (state, { payload }) => ({ ...state, error: payload}),

    [releaseBanquetReward.REQUEST]: (state, { payload }) => ({ ...state, releaseBanquetRewardLoading: true}),
    [releaseBanquetReward.SUCCESS]: (state, { payload }) => ({ ...state,releaseBanquetRewardLoading: false }),
    [releaseBanquetReward.FAIL]: (state, { payload }) => ({ ...state, error: payload,releaseBanquetRewardLoading: false}),
    
    [banquetRewardDelete.REQUEST]: (state, { payload }) => ({ ...state}),
    [banquetRewardDelete.SUCCESS]: (state, { payload }) => ({ ...state}),
    [banquetRewardDelete.FAIL]: (state, { payload }) => ({ ...state, error: payload,}),

    [banquetRewardPrizeList.REQUEST]: (state, { payload }) => ({ ...state,banquetRewardPrizeLoading: true}),
    [banquetRewardPrizeList.SUCCESS]: (state, { payload }) => ({ ...state,banquetRewardPrizeLoading: false,banquetRewardPrizeList: payload,}),
    [banquetRewardPrizeList.FAIL]: (state, { payload }) => ({ ...state, error: payload,banquetRewardPrizeLoading: false}),
    
    [writeOffStoreAll.REQUEST]: (state, { payload }) => ({ ...state,writeOffStoreAllLoading: true}),
    [writeOffStoreAll.SUCCESS]: (state, { payload }) => ({ ...state,writeOffStoreAllLoading: false,writeOffStoreAllList: payload}),
    [writeOffStoreAll.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffStoreAllLoading: false}),

    [writeOffStore.REQUEST]: (state, { payload }) => ({ ...state,writeOffStoreLoading: true}),
    [writeOffStore.SUCCESS]: (state, { payload }) => ({ ...state,writeOffStoreLoading: false,writeOffStoreList: payload}),
    [writeOffStore.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffStoreLoading: false}),

    [writeOffEmptyAll.REQUEST]: (state, { payload }) => ({ ...state,writeOffEmptyAllLoading: true}),
    [writeOffEmptyAll.SUCCESS]: (state, { payload }) => ({ ...state,writeOffEmptyAllLoading: false,}),
    [writeOffEmptyAll.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffEmptyAllLoading: false}),

    [writeOffUpload.REQUEST]: (state, { payload }) => ({ ...state,writeOffUploadLoading: true}),
    [writeOffUpload.SUCCESS]: (state, { payload }) => ({ ...state,writeOffUploadLoading: false,writeOffUploadList: payload}),
    [writeOffUpload.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffUploadLoading: false}),

    [writeOffEdit.REQUEST]: (state, { payload }) => ({ ...state,writeOffEditLoading: true}),
    [writeOffEdit.SUCCESS]: (state, { payload }) => ({ ...state,writeOffEditLoading: false,writeOffEditList: payload}),
    [writeOffEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffEditLoading: false}),

    [writeOffDelete.REQUEST]: (state, { payload }) => ({ ...state,writeOffDeleteLoading: true}),
    [writeOffDelete.SUCCESS]: (state, { payload }) => ({ ...state,writeOffDeleteLoading: false}),
    [writeOffDelete.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffDeleteLoading: false}),

    [writeOffPolicy.REQUEST]: (state, { payload }) => ({ ...state,writeOffPolicyLoading: true}),
    [writeOffPolicy.SUCCESS]: (state, { payload }) => ({ ...state,writeOffPolicyLoading: false}),
    [writeOffPolicy.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffPolicyLoading: false}),

    [writeOffDetail.REQUEST]: (state, { payload }) => ({ ...state,writeOffDetailLoading: true}),
    [writeOffDetail.SUCCESS]: (state, { payload }) => ({ ...state,writeOffDetailLoading: false,writeOffDetailList: payload}),
    [writeOffDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffDetailLoading: false}),

    [writeOffDetailExport.REQUEST]: (state, { payload }) => ({ ...state,writeOffDetailExportLoading: true}),
    [writeOffDetailExport.SUCCESS]: (state, { payload }) => ({ ...state,writeOffDetailExportLoading: false}),
    [writeOffDetailExport.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffDetailExportLoading: false}),

    [writeOffLog.REQUEST]: (state, { payload }) => ({ ...state,writeOffLogLoading: true}),
    [writeOffLog.SUCCESS]: (state, { payload }) => ({ ...state,writeOffLogLoading: false,writeOffLogList: payload}),
    [writeOffLog.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffLogLoading: false}),

    [writeOffLogExport.REQUEST]: (state, { payload }) => ({ ...state,writeOffLogExportLoading: true}),
    [writeOffLogExport.SUCCESS]: (state, { payload }) => ({ ...state,writeOffLogExportLoading: false}),
    [writeOffLogExport.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffLogExportLoading: false}),

    [banquetRewardFactory.REQUEST]: (state, { payload }) => ({ ...state,banquetRewardFactoryLoading: true}),
    [banquetRewardFactory.SUCCESS]: (state, { payload }) => ({ ...state,banquetRewardFactoryLoading: false,banquetRewardFactoryList: payload?.content || []}),
    [banquetRewardFactory.FAIL]: (state, { payload }) => ({ ...state, error: payload,banquetRewardFactoryLoading: false}),

    [banquetRewardWaitReleaseList.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [banquetRewardWaitReleaseList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, banquetReward: payload }),
    [banquetRewardWaitReleaseList.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),

    [timeReleaseBanquetReward.REQUEST]: (state, { payload }) => ({ ...state, timeReleaseBanquetRewardLoading: true}),
    [timeReleaseBanquetReward.SUCCESS]: (state, { payload }) => ({ ...state,timeReleaseBanquetRewardLoading: false }),
    [timeReleaseBanquetReward.FAIL]: (state, { payload }) => ({ ...state, error: payload,timeReleaseBanquetRewardLoading: false}),

    [cancelReleaseBanquetReward.REQUEST]: (state, { payload }) => ({ ...state, cancelReleaseBanquetRewardLoading: true}),
    [cancelReleaseBanquetReward.SUCCESS]: (state, { payload }) => ({ ...state,cancelReleaseBanquetRewardLoading: false }),
    [cancelReleaseBanquetReward.FAIL]: (state, { payload }) => ({ ...state, error: payload,cancelReleaseBanquetRewardLoading: false}),

    [banquetRewardWaitReleaseView.REQUEST]: (state, { payload }) => ({ ...state, }),
    [banquetRewardWaitReleaseView.SUCCESS]: (state, { payload }) => ({ ...state, banquetRewardHistoryView: payload,banquetRewardForm: payload, }),
    [banquetRewardWaitReleaseView.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    [orderActivityRecordList.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [orderActivityRecordList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, orderActivityRecord: payload }),
    [orderActivityRecordList.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),

    [orderActivityRecordExport.REQUEST]: (state, { payload }) => ({ ...state,orderActivityRecordExportLoading: true}),
    [orderActivityRecordExport.SUCCESS]: (state, { payload }) => ({ ...state,orderActivityRecordExportLoading: false}),
    [orderActivityRecordExport.FAIL]: (state, { payload }) => ({ ...state, error: payload,orderActivityRecordExportLoading: false}),

    [banquetOrderTypeList.REQUEST]: (state, { payload }) => ({ ...state }),
    [banquetOrderTypeList.SUCCESS]: (state, { payload }) => ({ ...state }),
    [banquetOrderTypeList.FAIL]: (state, { payload }) => ({ ...state }),

    [banquetOrderTypeInfo.REQUEST]: (state, { payload }) => ({ ...state }),
    [banquetOrderTypeInfo.SUCCESS]: (state, { payload }) => ({ ...state }),
    [banquetOrderTypeInfo.FAIL]: (state, { payload }) => ({ ...state }),

    [banquetOrderDataList.REQUEST]: (state, { payload }) => ({ ...state }),
    [banquetOrderDataList.SUCCESS]: (state, { payload }) => ({ ...state }),
    [banquetOrderDataList.FAIL]: (state, { payload }) => ({ ...state }),

    [banquetOrderDataInfo.REQUEST]: (state, { payload }) => ({ ...state }),
    [banquetOrderDataInfo.SUCCESS]: (state, { payload }) => ({ ...state }),
    [banquetOrderDataInfo.FAIL]: (state, { payload }) => ({ ...state }),

    
    [banquetOrderSelection.REQUEST]: (state, { payload }) => ({ ...state }),
    [banquetOrderSelection.SUCCESS]: (state, { payload }) => ({ ...state, banquetOrderSelect: payload.content }),
    [banquetOrderSelection.FAIL]: (state, { payload }) => ({ ...state }),

    [banquetTypeSelection.REQUEST]: (state, { payload }) => ({ ...state }),
    [banquetTypeSelection.SUCCESS]: (state, { payload }) => ({ ...state }),
    [banquetTypeSelection.FAIL]: (state, { payload }) => ({ ...state }),

    [banquetPolicySelection.REQUEST]: (state, { payload }) => ({ ...state }),
    [banquetPolicySelection.SUCCESS]: (state, { payload }) => ({ ...state, banquetPolicySelect: payload }),
    [banquetPolicySelection.FAIL]: (state, { payload }) => ({ ...state }),

    [banquetOrderTypeCreate.REQUEST]: (state, { payload }) => ({ ...state }),
    [banquetOrderTypeCreate.SUCCESS]: (state, { payload }) => ({ ...state }),
    [banquetOrderTypeCreate.FAIL]: (state, { payload }) => ({ ...state }),

    [banquetOrderTypeEdit.REQUEST]: (state, { payload }) => ({ ...state }),
    [banquetOrderTypeEdit.SUCCESS]: (state, { payload }) => ({ ...state }),
    [banquetOrderTypeEdit.FAIL]: (state, { payload }) => ({ ...state }),

    [banquetOrderTypeStatus.REQUEST]: (state, { payload }) => ({ ...state }),
    [banquetOrderTypeStatus.SUCCESS]: (state, { payload }) => ({ ...state }),
    [banquetOrderTypeStatus.FAIL]: (state, { payload }) => ({ ...state }),

    [levelRulesSelections.REQUEST]: (state, { payload }) => ({ ...state, }),
    [levelRulesSelections.SUCCESS]: (state, { payload }) => ({ ...state, levelRulesSelections: payload }),
    [levelRulesSelections.FAIL]: (state, { payload }) => ({ ...state, levelRulesSelections: [] })

  },
  initialState
);

export default reducer;
