import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'memberTask';

/**
 * TODO: 
 */
export const getTaskList = fasterActions(moduleName, 'getTaskList');
export const getTaskById = fasterActions(moduleName, 'getTaskById');
export const loadTimeAll = fasterActions(moduleName, 'loadTimeAll');
export const createTask = fasterActions(moduleName, 'createTask');
export const editTask = fasterActions(moduleName, 'editTask');
export const deleteTask = fasterActions(moduleName, 'deleteTask');

export const publishTask = fasterActions(moduleName, 'publishTask');
export const publishTaskTime = fasterActions(moduleName, 'publishTaskTime');
export const deletePublishTime = fasterActions(moduleName, 'deletePublishTime');
export const disabledOrEnableTask = fasterActions(moduleName, 'disabledOrEnableTask');
export const getTaskHistoryTable = fasterActions(moduleName, 'getTaskHistoryTable');
export const getTaskHistoryInfo = fasterActions(moduleName, 'getTaskHistoryInfo');

export const getTaskForSystem = fasterActions(moduleName, 'getTaskForSystem');

export const draftCount = fasterActions(moduleName, 'draftCount'); // 统计草稿数量
export const draftCountTime = fasterActions(moduleName, 'draftCountTime'); // 统计待发布数量
export const draftCountPublish = fasterActions(moduleName, 'draftCountPublish'); // 统计已发布数量


export const unmountSearch = fasterActions(moduleName, 'unmountSearch'); // 
export const unmountHistorySearch = fasterActions(moduleName, 'unmountHistorySearch'); //

const initialState = {

  taskList: [],
  taskById: {},
  taskForSystem: [],
  getTaskListLoading: false,
  getTaskListLoaded: false,


  taskSearch: { page: 0, size: 20},
  taskHistorySearch: { page: 0, size: 20 },
}

const reducer = handleActions(
  {

    [getTaskList.REQUEST]: (state, { payload }) => ({ ...state, getTaskListLoading: true, taskSearch: payload }),
    [getTaskList.SUCCESS]: (state, { payload }) => ({ ...state, getTaskListLoading: false, getTaskListLoaded: true, taskList: payload }),
    [getTaskList.FAIL]: (state, { payload }) => ({ ...state, getTaskListLoading: false, getTaskListLoaded: false, error: payload }),

    [getTaskById.REQUEST]: (state, { payload }) => ({ ...state }),
    [getTaskById.SUCCESS]: (state, { payload }) => ({ ...state, taskById: payload }),
    [getTaskById.FAIL]: (state, { payload }) => ({ ...state }),


    [createTask.REQUEST]: (state, { payload }) => ({ ...state, createTaskLoading: true }),
    [createTask.SUCCESS]: (state, { payload }) => ({ ...state, createTaskLoading: false }),
    [createTask.FAIL]: (state, { payload }) => ({ ...state, createTaskLoading: false }),

    [editTask.REQUEST]: (state, { payload }) => ({ ...state, createTaskLoading: true }),
    [editTask.SUCCESS]: (state, { payload }) => ({ ...state, createTaskLoading: false }),
    [editTask.FAIL]: (state, { payload }) => ({ ...state, createTaskLoading: false }),


    [deleteTask.REQUEST]: (state, { payload }) => ({ ...state }),
    [deleteTask.SUCCESS]: (state, { payload }) => ({ ...state }),
    [deleteTask.FAIL]: (state, { payload }) => ({ ...state }),

    [publishTask.REQUEST]: (state, { payload }) => ({ ...state, publishTaskLoading: true }),
    [publishTask.SUCCESS]: (state, { payload }) => ({ ...state, publishTaskLoading: false }),
    [publishTask.FAIL]: (state, { payload }) => ({ ...state, error: payload, publishTaskLoading: false }),

    /* 发布定时 */
    [publishTaskTime.REQUEST]: (state, { payload }) => ({ ...state, publishTaskLoading: true }),
    [publishTaskTime.SUCCESS]: (state, { payload }) => ({ ...state, publishTaskLoading: false }),
    [publishTaskTime.FAIL]: (state, { payload }) => ({ ...state, error: payload, publishTaskLoading: false }),


    /* 取消发布 */

    [deletePublishTime.REQUEST]: (state, { payload }) => ({ ...state }),
    [deletePublishTime.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [deletePublishTime.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [disabledOrEnableTask.REQUEST]: (state, { payload }) => ({ ...state }),
    [disabledOrEnableTask.SUCCESS]: (state, { payload }) => ({ ...state }),
    [disabledOrEnableTask.FAIL]: (state, { payload }) => ({ ...state }),



    /* 根据id查询历史版本 */
    [getTaskHistoryTable.REQUEST]: (state, { payload }) => ({ ...state, historyLoading: true, taskHistorySearch: payload }),
    [getTaskHistoryTable.SUCCESS]: (state, { payload }) => ({ ...state, historyLoading: false, taskHistoryData: payload }),
    [getTaskHistoryTable.FAIL]: (state, { payload }) => ({ ...state, historyLoading: false, error: payload }),

    [getTaskHistoryInfo.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [getTaskHistoryInfo.SUCCESS]: (state, { payload }) => ({ ...state, payload }),
    [getTaskHistoryInfo.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [loadTimeAll.REQUEST]: (state, { payload }) => ({ ...state, }),
    [loadTimeAll.SUCCESS]: (state, { payload }) => ({ ...state, timeList: payload }),
    [loadTimeAll.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getTaskForSystem.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [getTaskForSystem.SUCCESS]: (state, { payload }) => ({ ...state, payload, taskForSystem: payload }),
    [getTaskForSystem.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [draftCount.REQUEST]: (state, { payload }) => ({ ...state }),
    [draftCount.SUCCESS]: (state, { payload }) => ({ ...state, draftCount: payload }),
    [draftCount.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [draftCountTime.REQUEST]: (state, { payload }) => ({ ...state }),
    [draftCountTime.SUCCESS]: (state, { payload }) => ({ ...state, draftCountTime: payload }),
    [draftCountTime.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [draftCountPublish.REQUEST]: (state, { payload }) => ({ ...state }),
    [draftCountPublish.SUCCESS]: (state, { payload }) => ({ ...state, draftCountPublish: payload }),
    [draftCountPublish.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [unmountSearch.REQUEST]: (state, { payload }) => ({
      ...state,
      getTaskListLoading:true,
      taskSearch: { page: 0, size: 20 },
    }),
    //清除历史筛选
    [unmountHistorySearch.REQUEST]: (state, { payload }) => ({
      ...state,
      historyLoading:true,
      taskHistorySearch: { page: 0, size: 20 },
    }),
  },
  initialState
);

export default reducer;
