import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'rebate';

/**
 * TODO: 返利
 */
export const loadRebate = fasterActions(moduleName, 'loadRebate');
export const rebateHistory = fasterActions(moduleName, 'rebateHistory');
export const rebateHistoryId = fasterActions(moduleName, 'rebateHistoryId');
export const rebateStarus = fasterActions(moduleName, 'rebateStarus');
export const hasDraft = fasterActions(moduleName, 'hasDraft');
export const rebatePriority = fasterActions(moduleName, 'rebatePriority');
export const rebateBase = fasterActions(moduleName, 'rebateBase');
export const rebateBaseInquire = fasterActions(moduleName, 'rebateBaseInquire');
export const rebateCaseList = fasterActions(moduleName, 'rebateCaseList');
export const rebateCaseAdd = fasterActions(moduleName, 'rebateCaseAdd');
export const rebateCaseSort = fasterActions(moduleName, 'rebateCaseSort');
export const rebateCaseInquire = fasterActions(moduleName, 'rebateCaseInquire');
export const rebateCaseGetRule = fasterActions(moduleName, 'rebateCaseGetRule');
export const rebateAddpoint = fasterActions(moduleName, 'rebateAddpoint');
export const rebateGetPoint = fasterActions(moduleName, 'rebateGetPoint');
export const rebateCaseSaveCase = fasterActions(moduleName, 'rebateCaseSaveCase');
export const rebatePublish = fasterActions(moduleName, 'rebatePublish');
export const rebateDel = fasterActions(moduleName, 'rebateDel');
export const rebateDetailList = fasterActions(moduleName, 'rebateDetailList');
export const exportRebateDetail = fasterActions(moduleName, 'exportRebateDetail');
export const rebateEntryTimeConfigList = fasterActions(moduleName, 'rebateEntryTimeConfigList')
export const rebateEntryTimeConfigEdit = fasterActions(moduleName, 'rebateEntryTimeConfigEdit')


export const pointTypeDialogVisibale = createAction(`${moduleName}/pointTypeDialogVisibale`);
export const resetSelectorConversionRatio = createAction(`${moduleName}/resetSelectorConversionRatio`);

export const rebatePacketList = fasterActions(moduleName, 'rebatePacketList');
export const exportPacketList = fasterActions(moduleName, 'exportPacketList');

export const getInspectPage = fasterActions(moduleName, 'getInspectPage');

const initialState = {
  loading: false,
  loaded: false,
  statusLoading: false,
  statusLoaded: false,
  loadRebateLoaded: false,
  prizePointTypesLoaded: false,
  pointTypeDetailObj: {},
  pointTypeId: '',
  isPointTypeDialogVisibale: false,
  selectorConversionRatio: 0,
  rebateHistoryList:[],
  rebateHistoryIdRes:{}
}

const reducer = handleActions(
  {
    //返利列表
    [loadRebate.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [loadRebate.SUCCESS]: (state, { payload }) => ({ ...state, rebateTableList: payload, loading: false, loaded: true }),
    [loadRebate.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded: false }),
    //返利历史列表
    [rebateHistory.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [rebateHistory.SUCCESS]: (state, { payload }) => ({ ...state, rebateHistoryList: payload, loading: false, loaded: true }),
    [rebateHistory.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded: false }),
    //根据ID获取历史记录详情
    [rebateHistoryId.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [rebateHistoryId.SUCCESS]: (state, { payload }) => ({...state, rebateHistoryIdRes: payload, loading: false, loaded: true}),
    [rebateHistoryId.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded: false }),
    //返利活动状态更改
    [rebateStarus.REQUEST]: (state, { payload }) => ({ ...state, payload, statusLoading: true }),
    [rebateStarus.SUCCESS]: (state, { payload }) => ({ ...state, rebateStarusRes: payload, statusLoading: false, statusLoaded: true }),
    [rebateStarus.FAIL]: (state, { payload }) => ({ ...state, error: payload, statusLoading: false, statusLoaded: false }),
    //返利活动是否有草稿
    [hasDraft.REQUEST]: (state, { payload }) => ({ ...state, payload, statusLoading: true }),
    [hasDraft.SUCCESS]: (state, { payload }) => ({ ...state, hasDraftRes: payload, statusLoading: false, statusLoaded: true }),
    [hasDraft.FAIL]: (state, { payload }) => ({ ...state, error: payload, statusLoading: false, statusLoaded: false }),
    //返利活动优先级
    [rebatePriority.REQUEST]: (state, { payload }) => ({ ...state, payload, statusLoading: true }),
    [rebatePriority.SUCCESS]: (state, { payload }) => ({ ...state, rebatePriorityRes: payload, statusLoading: false, statusLoaded: true }),
    [rebatePriority.FAIL]: (state, { payload }) => ({ ...state, error: payload, statusLoading: false, statusLoaded: false }),
    //  保存/编辑返利活动基础信息
    [rebateBase.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [rebateBase.SUCCESS]: (state, { payload }) => ({ ...state, rebateBaseRes: payload, loading: false, loaded: true }),
    [rebateBase.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded: false }),
    //返利活动（id查询）
    [rebateBaseInquire.REQUEST]: (state, { payload }) => ({ ...state, payload, statusLoading: true }),
    [rebateBaseInquire.SUCCESS]: (state, { payload }) => ({ ...state, rebateBaseInquireRes: payload, statusLoading: false, statusLoaded: true }),
    [rebateBaseInquire.FAIL]: (state, { payload }) => ({ ...state, error: payload, statusLoading: false, statusLoaded: false }),
    //查询返利方案列表
    [rebateCaseList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [rebateCaseList.SUCCESS]: (state, { payload }) => ({ ...state, rebateCaseListRes: payload, loading: false, loaded: true }),
    [rebateCaseList.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded: false }),
    //新增方案
    [rebateCaseAdd.REQUEST]: (state, { payload }) => ({ ...state, payload, statusLoading: true }),
    [rebateCaseAdd.SUCCESS]: (state, { payload }) => ({ ...state, rebateCaseAddRes: payload, statusLoading: false, statusLoaded: true }),
    [rebateCaseAdd.FAIL]: (state, { payload }) => ({ ...state, error: payload, statusLoading: false, statusLoaded: false }),
    //编辑方案顺序
    [rebateCaseSort.REQUEST]: (state, { payload }) => ({ ...state, payload, statusLoading: true }),
    [rebateCaseSort.SUCCESS]: (state, { payload }) => ({ ...state, rebateCaseSortRes: payload, statusLoading: false, statusLoaded: true }),
    [rebateCaseSort.FAIL]: (state, { payload }) => ({ ...state, error: payload, statusLoading: false, statusLoaded: false }),
    //获取返利方案
    [rebateCaseInquire.REQUEST]: (state, { payload }) => ({ ...state, payload, statusLoading: true }),
    [rebateCaseInquire.SUCCESS]: (state, { payload }) => ({ ...state, rebateCaseInquireRes: payload, statusLoading: false, statusLoaded: true }),
    [rebateCaseInquire.FAIL]: (state, { payload }) => ({ ...state, error: payload, statusLoading: false, statusLoaded: false }),
    //获取规则模板
    [rebateCaseGetRule.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [rebateCaseGetRule.SUCCESS]: (state, { payload }) => ({ ...state, rebateCaseGetRuleRes: payload, loading: false, loaded: true }),
    [rebateCaseGetRule.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded: false }),
    //新增奖励
    [rebateAddpoint.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [rebateAddpoint.SUCCESS]: (state, { payload }) => ({ ...state, rebateAddpointRes: payload, loading: false, loaded: true }),
    [rebateAddpoint.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded: false }),
    //获取奖励
    [rebateGetPoint.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [rebateGetPoint.SUCCESS]: (state, { payload }) => ({ ...state, rebateGetPointRes: payload, loading: false, loaded: true }),
    [rebateGetPoint.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded: false }),
    //保存方案
    [rebateCaseSaveCase.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [rebateCaseSaveCase.SUCCESS]: (state, { payload }) => ({ ...state, rebateCaseSaveCaseRes: payload, loading: false, loaded: true }),
    [rebateCaseSaveCase.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded: false }),
    //发布
    [rebatePublish.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [rebatePublish.SUCCESS]: (state, { payload }) => ({ ...state, rebatePublishRes: payload, loading: false, loaded: true }),
    [rebatePublish.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded: false }),
    //删除草稿活动
    [rebateDel.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [rebateDel.SUCCESS]: (state, { payload }) => ({ ...state, rebateDelRes: payload, loading: false, loaded: true }),
    [rebateDel.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded: false }),
    //返利明细（分页查询）
    [rebateDetailList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [rebateDetailList.SUCCESS]: (state, { payload }) => ({ ...state, rebateDetailListRes: payload, loading: false, loaded: true }),
    [rebateDetailList.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded: false }),
    // 导出返利明细
    [exportRebateDetail.REQUEST]: (state, { payload }) => ({ ...state, }),
    [exportRebateDetail.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [exportRebateDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
    // 提现明细
    [rebatePacketList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [rebatePacketList.SUCCESS]: (state, { payload }) => ({ ...state, rebatePacketListRes: payload, loading: false, loaded: true }),
    [rebatePacketList.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false, loaded: false }),
    // 导出提现明细
    [exportPacketList.REQUEST]: (state, { payload }) => ({ ...state, }),
    [exportPacketList.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [exportPacketList.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
    // 弹窗
    [pointTypeDialogVisibale]: (state, { payload }) => ({ ...state, ...payload }),
    [resetSelectorConversionRatio]: (state, { payload }) => ({ ...state, selectorConversionRatio: 0 }),

    [getInspectPage.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [getInspectPage.SUCCESS]: (state, { payload }) => ({ ...state, tableData: payload, loading: false }),
    [getInspectPage.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    // 入库时间配置
    [rebateEntryTimeConfigList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [rebateEntryTimeConfigList.SUCCESS]: (state, { payload }) => ({ ...state,  loading: false }),
    [rebateEntryTimeConfigList.FAIL]: (state, { payload }) => ({ ...state,  loading: false }),

    [rebateEntryTimeConfigEdit.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [rebateEntryTimeConfigEdit.SUCCESS]: (state, { payload }) => ({ ...state,  loading: false }),
    [rebateEntryTimeConfigEdit.FAIL]: (state, { payload }) => ({ ...state,  loading: false }),
  },
  initialState
);

export default reducer;
