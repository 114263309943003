import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './customFields';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 自定义字段
 */

// 列表显示
export const customFieldssEpic = actions$ => actions$.pipe(
  ofType(actions.customFields.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { keywords = '', page, size } = action.payload;
    let url = `${urls.code.customFields.customFields}?page=${page}&size=${size}&sort=id,desc&name=${keywords}`;

    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.customFields.SUCCESS(res.response)),
      catchError(error => of(actions.customFields.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 查看
export const customFieldsByIdEpic = actions$ => actions$.pipe(
  ofType(actions.customFieldsById.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id } = action.payload;
    let url = `${urls.code.customFields.customFields}/${id}`;
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.customFieldsById.SUCCESS(res.response)),
      catchError(error => of(actions.customFieldsById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 预览
export const customFieldsPreviewEpic = actions$ => actions$.pipe(
  ofType(actions.customFieldsPreview.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { fieldValue, mode } = action.payload;
    let url = `${urls.code.customFields.customFields}/preview`;
    const formData = new FormData();
    formData.append('fieldValue', fieldValue);
    formData.append('mode', mode);
    return ajax({
      ...apiBaseConfig,
      method: 'POST',
      url,
      body: formData
    }).pipe(
      map(res => actions.customFieldsPreview.SUCCESS(res.response)),
      catchError(error => of(actions.customFieldsPreview.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 禁用/启用 
export const customFieldsStatusEpic = actions$ => actions$.pipe(
  ofType(actions.customFieldsStatus.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id, status } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.code.customFields.customFields}/${id}/?status=${status}`,
      body: action.payload
    }).pipe(
      map(res => actions.customFieldsStatus.SUCCESS(res.status)),
      catchError(error => of(actions.customFieldsStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);



// 创建/编辑 
export const customFieldsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.customFieldsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id, formData,fieldName, fieldValue, fieldCode } = action.payload;
    let url = `${urls.code.customFields.customFields}`;
    // if (id) {
    //   url += `${id}/`;
    // }

    return ajax({
      ...apiBaseConfig,
      method: id ? 'PUT' : 'POST',
      url,
      body: formData
    }).pipe(
      mergeMap(res => [actions.customFieldsModify.SUCCESS(res.status), uiActions.notification(id ? '修改成功' : '创建成功', 'success')]),
      catchError(error => of(actions.customFieldsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
