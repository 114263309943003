import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';


const moduleName = 'warnRecipients';

/**
 * TODO: 预警
 */

export const warnRecipients = fasterActions(moduleName, 'warnRecipients');
export const warnRecipientsModify = fasterActions(moduleName, 'warnRecipientsModify');
export const warnRecipientsStatus = fasterActions(moduleName, 'warnRecipientsStatus');


export const warnAiGetALL = fasterActions(moduleName, 'warnAiGetALL');
export const warnAiGet = fasterActions(moduleName, 'warnAiGet');
export const warnAiCreate = fasterActions(moduleName, 'warnAiCreate');
export const warnAiDel = fasterActions(moduleName, 'warnAiDel');
export const warnRecipientsClass = fasterActions(moduleName, 'warnRecipientsClass');
export const warnRecipientsEvent = fasterActions(moduleName, 'warnRecipientsEvent');
export const warnRecipientsEdit = fasterActions(moduleName, 'warnRecipientsEdit');
export const warnRecipientsMdn = fasterActions(moduleName, 'warnRecipientsMdn');
export const warnRecipientsField = fasterActions(moduleName, 'warnRecipientsField');
export const warnRecipientsIndexGet = fasterActions(moduleName, 'warnRecipientsIndexGet');
export const warnRecipientsIndexCreate = fasterActions(moduleName, 'warnRecipientsIndexCreate');
export const warnRecipientsIndexEdit = fasterActions(moduleName, 'warnRecipientsIndexEdit');

export const warnEseGet = fasterActions(moduleName, 'warnEseGet');
export const warnEseCreate = fasterActions(moduleName, 'warnEseCreate');

export const warnAiTipGet = fasterActions(moduleName, 'warnAiTipGet');
export const warnAiTipCreate = fasterActions(moduleName, 'warnAiTipCreate');
export const warnAiTipDel = fasterActions(moduleName, 'warnAiTipDel');

const initialState = {

}

const reducer = handleActions(
  {

    [warnRecipients.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [warnRecipients.SUCCESS]: (state, { payload }) => ({ ...state, warnRecipientsList: payload, loading: false }),
    [warnRecipients.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [warnRecipientsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, warnRecipientsModifyLoaded: false }),
    [warnRecipientsModify.SUCCESS]: (state, { payload }) => ({ ...state, warnRecipientsModifyLoaded: payload }),
    [warnRecipientsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [warnRecipientsStatus.REQUEST]: (state, { payload }) => ({ ...state, payload, warnRecipientsStatusLoaded: false }),
    [warnRecipientsStatus.SUCCESS]: (state, { payload }) => ({ ...state, warnRecipientsStatusLoaded: payload }),
    [warnRecipientsStatus.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [warnAiGetALL.REQUEST]: (state, { payload }) => ({ ...state, payload, }),
    [warnAiGetALL.SUCCESS]: (state, { payload }) => {
      let warnAiListAll = [];
      for (let elem of payload.values()) {
        warnAiListAll.push({ ...elem, value: elem.id, text: elem.name })
      }
      return {
        ...state,
        warnAiListAll,

      }
    },
    [warnAiGetALL.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    [warnAiGet.REQUEST]: (state, { payload }) => ({ ...state, payload, loadingWarnAi: true }),
    [warnAiGet.SUCCESS]: (state, { payload }) => ({ ...state, warnAiList: payload, loadingWarnAi: false }),
    [warnAiGet.FAIL]: (state, { payload }) => ({ ...state, error: payload, loadingWarnAi: false }),

    [warnAiCreate.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [warnAiCreate.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [warnAiCreate.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [warnAiDel.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [warnAiDel.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [warnAiDel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [warnEseGet.REQUEST]: (state, { payload }) => ({ ...state, payload, loadingWarnEse: true }),
    [warnEseGet.SUCCESS]: (state, { payload }) => ({ ...state, warnEseList: payload, loadingWarnEse: false }),
    [warnEseGet.FAIL]: (state, { payload }) => ({ ...state, error: payload, loadingWarnEse: false }),

    [warnEseCreate.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [warnEseCreate.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [warnEseCreate.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    

    [warnAiTipGet.REQUEST]: (state, { payload }) => ({ ...state, payload, loadingWarnAiTip: true }),
    [warnAiTipGet.SUCCESS]: (state, { payload }) => ({ ...state, warnAiTipList: payload, loadingWarnAiTip: false }),
    [warnAiTipGet.FAIL]: (state, { payload }) => ({ ...state, error: payload, loadingWarnAiTip: false }),
    [warnAiTipCreate.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [warnAiTipCreate.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [warnAiTipCreate.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    [warnAiTipDel.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [warnAiTipDel.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [warnAiTipDel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    
    [warnRecipientsClass.REQUEST]: (state, { payload }) => ({ ...state, payload  }),
    [warnRecipientsClass.SUCCESS]: (state, { payload }) => ({ ...state, warnRecipientsClass: payload }),
    [warnRecipientsClass.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    
    [warnRecipientsEvent.REQUEST]: (state, { payload }) => ({ ...state, payload  }),
    [warnRecipientsEvent.SUCCESS]: (state, { payload }) => ({ ...state, warnRecipientsEvent: payload }),
    [warnRecipientsEvent.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [warnRecipientsEdit.REQUEST]: (state, { payload }) => ({ ...state, payload  }),
    [warnRecipientsEdit.SUCCESS]: (state, { payload }) => ({ ...state, warnRecipientsEdit: payload }),
    [warnRecipientsEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [warnRecipientsMdn.REQUEST]: (state, { payload }) => ({ ...state, payload  }),
    [warnRecipientsMdn.SUCCESS]: (state, { payload }) => ({ ...state, warnRecipientsMdn: payload }),
    [warnRecipientsMdn.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [warnRecipientsField.REQUEST]: (state, { payload }) => ({ ...state, payload  }),
    [warnRecipientsField.SUCCESS]: (state, { payload }) => ({ ...state, warnRecipientsField: payload }),
    [warnRecipientsField.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



    [warnRecipientsIndexGet.REQUEST]: (state, { payload }) => ({ ...state, payload  }),
    [warnRecipientsIndexGet.SUCCESS]: (state, { payload }) => ({ ...state, warnRecipientsIndexGet: payload }),
    [warnRecipientsIndexGet.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [warnRecipientsIndexCreate.REQUEST]: (state, { payload }) => ({ ...state, payload  }),
    [warnRecipientsIndexCreate.SUCCESS]: (state, { payload }) => ({ ...state, warnRecipientsIndexCreate: payload }),
    [warnRecipientsIndexCreate.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [warnRecipientsIndexEdit.REQUEST]: (state, { payload }) => ({ ...state, payload  }),
    [warnRecipientsIndexEdit.SUCCESS]: (state, { payload }) => ({ ...state, warnRecipientsIndexEdit: payload }),
    [warnRecipientsIndexEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
  },

  initialState
);

export default reducer;
