import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';


const moduleName = 'domainConfigs';

/**
 * 域名管理
 */

export const domainConfigsByEseId = fasterActions(moduleName, 'domainConfigsByEseId');
export const loaddomainConfigsList = fasterActions(moduleName, 'loaddomainConfigsList');
export const ableDomainConfigs = fasterActions(moduleName, 'ableDomainConfigs');
export const addDomainConfigs = fasterActions(moduleName, 'addDomainConfigs');
export const editDomainConfigs = fasterActions(moduleName, 'editDomainConfigs');

export const domainConfigsByCodeSourceType = fasterActions(moduleName, 'domainConfigsByCodeSourceType');
export const domainConfigsByCodeBank = fasterActions(moduleName, 'domainConfigsByCodeBank');



const initialState = {
  domainConfigsListLoaded: false,
  domainConfigsListLoading: false,
  domainConfigsList: [],
}

const reducer = handleActions(
  {
    [domainConfigsByEseId.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [domainConfigsByEseId.SUCCESS]: (state, { payload }) => {
      let select = [];
      let domainConfigsWithoutWechat = [];
      if (payload.content) {
        payload.content.forEach(val => {
          val.text = val.value = `${val.protocol + val.domain}/`;
          if (val.subDomain) {
            val.text += `${val.subDomain}/`;
            val.value += `${val.subDomain}/`;
          }

          if (val.codeSourceType !== 'WECHART_CODE') {
            domainConfigsWithoutWechat.push(val);
          }
          select.push({
            text: val.name, value: val.id
          })
        });
      }
      return ({ ...state, domainConfigsByEseId: payload, domainConfigsByEseIdSelect: select, domainConfigsWithoutWechat });
    },
    [domainConfigsByEseId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [loaddomainConfigsList.REQUEST]: (state, { payload }) => ({ ...state, domainConfigsListLoading: true }),
    [loaddomainConfigsList.SUCCESS]: (state, { payload }) => ({ ...state, domainConfigsListLoading: false, domainConfigsListLoaded: true, domainConfigsList: payload }),
    [loaddomainConfigsList.FAIL]: (state, { payload }) => ({ ...state, domainConfigsListLoading: false, domainConfigsListLoaded: false, error: payload }),
    [ableDomainConfigs.REQUEST]: (state, { payload }) => ({ ...state }),
    [ableDomainConfigs.SUCCESS]: (state, { payload }) => ({ ...state }),
    [ableDomainConfigs.FAIL]: (state, { payload }) => ({ ...state }),
    [addDomainConfigs.REQUEST]: (state, { payload }) => ({ ...state }),
    [addDomainConfigs.SUCCESS]: (state, { payload }) => ({ ...state }),
    [addDomainConfigs.FAIL]: (state, { payload }) => ({ ...state }),
    [editDomainConfigs.REQUEST]: (state, { payload }) => ({ ...state }),
    [editDomainConfigs.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editDomainConfigs.FAIL]: (state, { payload }) => ({ ...state }),

    [domainConfigsByCodeSourceType.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [domainConfigsByCodeSourceType.SUCCESS]: (state, { payload }) => {
      let select = [];
      if (payload.content) {
        payload.content.forEach(val => {
          val.text = val.value = `${val.domain}/${val.subDomain}`;
          select.push({
            text: val.name, value: val.id
          })
        });
      }
      return ({ ...state, domainConfigsByEseId: payload, domainConfigsByCodeSourceTypeSelect: select });
    },
    [domainConfigsByCodeSourceType.FAIL]: (state, { payload }) => ({ ...state, error: payload }),





    [domainConfigsByCodeBank.REQUEST]: (state, { payload }) => ({ ...state, payload, domainConfigsListLoading: true }),
    [domainConfigsByCodeBank.SUCCESS]: (state, { payload }) => {
      let select = [];
      if (payload.content) {
        payload.content.forEach(val => {
          val.text = val.value = `${val.domain}/${val.subDomain}`;
          select.push({
            text: val.name, value: val.id
          })
        });
      }
      return ({ ...state, domainConfigsByEseId: payload, domainConfigsByCodeBankSelect: select, domainConfigsListLoading: false, domainConfigsListLoaded: true, });
    },
    [domainConfigsByCodeBank.FAIL]: (state, { payload }) => ({ ...state, error: payload, domainConfigsListLoading: false, domainConfigsListLoaded: false, }),
  },

  initialState
);

export default reducer;
