import { createAction } from 'redux-actions';
import queryString from 'query-string';

export const actionApi = () => {
  // console.log('postMessage');
  if (process.env.NODE_ENV === 'development') {
    return
  }
  if (window.location.pathname !== '/index-system.html') {
    window.parent.postMessage('actionApi', 'https://ma.cloud.tencent.com');
  }
}

export const fasterActions = (moduleName, actionName) => {
  const action = {
    REQUEST: createAction(`${moduleName}/${actionName}_REQUEST`),
    SUCCESS: createAction(`${moduleName}/${actionName}_SUCCESS`),
    FAIL: createAction(`${moduleName}/${actionName}_FAIL`),
  }
  return action;
}

export const ajaxBaseConfig = {
  headers: { 'Content-Type': 'application/json;charset=utf-8', 'X-CSRF-TOKEN': window.__INITIAL_STATE__.backend['X-CSRF-TOKEN'] || '', 'X-TC-TOKEN': window.__INITIAL_STATE__.backend['X-TC-TOKEN'] || '' },
  withCredentials: true,
}

export const apiBaseConfig = {
  headers: { 'X-CSRF-TOKEN': window.__INITIAL_STATE__.backend['X-CSRF-TOKEN'] || '', 'X-TC-TOKEN': window.__INITIAL_STATE__.backend['X-TC-TOKEN'] || '' },
  withCredentials: true,
}

export const setScrollTop = () => {
  let appMain = document.getElementsByClassName('app-main')[0];
  if (appMain) {
    appMain.scrollTop = 0;
  }
}

// 对象属性按照ASCII码表排序
export function sortAsc(o) {
  var n = [];
  for (var k in o) n.push(k);
  n.sort();
  for (var i = 0, str = ''; i < n.length; i++) {
    var v = o[n[i]];
    if (v) {
      if ({}.toString.call(v) == '[object Object]')
        v = sortAsc(v);
      else if ({}.toString.call(v) == '[object Array]') {
        let p = '';
        v.forEach((item, index) => {
          p += item + (index < v.length - 1?',':'');
        })
        v = p;
      }
    }
    if (n[i]) {
      str += '&' + n[i] + '=' + v;
    }

  }
  if (str) {
    return '&' + str.slice(1);
  } else {
    return '';
  }
}

export function sortUrlAsc(o) {
  var n = [];
  for (var k in o) n.push(k);
  n.sort();
  for (var i = 0, str = ''; i < n.length; i++) {
    var v = o[n[i]];
    if (v) {
      if ({}.toString.call(v) == '[object Object]')
        v = sortAsc(v);
      else if ({}.toString.call(v) == '[object Array]') {
        if (n[i] === 'sort') {
          v.forEach(item => {
            str += '&' + n[i] + '=' + encodeURIComponent(item);
          })
          continue;
        }
        v = JSON.stringify(v).replace(/:/g, '=');
      }
    }
    str += '&' + n[i] + '=' + encodeURIComponent(v);
  }
  if (str) {
    return str.slice(1);
  } else {
    return '';
  }
}

export function getKeyValue(url) { 
  return queryString.parse(url.split('?')[1]);
}
