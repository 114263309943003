import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'cityRank';

/**
 * TODO: 企业管理
 */
export const loadCityRankList = fasterActions(moduleName, 'loadCityRankList');
export const loadCityRankId = fasterActions(moduleName, 'loadCityRankId');
export const createCityRank = fasterActions(moduleName, 'createCityRank');
export const editCityRank = fasterActions(moduleName, 'editCityRank');
export const ableCityRank = fasterActions(moduleName, 'ableCityRank');
export const deleteCityRank = fasterActions(moduleName, 'deleteCityRank');



const initialState = {

  cityRankList: [],
  loadCityRankListLoading: false,
  loadCityRankListLoaded: false,

}

const reducer = handleActions(
  {

    [loadCityRankList.REQUEST]: (state, { payload }) => ({ ...state, loadCityRankListLoading: true }),
    [loadCityRankList.SUCCESS]: (state, { payload }) => ({ ...state, loadCityRankListLoading: false, loadCityRankListLoaded: true, cityRankList: payload }),
    [loadCityRankList.FAIL]: (state, { payload }) => ({ ...state, loadCityRankListLoading: false, loadCityRankListLoaded: false, error: payload }),

    [loadCityRankId.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadCityRankId.SUCCESS]: (state, { payload }) => ({ ...state }),
    [loadCityRankId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [createCityRank.REQUEST]: (state, { payload }) => ({ ...state }),
    [createCityRank.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createCityRank.FAIL]: (state, { payload }) => ({ ...state }),
    [editCityRank.REQUEST]: (state, { payload }) => ({ ...state }),
    [editCityRank.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editCityRank.FAIL]: (state, { payload }) => ({ ...state }),
    [ableCityRank.REQUEST]: (state, { payload }) => ({ ...state }),
    [ableCityRank.SUCCESS]: (state, { payload }) => ({ ...state }),
    [ableCityRank.FAIL]: (state, { payload }) => ({ ...state }),

    [deleteCityRank.REQUEST]: (state, { payload }) => ({ ...state }),
    [deleteCityRank.SUCCESS]: (state, { payload }) => ({ ...state }),
    [deleteCityRank.FAIL]: (state, { payload }) => ({ ...state }),
    
    
  },
  initialState
);

export default reducer;
