import { createAction, handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'factory';

export const loadSelections = fasterActions(moduleName, 'loadSelections');
export const factories = fasterActions(moduleName, 'factories');
export const loadFactoryPageList = fasterActions(moduleName, 'loadFactoryPageList');
export const loadFactoryById = fasterActions(moduleName, 'loadFactoryById');
export const createFactory = fasterActions(moduleName, 'createFactory');
export const editFactory = fasterActions(moduleName, 'editFactory');
export const enableOrDisabledFactory = fasterActions(moduleName, 'enableOrDisabledFactory');

export const uploadFactorySearchKey = createAction(`${moduleName}/uploadFactorySearchKey`);
// uploadFactorySearchKey


const initialState = {
  factorySelectionsLoading: false,
  factorySelectionsLoaded: false,
  printFactorySelector: [],
  productFactorySelector: [],
  productFactorySelector2:[],
  factoryPageList: [],
  factoryDetail: {},
  factorySearchKey: '',
  factoryPageListParam: {
    page: 0,
    size: 20,
    sort: 'id,desc',
    factoryType: ''
  },
}

const reducer = handleActions(
  {
    [loadSelections.REQUEST]: (state, { payload }) => {
      return ({ ...state, factorySelectionsLoading: true })
    },
    [loadSelections.SUCCESS]: (state, { payload }) => {
      let selector = [], printFactorySelector = state.printFactorySelector, productFactorySelector = state.productFactorySelector, productFactorySelector2 = state.productFactorySelector2;
      let selection = [];
      for (let elem of payload.content) {
        if (elem.status == 'ENABLE') {
          selector.push({...elem, value: elem.id, text: elem.name });
        }

        selection.push({ value: elem.id, text: elem.name })
      }
      
      if (payload.factoryType.factoryType == 'PRINT') {
        printFactorySelector = selector;
      }

      if (payload.factoryType.factoryType == 'PACKAGE') {
        productFactorySelector = JSON.parse(JSON.stringify(selector));
        productFactorySelector2 = JSON.parse(JSON.stringify(selector));
        productFactorySelector.push({ value: '', text: '暂无' });
      }

      return {
        ...state,
        factorySelectionsLoaded: true,
        factorySelectionsLoading: false,
        factorySelections: payload.content,
        factorySelector: selector,
        selection,
        printFactorySelector,
        productFactorySelector,
        productFactorySelector2,
      }
    },
    [loadSelections.FAIL]: (state, { payload }) => ({ ...state, factorySelectionsLoading: false, factorySelectionsLoaded: false, error: payload }),


    [loadFactoryPageList.REQUEST]: (state, { payload }) => {
      return ({ ...state, ...payload, factoryPageListLoading: true })
    },
    [loadFactoryPageList.SUCCESS]: (state, { payload }) => {
      const { content = [], pageable = {}, totalElements = 0 } = payload;
      return ({ ...state, factoryPageListParam: { ...state.factoryPageListParam, totalElements: totalElements || 0 }, factoryPageList: content, factoryPageListLoading: false, })
    },
    [loadFactoryPageList.FAIL]: (state, { payload }) => ({ ...state, factoryPageListLoading: false, error: payload }),

    [loadFactoryById.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [loadFactoryById.SUCCESS]: (state, { payload }) => ({ ...state, factoryDetail: payload }),
    [loadFactoryById.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [enableOrDisabledFactory.REQUEST]: (state, { payload }) => ({ ...state }),
    [enableOrDisabledFactory.SUCCESS]: (state, { payload }) => ({ ...state }),
    [enableOrDisabledFactory.FAIL]: (state, { payload }) => ({ ...state }),

    [createFactory.REQUEST]: (state, { payload }) => ({ ...state }),
    [createFactory.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createFactory.FAIL]: (state, { payload }) => ({ ...state }),

    [editFactory.REQUEST]: (state, { payload }) => ({ ...state }),
    [editFactory.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editFactory.FAIL]: (state, { payload }) => ({ ...state }),

    // 存储state
    [uploadFactorySearchKey]: (state, { payload }) => ({ ...state, ...payload }),

    // [factories.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    // [factories.SUCCESS]: (state, { payload }) => {
    //   const factory = [{ value: '', text: '请选择包材印刷工厂' }];
    //   const productFactory = [{ value: '', text: '请选择成品灌装厂' }];

    //   if (payload.content) {
    //     payload.content.forEach(val => {
    //       val.text = val.name;
    //       val.value = val.id;
    //       if (val.factoryType == 'PRINT') {
    //         factory.push(val);
    //       } else if (val.factoryType == 'PACKAGE') {
    //         productFactory.push(val);
    //       }
    //     });
    //   }

    //   return ({ ...state, factories: { factory, productFactory } })
    // },
    // [factories.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

  },
  initialState
)

export default reducer;