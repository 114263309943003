import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './warnRecipients';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// Epics
// 预警人员配置列表
export const warnRecipientsEpic = actions$ => actions$.pipe(
  ofType(actions.warnRecipients.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { query = '', page, size, eventClass, status, module2, name,callback } = action.payload;
    let url = `${urls.warn.recipients.recipients}?page=${page}&size=${size}&sort=id,desc`;
    if (query) {
      url += `&query=${query}`;
    }
    if (eventClass) {
      url += `&eventClass=${eventClass}`;
    }
    if (status) {
      url += `&status=${status}`;
    }
    if (module2) {
      url += `&module=${module2}`;
    }
    if (name) {
      url += `&eventName=${name}`;
    }
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.warnRecipients.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnRecipients.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// 预警人员配置
export const warnRecipientsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.warnRecipientsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.recipients.recipients}`,
      body: action.payload,
      method: 'POST',
    }).pipe(
      mergeMap(res => [actions.warnRecipientsModify.SUCCESS(res.status), uiActions.notification('添加成功', 'success')]),
      catchError(error => of(actions.warnRecipientsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// 禁用启用
export const warnRecipientsStatusEpic = actions$ => actions$.pipe(
  ofType(actions.warnRecipientsStatus.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.recipients.recipientsStatus}`,
      body: action.payload,
      method: 'PATCH',
    }).pipe(
      map(res => actions.warnRecipientsStatus.SUCCESS(res.response)),
      catchError(error => of(actions.warnRecipientsStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);



export const warnAiGetALLEpic = actions$ => actions$.pipe(
  ofType(actions.warnAiGetALL.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.ai.ai}/all`,
    }).pipe(
      map(res => {
        // callback && callback(res.response);
        return actions.warnAiGetALL.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnAiGetALL.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))

    )
  }
  )
);
export const warnRecipientsClassEpic = actions$ => actions$.pipe(
  ofType(actions.warnRecipientsClass.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.recipients.recipientsClass}`,
    }).pipe(
      map(res => actions.warnRecipientsClass.SUCCESS(res.response)),
      catchError(error => of(actions.warnRecipientsClass.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


export const warnAiGetEpic = actions$ => actions$.pipe(
  ofType(actions.warnAiGet.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.ai.ai}/page?page=${data.page}&size=${data.size}`,
    }).pipe(
      map(res => {
        // callback && callback(res.response);
        return actions.warnAiGet.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnAiGet.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
export const warnRecipientsEventEpic = actions$ => actions$.pipe(
  ofType(actions.warnRecipientsEvent.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.recipients.recipientsEvent}?eventCode=${data.id}`,
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.warnRecipientsEvent.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnRecipientsEvent.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const warnAiCreateEpic = actions$ => actions$.pipe(
  ofType(actions.warnAiCreate.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: data.id ? `${urls.warn.ai.ai}/update` : `${urls.warn.ai.ai}/create`,
      body: data,
      method: data.id ? 'PATCH' : 'POST',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.warnAiCreate.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnAiCreate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const warnRecipientsEditEpic = actions$ => actions$.pipe(
  ofType(actions.warnRecipientsEdit.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.recipients.recipientsParameter}`,
      body: data,
      method: 'POST',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.warnRecipientsEdit.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnRecipientsEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


export const warnAiDelEpic = actions$ => actions$.pipe(
  ofType(actions.warnAiDel.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.ai.ai}/delete?id=${data.id}`,
      method: 'DELETE',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.warnAiDel.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnAiDel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

 
export const warnRecipientsMdnEpic = actions$ => actions$.pipe(
  ofType(actions.warnRecipientsMdn.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.recipients.recipientsMdm}`,
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.warnRecipientsMdn.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnRecipientsMdn.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const warnEseGetEpic = actions$ => actions$.pipe(
  ofType(actions.warnEseGet.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.recipients.recipients}/eventRecipients?page=${data.page}&size=${data.size}&eventId=${data.eventId}`,
    }).pipe(
      map(res => {
        // callback && callback(res.response);
        return actions.warnEseGet.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnEseGet.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


export const warnRecipientsFieldEpic = actions$ => actions$.pipe(
  ofType(actions.warnRecipientsField.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.recipients.recipientsField}?fieldEname=${data.id}`,
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.warnRecipientsField.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnRecipientsField.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
export const warnEseCreateEpic = actions$ => actions$.pipe(
  ofType(actions.warnEseCreate.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.recipients.recipients}/updateEnterprise`,
      body: data,
      method: 'PATCH',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.warnEseCreate.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnEseCreate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


export const warnAiTipGetEpic = actions$ => actions$.pipe(
  ofType(actions.warnAiTipGet.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.recipients.recipients}/robotRemind?eventId=${data.eventId}&page=${data.page}&size=${data.size}`,
    }).pipe(
      map(res => {
        // callback && callback(res.response);
        return actions.warnAiTipGet.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnAiTipGet.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const warnRecipientsIndexGetEpic = actions$ => actions$.pipe(
  ofType(actions.warnRecipientsIndexGet.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.recipients.recipientsIndexGet}`,
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.warnRecipientsIndexGet.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnRecipientsIndexGet.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


export const warnAiTipCreateEpic = actions$ => actions$.pipe(
  ofType(actions.warnAiTipCreate.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: data.id ? `${urls.warn.recipients.recipients}/updateRemind` : `${urls.warn.recipients.recipients}/createRemind`,
      body: data,
      method: data.id ? 'PATCH' : 'POST',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.warnAiTipCreate.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnAiTipCreate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))

    )
  }
  )
);

export const warnRecipientsIndexCreateEpic = actions$ => actions$.pipe(
  ofType(actions.warnRecipientsIndexCreate.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.recipients.recipientsIndexCreate}`,
      body: data,
      method: 'POST',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.warnRecipientsIndexCreate.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnRecipientsIndexCreate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const warnAiTipDelEpic = actions$ => actions$.pipe(
  ofType(actions.warnAiTipDel.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.recipients.recipients}/deleteChildRemind?id=${data.id}`,
      method: 'DELETE',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.warnAiTipDel.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnAiTipDel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const warnRecipientsIndexEditEpic = actions$ => actions$.pipe(
  ofType(actions.warnRecipientsIndexEdit.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.recipients.recipientsIndexEdit}`,
      body: data,
      method: 'PUT',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.warnRecipientsIndexEdit.SUCCESS(res.response)
      }),
      catchError(error => of(actions.warnRecipientsIndexEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);




