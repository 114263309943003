import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './wechat';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 微信管理
 */

// Epics
// 微信号列表 ,只查小程序给MINI_PROGRAM，只查公众号给WECHAT_SCRIPTION，不给都查
export const authorizerConfigsEpic = actions$ => actions$.pipe(
  ofType(actions.authorizerConfigs.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.wechat.authorizerConfigs.authorizerConfigs(action.payload ? action.payload.eseId ? '' : action.payload : action.payload, action.payload ? action.payload.eseId ? action.payload.eseId : '' : '')}`
    }).pipe(
      map(res => actions.authorizerConfigs.SUCCESS({ res: res.response, type: action.payload })),
      catchError(error => of(actions.authorizerConfigs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

/**
 * TODO: 卡券信息查詢
 */

export const cardInfoLoadEpic = actions$ => actions$.pipe(
  ofType(actions.cardInfoLoad.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { appId, cardId, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.wechat.card.cardInfo(appId, cardId)}`,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.cardInfoLoad.SUCCESS(res.response)
        }),
        catchError(error => of(actions.cardInfoLoad.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 设置红包参数
export const setAuthorizerConfigsEpic = actions$ => actions$.pipe(
  ofType(actions.setAuthorizerConfigs.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'put',
      body: action.payload,
      url: `${urls.wechat.authorizerConfigs.authorizerConfigs()}`
    }).pipe(
      map(res => actions.setAuthorizerConfigs.SUCCESS(res.status)),
      catchError(error => of(actions.setAuthorizerConfigs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 查询工具，红包信息查询
export const loadRedpacketQueryInfoEpic = actions$ => actions$.pipe(
  ofType(actions.loadRedpacketQueryInfo.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'get',
      body: action.payload,
      url: action.payload.prizeType == 'CHANGE_OF_MONEY' || action.payload.prizeType == 'WECHAT_CHANGE' ? `${urls.wechat.redpacketQueryPayer(action.payload.appId, action.payload.merchantBillNo, action.payload.openId, action.payload.merchantId)}` : `${urls.wechat.redpacketQuery(action.payload.appId, action.payload.merchantBillNo, action.payload.openId, action.payload.merchantId)}`
    }).pipe(
      map(res => actions.loadRedpacketQueryInfo.SUCCESS(res.response)),
      catchError(error => of(actions.loadRedpacketQueryInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// export const wechatAuthorizeEpic = actions$ => actions$.pipe(
//   ofType(actions.wechatAuthorize.REQUEST.toString()),
//   mergeMap(action =>
//     ajax({
//       withCredentials: true,
//       url: `${urls.wechat.authorization.wechatAuthorize(action.payload)}`
//     }).pipe(
//       map(res => actions.wechatAuthorize.SUCCESS(res.response)),
//       catchError(error => of(actions.wechatAuthorize.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )
//   )
// );


//微信体验者
export const loadExperiencersEpic = actions$ => actions$.pipe(
  ofType(actions.loadExperiencers.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { name = '', page = 0, size = 20, sort = 'id,desc', appId = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'GET',
      // body: data,
      url: `${urls.wechat.experiencers.experiencers}/${appId}?openId=${name}&page=${page}&size=${size}&sort=${sort}`
    }).pipe(
      concatMap(res => {
        // callback && callback(res.response);
        return [actions.loadExperiencers.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.loadExperiencers.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
export const addExperiencersEpic = actions$ => actions$.pipe(
  ofType(actions.addExperiencers.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: data,
      url: `${urls.wechat.experiencers.experiencers}`
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.addExperiencers.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.addExperiencers.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
export const editExperiencersEpic = actions$ => actions$.pipe(
  ofType(actions.editExperiencers.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      body: data,
      url: `${urls.wechat.experiencers.experiencers}`
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.editExperiencers.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.editExperiencers.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const deleteExperiencersEpic = actions$ => actions$.pipe(
  ofType(actions.deleteExperiencers.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'DELETE',
      // body: data,
      url: `${urls.wechat.experiencers.experiencers}/${data.id}`
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.deleteExperiencers.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.deleteExperiencers.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
export const enableOrDisabledEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.enableOrDisabled.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.wechat.experiencers.experiencers}/${data.id}/status?status=${data.status}`,
        method: 'PATCH',
      }).pipe(
        concatMap(res => {
          callback && callback(res.response);
          return [actions.enableOrDisabled.SUCCESS(res.response)]
        }),
        catchError(error => of(actions.enableOrDisabled.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
export const checkExperiencersEpic = actions$ => actions$.pipe(
  ofType(actions.checkExperiencers.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'GET',
      url: `${urls.wechat.experiencers.experiencers}/${data.appId}/${data.openId}`
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.checkExperiencers.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.checkExperiencers.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


export const getSecretKeyEpic = actions$ => actions$.pipe(
  ofType(actions.getSecretKey.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'GET',
      url: `${urls.wechat.secretKey.secretKey}?appId=${data.appId}`
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.getSecretKey.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.getSecretKey.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
export const addSecretKeyEpic = actions$ => actions$.pipe(
  ofType(actions.addSecretKey.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: data.method,
      url: `${urls.wechat.secretKey.secretKey}`,
      body: data,
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.addSecretKey.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.addSecretKey.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


export const getMenuEpic = actions$ => actions$.pipe(
  ofType(actions.getMenu.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'GET',
      url: `${urls.wechat.menu.menu}/query?appId=${data.appId}&sort=id,asc`
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.getMenu.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.getMenu.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const getMenuFromEpic = actions$ => actions$.pipe(
  ofType(actions.getMenuFrom.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'GET',
      url: `${urls.wechat.menu.menuFrom}/wechatMenu/getCurrentMenu?appId=${data.appId}`
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.getMenuFrom.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.getMenuFrom.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
export const publishMenuEpic = actions$ => actions$.pipe(
  ofType(actions.publishMenu.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.wechat.menu.menuFrom}/wechatMenu/publishMenu?appId=${data.appId}`
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.publishMenu.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.publishMenu.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);



export const addMenuEpic = actions$ => actions$.pipe(
  ofType(actions.addMenu.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: data.id ? `${urls.wechat.menu.menu}/update?id=${data.id}&name=${data.name}` : `${urls.wechat.menu.menu}/create`,
      body: data,
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.addMenu.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.addMenu.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const putMenuEpic = actions$ => actions$.pipe(
  ofType(actions.putMenu.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: `${urls.wechat.menu.menu}/event`,
      body: data,
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.putMenu.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.putMenu.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const exchangeMenuEpic = actions$ => actions$.pipe(
  ofType(actions.exchangeMenu.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: `${urls.wechat.menu.menu}/exchange/${data.id}?type=${data.type}`,
      body: data,
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.exchangeMenu.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.exchangeMenu.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);



export const delMenuEpic = actions$ => actions$.pipe(
  ofType(actions.delMenu.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'DELETE',
      // body: data,
      url: `${urls.wechat.menu.menu}/delete/${data.id}`
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.delMenu.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.delMenu.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);



export const editPhoneSettingEpic = actions$ => actions$.pipe(
  ofType(actions.editPhoneSetting.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: `${urls.wechat.phoneSetting.phoneSetting}/${data.appId}/checkPhone?checkPhone=${data.checkPhone}`
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.editPhoneSetting.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.editPhoneSetting.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
