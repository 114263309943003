import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'categories';

const initialState = {
  listLoaded: false,
  categoriesSelectAll: [], //沒有区分一级，二级的类别数据

}

// Actions
export const loadcategoriesSelectAll = fasterActions(moduleName, 'loadcategoriesSelectAll');

export const prodcutCategory = fasterActions(moduleName, 'prodcutCategory');
export const prodcutCategoryTree = fasterActions(moduleName, 'prodcutCategoryTree');
export const prodcutCategorySelections = fasterActions(moduleName, 'prodcutCategorySelections');
export const prodcutCategoryById = fasterActions(moduleName, 'prodcutCategoryById');
export const prodcutCategoryStatus = fasterActions(moduleName, 'prodcutCategoryStatus');
export const prodcutCategoryModify = fasterActions(moduleName, 'prodcutCategoryModify');
export const prodcutCategoryFileds = fasterActions(moduleName, 'prodcutCategoryFileds');

// export const updataProductSearchKey = createAction(`${moduleName}/updataProductSearchKey`);



// Reducer
const reducer = handleActions(
  {
    // select
    [loadcategoriesSelectAll.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadcategoriesSelectAll.SUCCESS]: (state, { payload }) => {

      const loopInit = (data = []) => {
        data.map(v => {
          v.text = v.name;
          v.value = v.id;
          if (v.children) {
            loopInit(v);
          }
        });
        return data;
      }

      return ({ ...state, categoriesSelectAll: loopInit(payload.content) })
    },
    [loadcategoriesSelectAll.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    // [updataProductSearchKey]: (state, {payload}) => ({...state, ...payload})

    [prodcutCategory.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [prodcutCategory.SUCCESS]: (state, { payload }) => ({ ...state, prodcutCategory: payload, loading: false }),
    [prodcutCategory.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [prodcutCategoryTree.SUCCESS]: (state, { payload }) => {
      const { children = [] } = payload;
      children.map(item => {
        item.text = item.name;
        item.value = item.id;
        return item;
      })
      return { ...state, prodcutCategoryTree: children }
    },
    [prodcutCategoryTree.FAIL]: (state, { payload }) => ({ ...state }),

    [prodcutCategoryById.SUCCESS]: (state, { payload }) => ({ ...state, prodcutCategoryById: payload }),
    [prodcutCategoryById.FAIL]: (state, { payload }) => ({ ...state }),

    [prodcutCategorySelections.SUCCESS]: (state, { payload }) => {
      const prodcutCategorySelections = [{ text: '选择上级分类', value: '' }];
      if (payload.content) {
        payload.content.forEach(v => {
          prodcutCategorySelections.push({ text: v.name, value: v.id })
        });
      }
      return ({ ...state, prodcutCategorySelections })
    },
    [prodcutCategorySelections.FAIL]: (state, { payload }) => ({ ...state }),

    [prodcutCategoryStatus.REQUEST]: (state, { payload }) => ({ ...state, payload, prodcutCategoryStatusLoaded: false }),
    [prodcutCategoryStatus.SUCCESS]: (state, { payload }) => ({ ...state, prodcutCategoryStatusLoaded: payload, }),
    [prodcutCategoryStatus.FAIL]: (state, { payload }) => ({ ...state }),

    [prodcutCategoryModify.REQUEST]: (state, { payload }) => ({ ...state, payload, prodcutCategoryModifyLoaded: false }),
    [prodcutCategoryModify.SUCCESS]: (state, { payload }) => ({ ...state, prodcutCategoryModifyLoaded: payload, }),
    [prodcutCategoryModify.FAIL]: (state, { payload }) => ({ ...state }),

    [prodcutCategoryFileds.REQUEST]: (state, { payload }) => ({ ...state, payload, prodcutCategoryModifyLoaded: false }),
    [prodcutCategoryFileds.SUCCESS]: (state, { payload }) => ({ ...state, prodcutCategoryModifyLoaded: payload, }),
    [prodcutCategoryFileds.FAIL]: (state, { payload }) => ({ ...state }),

  },
  initialState
);
export default reducer;