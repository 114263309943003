import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'linkCirculate';

/**
 * TODO: 
 */

export const productionList = fasterActions(moduleName, 'productionList');
export const distributorList = fasterActions(moduleName, 'distributorList');
export const terminalList = fasterActions(moduleName, 'terminalList');
export const scanList = fasterActions(moduleName, 'scanList');
export const lotteryList = fasterActions(moduleName, 'lotteryList');
export const cashList = fasterActions(moduleName, 'cashList');
export const prizeList = fasterActions(moduleName, 'prizeList');

export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const productionStatic = fasterActions(moduleName, 'productionStatic');
export const distributorStatic = fasterActions(moduleName, 'distributorStatic');
export const terminalStatic = fasterActions(moduleName, 'terminalStatic');
export const scanStatic = fasterActions(moduleName, 'scanStatic');
export const lotteryStatic = fasterActions(moduleName, 'lotteryStatic');
export const cashStatic = fasterActions(moduleName, 'cashStatic');
export const prizeStatic = fasterActions(moduleName, 'prizeStatic');

//活动类型和奖品类型
export const promotionType = fasterActions(moduleName, 'promotionType');
export const prizeType = fasterActions(moduleName, 'prizeType');
//活动下拉
export const activity = fasterActions(moduleName, 'activity');

export const tendency = fasterActions(moduleName, 'tendency');
export const echartsTendency = fasterActions(moduleName, 'echartsTendency');

//下级部门下拉
export const childDeptSelection = fasterActions(moduleName, 'childDeptSelection');
//城市经理下拉
export const cityManagerPostSelection = fasterActions(moduleName, 'cityManagerPostSelection');
//办事处下拉
export const officePostSelection = fasterActions(moduleName, 'officePostSelection');
//方案下拉
export const planSelection = fasterActions(moduleName, 'planSelection');

const initialState = {
    activityData:[],
    promotionTypeData:[],
    prizeTypeData:[],
    productionData:[],
    distributorData:[],
    pageData:[],
    pageStaticData:{},
    tendencyData:{},
    echartsData:{},
    pageParams:{
      page:0,
      size:20,
      startTime:'',
      endTime:''
    },
    loading:false,
    tendencyLoading:false,
    childDeptSelectionData:[],
    cityManagerPostSelectionData:[],
    officePostSelectionData:[],
    planSelectionData:[]
}

const reducer = handleActions(
  { 
    [changeDictPaging]: (state, { payload }) => {
        return { ...state, pageParams: { ...payload} }
    },

    [promotionType.REQUEST]: (state, { payload }) => ({ ...state, promotionTypeData: [],  }),
    [promotionType.SUCCESS]: (state, { payload }) => ({ ...state, promotionTypeData: payload.content, }),
    [promotionType.FAIL]: (state, { payload }) => ({ ...state }),

    [prizeType.REQUEST]: (state, { payload }) => ({ ...state, prizeTypeData: [],  }),
    [prizeType.SUCCESS]: (state, { payload }) => ({ ...state, prizeTypeData: payload.content, }),
    [prizeType.FAIL]: (state, { payload }) => ({ ...state }),

    [activity.REQUEST]: (state, { payload }) => ({ ...state, activityData: [],  }),
    [activity.SUCCESS]: (state, { payload }) => ({ ...state, activityData: payload.content, }),
    [activity.FAIL]: (state, { payload }) => ({ ...state }),

    [productionList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [productionList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [productionList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [distributorList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [distributorList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [distributorList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [terminalList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [terminalList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [terminalList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [scanList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [scanList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [scanList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [lotteryList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [lotteryList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [lotteryList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [cashList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [cashList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [cashList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [prizeList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [prizeList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [prizeList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [productionStatic.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [productionStatic.SUCCESS]: (state, { payload }) => ({ ...state, pageStaticData: payload}),
    [productionStatic.FAIL]: (state, { payload }) => ({ ...state}),

    [distributorStatic.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [distributorStatic.SUCCESS]: (state, { payload }) => ({ ...state, pageStaticData: payload}),
    [distributorStatic.FAIL]: (state, { payload }) => ({ ...state}),

    [terminalStatic.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [terminalStatic.SUCCESS]: (state, { payload }) => ({ ...state, pageStaticData: payload }),
    [terminalStatic.FAIL]: (state, { payload }) => ({ ...state }),


    [scanStatic.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [scanStatic.SUCCESS]: (state, { payload }) => ({ ...state, pageStaticData: payload }),
    [scanStatic.FAIL]: (state, { payload }) => ({ ...state }),

    [lotteryStatic.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [lotteryStatic.SUCCESS]: (state, { payload }) => ({ ...state, pageStaticData: payload }),
    [lotteryStatic.FAIL]: (state, { payload }) => ({ ...state }),

    [prizeStatic.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [prizeStatic.SUCCESS]: (state, { payload }) => ({ ...state, pageStaticData: payload }),
    [prizeStatic.FAIL]: (state, { payload }) => ({ ...state }),

    [cashStatic.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [cashStatic.SUCCESS]: (state, { payload }) => ({ ...state, pageStaticData: payload }),
    [cashStatic.FAIL]: (state, { payload }) => ({ ...state }),


    [tendency.REQUEST]: (state, { payload }) => ({ ...state, tendencyData:[] }),
    [tendency.SUCCESS]: (state, { payload }) => ({ ...state, tendencyData: payload }),
    [tendency.FAIL]: (state, { payload }) => ({ ...state}),

    [echartsTendency.REQUEST]: (state, { payload }) => ({ ...state, echartsData:{}, tendencyLoading: true }),
    [echartsTendency.SUCCESS]: (state, { payload }) => ({ ...state, echartsData: payload, tendencyLoading: false }),
    [echartsTendency.FAIL]: (state, { payload }) => ({ ...state, tendencyLoading: false }),

    
    [childDeptSelection.REQUEST]: (state, { payload }) => ({ ...state, childDeptSelectionData:{}}),
    [childDeptSelection.SUCCESS]: (state, { payload }) => ({ ...state, childDeptSelectionData: payload,}),
    [childDeptSelection.FAIL]: (state, { payload }) => ({ ...state}),

    [cityManagerPostSelection.REQUEST]: (state, { payload }) => ({ ...state, cityManagerPostSelectionData:{}}),
    [cityManagerPostSelection.SUCCESS]: (state, { payload }) => ({ ...state, cityManagerPostSelectionData: payload,}),
    [cityManagerPostSelection.FAIL]: (state, { payload }) => ({ ...state}),

    [officePostSelection.REQUEST]: (state, { payload }) => ({ ...state, officePostSelectionData:{}}),
    [officePostSelection.SUCCESS]: (state, { payload }) => ({ ...state, officePostSelectionData: payload,}),
    [officePostSelection.FAIL]: (state, { payload }) => ({ ...state}),

    [planSelection.REQUEST]: (state, { payload }) => ({ ...state, planSelectionData:{}}),
    [planSelection.SUCCESS]: (state, { payload }) => ({ ...state, planSelectionData: payload,}),
    [planSelection.FAIL]: (state, { payload }) => ({ ...state}),
  },
  initialState
);

export default reducer;
