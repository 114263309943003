import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './physicalDelivery';
import { ajaxBaseConfig, actionApi } from '../../utils';

// Epics
export const getLogisticsTableEpic = actions$ => actions$.pipe(
    ofType(actions.getLogisticsTable.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        // console.log(action.payload, 'action.payload')
        const { pageNum = 0, pageSize = 20, queryKey = '', prizeName = '', expressNo = '', sapOrderNo = '', apApplyNo = '', apAppliedNo = '', orderByColumn = '', isAsc = '', exportState = [], expressState = [], } = action.payload;
        let tExpressState = expressState.length ? expressState.join(',') : '';
        let tExportState = exportState.length ? exportState.join(',') : '';
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            url: `${urls.logistics.list}`,
            body: { ...action.payload, pageNum: pageNum + 1, expressState: tExpressState, exportState: tExportState },
        }).pipe(
            map(res => actions.getLogisticsTable.SUCCESS(res.response)),
            catchError(error => of(actions.getLogisticsTable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 奖品登记 */
export const remarkRegisterEpic = actions$ => actions$.pipe(
    ofType(actions.remarkRegister.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: { id: data.id, sysRemark: data.sysRemark || '' },
            url: `${urls.logistics.edit}`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.remarkRegister.SUCCESS(res.response)
            }),
            catchError(error => of(actions.remarkRegister.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


/* 导出实物 */
export const exportPhysicalEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.exportPhysical.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        const { pageNum = 0, pageSize = 20, queryKey = '', prizeName = '', expressNo = '', sapOrderNo = '', apApplyNo = '', apAppliedNo = '', orderByColumn = '', isAsc = '', exportState = '', expressState = [], } = action.payload;
        let tExpressState = expressState.length ? expressState.join(',') : '';
        // let tExportState = exportState.length ? exportState.join(',') : '';
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: { ...data, expressState: tExpressState },
            url: `${urls.logistics.exportPhysical}`
        }).pipe(
            mergeMap(res => {
                callback && callback(res.status);
                // return [actions.exportPhysical.SUCCESS(res.response), actions.getLogisticsTable.REQUEST(state$.value.physicalDelivery.physicalSearch)]
                return [actions.exportPhysical.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.exportPhysical.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
/* 导出红包 */
export const exportRedPacketEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.exportRedPacket.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        const { pageNum = 0, pageSize = 20, queryKey = '', prizeName = '', expressNo = '', sapOrderNo = '', apApplyNo = '', apAppliedNo = '', orderByColumn = '', isAsc = '', exportState = '', expressState = [], } = action.payload;
        let tExpressState = expressState.length ? expressState.join(',') : '';
        // let tExportState = exportState.length ? exportState.join(',') : '';
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: { ...data, expressState: tExpressState },
            url: `${urls.logistics.exportRedPacket}`,
            responseType: 'arraybuffer',
        }).pipe(
            mergeMap(res => {
                callback && callback(res);
                // return [actions.exportRedPacket.SUCCESS(res.response), actions.getLogisticsTable.REQUEST(state$.value.physicalDelivery.physicalSearch)]
                return [actions.exportRedPacket.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.exportRedPacket.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 导入下单 */
export const importOrderEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.importOrder.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: { ...data },
            url: `${urls.logistics.add}`
        }).pipe(
            mergeMap(res => {
                callback && callback(res.status);
                return [actions.importOrder.SUCCESS(res.response), actions.importList.REQUEST(state$.value.physicalDelivery.importSearch)]
            }),
            catchError(error => of(actions.importOrder.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 导入列表 */
export const importListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.importList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { pageNum, pageSize } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: { pageNum: pageNum + 1, pageSize },
            url: `${urls.logistics.importList}`
        }).pipe(
            mergeMap(res => {
                return [actions.importList.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.importList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
