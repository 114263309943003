import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
// import { ajax } from '../../ajax';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import * as actions from './pointMallPresents';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 积分礼品管理
 */

// Epics
// 礼品库分页查询
export const presentsPoolsEpic = actions$ => actions$.pipe(
  ofType(actions.presentsPools.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { dynamicQueryField = '', prizeStatus = '', mallType = '', page, size } = action.payload;
    let url = `${urls.pointMall.present.presentPools}?page=${page}&size=${size}&dynamicQueryField=${dynamicQueryField}&prizeStatus=${prizeStatus}&mallType=${mallType}&sort=id,desc`;
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.presentsPools.SUCCESS(res.response)),
      catchError(error => of(actions.presentsPools.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


// 查询礼品库所有历史版本
export const presentsPoolsVersionsEpic = actions$ => actions$.pipe(
  ofType(actions.presentsPoolsVersions.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { presentPoolId = '', page, size } = action.payload;
    let url = `${urls.pointMall.present.presentPools}/${presentPoolId}/versions?page=${page}&size=${size}&sort=id,desc`;
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.presentsPoolsVersions.SUCCESS(res.response)),
      catchError(error => of(actions.presentsPoolsVersions.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);



// 查询礼品库信息 / 查询礼品库指定历史版本信息
export const presentsPoolsByIdEpic = actions$ => actions$.pipe(
  ofType(actions.presentsPoolsById.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { presentPoolId = '', version } = action.payload;
    let url = `${urls.pointMall.present.presentPools}/${presentPoolId}`;
    if (version) {
      url += `/versions/${version}`;
    }
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.presentsPoolsById.SUCCESS(res.response)),
      catchError(error => of(actions.presentsPoolsById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

// 查询礼品库信息 / 查询礼品库指定历史版本信息 -- 分页礼品数据
export const presentsPoolsPageByIdEpic = actions$ => actions$.pipe(
  ofType(actions.presentsPoolsPageById.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { presentPoolId = '', version, page = '', size = '', nameOrId = '', presentStatuses = '', deleted = '', callback } = action.payload;
    let url = `${urls.pointMall.present.presentPoolsPage}/${presentPoolId}`;
    if (version) {
      url += `/versions/${version}`;
    }
    url+=`?page=${page}&size=${size}&nameOrId=${nameOrId}&presentStatuses=${presentStatuses}&deleted=${deleted}&sort=id,desc`
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => { callback && callback(); return actions.presentsPoolsPageById.SUCCESS(res.response)}),
      catchError(error => of(actions.presentsPoolsPageById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
// 批量更新礼品库礼品信息（草稿存储）
export const presentsPoolsDraftEpic = actions$=>actions$.pipe(
  ofType(actions.presentsPoolsDraft.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback = '', data = {} } = action.payload
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.present.presentDraft}`,
      body: data,
      method: 'PUT'
    }).pipe(
      mergeMap(res => {
        callback && callback();
        return [actions.presentsPoolsDraft.SUCCESS(res.status), uiActions.notification('保存成功', 'success')]}),
      catchError(error => of(actions.presentsPoolsDraft.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


// 礼品分页查询
export const presentsEpic = actions$ => actions$.pipe(
  ofType(actions.presents.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { presentPoolId = '', page, size } = action.payload;
    let url = `${urls.pointMall.present.presentPools}?page=${page}&size=${size}&presentPoolId=${presentPoolId}&sort=id,desc`;
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.presents.SUCCESS(res.response)),
      catchError(error => of(actions.presents.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


/**
 * 礼品库礼品分发统计
 * @param {*} actions$ 
 */
export const presentsPoolsDeliverStatisticsEpic = actions$ => actions$.pipe(
  ofType(actions.presentsPoolsDeliverStatistics.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { presentPoolId = '' } = action.payload;
    return ajax({
      ...apiBaseConfig,
      url: `${urls.pointMall.present.present}/presents/deliverStatistics/${presentPoolId}`,
    }).pipe(
      map(res => actions.presentsPoolsDeliverStatistics.SUCCESS(res.response)),
      catchError(error => of(actions.presentsPoolsDeliverStatistics.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);



/**
 * 更新礼品库
 * @param {*} actions$ 
 */
export const presentsPoolsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.presentsPoolsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { data, callback } = action.payload;
    const { id } = data;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.present.presentPools}/${id}`,
      body: data,
      method: 'PUT'
    }).pipe(
      mergeMap(res => {
        callback && callback();
        return [actions.presentsPoolsModify.SUCCESS(res.status), uiActions.notification('更新成功', 'success')]
      }),
      catchError(error => of(actions.presentsPoolsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


/**
 * 更新礼品库名称
 * @param {*} actions$ 
 */
export const presentsPoolsModifyNameEpic = actions$ => actions$.pipe(
  ofType(actions.presentsPoolsModifyName.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { poolName, presentPoolId } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.present.presentPools}/poolName?poolName=${poolName}&presentPoolId=${presentPoolId}`,
      body: action.payload,
      method: 'PUT'
    }).pipe(
      mergeMap(res => [actions.presentsPoolsModifyName.SUCCESS(res.status)]),
      catchError(error => of(actions.presentsPoolsModifyName.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);




/**
 * 创建礼品
 * @param {*} actions$ 
 */

export const createPresentsEpic = actions$ => actions$.pipe(
  ofType(actions.createPresents.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback, data } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.present.present}/presents?mallId=${data.mallId}`,
      body: data,
      method: 'POST'
    }).pipe(
      map(res => {
        callback && callback();

        return actions.createPresents.SUCCESS(res.response);
      }),
      catchError(error => of(actions.createPresents.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
//编辑礼品
export const editPresentsEpic = actions$ => actions$.pipe(
  ofType(actions.editPresents.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback, data } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.present.present}/presents/${data.id}`,
      body: data,
      method: 'PUT'
    }).pipe(
      map(res => {
        callback && callback();

        return actions.editPresents.SUCCESS(res.response);
      }),
      catchError(error => of(actions.editPresents.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


//排序礼品
export const sortPresentsEpic = actions$ => actions$.pipe(
  ofType(actions.sortPresents.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback, data } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.present.present}/presents/reOrder`,
      body: data,
      method: 'PUT'
    }).pipe(
      map(res => {
        callback && callback();

        return actions.sortPresents.SUCCESS(res.response);
      }),
      catchError(error => of(actions.sortPresents.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
//删除礼品
export const delPresentsEpic = actions$ => actions$.pipe(
  ofType(actions.delPresents.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback, id } = action.payload;
    let url = `${urls.pointMall.present.present}/presents/${id}`;
    return ajax({
      ...apiBaseConfig,
      url,
      method: 'DELETE'
    }).pipe(
      map(res => {
        callback && callback(res.response);

        return actions.delPresents.SUCCESS(res.response);
      }),
      catchError(error => of(actions.delPresents.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


//礼品库不分页
export const presentsPoolsSelectEpic = actions$ => actions$.pipe(
  ofType(actions.presentsPoolsSelect.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback, id, backUpType = '', version = '' } = action.payload;
    let url = `${urls.pointMall.present.present}/presents/presentBackUp?mallId=${id}&backUpType=${backUpType}&version=${version}&sort=id,desc`;
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => {
        // res.response.reverse()
        callback && callback(res.response);

        return actions.presentsPoolsSelect.SUCCESS(res.response);
      }),
      catchError(error => of(actions.presentsPoolsSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

// 礼品库分页
export const presentsPoolsPageEpic = actions$ => actions$.pipe(
  ofType(actions.presentsPoolsPage.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback, id, backUpType = '', version = '', page,size, nameOrId = '', presentStatuses = 
  '' } = action.payload;
  // &sort=id,desc
    let url = `${urls.pointMall.present.present}/presents/pagePresentBackUp?mallId=${id}&backUpType=${backUpType}&nameOrId=${nameOrId}&presentStatuses=${presentStatuses}&version=${version}&&page=${page}&size=${size}`;
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => {
        // res.response.reverse()
        callback && callback(res.response);

        return actions.presentsPoolsPage.SUCCESS(res.response);
      }),
      catchError(error => of(actions.presentsPoolsPage.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


//礼品组列表
export const getPresentsGroupEpic = actions$ => actions$.pipe(
  ofType(actions.getPresentsGroup.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback, id, page, size, name, version = '', backUpType } = action.payload;
    let url = `${urls.pointMall.present.presentPoolsGroups}?mallId=${id}&page=${page}&size=${size}&sort=id,desc&dynamicQueryField=${name}&backUpType=${backUpType}&version=${version}`;
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => {
        callback && callback(res.response);

        return actions.getPresentsGroup.SUCCESS(res.response);
      }),
      catchError(error => of(actions.getPresentsGroup.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
//礼品组列表不分页
export const presentsGroupSelectEpic = actions$ => actions$.pipe(
  ofType(actions.presentsGroupSelect.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback, id } = action.payload;
    let url = `${urls.pointMall.present.presentPoolsGroups}/queryByMallId/${id}`;
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => {
        callback && callback(res.response);

        return actions.presentsGroupSelect.SUCCESS(res.response);
      }),
      catchError(error => of(actions.presentsGroupSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);




//新增OR修改礼品组
export const createPresentsGroupEpic = actions$ => actions$.pipe(
  ofType(actions.createPresentsGroup.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback, data, type } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.present.presentPoolsGroups}` + (type == 'PUT' ? `/${data.id}` : ''),
      body: data,
      method: type
    }).pipe(
      map(res => {
        callback && callback();

        return actions.createPresentsGroup.SUCCESS(res.response);
      }),
      catchError(error => of(actions.createPresentsGroup.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
//删除
export const deletePresentsGroupEpic = actions$ => actions$.pipe(
  ofType(actions.deletePresentsGroup.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback, id } = action.payload;
    let url = `${urls.pointMall.present.presentPoolsGroups}/${id}`;
    return ajax({
      ...apiBaseConfig,
      url,
      method: 'DELETE'
    }).pipe(
      map(res => {
        callback && callback(res.response);

        return actions.deletePresentsGroup.SUCCESS(res.response);
      }),
      catchError(error => of(actions.deletePresentsGroup.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);




//礼品库不分页历史
export const presentsPoolsSelectHistoryEpic = actions$ => actions$.pipe(
  ofType(actions.presentsPoolsSelectHistory.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback, presentPoolId, version } = action.payload;
    let url = `${urls.pointMall.present.present}/present-pools/${presentPoolId}/versions/${version}`;
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => {
        // res.response.reverse()
        callback && callback(res.response);

        return actions.presentsPoolsSelectHistory.SUCCESS(res.response);
      }),
      catchError(error => of(actions.presentsPoolsSelectHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


// // 订单状态统计
// export const presentsCountEpic = actions$ => actions$.pipe(
//   ofType(actions.presentsCount.REQUEST.toString()),
//   mergeMap(action => {
//     actionApi();
//     return ajax({
//       ...apiBaseConfig,
//       url: `${urls.pointMall.order.order}/status-count`,
//     }).pipe(
//       map(res => actions.presentsCount.SUCCESS(res.response)),
//       catchError(error => of(actions.presentsCount.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )
//   }
//   )
// );


// /**
//  * 导出订单列表
//  * @param {*} actions$ 
//  */
// export const presentsExportEpic = actions$ => actions$.pipe(
//   ofType(actions.presentsExport.REQUEST.toString()),
//   mergeMap(action => {
//     actionApi();
//     const { email, startTime, endTime, orderStatus, exportStatus } = action.payload;

//     return ajax({
//       ...apiBaseConfig,
//       url: `${urls.pointMall.order.order}/export?email=${email}&startTime=${startTime}&endTime=${endTime}&orderStatus=${orderStatus}&exportStatus=${exportStatus}`,
//       body: action.payload,
//     }).pipe(
//       mergeMap(res => [actions.presentsExport.SUCCESS(res.status), uiActions.notification('导出成功', 'success')]),
//       catchError(error => of(actions.presentsExport.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )
//   })
// );




// /**
//  * 订单备注
//  * @param {*} actions$ 
//  */
// export const presentsRemarkEpic = actions$ => actions$.pipe(
//   ofType(actions.presentsRemark.REQUEST.toString()),
//   mergeMap(action => {
//     actionApi();
//     const { orderId, description } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       url: `${urls.pointMall.order.order}/${orderId}/description?orderId=${orderId}&description=${description}`,
//       // body: action.payload,
//       method: 'PUT'
//     }).pipe(
//       mergeMap(res => [actions.presentsRemark.SUCCESS(res.status), uiActions.notification('修改成功', 'success')]),
//       catchError(error => of(actions.presentsRemark.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )
//   })
// );

// /**
//  * 查询订单详情
//  * @param {*} actions$ 
//  */
// export const presentsByIdEpic = actions$ => actions$.pipe(
//   ofType(actions.presentsById.REQUEST.toString()),
//   mergeMap(action => {
//     actionApi();
//     const { orderId } = action.payload;
//     return ajax({
//       ...apiBaseConfig,
//       url: `${urls.pointMall.order.order}/${orderId}`,
//     }).pipe(
//       map(res => actions.presentsById.SUCCESS(res.response)),
//       catchError(error => of(actions.presentsById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )
//   }
//   )
// );

// /**
//  * 查询订单详情
//  * @param {*} actions$ 
//  */
// export const presentsDetailEpic = actions$ => actions$.pipe(
//   ofType(actions.presentsDetail.REQUEST.toString()),
//   mergeMap(action => {
//     actionApi();
//     const { orderId } = action.payload;
//     return ajax({
//       ...apiBaseConfig,
//       url: `${urls.pointMall.order.order}/${orderId}/detail`,
//     }).pipe(
//       map(res => actions.presentsDetail.SUCCESS(res.response)),
//       catchError(error => of(actions.presentsDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )
//   }
//   )
// );


// /**
//  * 查询第三方订单详情
//  * @param {*} actions$ 
//  */
// export const presentsThirdDetailEpic = actions$ => actions$.pipe(
//   ofType(actions.presentsThirdDetail.REQUEST.toString()),
//   mergeMap(action => {
//     actionApi();
//     const { orderDetailId } = action.payload;
//     return ajax({
//       ...apiBaseConfig,
//       url: `${urls.pointMall.order.order}/third-detail/${orderDetailId}`,
//     }).pipe(
//       map(res => actions.presentsThirdDetail.SUCCESS(res.response)),
//       catchError(error => of(actions.presentsThirdDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )
//   })
// );

// /**
//  * 登记物流
//  * @param {*} actions$ 
//  */
// export const presentsRegLogisticsEpic = actions$ => actions$.pipe(
//   ofType(actions.presentsRegLogistics.REQUEST.toString()),
//   mergeMap(action => {
//     actionApi();
//     const { orderId, orderDetailIds, expressName, trackingNumber } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       url: `${urls.pointMall.order.order}/${orderId}/regLogistics?orderId=${orderId}&orderDetailIds=${orderDetailIds}&expressName=${expressName}&trackingNumber=${trackingNumber}`,
//       body: action.payload,
//       method: 'POST'
//     }).pipe(
//       mergeMap(res => [actions.presentsRegLogistics.SUCCESS(res.status), uiActions.notification('登记成功', 'success')]),
//       catchError(error => of(actions.presentsRegLogistics.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )
//   })
// );