import { handleActions } from "redux-actions"
import { fasterActions } from "../../utils"

const moduleName = "subscriber"

// 这个作为模板进行参考
// export const search = fasterActions(moduleName, 'search');
export const getSubscriberList = fasterActions(moduleName, 'getSubscriberList');
export const subscriberSubmit = fasterActions(moduleName, 'subscriberSubmit');
export const subscriberDelete = fasterActions(moduleName, 'subscriberDelete');
export const subscriberConfigureList = fasterActions(moduleName, 'subscriberConfigureList');
export const editSubscriber = fasterActions(moduleName, 'editSubscriber');
export const echo = fasterActions(moduleName, 'echo');

const initialState = {
}
const reducer = handleActions(
  {
    // 这三个作为模板参考
    // [search.REQUEST]: (state, { payload }) => ({ ...state, }),
    // [search.SUCCESS]: (state, { payload }) => ({ ...state, list: payload }),
    // [search.FAIL]: (state, { payload }) => ({ ...state, codesError: payload }),

    // 获取列表
    [getSubscriberList.REQUEST]: (state, { payload }) => ({ ...state, loading: true}),
    [getSubscriberList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, list: payload}),
    [getSubscriberList.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload}),
    // 新增订阅配置
    [subscriberSubmit.REQUEST]: (state, { payload }) => ({ ...state, editLoading: true}),
    [subscriberSubmit.SUCCESS]: (state, { payload }) => ({ ...state, editLoading: false}),
    [subscriberSubmit.FAIL]: (state, { payload }) => ({ ...state, editLoading: false, editError: payload}),
    // 删除当前项列表
    [subscriberDelete.REQUEST]: (state, { payload }) => ({ ...state, deleteLoading: true}),
    [subscriberDelete.SUCCESS]: (state, { payload }) => ({ ...state, deleteLoading: false}),
    [subscriberDelete.FAIL]: (state, { payload }) => ({ ...state, deleteLoading: false, deleteError: payload}),
    // 订阅触发配置列表
    [subscriberConfigureList.REQUEST]: (state, { payload }) => ({ ...state, configureLoading: true}),
    [subscriberConfigureList.SUCCESS]: (state, { payload }) => ({ ...state, configureLoading: true,configureList: payload}),
    [subscriberConfigureList.FAIL]: (state, { payload }) => ({ ...state, configureLoading: true,subscriberConfigureError: payload}),
    // 修改订阅配置
    [editSubscriber.REQUEST]: (state, { payload }) => ({ ...state, editSubscriberLoading: true}),
    [editSubscriber.SUCCESS]: (state, { payload }) => ({ ...state, editSubscriberLoading: false}),
    [editSubscriber.FAIL]: (state, { payload }) => ({ ...state, editSubscriberLoading: false,editSubscriberError: payload}),
    // 表单回显
    [echo.REQUEST]: (state, { payload }) => ({ ...state, echoLoading: true}),
    [echo.SUCCESS]: (state, { payload }) => ({ ...state, echoLoading: false,echoForm: payload}),
    [echo.FAIL]: (state, { payload }) => ({ ...state, echoLoading: false, echoError: payload}),
  },
  initialState 
)

export default reducer