import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';
import * as uiActions from '../ui';


const moduleName = 'miniProgramEseRelations';

/**
 * 小程序开通工具: 
 */
export const loadEseRelations = fasterActions(moduleName, 'loadEseRelations');


const initialState = {
    eseRelations: []
}

const reducer = handleActions(
    {
        [loadEseRelations.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [loadEseRelations.SUCCESS]: (state, { payload }) => ({ ...state, eseRelations: payload.content }),
        [loadEseRelations.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    },

    initialState
);

export default reducer;
