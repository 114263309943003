import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'dictionary';

/**
 * TODO: 
 */

// 主数据字典管理
export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const dictoinaryList = fasterActions(moduleName, 'dictoinaryList');
export const createDictionary = fasterActions(moduleName, 'createDictionary');
export const enableOrDisabledDict = fasterActions(moduleName, 'enableOrDisabledDict');
export const addEnums = fasterActions(moduleName, 'addEnums');
export const queryDictList = fasterActions(moduleName, 'queryDictList');



const initialState = {
    dictPaging: {
        page: 0,
        size: 20,
        dictDataType: 2,
    },
    dictionaryData: {},
    queryDictData: [],
}

const reducer = handleActions(
    {
        [changeDictPaging]: (state, { payload }) => {
            return { ...state, dictPaging: { ...payload, page: 0 } }
        },

        [dictoinaryList.REQUEST]: (state, { payload }) => ({ ...state, dictPaging: payload, loading: true }),
        [dictoinaryList.SUCCESS]: (state, { payload }) => ({ ...state, dictionaryData: payload, loading: false }),
        [dictoinaryList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [createDictionary.REQUEST]: (state, { payload }) => ({ ...state, payload, modifyStatus: false }),
        [createDictionary.SUCCESS]: (state, { payload }) => ({ ...state, modifyStatus: payload }),
        [createDictionary.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [enableOrDisabledDict.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [enableOrDisabledDict.SUCCESS]: (state, { payload }) => ({ ...state }),
        [enableOrDisabledDict.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [addEnums.REQUEST]: (state, { payload }) => ({ ...state, payload, enumsStatus: false }),
        [addEnums.SUCCESS]: (state, { payload }) => ({ ...state, enumsStatus: payload }),
        [addEnums.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [queryDictList.REQUEST]: (state, { payload }) => ({ ...state, payload, queryLodaing: true }),
        [queryDictList.SUCCESS]: (state, { payload }) => {
            // if (payload) {
            //   payload.forEach(v => { v.text = v.name, v.value = v.code });
            // }
            return ({ ...state, queryDictData: payload });
        },
        [queryDictList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    },
    initialState
);

export default reducer;
