import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'activityInfomation';

/**
 * TODO: 
 */

// 主数据字典管理
export const changeActivityPaging = createAction(`${moduleName}/changeActivityPaging`);
export const changeActivityDataPaging = createAction(`${moduleName}/changeActivityDataPaging`);
export const activityTypeList = fasterActions(moduleName, 'activityTypeList');
export const activityTypeInfo = fasterActions(moduleName, 'activityTypeInfo');
export const createActivityType = fasterActions(moduleName, 'createActivityType');
export const enableOrDisabledActivityType = fasterActions(moduleName, 'enableOrDisabledActivityType');
export const activityDataList = fasterActions(moduleName, 'activityDataList');
export const activityDataInfo = fasterActions(moduleName, 'activityDataInfo');
// 宴会下拉
export const loadbanquetSelect = fasterActions(moduleName, 'loadbanquetSelect');


const initialState = {
    activityPaging: {
        page: 0,
        size: 20,
    },
    activityDataPaging: {
        page: 0,
        size: 20
    },
    activityTypeData: {},
    activityTypeInfo: {}
}

const reducer = handleActions(
    {
        [changeActivityPaging]: (state, { payload }) => {
            return { ...state, activityPaging: { ...payload, page: 0 } }
        },

        [changeActivityDataPaging]: (state, { payload }) => {
            return { ...state, activityDataPaging: { ...payload, page: 0 } }
        },

        [activityTypeList.REQUEST]: (state, { payload }) => ({ ...state, activityPaging: payload, loading: true }),
        [activityTypeList.SUCCESS]: (state, { payload }) => ({ ...state, activityTypeData: payload, loading: false }),
        [activityTypeList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [createActivityType.REQUEST]: (state, { payload }) => ({ ...state, payload, createStatus: false }),
        [createActivityType.SUCCESS]: (state, { payload }) => ({ ...state, createStatus: payload }),
        [createActivityType.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [enableOrDisabledActivityType.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [enableOrDisabledActivityType.SUCCESS]: (state, { payload }) => ({ ...state }),
        [enableOrDisabledActivityType.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [activityDataList.REQUEST]: (state, { payload }) => ({ ...state, activityDataPaging: payload, loading: true }),
        [activityDataList.SUCCESS]: (state, { payload }) => ({ ...state, activityTypeData: payload, loading: false }),
        [activityDataList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [activityTypeInfo.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [activityTypeInfo.SUCCESS]: (state, { payload }) => ({ ...state, activityTypeInfo: payload, loading: false }),
        [activityTypeInfo.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [activityDataInfo.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [activityDataInfo.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [activityDataInfo.FAIL]: (state, { payload }) => ({ ...state, }),

        // 宴会下拉
        [loadbanquetSelect.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [loadbanquetSelect.SUCCESS]: (state, { payload }) => ({ ...state, banquetSelections: payload.content||[] }),
        [loadbanquetSelect.FAIL]: (state, { payload }) => ({ ...state, }),

    },
    initialState
);

export default reducer;
