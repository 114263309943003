import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './terminalManagement';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 单据管理
 */

// 单据类型列表
export const terminalListEpic = actions$ => actions$.pipe(
    ofType(actions.terminalList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page, size, code, ...rest } = action.payload;
        const params = {
            page,
            size,
            word: code,
            sort: 'id,desc',
            ...rest
        }
        const str = queryString.stringify(params, { encode: false })
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.terminal.terminal}/v1?${str}`
        }).pipe(
            map(res => actions.terminalList.SUCCESS(res.response)),
            catchError(error => of(actions.terminalList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 禁用、启用
export const enableOrDisabledTerminalEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableOrDisabledTerminal.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id = '', status = '' } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.terminal.enableTerminal(id, status)}`,
                method: 'PATCH',
            }).pipe(
                concatMap(res => [actions.enableOrDisabledTerminal.SUCCESS(res.response), actions.terminalList.REQUEST(state$.value.terminalManagement.terminalPaging)]),
                catchError(error => of(actions.enableOrDisabledTerminal.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 表单详情
export const terminalInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.terminalInfo.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { terminalId = '', callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.terminal.terminal}/v1/${terminalId}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.terminalInfo.SUCCESS(res.response);
                }),
                catchError(error => of(actions.terminalInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 创建单据
export const createTerminalEpic = actions$ => actions$.pipe(
    ofType(actions.createTerminal.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                method: data.id ? 'PUT' : 'POST',
                url: data.id ? `${urls.data.terminal.createTerminal}/v1` : `${urls.data.terminal.createTerminal}/v1`,
                body: action.payload.data,
            }).pipe(
                concatMap(res => {
                    callback && callback(res.response);
                    return [actions.createTerminal.SUCCESS(res.response)];
                }),
                catchError(error => of(actions.createTerminal.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 下拉
export const terminalSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.terminalSelection.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { status } = action.payload;
        let url = `${urls.data.terminal.terminal}/v1/allots`;
        if (action.payload && action.payload.page) {
            url += `?isPage=${action.payload.page}`;
        }
        else {
            url += `?isPage=false`;
        }

        if (action.payload && action.payload.status) {
            url += `&status=${action.payload.status}`;
        }
        if (action.payload && action.payload.size) {
            url += `&size=${action.payload.size}`;
        }

        if (action.payload && action.payload.word) {
            url += `&word=${action.payload.word}`;
        }

        return (
            ajax({
                ...ajaxBaseConfig,
                 url,
                // url: `${urls.data.terminal.createTerminal}/selections?status=${status}`,
            }).pipe(
                concatMap(res => {
                    action.payload?.callback && action.payload.callback(res.response);
                    return [actions.terminalSelection.SUCCESS(res.response)];
                }),
                catchError(error => of(actions.terminalSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

