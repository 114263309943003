import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './categories';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';
// Epics
// 未区分一级，二级的列表数据
export const loadcategoriesSelectAllEpic = actions$ => actions$.pipe(
  ofType(actions.loadcategoriesSelectAll.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: action.payload && action.payload.status ? `${urls.data.categories.categories}/selections?status=${action.payload.status}` : `${urls.data.categories.categories}/selections`
    }).pipe(
      map(res => actions.loadcategoriesSelectAll.SUCCESS(res.response)),
      catchError(error => of(actions.loadcategoriesSelectAll.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  })
)



// 产品分类查询分页查询
export const prodcutCategoryEpic = actions$ => actions$.pipe(
  ofType(actions.prodcutCategory.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { keywords = '', page, size } = action.payload;
    let url = `${urls.data.categories.categories}?page=${page}&size=${size}&sort=id,desc&name=${keywords}`;
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.prodcutCategory.SUCCESS(res.response)),
      catchError(error => of(actions.prodcutCategory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 产品分类查询 树查询
export const prodcutCategoryTreeEpic = actions$ => actions$.pipe(
  ofType(actions.prodcutCategoryTree.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: `${urls.data.categories.categories}/tree`,
    }).pipe(
      map(res => actions.prodcutCategoryTree.SUCCESS(res.response)),
      catchError(error => of(actions.prodcutCategoryTree.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 分类选框
export const prodcutCategorySelectionsEpic = actions$ => actions$.pipe(
  ofType(actions.prodcutCategorySelections.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { status, parentId } = action.payload || {};
    let url = `${urls.data.categories.categories}/selections?status=${status}`;
    if (parentId !== undefined) {
      url += `&parentId=${parentId}`;
    }
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.prodcutCategorySelections.SUCCESS(res.response)),
      catchError(error => of(actions.prodcutCategorySelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 分类查询（id查询）
export const prodcutCategoryByIdEpic = actions$ => actions$.pipe(
  ofType(actions.prodcutCategoryById.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id } = action.payload || {};
    return ajax({
      ...apiBaseConfig,
      url: `${urls.data.categories.categories}/${id}`,
    }).pipe(
      map(res => actions.prodcutCategoryById.SUCCESS(res.response)),
      catchError(error => of(actions.prodcutCategoryById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 禁用/启用 产品分类
export const prodcutCategoryStatusEpic = actions$ => actions$.pipe(
  ofType(actions.prodcutCategoryStatus.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id, status } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.data.categories.categories}/${id}/status?status=${status}`,
      body: action.payload
    }).pipe(
      map(res => actions.prodcutCategoryStatus.SUCCESS(res.status)),
      catchError(error => of(actions.prodcutCategoryStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 创建/编辑 产品分类
export const prodcutCategoryModifyEpic = actions$ => actions$.pipe(
  ofType(actions.prodcutCategoryModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      method: action.payload.id ? 'PUT' : 'POST',
      url: `${urls.data.categories.categories}`,
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.prodcutCategoryModify.SUCCESS(res.status), uiActions.notification(action.payload.id ? '修改成功' : '创建成功', 'success')]),
      catchError(error => of(actions.prodcutCategoryModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 分类字段
export const prodcutCategoryFiledsEpic = actions$ => actions$.pipe(
  ofType(actions.prodcutCategoryFileds.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id, filedIds } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.data.categories.categories}/${id}/fileds`,
      body: filedIds
    }).pipe(
      mergeMap(res => [actions.prodcutCategoryFileds.SUCCESS(res.status), uiActions.notification('添加成功', 'success')]),
      catchError(error => of(actions.prodcutCategoryFileds.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);