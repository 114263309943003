import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './enterprises';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 企业管理
 */


//list
export const loadEnterprisesListEpic = actions$ => actions$.pipe(
  ofType(actions.loadEnterprisesList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { name = '', page = 0, size = 20, sort = 'id,desc' } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.enterprises.enterprises}?name=${name}&page=${page}&size=${size}&sort=${sort}`,
      }).pipe(
        map(res => actions.loadEnterprisesList.SUCCESS(res.response || [])),
        catchError(error => of(actions.loadEnterprisesList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//create
export const createEnterpriseEpic = actions$ => actions$.pipe(
  ofType(actions.createEnterprise.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.data.enterprises.enterprises}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.createEnterprise.SUCCESS(res)
        }),
        catchError(error => of(actions.createEnterprise.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//edit
export const editEnterpriseEpic = actions$ => actions$.pipe(
  ofType(actions.editEnterprise.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.data.enterprises.enterprises}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.editEnterprise.SUCCESS(res)
        }),
        catchError(error => of(actions.editEnterprise.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//able

export const ableEnterpriseEpic = actions$ => actions$.pipe(
  ofType(actions.ableEnterprise.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PATCH',
        url: `${urls.data.enterprises.enterprises}/${data.id}/status?status=${data.status}`,
        //  body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.ableEnterprise.SUCCESS(res)
        }),
        catchError(error => of(actions.ableEnterprise.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//通信录查询by id
export const loadEnterprisesSelectionsEpic = actions$ => actions$.pipe(
  ofType(actions.loadEnterprisesSelections.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: action.payload && action.payload.status ? `${urls.data.enterprises.selections}?status=${action.payload.status}` : `${urls.data.enterprises.selections}`,
      }).pipe(
        map(res => actions.loadEnterprisesSelections.SUCCESS(res.response.content || [])),
        catchError(error => of(actions.loadEnterprisesSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);



//list
export const enterpriseTimeGetEpic = actions$ => actions$.pipe(
  ofType(actions.enterpriseTimeGet.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { eseId = '', page = 0, size = 20, sort = 'id,desc' } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.enterprises.timeConfig}/${eseId}/v1?page=${page}&size=${size}&sort=${sort}`,
      }).pipe(
        map(res => actions.enterpriseTimeGet.SUCCESS(res.response)),
        catchError(error => of(actions.enterpriseTimeGet.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//create
export const createEnterpriseTimeEpic = actions$ => actions$.pipe(
  ofType(actions.createEnterpriseTime.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PATCH',
        url: `${urls.data.enterprises.enterprises}/${data.eseId}/expirationTimeRecord`,
        body: data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.createEnterpriseTime.SUCCESS(res)
        }),
        catchError(error => of(actions.createEnterpriseTime.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
