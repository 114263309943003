import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'anticounterfeiting';

//异常监控
export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const securityAreaDistribution = fasterActions(moduleName, 'securityAreaDistribution');
export const flowAreaDistribution = fasterActions(moduleName, 'flowAreaDistribution');
export const securityProDistribution = fasterActions(moduleName, 'securityProDistribution');
export const securityCityRank = fasterActions(moduleName, 'securityCityRank');
export const flowProDistribution = fasterActions(moduleName, 'flowProDistribution');
export const flowCityRank = fasterActions(moduleName, 'flowCityRank');
export const flowTrend = fasterActions(moduleName, 'flowTrend');
export const securityPage = fasterActions(moduleName, 'securityPage');
export const flowPage = fasterActions(moduleName, 'flowPage');
export const flowStatistics = fasterActions(moduleName, 'flowStatistics');

const initialState = {
    pageParams: {
        page: 0,
        size: 20,
        startTime: '',
        endTime: ''
    },
    loading: false,
    pageData: [],
    mapData: [],
    areaData: [],
    flowTrendData: [],
    pageData:[],
    staticsData:[],
}

const reducer = handleActions(
    {
        [changeDictPaging]: (state, { payload }) => {
            return { ...state, pageParams: { ...payload } }
        },

        [securityAreaDistribution.REQUEST]: (state, { payload }) => ({ ...state, mapData: [] }),
        [securityAreaDistribution.SUCCESS]: (state, { payload }) => ({ ...state, mapData: payload }),
        [securityAreaDistribution.FAIL]: (state, { payload }) => ({ ...state }),

        [flowAreaDistribution.REQUEST]: (state, { payload }) => ({ ...state, mapData: [] }),
        [flowAreaDistribution.SUCCESS]: (state, { payload }) => ({ ...state, mapData: payload }),
        [flowAreaDistribution.FAIL]: (state, { payload }) => ({ ...state }),

        [securityProDistribution.REQUEST]: (state, { payload }) => ({ ...state, areaData: [] }),
        [securityProDistribution.SUCCESS]: (state, { payload }) => ({ ...state, areaData: payload }),
        [securityProDistribution.FAIL]: (state, { payload }) => ({ ...state }),

        [securityCityRank.REQUEST]: (state, { payload }) => ({ ...state, areaData: [] }),
        [securityCityRank.SUCCESS]: (state, { payload }) => ({ ...state, areaData: payload }),
        [securityCityRank.FAIL]: (state, { payload }) => ({ ...state }),

        [flowProDistribution.REQUEST]: (state, { payload }) => ({ ...state, areaData: [] }),
        [flowProDistribution.SUCCESS]: (state, { payload }) => ({ ...state, areaData: payload }),
        [flowProDistribution.FAIL]: (state, { payload }) => ({ ...state }),

        [flowCityRank.REQUEST]: (state, { payload }) => ({ ...state, areaData: [] }),
        [flowCityRank.SUCCESS]: (state, { payload }) => ({ ...state, areaData: payload }),
        [flowCityRank.FAIL]: (state, { payload }) => ({ ...state }),

        [flowTrend.REQUEST]: (state, { payload }) => ({ ...state, flowTrendData: [] }),
        [flowTrend.SUCCESS]: (state, { payload }) => ({ ...state, flowTrendData: payload }),
        [flowTrend.FAIL]: (state, { payload }) => ({ ...state }),

        [securityPage.REQUEST]: (state, { payload }) => ({ ...state, pageData: [], loading: true }),
        [securityPage.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
        [securityPage.FAIL]: (state, { payload }) => ({ ...state,loading: false }),

        [flowPage.REQUEST]: (state, { payload }) => ({ ...state, pageData: [], loading: true }),
        [flowPage.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
        [flowPage.FAIL]: (state, { payload }) => ({ ...state,loading: false }),

        [flowStatistics.REQUEST]: (state, { payload }) => ({ ...state, staticsData: []}),
        [flowStatistics.SUCCESS]: (state, { payload }) => ({ ...state, staticsData: payload}),
        [flowStatistics.FAIL]: (state, { payload }) => ({ ...state }),

    },
    initialState
);

export default reducer;
