import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';
import * as actions from './tagbatchConfigs';

// Epics
// 企业配置管理 配置列表
export const loadListEpic = actions$ => actions$.pipe(
  ofType(actions.loadList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.tagbatchConfigs.tagbatchConfigs}`
    }).pipe(
      map(res => actions.loadList.SUCCESS(res.response)),
      catchError(error => of(actions.loadList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

// 企业配置管理 配置列表
export const loadAllListEpic = actions$ => actions$.pipe(
  ofType(actions.loadAllList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...apiBaseConfig,
      url: `${urls.code.tagbatchConfigs.tagbatchConfigs}/allots?isPage=false`,
      // url: `${urls.code.tagbatchConfigs.tagbatchConfigs}/all`
    }).pipe(
      map(res => actions.loadAllList.SUCCESS(res.response)),
      catchError(error => of(actions.loadAllList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

// 企业配置管理 配置列表 (发码选择）
export const loadChoiceListEpic = actions$ => actions$.pipe(
  ofType(actions.loadChoiceList.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { all } = action.payload || {};
    let url = `${urls.code.tagbatchConfigs.tagbatchConfigsChoice}`;
    if (all) {
      url = `${urls.code.tagbatchConfigs.tagbatchConfigs}/allots?isPage=false`
      // url += `?all=${all}`
    }

    return ajax({
      ...ajaxBaseConfig,
      url,
    }).pipe(
      map(res => actions.loadChoiceList.SUCCESS(res.response)),
      catchError(error => of(actions.loadChoiceList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const downLoadCodeEpic = actions$ => actions$.pipe(
  ofType(actions.downLoadCode.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: `${urls.code.tagbatchConfigs.downLoadCode}`,
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.downLoadCode.SUCCESS(res.response), uiActions.updateDownloadCodeDialogStatus(false)]),
      catchError(error => of(actions.downLoadCode.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

// 配置详情
export const tagbatchConfigsDetailEpic = actions$ => actions$.pipe(
  ofType(actions.tagbatchConfigsDetail.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.tagbatchConfigs.tagbatchConfigsDetail(action.payload)}`,
    }).pipe(
      map(res => actions.tagbatchConfigsDetail.SUCCESS(res.response)),
      catchError(error => of(actions.tagbatchConfigsDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


// 获取码包列表
export const extractionCodeEpic = actions$ => actions$.pipe(
  ofType(actions.extractionCode.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.extractionCode(action.payload)}`,
    }).pipe(
      map(res => actions.extractionCode.SUCCESS(res.response)),
      catchError(error => of(actions.extractionCode.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

// 下载码包列表
export const downloadExtractionCodeEpic = actions$ => actions$.pipe(
  ofType(actions.downloadExtractionCode.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      responseType: 'blob',
      url: action.payload,
    }).pipe(
      map(res => {

        return actions.downloadExtractionCode.SUCCESS(res.response)
      }),
      catchError(error => of(actions.downloadExtractionCode.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

// 上传批次信息
export const promotionUploadEpic = actions$ => actions$.pipe(
  ofType(actions.promotionUpload.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    const formData = new FormData();
    formData.append('uploadFile', data.uploadFile.file);
    return ajax({
      ...apiBaseConfig,
      withCredentials: true,
      method: 'POST',
      body: formData,
      url: `${urls.data.upload.promotionUpload}?data=${data.data}`
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.promotionUpload.SUCCESS(res.response)
      }),
      catchError(error => {
        callback && callback(false);
        return of(actions.promotionUpload.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      }
      )
    )
  })
);

export const getpromotionUploadListEpic = actions$ => actions$.pipe(
  ofType(actions.getpromotionUploadList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.upload.promotionUploadList}`,
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.getpromotionUploadList.SUCCESS(res.response)
      }),
      catchError(error => of(actions.getpromotionUploadList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
export const getpromotionCodeListEpic = actions$ => actions$.pipe(
  ofType(actions.getpromotionCodeList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      // dataType:'jsonp',
      url: `${urls.code.customer.customer}?query=${data.data}`,
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.getpromotionCodeList.SUCCESS(res.response)
      }),
      catchError(error => of(actions.getpromotionCodeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
