import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './targetAll';
import { ajaxBaseConfig, actionApi } from '../../utils';

// Epics
// 指标说明
export const targetDetailSumEpic = actions$ => actions$.pipe(
    ofType(actions.targetDetailSum.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { childModuleEn=''} = action.payload;
        // let indicatorsEnVal= indicatorsEn.value || '';
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.targetManage.target}?childModuleEn=${childModuleEn}`
        }).pipe(
            map(res => actions.targetDetailSum.SUCCESS(res.response)),
            catchError(error => of(actions.targetDetailSum.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    } 
    )
);