import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './plan';
import { ajaxBaseConfig, actionApi } from '../../utils';
import { SIGSTKFLT } from 'constants';

//获取step2方案
export const getPlanEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getPlan.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { promotionId, promotionType, planId, pageTemplateId, pageManageId, callback } = action.payload;


    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.plans.plans}?promotionId=${promotionId}${promotionType == 'CHECK_IN' ? `&detail=true` : ''}`
    }).pipe(
      concatMap(res => {
        callback && callback(res.response)
        // if (res.response.content.length == 0) {
        if (res.response.content.length == 0 && promotionType != 'CHECK_IN') {
          return [actions.getPlan.SUCCESS(res.response.content), actions.getPlanNo.REQUEST({ promotionId: promotionId, promotionType: promotionType, pageTemplateId: pageTemplateId, pageManageId: pageManageId })]
        }
        else if (promotionType == 'CHECK_IN') {
          if (res.response.content.length == 0) {
            return [actions.getPlan.SUCCESS(res.response.content), actions.editPlanIsEdit.REQUEST(true)]
          } else {
            return [actions.getPlan.SUCCESS(res.response.content), actions.editPlanIsEdit.REQUEST(false)]
          }
        }
        else {
          function sortWeight(a, b) {
            return b.weight - a.weight
          }
          let newlist = res.response.content.sort(sortWeight);
          let num = newlist.map((item) => { return item.id }).indexOf(state$.value.plan.planChoose.id);

          if (planId) {

            if (newlist.filter(key => key.id == planId).length > 0) {
              return [actions.getPlan.SUCCESS(newlist), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: newlist.filter(key => key.id == planId)[0] })]
            }
            else {
              return [actions.getPlan.SUCCESS(newlist), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: newlist[0] })]
            }
          }
          else {
            if (state$.value.plan.planChoose.id && num >= 0) {
              let array = {
                ...newlist[num],
              }
              return [actions.getPlan.SUCCESS(newlist), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: array })]
            }
            else {
              return [actions.getPlan.SUCCESS(newlist), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: newlist[0] })]
            }

          }
        }
      }),
      catchError(error => of(actions.getPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

//获取step2方案历史
export const getPlanHistoryEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getPlanHistory.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { promotionId, historyId, step } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: urls.promotion.promotionHistories.step(historyId, promotionId, step)
    }).pipe(
      concatMap(res => {

        function sortWeight(a, b) {
          return b.weight - a.weight
        }
        let newlist = res.response.plans.sort(sortWeight);
        let num = newlist.map((item) => { return item.id }).indexOf(state$.value.plan.planChoose.id);

        return [actions.getPlanHistory.SUCCESS(newlist), actions.choosePlan.REQUEST({ historyId: historyId, data: newlist[0] })]



      }),
      catchError(error => of(actions.getPlanHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
//方案为空时保存默认方案
export const getPlanNoEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getPlanNo.REQUEST.toString()),
  concatMap(action => {
    if (action.payload.promotionType == 'DISSEMINATE_PROMOTION') {
      return [actions.createPlanMore.REQUEST({
        data:
          [
            { 'name': '发起设置', 'planType': 'SPONSOR', 'promotionId': action.payload.promotionId },
            { 'name': '参与设置', 'planType': 'PARTICIPATION', 'promotionId': action.payload.promotionId },
          ]

      }),
      ]
    } else {
      return [actions.createPlan.REQUEST({ data: { 'name': '默认方案', 'planType': 'DEFAULT', 'promotionId': action.payload.promotionId, pageTemplateId: action.payload.pageTemplateId, pageManageId: action.payload.pageManageId } })]

    }
  }

  ),
);

// export const getPlanNo2Epic = (actions$, state$) => actions$.pipe(
//   ofType(actions.getPlanNo2.REQUEST.toString()),
//   concatMap(action => {
//     // setTimeout(() => {
//     return [
//       actions.createPlan.REQUEST({ data: { 'name': '发起设置', 'planType': 'SPONSOR', 'promotionId': action.payload.promotionId } })
//     ]
//     // }, 1000);

//   }

//   ),
// );




//选择step2方案
export const choosePlanEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.choosePlan.REQUEST.toString()),
  concatMap(action => {
    if (action.payload.data.id) {
      if (action.payload.historyId) {
        const { data, historyId } = action.payload;
        return [actions.getPlanDetailHistory.REQUEST({ historyId, data })]
      } else {
        return [actions.getPlanDetail.REQUEST(action.payload.data.id)]
      }
    }
    else {
      return []
    }
  }),
);
//获取step2方案详情
export const getPlanDetailEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getPlanDetail.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'GET',
      url: `${urls.promotion.plans.plans}?planId=${action.payload}`
    }).pipe(
      // concatMap(res => [actions.getPlanDetail.SUCCESS(res.response)]),
      concatMap(res => {

        let newRadio = [[], -1, -1, -1, -1, -1, -1, -1, -1, [], -1, -1, -1, -1, -1]
        if (res.response.planType == 'DEFAULT' && ['INTERACT', 'COMPLIANCE_ACTIVITIES'].includes(state$.value.promotions.byId.promotionType)) {


          if (res.response.conditionKeys) {
            let newdata = res.response;
            newdata.prizeProbabilities = res.response.prizeProbabilities.map((item, index) => {
              return {
                ...item,
                rate: parseFloat((item.rate * 10000 * 100 / 10000).toPrecision(12)),
                // rate: item.rate * 1000000 / 10000,
                overrunWinType: item.overrunWinType ? item.overrunWinType : 'NOT_WIN',
              }
            })

            newRadio[0] = ['INTERACT']
            return [
              actions.editPlanIsEdit.REQUEST(false),
              actions.getPlanDetail.SUCCESS(newdata),

              actions.editTemplateList.REQUEST(newdata.strategies),
              actions.editAccurateRadio.REQUEST(newRadio),
              actions.editAccurateRadioSelf.REQUEST(newRadio),


            ]
            // actions.editAccurateRadio.REQUEST(2),


          }
          else {
            let newdata = res.response;
            newRadio[0] = ['INTERACT']
            return [
              // actions.editPlanIsEdit.REQUEST(null),
              actions.editPlanIsEdit.REQUEST(true),
              actions.getPlanDetail.SUCCESS(newdata),
              actions.editTemplateList.REQUEST([{ conditions: { INTERACT: [] } }]),
              actions.editAccurateRadio.REQUEST(newRadio),
              actions.editAccurateRadioSelf.REQUEST(newRadio),

            ]
          }

        }
        else if (res.response.planType == 'DEFAULT') {
          let newdata = res.response;
          newdata.prizeProbabilities = res.response.prizeProbabilities.map((item, index) => {
            return {
              ...item,
              rate: parseFloat((item.rate * 10000 * 100 / 10000).toPrecision(12)),
              overrunWinType: item.overrunWinType ? item.overrunWinType : 'NOT_WIN',
            }
          })
          return [actions.getPlanDetail.SUCCESS(newdata), actions.editPlanIsEdit.REQUEST(false)]
          // }
        }
        else {
          if (res.response.conditionKeys || ((res.response.planType == 'SPONSOR' || res.response.planType == 'PARTICIPATION') && res.response.name.indexOf('1') >= 0)) {

            let newdata = res.response;
            newdata.prizeProbabilities = res.response.prizeProbabilities.map((item, index) => {
              return {
                ...item,
                rate: parseFloat((item.rate * 10000 * 100 / 10000).toPrecision(12)),
                overrunWinType: item.overrunWinType ? item.overrunWinType : 'NOT_WIN',
              }
            })
            let title = {
              PRODUCT: 0,
              INTERACT: 0,
              PARTICIPATING_DEALERS: 0,
              PARTICIPATING_SHOP: 0,
              PARTICIPATING_SALES_AREA: 0,
              MARKETING_CHANNEL: 0,
              CODE_STATUS: 0,
              CODE_LABEL: 0,
              CODE_INFORMATION: 0,
              ACCESS_ASSIGN_BANQUET: 0,

              PROMOTION_USER: 1,
              NEW_OLD_SHOP: 1,
              ESE_USER: 1,
              LOTTERY_NUM: 1,
              TIME_RANGE_PROMOTIONS_LUM: 1,
              TIME_PERIOD_PROMOTIONS_LUM: 1,
              TIME_PERIOD_DAYS_PROMOTIONS_LUM: 1,
              NEW_MEMBER_TAG: 2,
              LAST_MEMBER_TAG: 2,
              GPS_PROVINCE: 3,
              GPS_CITY: 3,
              GPS_DISTRICT: 3,
              GPS_POSITION: 3,
              IP_PROVINCE: 3,
              SPONSOR_GPS_POSITION: 3,
              // ipAreaProvince: 3,
              // ipAreaCity: 3,
              POSITION_SCENE_LARGE: 4,
              POSITION_SCENE_SMALL: 4,

              TIME_PERIOD: 5,
              TIME_RANGE: 5,
              TIME_RANGE_TIME_PERIOD: 5,
              ANTI_ATTACK: 6,
              USER_VALUE: 7,
              PROVINCIAL_SHOP: 8,
              CITY_SHOP: 8,
              BUTTON: 9,
              COUNTER: 9,
              TAG_LUM: 9,
              USER_SOURCE: 9,
              MEMBER_LEVEL: 9,
              WP_SUBSCRIBE: 9,
              SHOP_AUTHENTICITY: 9,
              BOX_POSITION: 9,
              LOTTERY_TIME_INTERVAL: 9,

              PARTICIPANTS: 10,
              AUTOMATIC_CALCULATION_TIME: 11,
              SCHEDULE_TIME: 11,
              AUTOMATIC_COUNTING_DAY: 11,

              VERIFY_SHOP: 12,




              SMUGGLING_PROVINCE: 14,
              SMUGGLING_CITY: 14,
              SMUGGLING_DISTRICT: 14,
            }
            // let newRadio = [[], -1, -1, -1, -1, -1, -1, -1, -1, []]

            if (res.response.planType == 'SPONSOR' || res.response.planType == 'PARTICIPATION') {
              newRadio[0] = -1;
            }
            if (newdata.conditionKeys) {
              for (let i = 0; i < newdata.conditionKeys.split(',').length; i++) {
                if (title[newdata.conditionKeys.split(',')[i]] === 0) {

                  if (res.response.planType == 'SPONSOR' || res.response.planType == 'PARTICIPATION') {
                    newRadio[title[newdata.conditionKeys.split(',')[i]]] = newdata.conditionKeys.split(',')[i];
                  }
                  else {
                    newRadio[0].push(newdata.conditionKeys.split(',')[i])
                  }

                }
                else if (title[newdata.conditionKeys.split(',')[i]] === 9) {
                  newRadio[9].push(newdata.conditionKeys.split(',')[i])
                }
                else {
                  newRadio[title[newdata.conditionKeys.split(',')[i]]] = newdata.conditionKeys.split(',')[i];
                }


              }



            }
            //采集信息部分调整
            if (newdata.conditionKeys.indexOf("CODE_INFORMATION") >= 0) {
              newdata.strategies.map(item => {
                let newdata2 = JSON.parse(item.conditions.CODE_INFORMATION[0].value).map(items => {
                  return {
                    value: JSON.stringify([items])
                  }
                })
                item.conditions.CODE_INFORMATION = newdata2
              })
            }

            return [
              actions.editAccurateType.REQUEST(2),
              actions.editPlanIsEdit.REQUEST(false),
              actions.getPlanDetail.SUCCESS(newdata),

              actions.editTemplateList.REQUEST(newdata.strategies),

              actions.editAccurateRadio.REQUEST(newRadio),
              actions.editAccurateRadioSelf.REQUEST(newRadio),


            ]
            // actions.editAccurateRadio.REQUEST(2),


          }
          else {
            let newdata = res.response;
            newRadio[0] = ['PRODUCT']
            // = [["PRODUCT"], -1, -1, -1, -1, -1, -1, -1, -1, []]
            if (['INTERACT', 'COMPLIANCE_ACTIVITIES'].includes(state$.value.promotions.byId.promotionType)) {
              newRadio[0] = ['INTERACT']
            }
            else if (res.response.planType == 'ACCURATE' && state$.value.promotions.byId.promotionType == "DISSEMINATE_PROMOTION") {
              newRadio[0] = []
              newRadio[10] = 'PARTICIPANTS'
            }
            else if (res.response.planType == 'SPONSOR') {
              newRadio[0] = -1;
              newRadio[10] = -1;
            }
            else if (res.response.planType == 'PARTICIPATION') {
              newRadio[0] = -1;
              newRadio[10] = -1;

            }

            // newdata.prizeProbabilities.push({ awardId: '', rate: 0, overrunWinType: 'NOT_WIN' })
            return [
              actions.editAccurateType.REQUEST(1),
              // actions.editPlanIsEdit.REQUEST(null),
              actions.editPlanIsEdit.REQUEST(true),
              actions.getPlanDetail.SUCCESS(newdata),

              actions.editTemplateList.REQUEST([]),

              actions.editAccurateRadio.REQUEST(newRadio),
              actions.editAccurateRadioSelf.REQUEST(newRadio),

            ]
          }


        }

      }),



      catchError(error => of(actions.getPlanDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
//获取step2方案详情历史
export const getPlanDetailHistoryEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getPlanDetailHistory.REQUEST.toString()),
  // mergeMap(action => { actionApi()
  //   const { data, historyId } = action.payload;
  //   return ajax({
  //     ...ajaxBaseConfig,
  //     method: 'GET',

  //     url: urls.promotion.promotionHistories.historyPlan(historyId, data.id)
  //   }).pipe(

  concatMap(action => {
    const { data, historyId } = action.payload;
    let res = JSON.parse(JSON.stringify(state$.value.plan.planAllList.filter(key => key.id == data.id)[0]))

    let newRadio = [[], -1, -1, -1, -1, -1, -1, -1, -1, [], -1, -1, -1, -1, -1]
    if (data.planType == 'DEFAULT' && ['INTERACT', 'COMPLIANCE_ACTIVITIES'].includes(state$.value.promotions.historyStep0.promotion.promotionType)) {
      let newdata = res;
      if (newdata.prizeProbabilities) {
        newdata.prizeProbabilities = res.prizeProbabilities.map((item, index) => {
          return {
            ...item,
            rate: parseFloat((item.rate * 10000 * 100 / 10000).toPrecision(12)),
            overrunWinType: item.overrunWinType ? item.overrunWinType : 'NOT_WIN',
          }
        })
      }
      else {

      }
      newRadio[0] = ['INTERACT']
      // let newRadio = [['INTERACT'], -1, -1, -1, -1, -1, -1, -1, -1, []]
      return [
        actions.editPlanIsEdit.REQUEST(false),
        actions.getPlanDetailHistory.SUCCESS(newdata),
        actions.editTemplateList.REQUEST(newdata.strategies ? newdata.strategies : []),
        actions.editAccurateRadio.REQUEST(newRadio),
        actions.editAccurateRadioSelf.REQUEST(newRadio),
      ]

    }
    else if (data.planType == 'DEFAULT') {
      let newdata = res;
      if (newdata.prizeProbabilities) {
        newdata.prizeProbabilities = res.prizeProbabilities.map((item, index) => {
          return {
            ...item,
            rate: parseFloat((item.rate * 10000 * 100 / 10000).toPrecision(12)),
            overrunWinType: item.overrunWinType ? item.overrunWinType : 'NOT_WIN',
          }
        })
      }
      return [actions.getPlanDetailHistory.SUCCESS(newdata), actions.editPlanIsEdit.REQUEST(false)]

    }
    else {

      let newdata = res;
      if (newdata.prizeProbabilities) {
        newdata.prizeProbabilities = res.prizeProbabilities.map((item, index) => {
          return {
            ...item,
            rate: parseFloat((item.rate * 10000 * 100 / 10000).toPrecision(12)),
            overrunWinType: item.overrunWinType ? item.overrunWinType : 'NOT_WIN',
          }
        })
      }
      let title = {
        PRODUCT: 0,
        INTERACT: 0,
        PARTICIPATING_DEALERS: 0,
        PARTICIPATING_SHOP: 0,
        PARTICIPATING_SALES_AREA: 0,
        MARKETING_CHANNEL: 0,
        CODE_STATUS: 0,
        CODE_LABEL: 0,
        CODE_INFORMATION: 0,
        ACCESS_ASSIGN_BANQUET: 0,

        PROMOTION_USER: 1,
        NEW_OLD_SHOP: 1,
        ESE_USER: 1,
        LOTTERY_NUM: 1,
        TIME_RANGE_PROMOTIONS_LUM: 1,
        TIME_PERIOD_PROMOTIONS_LUM: 1,
        TIME_PERIOD_DAYS_PROMOTIONS_LUM: 1,
        NEW_MEMBER_TAG: 2,
        LAST_MEMBER_TAG: 2,
        GPS_PROVINCE: 3,
        GPS_CITY: 3,
        GPS_DISTRICT: 3,
        GPS_POSITION: 3,
        IP_PROVINCE: 3,
        SPONSOR_GPS_POSITION: 3,
        // ipAreaProvince: 3,
        // ipAreaCity: 3,

        POSITION_SCENE_LARGE: 4,
        POSITION_SCENE_SMALL: 4,

        TIME_PERIOD: 5,
        TIME_RANGE: 5,
        TIME_RANGE_TIME_PERIOD: 5,
        ANTI_ATTACK: 6,

        USER_VALUE: 7,
        PROVINCIAL_SHOP: 8,
        CITY_SHOP: 8,

        BUTTON: 9,
        COUNTER: 9,
        TAG_LUM: 9,
        USER_SOURCE: 9,
        MEMBER_LEVEL: 9,
        WP_SUBSCRIBE: 9,
        SHOP_AUTHENTICITY: 9,
        BOX_POSITION: 9,
        LOTTERY_TIME_INTERVAL: 9,



        PARTICIPANTS: 10,
        AUTOMATIC_CALCULATION_TIME: 11,
        SCHEDULE_TIME: 11,
        AUTOMATIC_COUNTING_DAY: 11,

        VERIFY_SHOP: 12,


        SMUGGLING_PROVINCE: 14,
        SMUGGLING_CITY: 14,
        SMUGGLING_DISTRICT: 14,

      }

      // let newRadio = [[], -1, -1, -1, -1, -1, -1, -1, -1, []]
      // if (data.conditionKeys) {
      //   for (let i = 0; i < data.conditionKeys.split(',').length; i++) {

      //     if (title[newdata.conditionKeys.split(',')[i]] === 0) {
      //       newRadio[0].push(data.conditionKeys.split(',')[i])
      //     }
      //     else if (title[newdata.conditionKeys.split(',')[i]] === 9) {
      //       newRadio[9].push(data.conditionKeys.split(',')[i])
      //     }
      //     else {
      //       newRadio[title[data.conditionKeys.split(',')[i]]] = data.conditionKeys.split(',')[i];
      //     }
      //   }
      // }


      if (data.planType == 'SPONSOR' || data.planType == 'PARTICIPATION') {
        newRadio[0] = -1;
      }

      if (data.conditionKeys) {
        for (let i = 0; i < data.conditionKeys.split(',').length; i++) {
          if (title[data.conditionKeys.split(',')[i]] === 0) {

            if (data.planType == 'SPONSOR' || data.planType == 'PARTICIPATION') {
              newRadio[title[data.conditionKeys.split(',')[i]]] = data.conditionKeys.split(',')[i];
            }
            else {
              newRadio[0].push(data.conditionKeys.split(',')[i])
            }

          }
          else if (title[data.conditionKeys.split(',')[i]] === 9) {
            newRadio[9].push(data.conditionKeys.split(',')[i])
          }
          else {
            newRadio[title[data.conditionKeys.split(',')[i]]] = data.conditionKeys.split(',')[i];
          }

        }
      }
      //采集信息部分调整
      if (data.conditionKeys.indexOf("CODE_INFORMATION") >= 0) {
        newdata.strategies.map(item => {
          let newdata2 = JSON.parse(item.conditions.CODE_INFORMATION[0].value).map(items => {
            return {
              value: JSON.stringify([items])
            }
          })
          item.conditions.CODE_INFORMATION = newdata2
        })
      }

      console.log(newdata)


      return [

        actions.editAccurateType.REQUEST(2),
        actions.editPlanIsEdit.REQUEST(false),
        actions.getPlanDetailHistory.SUCCESS(newdata),

        actions.editTemplateList.REQUEST(newdata.strategies ? newdata.strategies : []),

        actions.editAccurateRadio.REQUEST(newRadio),
        actions.editAccurateRadioSelf.REQUEST(newRadio),


      ]
      // actions.editAccurateRadio.REQUEST(2),




    }

  }),

  //     catchError(error => of(actions.getPlanDetailHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
  //     )
  //   )
  // }
  // )
);

//保存step2方案
export const createPlanEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.createPlan.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: data,
      url: `${urls.promotion.plans.plans}`
    }).pipe(
      concatMap(res => {
        let array = [
          res.response,
          ...state$.value.plan.planAllList,

        ];
        callback && callback()
        if (array.length == 1) {
          return [actions.createPlan.SUCCESS(array), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: array[0] })]
        } else {
          return [actions.createPlan.SUCCESS(array), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue)]
        }
      }),
      catchError(error => of(actions.createPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
//保存step2方案多个
export const createPlanMoreEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.createPlanMore.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: data,
      url: `${urls.promotion.plans.plans}/batch`
    }).pipe(
      concatMap(res => {
        let array = [
          ...res.response,
          ...state$.value.plan.planAllList,

        ];
        callback && callback()
        if (array.length == 2) {
          return [actions.createPlanMore.SUCCESS(array), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: array[0] })]
        } else {
          return [actions.createPlanMore.SUCCESS(array), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue)]
        }
      }),
      catchError(error => of(actions.createPlanMore.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


//排序step2方案
export const sortPlanEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.sortPlan.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      body: data,
      url: `${urls.promotion.plans.sort}`
    }).pipe(
      concatMap(res => {
        callback && callback()
        return [actions.sortPlan.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.sortPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
//copystep2方案
export const copyPlanEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.copyPlan.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      body: data,
      url: `${urls.promotion.plans.plans}/copy`
    }).pipe(
      concatMap(res => {
        let array = [
          res.response,
          ...state$.value.plan.planAllList,

        ];
        callback && callback()
        if (array.length == 1) {
          return [actions.copyPlan.SUCCESS(array), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: array[0] })]
        } else {
          return [actions.copyPlan.SUCCESS(array), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: array[0] })]
        }
      }),
      catchError(error => of(actions.copyPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

//导出step2方案
export const exportPlanEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.exportPlan.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.plans.plans}/export/${data.id}` + (data.version ? `/${data.version}` : ``)
    }).pipe(
      concatMap(res => {
        callback && callback()
        return [actions.exportPlan.SUCCESS(res)]
      }),
      catchError(error => of(actions.exportPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);



//修改step2方案
export const editPlanEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.editPlan.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      body: action.payload,
      url: `${urls.promotion.plans.plans}`
    }).pipe(
      concatMap(res => [actions.editPlan.SUCCESS(res.response), actions.getPlan.REQUEST({ promotionId: action.payload.promotionId })]),
      catchError(error => of(actions.editPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

// 方案下拉列表
export const getPlanSelectionsEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getPlanSelections.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { promotionIds = '', callback } = action.payload;
    let url = `${urls.promotion.plans.plans}/selections?promotionIds=${promotionIds}`;
    if (typeof promotionIds === 'object' && promotionIds.join() == -1) {
      url = `${urls.promotion.plans.plans}/selections?promotionIds=`
    }
    return ajax({
      ...ajaxBaseConfig,
      method: 'GET',
      url
    }).pipe(
      map(res => {
        callback && callback(res.response.content || []);
        return actions.getPlanSelections.SUCCESS(res.response.content || [])
      }),
      catchError(error => of(action.getPlanSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


//获取step2计数器list
export const getCounterEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getCounter.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.plans.plans}/counter?promotionId=${action.payload}`
    }).pipe(
      concatMap(res => {
        let newList = [];
        for (let i = 0; i < res.response.content.length; i++) {
          let newarray = {
            ...res.response.content[i],
            value: res.response.content[i].id.toString(),
            text: res.response.content[i].name,
          }
          newList.push(newarray)
        }
        return [actions.getCounter.SUCCESS(newList)]
      }),
      catchError(error => of(actions.getCounter.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);



//获取step2定点范围
export const getGpsPositionEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getGpsPosition.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.gpsPositions.gpsPositions}?promotionId=${action.payload}`
    }).pipe(
      concatMap(res => {
        let newList = [];
        for (let i = 0; i < res.response.content.length; i++) {
          let newarray = {
            ...res.response.content[i],
            value: res.response.content[i].id.toString(),
            text: res.response.content[i].name,
          }
          newList.push(newarray)
        }
        return [actions.getGpsPosition.SUCCESS(newList)]
      }),
      catchError(error => of(actions.getGpsPosition.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
//保存step2定点范围
export const createGpsPositionEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.createGpsPosition.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: data,
      url: `${urls.promotion.gpsPositions.gpsPositions}`
    }).pipe(
      concatMap(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,
        // ];
        callback && callback()
        // if (array.length == 1) {
        //   return [actions.createGpsPosition.SUCCESS(array), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: array[0] })]
        // } else {
        return [actions.createGpsPosition.SUCCESS(res.response), actions.getGpsPosition.REQUEST(data.promotionId)]
        // }
      }),
      catchError(error => of(actions.createGpsPosition.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

//修改step2定点范围
export const editGpsPositionEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.editGpsPosition.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      body: data,
      url: `${urls.promotion.gpsPositions.gpsPositions}`
    }).pipe(
      concatMap(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,
        // ];
        callback && callback()
        // if (array.length == 1) {
        //   return [actions.createGpsPosition.SUCCESS(array), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: array[0] })]
        // } else {
        return [actions.editGpsPosition.SUCCESS(res.response), actions.getGpsPosition.REQUEST(data.promotionId)]
        // }
      }),
      catchError(error => of(actions.editGpsPosition.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


//删除step2定点范围
export const deleteGpsPositionEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.deleteGpsPosition.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'DELETE',
      url: `${urls.promotion.gpsPositions.gpsPositions}/${data.id}`
    }).pipe(
      concatMap(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,
        // ];
        callback && callback()
        // if (array.length == 1) {
        //   return [actions.createGpsPosition.SUCCESS(array), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: array[0] })]
        // } else {
        return [actions.deleteGpsPosition.SUCCESS(res.response), actions.getGpsPosition.REQUEST(data.promotionId)]
        // }
      }),
      catchError(error => of(actions.deleteGpsPosition.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


//获取step2大类小类
export const getPositionSceneEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getPositionScene.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.plans.positionScene}`
    }).pipe(
      concatMap(res => {
        let newarray = res.response.content.map((item) => { return { value: item.id, text: item.name, id: item.id } })

        let newarray2 = JSON.parse(JSON.stringify(res.response.content));
        for (let i = 0; i < newarray2.length; i++) {
          newarray2[i].text = newarray2[i].name;
          for (let j = 0; j < newarray2[i].children.length; j++) {
            newarray2[i].children[j].text = newarray2[i].children[j].name;
            delete newarray2[i].children[j].children
          }
        }
        return [actions.getPositionScene.SUCCESS({ list: newarray, list2: newarray2 })]
      }),
      catchError(error => of(actions.getPositionScene.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


//获取step2 活动列表
export const getActiveListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getActiveList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.promotions.selections}?promotionCategory=${data}`
    }).pipe(
      concatMap(res => {
        let newList = [];
        for (let i = 0; i < res.response.content.length; i++) {
          let newarray = {
            ...res.response.content[i],
            value: res.response.content[i].promotionId.toString(),
            text: res.response.content[i].name,
          }
          newList.push(newarray)
        }
        return [actions.getActiveList.SUCCESS(newList)]
      }),
      catchError(error => of(actions.getActiveList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

//获取 方案列表外部
export const getPlanListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getPlanList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.plans.plans}/planIds?promotionId=${data}`
    }).pipe(
      concatMap(res => {
        // callback && callback(res.response)
        function sortWeight(a, b) {
          return b.weight - a.weight
        }
        let newlist = res.response.content.sort(sortWeight);
        let newList2 = [];
        for (let i = 0; i < newlist.length; i++) {
          let newarray = {
            ...newlist[i],
            value: newlist[i].id.toString(),
            text: newlist[i].name,
          }
          newList2.push(newarray)
        }
        return [actions.getPlanList.SUCCESS(newList2)]
      }),
      catchError(error => of(actions.getPlanList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);




//获取查询工具的活动列表
export const getActiveListForQoolEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getActiveListForQool.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, eseId, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.promotions.selectionsForQool}/selections/promotion?promotionCategory=${data}` + (eseId ? `&eseId=${eseId}` : '')
    }).pipe(
      concatMap(res => {
        let newList = [];
        for (let i = 0; i < res.response.length; i++) {
          let newarray = {
            ...res.response[i],
            value: res.response[i].promotionId.toString(),
            text: res.response[i].name,
          }
          newList.push(newarray)
        }
        return [actions.getActiveListForQool.SUCCESS(newList)]
      }),
      catchError(error => of(actions.getActiveListForQool.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

//获取查询工具的方案列表外部
export const getPlanListForQoolEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getPlanListForQool.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, eseId } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.promotions.selectionsForQool}/selections/plan?id=${data}` + (eseId ? `&eseId=${eseId}` : '')
    }).pipe(
      concatMap(res => {
        // callback && callback(res.response)
        function sortWeight(a, b) {
          return b.weight - a.weight
        }
        let newlist = res.response ? res.response.sort(sortWeight) : [];
        let newList2 = [];
        for (let i = 0; i < newlist.length; i++) {
          let newarray = {
            ...newlist[i],
            value: newlist[i].id.toString(),
            text: newlist[i].name,
          }
          newList2.push(newarray)
        }
        return [actions.getPlanListForQool.SUCCESS(newList2)]
      }),
      catchError(error => of(actions.getPlanListForQool.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


export const getAwardInfoEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getAwardInfo.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, promotionId, dealerId, eseId, caseId } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.promotions.selectionsForQool}/award?promotionId=${promotionId}&id=${data}` + (dealerId ? `&dealerId=${dealerId}` : '') + (eseId ? `&eseId=${eseId}` : '')
    }).pipe(
      concatMap(res => {
        // // callback && callback(res.response)
        // function sortWeight(a, b) {
        //   return b.weight - a.weight
        // }
        // let newlist = res.response ? res.response.sort(sortWeight) : [];
        // let newList2 = [];
        // for (let i = 0; i < newlist.length; i++) {
        //   let newarray = {
        //     ...newlist[i],
        //     value: newlist[i].id.toString(),
        //     text: newlist[i].name,
        //   }
        //   newList2.push(newarray)
        // }
        return [actions.getAwardInfo.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.getAwardInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
export const getPlanInfoEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getPlanInfo.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { promotionId, planId, eseId } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.promotions.selectionsForQool}/plan?promotionId=${promotionId}&planId=${planId}` + (eseId ? `&eseId=${eseId}` : '')
    }).pipe(
      concatMap(res => {
        // // callback && callback(res.response)
        // function sortWeight(a, b) {
        //   return b.weight - a.weight
        // }
        // let newlist = res.response ? res.response.sort(sortWeight) : [];
        // let newList2 = [];
        // for (let i = 0; i < newlist.length; i++) {
        //   let newarray = {
        //     ...newlist[i],
        //     value: newlist[i].id.toString(),
        //     text: newlist[i].name,
        //   }
        //   newList2.push(newarray)
        // }
        return [actions.getPlanInfo.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.getPlanInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);





export const getdealerEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getdealer.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { eseId } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.promotions.selectionsForDealer}/selections/dealer` + (eseId ? `?eseId=${eseId}` : '')
    }).pipe(
      concatMap(res => {
        return [actions.getdealer.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.getdealer.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);








//保存step0准入条件
export const createAccessEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.createAccess.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: data,
      url: `${urls.promotion.access.access}`
    }).pipe(
      concatMap(res => {
        let array = [
          res.response,
          ...state$.value.plan.accessAllList,

        ];
        callback && callback()
        return [actions.createAccess.SUCCESS(array)]
        // if (array.length == 1) {
        //   return [actions.createPlan.SUCCESS(array), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: array[0] })]
        // } else {
        //   return [actions.createPlan.SUCCESS(array), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue)]
        // }
      }),
      catchError(error => of(actions.createAccess.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

//获取step0准入条件
export const getAccessEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getAccess.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    const { promotionId, planId } = data;
    let url = ''
    if (promotionId) {
      url = `${urls.promotion.access.access}/selections?promotionId=${promotionId}`
    }
    else {
      url = `${urls.promotion.access.access}/selections`
    }

    return ajax({
      ...ajaxBaseConfig,
      url: url
    }).pipe(
      concatMap(res => {
        function sortWeight(a, b) {
          return b.id - a.id
        }
        let newlist = res.response.content.sort(sortWeight);
        callback && callback(newlist)

        return [actions.getAccess.SUCCESS(newlist)]
        // }


        // else {
        //   function sortWeight(a, b) {
        //     return b.weight - a.weight
        //   }
        //   let newlist = res.response.content.sort(sortWeight);
        //   let num = newlist.map((item) => { return item.id }).indexOf(state$.value.plan.planChoose.id);

        //   if (planId) {

        //     if (newlist.filter(key => key.id == planId).length > 0) {
        //       return [actions.getPlan.SUCCESS(newlist), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: newlist.filter(key => key.id == planId)[0] })]
        //     }
        //     else {
        //       return [actions.getPlan.SUCCESS(newlist), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: newlist[0] })]
        //     }
        //   }
        //   else {
        //     if (state$.value.plan.planChoose.id && num >= 0) {
        //       let array = {
        //         ...newlist[num],
        //       }
        //       return [actions.getPlan.SUCCESS(newlist), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: array })]
        //     }
        //     else {
        //       return [actions.getPlan.SUCCESS(newlist), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: newlist[0] })]
        //     }

        //   }
        // }
      }),
      catchError(error => of(actions.getAccess.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)
      )
      )
    )
  }
  )
);
//修改step0准入条件
export const editAccessEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.editAccess.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      body: data,
      url: `${urls.promotion.access.access}`
    }).pipe(

      // , actions.getPlan.REQUEST({ promotionId: action.payload.promotionId })
      concatMap(res => {
        callback && callback()
        return [actions.editAccess.SUCCESS(res.response)]
      }
      ),
      catchError(error => of(actions.editAccess.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

//删除step0准入条件
export const deleteAccessEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.deleteAccess.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'DELETE',
      url: `${urls.promotion.access.access}/${data.id}`
    }).pipe(
      concatMap(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,
        // ];
        callback && callback()
        // if (array.length == 1) {
        //   return [actions.createGpsPosition.SUCCESS(array), actions.planTypeValue.REQUEST(state$.value.plan.planfilterValue), actions.choosePlan.REQUEST({ data: array[0] })]
        // } else {
        return [actions.deleteAccess.SUCCESS(res.response)]
        // , actions.deleteAccessAfter.REQUEST(data.id)
        // 
        // }
      }),
      catchError(error => of(actions.deleteAccess.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


//保存step2方案-签到活动
export const createCheckInPlanEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.createCheckInPlan.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, promotionType, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: data,
      url: `${urls.promotion.plans.checkIn}`
    }).pipe(
      concatMap(res => {
        callback && callback(res);
        return [actions.createCheckInPlan.SUCCESS(res.response), actions.getPlan.REQUEST({ promotionId: data.promotionId, promotionType })];
      }),
      catchError(error => of(actions.createCheckInPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
