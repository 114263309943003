import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
//不要encode
import { ajax } from '../../ajax';

import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './codeDataManagement';
import { ajaxBaseConfig, actionApi } from '../../utils';
import { apiBaseConfig } from '@/redux/utils';
// 码数据变更列表
export const loadcodeDatachangeListEpic = actions$ => actions$.pipe(
  ofType(actions.loadcodeDatachangeList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { code = '', startTime = '', endTime = '', page = 0, size = 20, sort = 'id,desc' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.linkTags.linkTags}?code=${code}&startTime=${startTime}&endTime=${endTime}&page=${page}&size=${size}&sort=${sort}`,

      method: 'GET'
    }).pipe(
      map(res => actions.loadcodeDatachangeList.SUCCESS(res.response)),
      catchError(error => of(actions.loadcodeDatachangeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 单码查询 变更
export const saveCodeDataChangeEpic = actions$ => actions$.pipe(
  ofType(actions.saveCodeDataChange.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    // const contentType = 'multipart/form-data';

    return ajax({
      ...apiBaseConfig,
      method: 'POST',
      body: data,
      url: `${urls.dataManage.linkTags.linkTags2}`,
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.saveCodeDataChange.SUCCESS(res)
      }),
      catchError(error => of(actions.saveCodeDataChange.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
// // 批量查询 变更
// export const batchCodeDataChangeEpic = actions$ => actions$.pipe(
//   ofType(actions.batchCodeDataChange.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     const { data, callback } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       method: 'POST',
//       body: action.payload,
//       url: `${urls.dataManage.linkTags.linkTagsUpload}`
//     }).pipe(
//       map(res => {
//         // let array = [
//         //   res.response,
//         //   ...state$.value.plan.planAllList,

//         // ];
//         callback && callback()
//         return actions.batchCodeDataChange.SUCCESS(res)
//       }),
//       catchError(error => of(actions.batchCodeDataChange.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
//       )
//     )
//   }
//   )
//   );

// 批量查询 变更
export const batchCodeDataChangeEpic = actions$ => actions$.pipe(
  ofType(actions.batchCodeDataChange.REQUEST.toString()),
  mergeMap(action => {
    const { data, callback } = action.payload;
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: `${urls.dataManage.linkTags.linkTagsUpload}`,
      body: data,
      method: 'POST',
    }).pipe(
      mergeMap(res => {
        callback && callback();
        return [actions.batchCodeDataChange.SUCCESS(res.status), uiActions.notification('导入成功', 'success')]
      }),
      catchError(error => of(actions.batchCodeDataChange.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// 环节数据查看分页
export const loadLinkUploadLogsListEpic = actions$ => actions$.pipe(
  ofType(actions.loadLinkUploadLogsList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { page = 0, size = 20, sort = 'id,desc', linkCode = '', linkType = '', param = [], id, jobNo, isvAccount, operateStartDate, operateEndDate, uploadStartDate, uploadEndDate, fileStatus = '' } = action.payload;
    // let paramStr = param.length ? `&param=${encodeURIComponent(JSON.stringify(param[0]))}` : '';

    let getFormParamsStr = (args = {}) => {
      let str = '';
      Object.entries(args).map(item => {
        if (item[1]) {
          str += `&${item[0]}=${item[1]}`
        }
      })
      return str;
    }
    let paramsStr = getFormParamsStr({ id, jobNo, isvAccount, operateStartDate, operateEndDate, uploadStartDate, uploadEndDate, fileStatus });

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.linkTags.linkUploadLogs}?linkCode=${linkCode}&linkType=${linkType}&page=${page}&size=${size}&sort=${sort}${paramsStr}`,
      method: 'GET'
    }).pipe(
      map(res => actions.loadLinkUploadLogsList.SUCCESS(res.response)),
      catchError(error => of(actions.loadLinkUploadLogsList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 下载
export const exportsEpic = actions$ => actions$.pipe(
  ofType(actions.exports.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.linkTags.linkUploadLogs}/exports/${id}`,
      method: 'GET'
    }).pipe(
      map(res => actions.exports.SUCCESS(res.response)),
      catchError(error => of(actions.exports.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 重试
export const reTryEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.reTry.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, dataJson, check } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.linkTags.linkUploadLogs}`,
      body: action.payload,
      method: 'PUT'
    }).pipe(
      concatMap(res => [actions.reTry.SUCCESS(res.response), actions.loadLinkUploadLogsList.REQUEST(state$.value.codeDataManagement.paging)]),
      catchError(error => of(actions.reTry.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// // 码数据查询列表
// export const getTagDatasEpic = actions$ => actions$.pipe(
//   ofType(actions.getTagDatas.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     const {  page = 0, size = 20, sort = '' } = action.payload;

//     return ajax({
//       ...ajaxBaseConfig,
//       url: `${urls.dataManage.tagDatas.tagDatas}?page=${page}&size=${size}&sort=${sort}`,
//       method: 'POST'
//     }).pipe(
//       map(res => actions.getTagDatas.SUCCESS(res.response)),
//       catchError(error => of(actions.getTagDatas.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )
//   }
//   )
// );
// // 码数据查询移除
// export const deleteTagDatasTagDatasEpic = actions$ => actions$.pipe(
//   ofType(actions.deleteTagDatas.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     const {id, field} = action.payload;

//     return ajax({
//       ...ajaxBaseConfig,
//       url: `${urls.dataManage.tagDatas.tagDatas}/${id}`,
//       method: 'delete',
//       // body: action.payload
//     }).pipe(
//       concatMap(res => [actions.deleteTagDatas.SUCCESS(res.response), actions.getTagDatas.REQUEST({})]),
//       catchError(error => of(actions.deleteTagDatas.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )
//   }
//   )
// );
// 码数据查询
export const postTagDatasEpic = actions$ => actions$.pipe(
  ofType(actions.postTagDatas.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, field, additionalField, linkStatus, page = 0, size = 20, sort = 'ext.upt,desc' } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.tagDatas.tagDatas}?page=${page}&size=${size}&sort=${sort}`,
      body: { field: { ...field, ...additionalField }, additionalField: {} },
      method: 'POST'
    }).pipe(
      concatMap(res => [actions.postTagDatas.SUCCESS(res.response)]),
      // concatMap(res => [actions.postTagDatas.SUCCESS(res.response), actions.getTagDatas.REQUEST({})]),
      catchError(error => of(actions.postTagDatas.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 码数据查询下载
export const exportsTagDatasEpic = actions$ => actions$.pipe(
  ofType(actions.exportsTagDatas.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, field, additionalField, linkStatus } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.tagDatas.tagDatas}/exports/${id}`,
      body: action.payload,
      method: 'GET'
    }).pipe(
      map(res => actions.exportsTagDatas.SUCCESS(res.response)),
      catchError(error => of(actions.exportsTagDatas.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);



// 码数据下载列表
export const getTagDatasExportEpic = actions$ => actions$.pipe(
  ofType(actions.getTagDatasExport.REQUEST.toString()),
  mergeMap(action => {
    actionApi()

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.tagDatas.tagDataLogs}`,
      method: 'GET'
    }).pipe(
      map(res => actions.getTagDatasExport.SUCCESS(res.response)),
      catchError(error => of(actions.getTagDatasExport.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 码数据下载移除
export const deleteTagDatasExportEpic = actions$ => actions$.pipe(
  ofType(actions.deleteTagDatasExport.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, field } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.tagDatas.tagDataLogs}/${id}`,
      method: 'delete',
      // body: action.payload
    }).pipe(
      concatMap(res => [actions.deleteTagDatasExport.SUCCESS(res.response), actions.getTagDatasExport.REQUEST()]),
      catchError(error => of(actions.deleteTagDatasExport.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 码数据下载点击查询按钮
export const postTagDatasExportEpic = actions$ => actions$.pipe(
  ofType(actions.postTagDatasExport.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, field, additionalField, linkStatus } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.tagDatas.tagDataLogs}`,
      body: { field: { ...field, ...additionalField }, additionalField: {} },
      method: 'POST'
    }).pipe(
      concatMap(res => [actions.postTagDatasExport.SUCCESS(res.response), actions.getTagDatasExport.REQUEST()]),
      catchError(error => of(actions.postTagDatasExport.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 码数据下载下载
export const exportsTagDatasExportEpic = actions$ => actions$.pipe(
  ofType(actions.exportsTagDatasExport.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, field, additionalField, linkStatus } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.tagDatas.tagDataLogs}/exports/${id}`,
      body: action.payload,
      method: 'GET'
    }).pipe(
      map(res => actions.exportsTagDatasExport.SUCCESS(res.response)),
      catchError(error => of(actions.exportsTagDatasExport.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 环节数据 批量下载
export const linkBatchDownloadEpic = actions$ => actions$.pipe(
  ofType(actions.linkBatchDownload.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { linkCode, linkType, ids, isvAccount, operateStartDate, operateEndDate } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.linkTags.linkUploadLogs}/batch/download`,
      method: 'POST',
      body: action.payload
    }).pipe(
      map(res => actions.linkBatchDownload.SUCCESS(res.response)),
      catchError(error => of(actions.linkBatchDownload.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 环节数据 分页统计
export const linkStatisticEpic = actions$ => actions$.pipe(
  ofType(actions.linkStatistic.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { page = 0, size = 20, sort = 'id,desc', linkCode = '', linkType = '', param = [], id, jobNo, isvAccount, operateStartDate, operateEndDate, uploadStartDate, uploadEndDate } = action.payload;
    // let paramStr = param.length ? `&param=${encodeURIComponent(JSON.stringify(param[0]))}` : '';

    let getFormParamsStr = (args = {}) => {
      let str = '';
      Object.entries(args).map(item => {
        if (item[1]) {
          str += `&${item[0]}=${item[1]}`
        }
      })
      return str;
    }
    let paramsStr = getFormParamsStr({ id, jobNo, isvAccount, operateStartDate, operateEndDate, uploadStartDate, uploadEndDate });

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.linkTags.linkStatics}?linkCode=${linkCode}&linkType=${linkType}&page=${page}&size=${size}${paramsStr}`,
    }).pipe(
      map(res => actions.linkStatistic.SUCCESS(res.response)),
      catchError(error => of(actions.linkStatistic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// linkFileCenterDownload
export const linkFileCenterDownload = actions$ => actions$.pipe(
  ofType(actions.linkFileCenterDownload.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id = '', remark } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.linkTags.linkUploadV1Logs}/exports/${id}`,
      method: 'POST',
      body: {
        remark
      }
    }).pipe(
      map(res => actions.linkFileCenterDownload.SUCCESS(res.response)),
      catchError(error => of(actions.linkFileCenterDownload.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 获取码类型数据
export const getDecodeDiTypeEpic = actions$ => actions$.pipe(
  ofType(actions.getDecodeDiType.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.tagTypes.tagTypes}`,
      method: 'GET',
    }).pipe(
      map(res => {

        let response = callback && callback(res.response);
        return actions.getDecodeDiType.SUCCESS(res.response)
      }),
      catchError(error => { console.log(error); return of(actions.getDecodeDiType.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)) })
    )
  }
  )
);