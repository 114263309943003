import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'zone';

export const getMemberTag = fasterActions(moduleName, 'getMemberTag');

export const getGroupSpeciesLabel = fasterActions(moduleName, 'getGroupSpeciesLabel'); // 过渡用
// 基础标签
export const getBasicLabelData = fasterActions(moduleName, 'getBasicLabelData');
export const enableBasicLabel = fasterActions(moduleName, 'enableBasicLabel');

// 自定义标签
export const getUserDefinedLabelData = fasterActions(moduleName, 'getUserDefinedLabelData');
export const postUserDefinedLabel = fasterActions(moduleName, 'postUserDefinedLabel');
export const patchUserDefinedLabel = fasterActions(moduleName, 'patchUserDefinedLabel');
export const getUserDefinedDetail = fasterActions(moduleName, 'getUserDefinedDetail');
export const tagTaskUserDefinedLabel = fasterActions(moduleName, 'tagTaskUserDefinedLabel');
export const groupTaskUserDefinedLabel = fasterActions(moduleName, 'groupTaskUserDefinedLabel');
export const cancelGroupTaskUserDefinedLabel = fasterActions(moduleName, 'cancelGroupTaskUserDefinedLabel');
export const enableUserDefinedLabel = fasterActions(moduleName, 'enableUserDefinedLabel');
export const deleteGroupUserDefinedLabel = fasterActions(moduleName, 'deleteGroupUserDefinedLabel');
export const getMemberTagAttr = fasterActions(moduleName, 'getMemberTagAttr');
export const getMemberTagAttrGroup = fasterActions(moduleName, 'getMemberTagAttrGroup');


// upstate
export const updateUserDefinedStep = createAction(`${moduleName}/updateUserDefinedStep`);


// 手动标签
export const changeSearchInput = createAction(`${moduleName}/changeSearchInput`);
export const getManualTagTable = fasterActions(moduleName, 'getManualTagTable');
export const enableOrDisabledManualTag = fasterActions(moduleName, 'enableOrDisabledManualTag');
export const postManualTagGroup = fasterActions(moduleName, 'postManualTagGroup');
export const editManualTag = fasterActions(moduleName, 'editManualTag');
export const patchManualTagGroup = fasterActions(moduleName, 'patchManualTagGroup');
export const getManualTagInfo = fasterActions(moduleName, 'getManualTagInfo');
export const uploadManual = fasterActions(moduleName, 'uploadManual');
export const cancelTagLabel = fasterActions(moduleName, 'cancelTagLabel');
export const importHistory = fasterActions(moduleName, 'importHistory');

//第三方标签
export const getThirdTagTable = fasterActions(moduleName, 'getThirdTagTable');
export const postThirdTag = fasterActions(moduleName, 'postThirdTag');
export const uploadThird = fasterActions(moduleName, 'uploadThird');
export const importThirdHistory = fasterActions(moduleName, 'importThirdHistory');
export const downLoadThird = fasterActions(moduleName, 'downLoadThird');
//运算标签组


export const getOperationTagData = fasterActions(moduleName, 'getOperationTagData');
export const getOperationTagById = fasterActions(moduleName, 'getOperationTagById');
export const postOperationTag = fasterActions(moduleName, 'postOperationTag');
export const putOperationTag = fasterActions(moduleName, 'putOperationTag');
export const delOperationTag = fasterActions(moduleName, 'delOperationTag');
export const cancelOperationTag = fasterActions(moduleName, 'cancelOperationTag');
export const enableOperationTag = fasterActions(moduleName, 'enableOperationTag');
export const loadOperationTagHistoriesById = fasterActions(moduleName, 'loadOperationTagHistoriesById');
export const loaTagHistories = fasterActions(moduleName, 'loaTagHistories');

//新增标签
export const addTag = fasterActions(moduleName, 'addTag');
export const delTag = fasterActions(moduleName, 'delTag');
export const sortTag = fasterActions(moduleName, 'sortTag');
export const enabelTag = fasterActions(moduleName, 'enabelTag');
export const publishTag = fasterActions(moduleName, 'publishTag');


//清空数据
export const unmountOperationTag = fasterActions(moduleName, 'unmountOperationTag');
export const unmountOperationTagHistroy = fasterActions(moduleName, 'unmountOperationTagHistroy');

const initialPoolState = {
    memberTagList: [],
    memberTagListTwoTree: [],
    basicTagData: [],
    groupSpecies: '',
    // 自定义标签
    userDefinedLabelData: [],
    userDefinedStep: 0,
    memberTagAttrGroup: [],
    memberTagAttrList: [], // 会员维度标签属性
    promotionTagAttrList: [], // 活动维度标签属性
    memberTagAttrGroupList: [],
    userDefinedDetail: {},
    // 手动标签
    manualTagTable: [],

    importHistoryList: [],
    importPaging: { page: 0, size: 20 },
    //运算标签
    operationTagData: [],
    operationTagAll: {},

    operationTagHistory: '',
    //第三方标签
    importThirdHistoryList: [],
    thirdTagTable: [],
}

// Reducer
const reducer = handleActions(
    {
        [getMemberTag.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [getMemberTag.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, memberTagList: payload.list, memberTagListTwoTree: payload.list2 }),
        [getMemberTag.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),

        [getGroupSpeciesLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload, loading: true }),
        [getGroupSpeciesLabel.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, basicTagData: payload.content }),
        [getGroupSpeciesLabel.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),

        // 标准标签
        [getBasicLabelData.REQUEST]: (state, { payload }) => ({ ...state, ...payload, loading: true }),
        [getBasicLabelData.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, basicTagData: payload.content }),
        [getBasicLabelData.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),

        [enableBasicLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload, basicEnableing: true }),
        [enableBasicLabel.SUCCESS]: (state, { payload }) => ({ ...state, basicEnableing: false }),
        [enableBasicLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload, basicEnableing: false }),

        // 自定义标签
        // 获取列表
        [getUserDefinedLabelData.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
        [getUserDefinedLabelData.SUCCESS]: (state, { payload }) => ({ ...state, userDefinedLabelData: payload.content || [] }),
        [getUserDefinedLabelData.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        // 创建
        [postUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload, changeUserDefinedLoading: true }),
        [postUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state, changeUserDefinedLoading: false }),
        [postUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, changeUserDefinedLoading: true, error: payload }),
        // 获取详情
        [getUserDefinedDetail.REQUEST]: (state, { payload }) => ({ ...state, ...payload, userDefinedDetailLoading: true }),
        [getUserDefinedDetail.SUCCESS]: (state, { payload }) => ({ ...state, userDefinedDetail: payload || {}, userDefinedDetailLoading: false }),
        [getUserDefinedDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload, userDefinedDetailLoading: false }),

        // 更新标签组
        [groupTaskUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
        [groupTaskUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state }),
        [groupTaskUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        // 撤回更新标签组
        [cancelGroupTaskUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
        [cancelGroupTaskUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state }),
        [cancelGroupTaskUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        // 删除标签组
        [deleteGroupUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
        [deleteGroupUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state }),
        [deleteGroupUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        // 禁用，启用
        [enableUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
        [enableUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state }),
        [enableUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        // (详情页面接口)
        // 更新标签
        [tagTaskUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
        [tagTaskUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state }),
        [tagTaskUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        // 修改标签组
        [patchUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, changeUserDefinedLoading: true, ...payload }),
        [patchUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state, changeUserDefinedLoading: false }),
        [patchUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, changeUserDefinedLoading: true, error: payload }),

        // [getMemberTagAttr.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
        // [getMemberTagAttr.SUCCESS]: (state, { payload }) => ({ ...state, memberTagAttrList: payload.content || [] }),
        // [getMemberTagAttr.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [getMemberTagAttrGroup.REQUEST]: (state, { payload }) => ({ ...state, ...payload, getMemberTagAttrGroupLoading: true }),
        [getMemberTagAttrGroup.SUCCESS]: (state, { payload }) => {
            let selector = [];
            if (payload) {
                for (let elem of payload.values()) {
                    if (elem.parentId) {
                        elem.parentid = elem.parentId;
                        delete elem.parentId;
                    }
                    selector.push({ ...elem, value: elem.id, text: elem.name });
                }
            }
            if (selector[0].dimension == 'PROMOTION') {
                return ({ ...state, memberTagAttrGroupList: selector, promotionTagAttrList: selector, getMemberTagAttrGroupLoading: false });
            } else {
                return ({ ...state, memberTagAttrGroupList: selector, memberTagAttrList: selector, getMemberTagAttrGroupLoading: false });
            }
        },
        [getMemberTagAttrGroup.FAIL]: (state, { payload }) => ({ ...state, error: payload, getMemberTagAttrGroupLoading: false }),

        [updateUserDefinedStep]: (state, { payload }) => ({ ...state, ...payload }),

        /* 手动标签 */
        [changeSearchInput]: (state, { payload }) => ({ ...state, importPaging: payload }),
        [getManualTagTable.REQUEST]: (state, { payload }) => ({ ...state, manualLoading: true }),
        [getManualTagTable.SUCCESS]: (state, { payload }) => {
            const { content = [] } = payload;
            content.map(item => {
                item.text = item.name;
                item.value = item.id;
                return item;
            })
            return { ...state, manualLoading: false, manualTagTable: content }
        },
        [getManualTagTable.FAIL]: (state, { payload }) => ({ ...state, manualLoading: false, error: payload }),
        // 禁用、启用手动标签
        [enableOrDisabledManualTag.REQUEST]: (state, { payload }) => ({ ...state, }),
        [enableOrDisabledManualTag.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [enableOrDisabledManualTag.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        // 创建标签组
        [postManualTagGroup.REQUEST]: (state, { payload }) => ({ ...state, }),
        [postManualTagGroup.SUCCESS]: (state, { payload }) => ({ ...state }),
        [postManualTagGroup.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        // 修改标签
        [editManualTag.REQUEST]: (state, { payload }) => ({ ...state }),
        [editManualTag.SUCCESS]: (state, { payload }) => ({ ...state }),
        [editManualTag.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        // 更新标签组
        [patchManualTagGroup.REQUEST]: (state, { payload }) => ({ ...state }),
        [patchManualTagGroup.SUCCESS]: (state, { payload }) => ({ ...state }),
        [patchManualTagGroup.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        // 获取标签详情
        [getManualTagInfo.REQUEST]: (state, { payload }) => ({ ...state }),
        [getManualTagInfo.SUCCESS]: (state, { payload }) => ({ ...state }),
        [getManualTagInfo.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        // 导入
        [uploadManual.REQUEST]: (state, { payload }) => ({ ...state }),
        [uploadManual.SUCCESS]: (state, { payload }) => ({ ...state }),
        [uploadManual.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        // 撤回
        [cancelTagLabel.REQUEST]: (state, { payload }) => ({ ...state }),
        [cancelTagLabel.SUCCESS]: (state, { payload }) => ({ ...state }),
        [cancelTagLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        // 导入记录
        [importHistory.REQUEST]: (state, { payload }) => ({ ...state, importLoading: true }),
        [importHistory.SUCCESS]: (state, { payload }) => {
            const { content = [], totalElements, size, number, query = '' } = payload;
            return { ...state, importHistoryList: content, importTotalElements: totalElements, importLoading: false, importPaging: { size, page: number, query } }
        },
        [importHistory.FAIL]: (state, { payload }) => ({ ...state, error: payload, importLoading: false }),


        //第三方标签
        [getThirdTagTable.REQUEST]: (state, { payload }) => ({ ...state, thirdLoading: true }),
        [getThirdTagTable.SUCCESS]: (state, { payload }) => {
            const { content = [] } = payload;
            content.map(item => {
                item.text = item.name;
                item.value = item.code;
                return item;
            })
            return { ...state, thirdLoading: false, thirdTagTable: content }
        },
        [getThirdTagTable.FAIL]: (state, { payload }) => ({ ...state, thirdLoading: false, error: payload }),

        [postThirdTag.REQUEST]: (state, { payload }) => ({ ...state, postThirdTagLoading: true }),
        [postThirdTag.SUCCESS]: (state, { payload }) => ({ ...state, postThirdTagLoading: false }),
        [postThirdTag.FAIL]: (state, { payload }) => ({ ...state, error: payload, postThirdTagLoading: false }),

        // 导入
        [uploadThird.REQUEST]: (state, { payload }) => ({ ...state, uploadThirdLoading: true }),
        [uploadThird.SUCCESS]: (state, { payload }) => ({ ...state, uploadThirdLoading: false }),
        [uploadThird.FAIL]: (state, { payload }) => ({ ...state, error: payload, uploadThirdLoading: false }),

        // 导入记录
        [importThirdHistory.REQUEST]: (state, { payload }) => ({ ...state, importHistoryLoading: true }),
        [importThirdHistory.SUCCESS]: (state, { payload }) => {
            return { ...state, importThirdHistoryList: payload, importHistoryLoading: false, }
        },
        [importThirdHistory.FAIL]: (state, { payload }) => ({ ...state, error: payload, importHistoryLoading: false }),

        [downLoadThird.REQUEST]: (state, { payload }) => ({ ...state, }),
        [downLoadThird.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [downLoadThird.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),


        //运算标签
        [getOperationTagData.REQUEST]: (state, { payload }) => ({ ...state, getOperationTagDataLoading: true, ...payload }),
        [getOperationTagData.SUCCESS]: (state, { payload }) => ({ ...state, getOperationTagDataLoading: false, operationTagData: payload || [] }),
        [getOperationTagData.FAIL]: (state, { payload }) => ({ ...state, getOperationTagDataLoading: false, error: payload }),

        [getOperationTagById.REQUEST]: (state, { payload }) => ({ ...state, }),
        [getOperationTagById.SUCCESS]: (state, { payload }) => ({ ...state, operationTagAll: payload || {} }),
        [getOperationTagById.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [putOperationTag.REQUEST]: (state, { payload }) => ({ ...state, putTagLoading: true, }),
        [putOperationTag.SUCCESS]: (state, { payload }) => ({ ...state, putTagLoading: false, }),
        [putOperationTag.FAIL]: (state, { payload }) => ({ ...state, error: payload, putTagLoading: false, }),

        [postOperationTag.REQUEST]: (state, { payload }) => ({ ...state, putTagLoading: true, }),
        [postOperationTag.SUCCESS]: (state, { payload }) => ({ ...state, putTagLoading: false, }),
        [postOperationTag.FAIL]: (state, { payload }) => ({ ...state, error: payload, putTagLoading: false }),

        [delOperationTag.REQUEST]: (state, { payload }) => ({ ...state, }),
        [delOperationTag.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [delOperationTag.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [cancelOperationTag.REQUEST]: (state, { payload }) => ({ ...state, }),
        [cancelOperationTag.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [cancelOperationTag.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [enableOperationTag.REQUEST]: (state, { payload }) => ({ ...state, }),
        [enableOperationTag.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [enableOperationTag.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [loadOperationTagHistoriesById.REQUEST]: (state, { payload }) => ({ ...state, loadOperationTagHistoriesByIdLoading: true }),
        [loadOperationTagHistoriesById.SUCCESS]: (state, { payload }) => ({ ...state, loadOperationTagHistoriesByIdLoading: false, loadOperationTagHistoriesByIdLoaded: true, operationTagHistories: payload }),
        [loadOperationTagHistoriesById.FAIL]: (state, { payload }) => ({ ...state, loadOperationTagHistoriesByIdLoading: false, loadOperationTagHistoriesByIdLoaded: false, error: payload }),

        [loaTagHistories.REQUEST]: (state, { payload }) => ({ ...state, }),
        [loaTagHistories.SUCCESS]: (state, { payload }) => ({ ...state, operationTagHistory: payload }),
        [loaTagHistories.FAIL]: (state, { payload }) => ({ ...state, error: payload }),




        [addTag.REQUEST]: (state, { payload }) => ({ ...state, }),
        [addTag.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [addTag.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [delTag.REQUEST]: (state, { payload }) => ({ ...state, }),
        [delTag.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [delTag.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [sortTag.REQUEST]: (state, { payload }) => ({ ...state, }),
        [sortTag.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [sortTag.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [enabelTag.REQUEST]: (state, { payload }) => ({ ...state, }),
        [enabelTag.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [enabelTag.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [publishTag.REQUEST]: (state, { payload }) => ({ ...state, }),
        [publishTag.SUCCESS]: (state, { payload }) => ({ ...state }),
        [publishTag.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [unmountOperationTag.REQUEST]: (state, { payload }) => ({
            ...state,
            operationTagAll: {},
        }),
        [unmountOperationTagHistroy.REQUEST]: (state, { payload }) => ({
            ...state,
            operationTagHistory: '',
        }),
    },
    initialPoolState
)
export default reducer;