import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './contractInfomation';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 合同管理
 */

// 合同类型列表
export const contractTypeListEpic = actions$ => actions$.pipe(
    ofType(actions.contractTypeList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page, size, query = '' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.contract.contractType}?page=${page}&size=${size}&query=${query}`
        }).pipe(
            map(res => actions.contractTypeList.SUCCESS(res.response)),
            catchError(error => of(actions.contractTypeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 禁用、启用
export const enableOrDisabledContractTypeEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableOrDisabledContractType.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id = '', status = '' } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.contract.contractType}/${id}/status?status=${status}`,
                method: 'PATCH',
            }).pipe(
                concatMap(res => [actions.enableOrDisabledContractType.SUCCESS(res.response), actions.contractTypeList.REQUEST(state$.value.contractInfomation.contractPaging)]),
                catchError(error => of(actions.enableOrDisabledContractType.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 表单详情
export const contractTypeInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.contractTypeInfo.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { typeId = '', callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.contract.contractType}/${typeId}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.contractTypeInfo.SUCCESS(res.response);
                }),
                catchError(error => of(actions.contractTypeInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 创建合同
export const createContractTypeEpic = actions$ => actions$.pipe(
    ofType(actions.createContractType.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                method: data.id ? 'PUT' : 'POST',
                url: `${urls.data.contract.contractType}`,
                body: action.payload.data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.createContractType.SUCCESS(res.response);
                }),
                catchError(error => of(actions.createContractType.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 合同数据
export const contractDataListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.contractDataList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page, size, name = '' } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.contract.contractData}?page=${page}&size=${size}&name=${name}`,
            }).pipe(
                map(res => actions.contractDataList.SUCCESS(res.response)),
                catchError(error => of(actions.contractDataList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 合同数据查看详情
export const contractDataInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.contractDataInfo.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.contract.contractData}/${id}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.contractDataInfo.SUCCESS(res.response);
                }),
                catchError(error => of(actions.contractDataInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);


// selectiong
export const contractSelectionsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.contractSelections.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.contract.selection}`,
            }).pipe(
                map(res => {
                    return actions.contractSelections.SUCCESS(res.response);
                }),
                catchError(error => of(actions.contractSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 创建合同数据
export const createContractDataEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.createContractData.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                method: 'POST',
                url: `${urls.data.contract.contractData}`,
                body: action.payload.data,
            }).pipe(
                concatMap(res => {
                    callback && callback(res.response);
                    return [actions.createContractData.SUCCESS(res.response),actions.contractDataList.REQUEST(state$.value.contractInfomation.contractDataPaging)];
                }),
                catchError(error => of(actions.createContractData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

