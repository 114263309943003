import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './fileds';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 产品分类
 */

// 产品字段查询分页查询
export const prodcutFiledssEpic = actions$ => actions$.pipe(
  ofType(actions.prodcutFileds.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { keywords = '', page, size } = action.payload;
    let url = `${urls.data.fileds.fileds}?page=${page}&size=${size}&sort=id,desc&name=${keywords}`;

    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.prodcutFileds.SUCCESS(res.response)),
      catchError(error => of(actions.prodcutFileds.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 字段选框
export const prodcutFiledsSelectionsEpic = actions$ => actions$.pipe(
  ofType(actions.prodcutFiledsSelections.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { status } = action.payload || {};
    return ajax({
      ...apiBaseConfig,
      url: `${urls.data.fileds.fileds}/selections?status=${status}`,
    }).pipe(
      map(res => actions.prodcutFiledsSelections.SUCCESS(res.response)),
      catchError(error => of(actions.prodcutFiledsSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 字段查询（id查询）
export const prodcutFiledsByIdEpic = actions$ => actions$.pipe(
  ofType(actions.prodcutFiledsById.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id } = action.payload || {};
    return ajax({
      ...apiBaseConfig,
      url: `${urls.data.fileds.fileds}/${id}`,
    }).pipe(
      map(res => actions.prodcutFiledsById.SUCCESS(res.response)),
      catchError(error => of(actions.prodcutFiledsById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// 创建/编辑 产品字段
export const prodcutFiledsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.prodcutFiledsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      method: action.payload.id ? 'PUT' : 'POST',
      url: `${urls.data.fileds.fileds}`,
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.prodcutFiledsModify.SUCCESS(res.status), uiActions.notification(action.payload.id ? '修改成功' : '创建成功', 'success')]),
      catchError(error => of(actions.prodcutFiledsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 禁用/启用 产品字段
export const prodcutFiledsStatusEpic = actions$ => actions$.pipe(
  ofType(actions.prodcutFiledsStatus.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id, status } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.data.fileds.fileds}/${id}/status?status=${status}`,
      body: action.payload
    }).pipe(
      map(res => actions.prodcutFiledsStatus.SUCCESS(res.status)),
      catchError(error => of(actions.prodcutFiledsStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 设置字段枚举
export const prodcutFiledsEnumsEpic = actions$ => actions$.pipe(
  ofType(actions.prodcutFiledsEnums.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id, enums } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.data.fileds.fileds}/${id}/enums`,
      body: enums
    }).pipe(
      mergeMap(res => [actions.prodcutFiledsEnums.SUCCESS(res.status), uiActions.notification('添加成功', 'success')]),
      catchError(error => of(actions.prodcutFiledsEnums.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);