import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';


const moduleName = 'pointMallOrder';

/**
 * TODO: 订单管理
 */

export const pointMallOrder = fasterActions(moduleName, 'pointMallOrder');
export const pointMallOrderCount = fasterActions(moduleName, 'pointMallOrderCount');

export const pointMallOrderExport = fasterActions(moduleName, 'pointMallOrderExport');
export const pointMallOrderRemark = fasterActions(moduleName, 'pointMallOrderRemark');

export const pointMallOrderById = fasterActions(moduleName, 'pointMallOrderById');
export const pointMallOrderDetail = fasterActions(moduleName, 'pointMallOrderDetail');
export const pointMallOrderThirdDetail = fasterActions(moduleName, 'pointMallOrderThirdDetail');
export const pointMallOrderRegLogistics = fasterActions(moduleName, 'pointMallOrderRegLogistics');




const initialState = {

}

const reducer = handleActions(
  {

    [pointMallOrder.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [pointMallOrder.SUCCESS]: (state, { payload }) => ({ ...state, pointMallOrderList: payload, loading: false }),
    [pointMallOrder.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [pointMallOrderCount.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [pointMallOrderCount.SUCCESS]: (state, { payload }) => ({ ...state, pointMallOrderCount: payload }),
    [pointMallOrderCount.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [pointMallOrderExport.REQUEST]: (state, { payload }) => ({ ...state, payload, pointMallOrderExportLoaded: false }),
    [pointMallOrderExport.SUCCESS]: (state, { payload }) => ({ ...state, pointMallOrderExportLoaded: payload }),
    [pointMallOrderExport.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [pointMallOrderRemark.REQUEST]: (state, { payload }) => ({ ...state, payload, pointMallOrderRemarkLoaded: false }),
    [pointMallOrderRemark.SUCCESS]: (state, { payload }) => ({ ...state, pointMallOrderRemarkLoaded: payload }),
    [pointMallOrderRemark.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [pointMallOrderById.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [pointMallOrderById.SUCCESS]: (state, { payload }) => ({ ...state, pointMallOrderById: payload }),
    [pointMallOrderById.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    

    [pointMallOrderDetail.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [pointMallOrderDetail.SUCCESS]: (state, { payload }) => ({ ...state, pointMallOrderDetailList: payload, loading: false }),
    [pointMallOrderDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [pointMallOrderThirdDetail.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [pointMallOrderThirdDetail.SUCCESS]: (state, { payload }) => ({ ...state, pointMallOrderThirdDetail: payload, loading: false }),
    [pointMallOrderThirdDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [pointMallOrderRegLogistics.REQUEST]: (state, { payload }) => ({ ...state, payload, pointMallOrderRegLogisticsLoaded: false }),
    [pointMallOrderRegLogistics.SUCCESS]: (state, { payload }) => ({ ...state, pointMallOrderRegLogisticsLoaded: payload }),
    [pointMallOrderRegLogistics.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    
  },

  initialState
);

export default reducer;
