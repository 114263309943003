import React, { } from 'react';
import { AppRouter, AppRoute } from '@ice/stark';
import { PageLoading } from 'tyb';
import queryString from 'query-string';


const { apps: mockAppsConfig = [] } = require('../../mock/apps'); // 本地调试用
const parsed = queryString.parse(location.search);


/**
 * 注册本地子应用
 * _appname_ 子应用基准路由
 * _appprod_ 子应用端口号
 *  
 * */
mockAppsConfig.forEach((val) => {
    const newVal = val;
    newVal.entry = `${val.entry}?d=${+new Date()}`;
    if(parsed._appname_ && parsed._appname_ === newVal.activePath.split('/')[1]) {
        newVal.entry = `http://localhost:${parsed?._appprod_ || '8080'}/index-system.html#/`;
    }
});
console.log(mockAppsConfig, '主应用——mockAppsConfig');


function BasicLayout({ children }) {
    return (
        <div>
            {/* 必须渲染 layout 用来渲染微前端的结构 */}
            {children}
        </div>
    );
}


function FrameworkLayout() {
    return (
        <>
            <BasicLayout>
                <AppRouter
                    NotFoundComponent={()=> (<div></div>)}
                    LoadingComponent={PageLoading}
                >
                    {
                        mockAppsConfig.map((item) => {
                            // console.log(item, 'item')
                            return (
                                <AppRoute
                                    key={item.activePath}
                                    activePath={item.activePath}
                                    path={item.path}
                                    title={item.title}
                                    entry={`${item.entry}`}
                                    umd={true}
                                    sandbox={true}
                                    hashType={true}
                                />
                            );
                        })
                    }
                </AppRouter>
            </BasicLayout>
        </>
    );
}
export default FrameworkLayout;