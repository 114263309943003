import { ajax as ajaxJson } from 'rxjs/ajax';
import md5 from '@/libs/md5.js'
import C from 'crypto-js';
import { sortAsc, sortUrlAsc, getKeyValue } from './utils'
import hmacSha256 from 'crypto-js/hmac-sha256';

// 是否开放验证签名功能 --防止数据越权
// 签名规则 --_method='大写请求方式'&_uri='api-gateway/后的请求路径'&_timestamp='后端定义时间格式'&_body={body}&'url上的请求参数拼接formData的请求参数并进行ASCII码排序'
// 签名进行md5加密,并且进行hmac256加密
// 请求头加上signature,以及timestamp
let openSignature = false;
// 后端秘钥 --放前端其实不安全，最好通过后端私钥加密传递，再通过公钥解密，放在前端可以藏在某个第三方包中，和压缩代码混合，不便于人家查找
let backEndKey = 'Kqj6b22MfyCRVXdTs2PwanBDcIO9ReUPcnyxkvcuZMw=';

export function ajax(config) {
    if (!openSignature) {
        config.url = encodeURI(config.url);
        return ajaxJson(config);
    }

    return handleAjax(config);

}

export function handleAjax(config) {

    let signatureObj = getSignature(config);
    // 验证

    config.headers['ucode-api-signature'] = signatureObj.signature;
    config.headers['ucode-api-timestamp'] = signatureObj.timestamp;
    config.url = signatureObj.newUrl;

    return ajaxJson(config)
}

export function getSignature(config) {
    const { method = 'GET', url, body = {}, formdata = {} } = config;
    // console.log('config', config)
    let uri = url.split('?')[0].match(/\/api-gateway\/[\w\W|\/|\-]+/g)[0].replace(/\/api-gateway/g, '').replace(/\/\//g, '/');

    // let uri = url.split('?')[0].match(/8082\/[\w\W|\/|\-]+/g)[0].replace(/8082/g,'').replace(/\/\//g,'/');
    let timestamp = +new Date() + 1000 * 60 * 60 * 8;
    let timestamp_Three = String(timestamp).slice(-3);
    let toGMTString = new Date(timestamp).toGMTString();

    timestamp = toGMTString.split(' GMT')[0];
    timestamp = `${timestamp} ${timestamp_Three} GMT+08:00`;

    let paramsObj = {};
    paramsObj = getKeyValue(url);

    let urlParamsObj = { ...paramsObj };

    let newUrl = config.url.split('?')[0] + `${sortUrlAsc(urlParamsObj) ? '?' + sortUrlAsc(urlParamsObj) : ''}`;

    // formData和get请求参数合并 传入签名时按照ASCII码表排序
    // console.log('body', body, Object.prototype.toString.call(body))
    if (Object.prototype.toString.call(body) === '[object FormData]') {

        for (var pair of body.entries()) {
            if (pair[0] === 'file') {
                continue;
            }
            paramsObj[pair[0]] = pair[1];
        }
    }
 
    if (Object.keys(formdata).length > 0) {
        for (var pair of Object.entries(formdata)) {
            paramsObj[pair[0]] = pair[1];
        }
    }
    let _body = body;

    if (JSON.stringify(body) === '{}' || method.toUpperCase() === 'GET') {
        _body = '';
    } else {
        if (typeof _body === 'string') {
            _body = body;
        } else {
            _body = JSON.stringify(body)
        };
    }

    let signature = `_method=${method.toUpperCase()}&_uri=${uri}&_timestamp=${timestamp}&_body=${_body}${sortAsc(paramsObj)}`;

    let signature1 = signature;
    signature = C.enc.Base64.stringify(hmacSha256(md5(signature), backEndKey));

    return { signature, timestamp, newUrl, signature1 }
}