import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';


const moduleName = 'miniProgramRelease';

/**
 * TODO: 微信管理
 */

export const develop = fasterActions(moduleName, 'develop');
export const experience = fasterActions(moduleName, 'experience');
export const release = fasterActions(moduleName, 'release');
export const experienceCount = fasterActions(moduleName, 'experienceCount');
export const submitRelease = fasterActions(moduleName, 'submitRelease');
export const submitAuditRelease = fasterActions(moduleName, 'submitAuditRelease');
export const undocodeAudit = fasterActions(moduleName, 'undocodeAudit');
export const releaseLog = fasterActions(moduleName, 'releaseLog');




const initialState = {

}

const reducer = handleActions(
  {
    [develop.SUCCESS]: (state, { payload }) => ({ ...state, developList: payload }),
    [develop.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [experience.REQUEST]: (state, { payload }) => ({ ...state, payload, publishExperience: false }),
    [experience.SUCCESS]: (state, { payload }) => {
      let experienceList = state.experienceList;
      let publishExperience = state.publishExperience;
      if (payload) {
        experienceList = payload;
      } else {
        publishExperience = 200;
      }

      return ({ ...state, experienceList, publishExperience });
    },
    [experience.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [release.SUCCESS]: (state, { payload }) => {
     return  ({ ...state, releaseList: payload })},
    [release.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [submitRelease.REQUEST]: (state, { payload }) => ({ ...state, payload, submitReleaseLoaded: false }),
    [submitRelease.SUCCESS]: (state, { payload }) => ({ ...state, submitReleaseLoaded: payload }),
    [submitRelease.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



    [experienceCount.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [experienceCount.SUCCESS]: (state, { payload }) => ({ ...state, _experienceCount: payload }),
    [experienceCount.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [submitAuditRelease.REQUEST]: (state, { payload }) => ({ ...state, payload, auditReleaseLoaded: false }),
    [submitAuditRelease.SUCCESS]: (state, { payload }) => ({ ...state, auditReleaseLoaded: payload }),
    [submitAuditRelease.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [undocodeAudit.REQUEST]: (state, { payload }) => ({ ...state, payload, auditReleaseLoaded: false }),
    [undocodeAudit.SUCCESS]: (state, { payload }) => ({ ...state, auditReleaseLoaded: payload }),
    [undocodeAudit.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [releaseLog.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [releaseLog.SUCCESS]: (state, { payload }) => ({ ...state, releaseLogLoaded: payload }),
    [releaseLog.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    
  },

  initialState
);

export default reducer;
