const moduleName = 'backend';

const initialState = {
  
}

// Reducer
export default function reducer(state = initialState, action) {
  return state;
}
