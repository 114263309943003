import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'security';


export const loadList = fasterActions(moduleName, 'loadList');
export const loadById = fasterActions(moduleName, 'loadById');
export const editSecurity = fasterActions(moduleName, 'editSecurity');
export const deleteSecurity = fasterActions(moduleName, 'deleteSecurity');
export const securityPause = fasterActions(moduleName, 'securityPause');
export const securityResume = fasterActions(moduleName, 'securityResume');

export const publishSecurity = fasterActions(moduleName, 'publishSecurity');
export const loadPromotionHistoriesById = fasterActions(moduleName, 'loadPromotionHistoriesById');
//新增方案
export const addLinkPlan = fasterActions(moduleName, 'addLinkPlan');
export const copyPlan = fasterActions(moduleName, 'copyPlan');
//排序方案

export const sortLinkPlan = fasterActions(moduleName, 'sortLinkPlan');
//方案不分页
export const linkPlanSelect = fasterActions(moduleName, 'linkPlanSelect');
export const getPlanDetail = fasterActions(moduleName, 'getPlanDetail');

//搜索特定分组
export const linkPlanTypeValue = fasterActions(moduleName, 'linkPlanTypeValue');

export const linkPlanCheckRule = fasterActions(moduleName, 'linkPlanCheckRule');
//清空数据
export const unmount = fasterActions(moduleName, 'unmount');

export const codeSingleDown = fasterActions(moduleName, 'codeSingleDown');
// 防伪异步下载
export const SecurityDetailDown = fasterActions(moduleName, 'SecurityDetailDown');


export const loadPriorities = fasterActions(moduleName, 'loadPriorities');
export const updatePriorities = fasterActions(moduleName, 'updatePriorities');

// 防伪明细新加
export const secActivitySelector = fasterActions(moduleName, 'secActivitySelector');
export const securityDetail = fasterActions(moduleName, 'securityDetail');

//防伪明细-单码统计
export const codeSingleList = fasterActions(moduleName, 'codeSingleList');

export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);


const INIT_BYID = `${moduleName}/INIT_BYID`;

export function initByid() {
    return { type: INIT_BYID };
}


const initialPoolState = {
    loadinglist: false,
    byId: {},
    byIdLoaded: false,

    linkPlanRule: [],
    linkPlanfilterValue: '',
    pageParams: {
        page: 0,
        size: 20,
        startTime: '',
        endTime: ''
    },
    loading: false,
    secActivitySelectorData: [],
    pageData: {},
    codeList: {},

}

// Reducer
const reducer = handleActions(
    {
        // 防伪明细新加
        [changeDictPaging]: (state, { payload }) => {
            return { ...state, pageParams: { ...payload } }
        },

        [codeSingleDown.REQUEST]: (state, { payload }) => ({ ...state,  Loading: true }),
        [codeSingleDown.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [codeSingleDown.FAIL]: (state, { payload }) => ({ ...state, oLoading: false, error: payload }),

        [SecurityDetailDown.REQUEST]: (state, { payload }) => ({ ...state,  Loading: true }),
        [SecurityDetailDown.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [SecurityDetailDown.FAIL]: (state, { payload }) => ({ ...state, oLoading: false, error: payload }),

        [secActivitySelector.REQUEST]: (state, { payload }) => ({ ...state, secActivitySelectorData: {} }),
        [secActivitySelector.SUCCESS]: (state, { payload }) => ({ ...state, secActivitySelectorData: payload }),
        [secActivitySelector.FAIL]: (state, { payload }) => ({ ...state }),

        [securityDetail.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
        [securityDetail.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
        [securityDetail.FAIL]: (state, { payload }) => ({ ...state, loading: false }),


        [INIT_BYID]: (state, { payload }) => ({ ...state, byId: {}, history: {}, historyStep0: '', historyStep1: '', historyStep2: '', historyStep3: '' }),

        [loadList.REQUEST]: (state, { payload }) => ({ ...state, loadinglist: true }),
        [loadList.SUCCESS]: (state, { payload }) => ({ ...state, loadinglist: false, listLoaded: true, list: payload }),
        [loadList.FAIL]: (state, { payload }) => ({ ...state, loadinglist: false, listLoaded: false, error: payload }),

        [codeSingleList.REQUEST]: (state, { payload }) => ({ ...state, loadinglist: true }),
        [codeSingleList.SUCCESS]: (state, { payload }) => ({ ...state, loadinglist: false, listLoaded: true, codeList: payload }),
        [codeSingleList.FAIL]: (state, { payload }) => ({ ...state, loadinglist: false, listLoaded: false, error: payload }),

        [loadById.REQUEST]: (state, { payload }) => ({ ...state, byIdLoaded: true }),
        [loadById.SUCCESS]: (state, { payload }) => {
            let data = {
                PRODUCT: [],
                PARTICIPATING_DEALERS: [],
                PARTICIPATING_SHOP: [],
                PARTICIPATING_SALES_AREA: [],
                MARKETING_CHANNEL: [],
                CODE_STATUS: [],

                likeProduct: '-1',
                likeDealers: '-1',
                likeShop: '-1',
                likeChannel: '-1',
            }
            let conditions = {};
            conditions = payload.conditions;
            if (conditions && conditions.PRODUCT) {
                for (let elem of conditions.PRODUCT.values()) {
                    data.PRODUCT.push(elem.value)
                    if (elem.notContain) {
                        data.likeProduct = 'true'
                    }
                }
            }

            if (conditions && conditions.PARTICIPATING_DEALERS) {
                for (let elem of conditions.PARTICIPATING_DEALERS.values()) {
                    data.PARTICIPATING_DEALERS.push(elem.value)
                    if (elem.notContain) {
                        data.likeDealers = 'true'
                    }
                }
            }
            if (conditions && conditions.PARTICIPATING_SHOP) {
                for (let elem of conditions.PARTICIPATING_SHOP.values()) {
                    data.PARTICIPATING_SHOP.push(elem.value)
                    if (elem.notContain) {
                        data.likeDealers = 'true'
                    }
                }
            }
            if (conditions && conditions.PARTICIPATING_SALES_AREA) {
                for (let elem of conditions.PARTICIPATING_SALES_AREA.values()) {
                    data.PARTICIPATING_SALES_AREA.push(elem.value)

                }
            }
            if (conditions && conditions.MARKETING_CHANNEL) {
                for (let elem of conditions.MARKETING_CHANNEL.values()) {
                    data.MARKETING_CHANNEL.push(elem.value)
                    if (elem.notContain) {
                        data.likeChannel = 'true'
                    }
                }
            }
            if (conditions && conditions.CODE_STATUS) {
                for (let elem of conditions.CODE_STATUS.values()) {
                    data.CODE_STATUS.push(elem.value)
                }
            }




            return {
                ...state, byId: payload, byIdLoaded: true,
                selectProductValues: data.PRODUCT || [],
                likeProduct: data.likeProduct,
                selectDealersValues: data.PARTICIPATING_DEALERS || [],
                likeDealers: data.likeDealers,
                selectShopValues: data.PARTICIPATING_SHOP || [],
                likeShop: data.likeShop,
                selectSalesAreaValues: data.PARTICIPATING_SALES_AREA || [],
                selectChannelValues: data.MARKETING_CHANNEL || [],
                likeChannel: data.likeChannel,
                selectCodeStatusValues: data.CODE_STATUS || [],
            };
        },

        [loadById.FAIL]: (state, { payload }) => ({ ...state, byIdLoaded: false, error: payload }),


        [editSecurity.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [editSecurity.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, }),
        [editSecurity.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),


        [deleteSecurity.REQUEST]: (state, { payload }) => ({ ...state, }),
        [deleteSecurity.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [deleteSecurity.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [securityPause.REQUEST]: (state, { payload }) => ({ ...state, }),
        [securityPause.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [securityPause.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [securityResume.REQUEST]: (state, { payload }) => ({ ...state, }),
        [securityResume.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [securityResume.FAIL]: (state, { payload }) => ({ ...state, perror: payload }),

        [publishSecurity.REQUEST]: (state, { payload }) => ({ ...state, }),
        [publishSecurity.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [publishSecurity.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [loadPromotionHistoriesById.REQUEST]: (state, { payload }) => ({ ...state, loadPromotionHistoriesByIdLoading: true }),
        [loadPromotionHistoriesById.SUCCESS]: (state, { payload }) => ({ ...state, loadPromotionHistoriesByIdLoading: false, loadPromotionHistoriesByIdLoaded: true, promotionHistories: payload }),
        [loadPromotionHistoriesById.FAIL]: (state, { payload }) => ({ ...state, loadPromotionHistoriesByIdLoading: false, loadPromotionHistoriesByIdLoaded: false, error: payload }),


        // 环节管理新版
        [addLinkPlan.REQUEST]: (state, { payload }) => ({ ...state, }),
        [addLinkPlan.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [addLinkPlan.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        //获取环境方案
        [linkPlanSelect.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [linkPlanSelect.SUCCESS]: (state, { payload }) => {
            let linkPlanTypeValueList = payload.filter(key => { return (key.name || '').indexOf(state.linkPlanfilterValue) >= 0 || (key.id.toString()).indexOf(state.linkPlanfilterValue) >= 0 });
            return { ...state, linkPlanSelection: linkPlanTypeValueList, linkPlanAllSelection: payload, loading: false }

        },

        [linkPlanTypeValue.REQUEST]: (state, { payload }) => {
            let linkPlanTypeValueList = state.linkPlanAllSelection.filter(key => { return (key.name || '').indexOf(payload) >= 0 || (key.id.toString()).indexOf(payload) >= 0 });
            return { ...state, linkPlanfilterValue: payload, linkPlanSelection: linkPlanTypeValueList }
        },


        [linkPlanSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),


        [getPlanDetail.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [getPlanDetail.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, }),
        [getPlanDetail.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),


        //排序环节方案
        [sortLinkPlan.REQUEST]: (state, { payload }) => ({ ...state }),
        [sortLinkPlan.SUCCESS]: (state, { payload }) => ({ ...state }),
        [sortLinkPlan.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [copyPlan.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [copyPlan.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
        [copyPlan.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
        //RULE
        [linkPlanCheckRule.REQUEST]: (state, { payload }) => ({ ...state, }),
        [linkPlanCheckRule.SUCCESS]: (state, { payload }) => ({ ...state, linkPlanRule: payload }),
        [linkPlanCheckRule.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [unmount.REQUEST]: (state, { payload }) => ({
            linkPlanSelection: [],
            linkPlanfilterValue: '',

        }),





        [loadPriorities.REQUEST]: (state, { payload }) => ({ ...state, loadPrioritiesLoading: true }),
        [loadPriorities.SUCCESS]: (state, { payload }) => ({ ...state, loadPrioritiesLoading: false, loadPrioritiesLoaded: true, priorities: payload }),
        [loadPriorities.FAIL]: (state, { payload }) => ({ ...state, loadPrioritiesLoading: false, loadPrioritiesLoaded: false, error: payload }),
        [updatePriorities.REQUEST]: (state, { payload }) => ({ ...state, updatePrioritiesLoading: true, loadPrioritiesParams: payload.params }),
        [updatePriorities.SUCCESS]: (state, { payload }) => ({ ...state, updatePrioritiesLoading: false, updatePrioritiesLoaded: true }),
        [updatePriorities.FAIL]: (state, { payload }) => ({ ...state, updatePrioritiesLoading: false, updatePrioritiesLoaded: false, error: payload }),


    },
    initialPoolState
)
export default reducer;