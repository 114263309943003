import { createAction, handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'pinCodeRecord';

export const changeDictPage = createAction(`${moduleName}/changeDictPage`);

export const getPinCodeRecord = fasterActions(moduleName, 'getPinCodeRecord');


const initialState = {
    pageData:{},
    pageParams:{},
    loading:false,
}

const reducer = handleActions(
  {
    [changeDictPage]: (state, { payload }) => {
        return { ...state, pageParams: { ...payload} }
    },

    [getPinCodeRecord.REQUEST]: (state, { payload }) => ({ ...state, payload,loading:true}),
    [getPinCodeRecord.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload,loading:false}),
    [getPinCodeRecord.FAIL]: (state, { payload }) => ({ ...state,loading:false}),

  },
  initialState
)

export default reducer;