import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import { ajaxBaseConfig, actionApi } from '../../utils';
import * as actions from './tagbatch';

// Epics
// 获取批次下载地址
export const tagbatchEpic = actions$ => actions$.pipe(
  ofType(actions.tagbatch.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { key, extractionCode } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.tool.tagbatch(key)}?key=${key}&extractionCode=${extractionCode}`,
    }).pipe(
      map(res => actions.tagbatch.SUCCESS(res.response)),
      catchError(error => of(actions.tagbatch.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )

  }
  )
);

// 获取批次下载日志
export const downloadLogEpic = actions$ => actions$.pipe(
  ofType(actions.downloadLog.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.tool.downloadLog(action.payload)}`
    }).pipe(
      map(res => actions.downloadLog.SUCCESS(res.response)),
      catchError(error => of(actions.downloadLog.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


// 获取批量下载日志
export const packageDownloadLogEpic = actions$ => actions$.pipe(
  ofType(actions.packageDownloadLog.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.tool.packageDownloadLog(action.payload)}`
    }).pipe(
      map(res => actions.packageDownloadLog.SUCCESS(res.response)),
      catchError(error => of(actions.packageDownloadLog.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

// 获取批量下载地址
export const downloadPackageEpic = actions$ => actions$.pipe(
  ofType(actions.downloadPackage.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'post',
      url: `${urls.code.tool.downloadPackage(action.payload)}`,
      body: action.payload,
    }).pipe(
      map(res => actions.downloadPackage.SUCCESS(res.response)),
      catchError(error => of(actions.downloadPackage.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);