import { of } from "rxjs"
import { ofType } from "redux-observable"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ajax } from "rxjs/ajax"
import urls from "../../../api/urls"
import * as uiActions from '../ui';
import queryString from 'query-string';
import { trims } from 'ucode-utils'
import * as actions from "./messageTemplate"
import { ajaxBaseConfig, apiBaseConfig, actionApi } from "../../utils";

// 以下这个作为模板进行参考
// export const searchEpic = actions$ => actions$.pipe(
//   ofType(actions.search.REQUEST.toString()),
//   mergeMap(action => {
//     actionApi();
//     return ajax({
//       ...apiBaseConfig,
//       url: `${urls.pointMall.order.order}/status-count`,
//     }).pipe(
//       map(res => actions.search.SUCCESS(res.response)),
//       catchError(error => of(actions.search.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )
//   }
//   )
// );

// 获取消息模板列表
export const messageTemplateListEpic = actions$ => actions$.pipe(
  ofType(actions.messageTemplateList.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { page = 0,size = 20,wechatTemplateId,msgNameOrId} = action.payload;
    return ajax({
      ...apiBaseConfig,
      url: `${urls.subscribeMsg.template}?wechatTemplateId=${wechatTemplateId}&msgNameOrId=${msgNameOrId}&page=${page}&size=${size}`,
    }).pipe(
      map(res => actions.messageTemplateList.SUCCESS(res.response)),
      catchError(error => of(actions.messageTemplateList.FAIL(error.xhr), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 新增/修改消息模板列表
export const msgTemplateModifyEpic = actions$ => actions$.pipe(
  ofType(actions.msgTemplateModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.subscribeMsg.template}`,
      body: action.payload,
      method: id ? 'PUT' : 'POST'
    }).pipe(
      map(res => {
        callback && callback()
        return actions.msgTemplateModify.SUCCESS(res.response)
      }),
      catchError(error => of(actions.msgTemplateModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 查看消息模板
export const msgTemplateByIdEpic = actions$ => actions$.pipe(
  ofType(actions.msgTemplateById.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id, callback } = action.payload;
    return ajax({
      ...apiBaseConfig,
      url: `${urls.subscribeMsg.templateInfoById}?id=${id}`,
      // method: 'get'
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.msgTemplateById.SUCCESS(res.response)
      }),
      catchError(error => of(actions.msgTemplateById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 删除消息模板
export const deleteMsgTemplateEpic = actions$ => actions$.pipe(
  ofType(actions.deleteMsgTemplate.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id, callback } = action.payload;
    return ajax({
      ...apiBaseConfig,
      url: `${urls.subscribeMsg.template}/${id}`,
      method: 'delete',
      body: {
        id
      }
    }).pipe(
      map(res => {
        callback && callback();
        return actions.deleteMsgTemplate.SUCCESS(res)
      }),
      catchError(error => { 
        console.log(error)
        return of(actions.deleteMsgTemplate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      })
    )
  })
)
// 根据appId查看消息模板配置列表
export const getMsgTemplateListByAppIdEpic = actions$ => actions$.pipe(
  ofType(actions.getMsgTemplateListByAppId.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { appId } = action.payload;
    return ajax({
      ...apiBaseConfig,
      url: `${urls.subscribeMsg.templateListByAppId}?appId=${appId}`,
    }).pipe(
      map(res => actions.getMsgTemplateListByAppId.SUCCESS(res.response)),
      catchError(error => of(actions.getMsgTemplateListByAppId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 根据appId查询订阅按钮列表
export const getSubscribeBtnListByAppIdEpic = actions$ => actions$.pipe(
  ofType(actions.getSubscribeBtnListByAppId.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { appId } = action.payload;
    return ajax({
      ...apiBaseConfig,
      url: `${urls.subscribeMsg.config}/${appId}`,
      // url: `${urls.subscribeMsg.findByMiniAppSubscribeId}?miniAppSubscribeId=1`,
    }).pipe(
      map(res => actions.getSubscribeBtnListByAppId.SUCCESS(res.response)),
      catchError(error => of(actions.getSubscribeBtnListByAppId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 根据appId查看消息列表不分页
export const getMsgListNoPageableEpic = actions$ => actions$.pipe(
  ofType(actions.getMsgListNoPageable.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { appId } = action.payload;
    return ajax({
      ...apiBaseConfig,
      url: `${urls.subscribeMsg.templateListNoPageable}?appId=${appId}`,
    }).pipe(
      map(res => { 
        return actions.getMsgListNoPageable.SUCCESS(res.response)
      }),
      catchError(error => of(actions.getMsgListNoPageable.FAIL(error.xhr), uiActions.notification(error.xhr)))
    )
  }
  )
);
// 根据消息模板配置查询对应微信模板详情信息
export const getWechatTemplateListEpic = actions$ => actions$.pipe(
  ofType(actions.getWechatTemplateList.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const {appId, id } = action.payload;
    return ajax({
      ...apiBaseConfig,
      url: `${urls.subscribeMsg.getTemplateContent}?appId=${appId}&wechatTemplateId=${id}`,
    }).pipe(
      map(res => { 
        return actions.getWechatTemplateList.SUCCESS(res.response)
      }),
      catchError(error => of(actions.getWechatTemplateList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 查看订阅数
export const getSubscribeCountByIdEpic = actions$ => actions$.pipe(
  ofType(actions.getSubscribeCountById.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id,callback } = action.payload;
    return ajax({
      ...apiBaseConfig,
      url: `${urls.subscribeMsg.subscribeCountById}?id=${id}`,
    }).pipe(
      map(res => { 
        callback && callback(res.response)
        return actions.getSubscribeCountById.SUCCESS(res.response)
      }),
      catchError(error => of(actions.getSubscribeCountById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);