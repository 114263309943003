import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'codeDetail';

/**
 * TODO: 
 */

export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const codeDetailSum = fasterActions(moduleName, 'codeDetailSum');
export const orderType = fasterActions(moduleName, 'orderType');
export const codeOrderNo = fasterActions(moduleName, 'codeOrderNo');
 

const initialState = {
  pageData:[],
  pageParams:{
    page:0,
    size:20,
    startTime:'',
    endTime:'',
    
  },
  downloadData:[],
  loading:false,
  codeOrderList:[]
}

const reducer = handleActions(
  { 
    [changeDictPaging]: (state, { payload }) => {
        return { ...state, pageParams: { ...payload} }
    },

    [orderType.REQUEST]: (state, { payload }) => ({ ...state, payload, }),
    [orderType.SUCCESS]: (state, { payload }) => {
        let orderType= [];
        for (let elem of payload.content) {
            const obj = { ...elem, value: elem.type, text: elem.type_name }
            orderType.push(obj);
        }
        return ({ ...state, orderType})
    },
    [orderType.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [codeDetailSum.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [codeDetailSum.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [codeDetailSum.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [codeOrderNo.REQUEST]: (state, { payload }) => ({ ...state, codeOrderList: [], loading: true }),
    [codeOrderNo.SUCCESS]: (state, { payload }) => ({ ...state, codeOrderList: payload, loading: false }),
    [codeOrderNo.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

  },
  initialState
);

export default reducer;
