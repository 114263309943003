/**品牌红包配置弹窗 */

import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'brandRedpackConfig';

export const brandRedpackConfigSelect = fasterActions(moduleName, 'brandRedpackConfigSelect');
export const brandRedpackConfigCreate = fasterActions(moduleName, 'brandRedpackConfigCreate');
export const brandRedpackConfigEdit = fasterActions(moduleName, 'brandRedpackConfigEdit');
export const brandRedpackConfigVisible = createAction(`${moduleName}/brandRedpackConfigVisible`);

const initialState = {
  // prizePointTypesLoaded: false,
  brandRedpackConfigContent: [],
  brandRedpackConfigDetailObj: {},
  brandRedpacketConfigId: '',
  isBrandRedpackConfigVisible: false,
  // selectorConversionRatio: 0
}

const reducer = handleActions(
  {
    // 查询
    [brandRedpackConfigSelect.REQUEST]: (state, { payload }) => ({ ...state }),
    [brandRedpackConfigSelect.SUCCESS]: (state, { payload }) => {
      // 下拉框内容
      let select = [];
      if (payload) {
        payload.content.forEach(val => {
          select.push({
            text: val.name, value: val.id
          })
        });
      } else {
        select = [];
      }
      return ({ ...state, brandRedpackConfigList: select, brandRedpackConfigContent: payload.content, brandRedpackConfigAll: payload });
    },

    [brandRedpackConfigSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 创建
    [brandRedpackConfigCreate.REQUEST]: (state, { payload }) => ({ ...state }),
    [brandRedpackConfigCreate.SUCCESS]: (state, { payload }) => ({ ...state, isBrandRedpackConfigVisible: false, brandRedpackConfigDetailObj: {}, resBrandRedpackConfigCreate: payload }),
    [brandRedpackConfigCreate.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 编辑
    [brandRedpackConfigEdit.REQUEST]: (state, { payload }) => ({ ...state }),
    [brandRedpackConfigEdit.SUCCESS]: (state, { payload }) => ({ ...state, isBrandRedpackConfigVisible: false, selectorConversionRatio: payload.conversionRatio, brandRedpackConfigDetailObj: {} }),
    [brandRedpackConfigEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 弹窗
    [brandRedpackConfigVisible]: (state, { payload }) => ({ ...state, ...payload }),
  },
  initialState
);

export default reducer;