import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';
import * as uiActions from '../ui';


const moduleName = 'miniProgramTesters';

/**
 * 小程序开发-基础配置: 
 */
export const testers = fasterActions(moduleName, 'testers');


const initialState = {

}

const reducer = handleActions(
    {
        [testers.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [testers.SUCCESS]: (state, { payload }) => {
            return ({ ...state, testersRes: payload });
        },
        [testers.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    },

    initialState
);

export default reducer;
