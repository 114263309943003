import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'prizeCardTypes';

/**
 * TODO: 卡片类型
 */
export const loadPrizeCardTypes = fasterActions(moduleName, 'loadPrizeCardTypes');
export const loadCardTypeAll = fasterActions(moduleName, 'loadCardTypeAll');
export const loadCardTypeDetail = fasterActions(moduleName, 'loadCardTypeDetail');
export const cardTypeCreate = fasterActions(moduleName, 'cardTypeCreate');
export const cardTypeEdit = fasterActions(moduleName, 'cardTypeEdit');
export const cardTypeDialogVisibale = createAction(`${moduleName}/cardTypeDialogVisibale`);

const initialState = {
  prizeCardTypesLoaded: false,
  cardTypeAllList: [],
  cardTypeDetailObj: {},
  cardTypeId: '',
  isCardTypeDialogVisibale: false,
}

const reducer = handleActions(
  {
    // [loadPrizeCardTypes.SUCCESS]: (state, { payload }) => ({
    //   ...state, prizeCardTypesLoaded: true, prizeCardTypes: payload
    // }),
    // [loadPrizeCardTypes.FAIL]: (state, { payload }) => ({
    //   ...state, prizeCardTypesLoaded: false, prizeCardTypes: []
    // }),

     /**
     * TODO: 卡片类型 
     */
    // 下拉列表
    [loadCardTypeAll.REQUEST]: (state, { payload }) => ({ ...state}),
    [loadCardTypeAll.SUCCESS]: (state, { payload }) => {
      const selector = [];
      for (let elem of payload.values()) {
        selector.push({ value: elem.id, text: elem.name })
      }
      return ({ ...state, cardTypeAllList: selector })
    },
    [loadCardTypeAll.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 详情
    [loadCardTypeDetail.REQUEST]: (state, { payload }) => ({ ...state}),
    [loadCardTypeDetail.SUCCESS]: (state, { payload }) => ({ ...state, cardTypeDetailObj: payload }),
    [loadCardTypeDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 创建
    [cardTypeCreate.REQUEST]: (state, { payload }) => ({ ...state}),
    [cardTypeCreate.SUCCESS]: (state, { payload }) => ({ ...state, isCardTypeDialogVisibale: false, cardTypeId: payload.id, cardTypeDetailObj: {} }),
    [cardTypeCreate.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 编辑
    [cardTypeEdit.REQUEST]: (state, { payload }) => ({ ...state}),
    [cardTypeEdit.SUCCESS]: (state, { payload }) => ({ ...state, isCardTypeDialogVisibale: false, cardTypeId: payload.id, cardTypeDetailObj: {} }),
    [cardTypeEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 弹窗
    [cardTypeDialogVisibale]: (state, { payload }) => ({ ...state, ...payload  }),
  },
  initialState
);

export default reducer;