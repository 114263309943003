import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'operationTools';
//留资信息
export const getLeaveInfoList = fasterActions(moduleName, 'getLeaveInfoList');

export const putLeaveInfo = fasterActions(moduleName, 'putLeaveInfo');
export const exportLeaveInfo = fasterActions(moduleName, 'exportLeaveInfo');


//字段
export const getLeaveFieldList = fasterActions(moduleName, 'getLeaveFieldList');
export const putLeaveField = fasterActions(moduleName, 'putLeaveField');
export const statusLeaveField = fasterActions(moduleName, 'statusLeaveField');
export const getLeaveFieldSelect = fasterActions(moduleName, 'getLeaveFieldSelect');

//类型
export const getLeaveTypeList = fasterActions(moduleName, 'getLeaveTypeList');
export const getLeaveTypeById = fasterActions(moduleName, 'getLeaveTypeById');
export const putLeaveType = fasterActions(moduleName, 'putLeaveType');
export const statusLeaveType = fasterActions(moduleName, 'statusLeaveType');
export const getLeaveTypeSelect = fasterActions(moduleName, 'getLeaveTypeSelect');


export const getLeaveTypecriteria = fasterActions(moduleName, 'getLeaveTypecriteria');



//活动码
export const getPromotionCodeList = fasterActions(moduleName, 'getPromotionCodeList');
export const createPromotionCode = fasterActions(moduleName, 'createPromotionCode');
export const putPromotionCode = fasterActions(moduleName, 'putPromotionCode');

export const exportPromotionCode = fasterActions(moduleName, 'exportPromotionCode');

export const downPromotionCode = fasterActions(moduleName, 'downPromotionCode');

//产品手册
export const getProductManualList = fasterActions(moduleName, 'getProductManualList');
export const createProductManual = fasterActions(moduleName, 'createProductManual');
export const putProductManual = fasterActions(moduleName, 'putProductManual');
export const delProductManual = fasterActions(moduleName, 'delProductManual');
export const disabledOrEnableProductManual = fasterActions(moduleName, 'disabledOrEnableProductManual');

const initialPrizeInfoState = {
    leaveInfoList: {},
    leaveInfoId: {},

    leaveFieldList:{},
    leaveFieldSelect: [],

    leaveTypeList: {},
    leaveTypecriteria: {},

    promotionCodeList: [],

    productManualList: [],
}


const reducer = handleActions(
    {
        [getLeaveInfoList.REQUEST]: (state, { payload }) => ({ ...state, leaveInfoLoading: true, }),
        [getLeaveInfoList.SUCCESS]: (state, { payload }) => ({ ...state, leaveInfoList: payload, leaveInfoLoading: false, }),
        [getLeaveInfoList.FAIL]: (state, { payload }) => ({ ...state, error: payload, leaveInfoLoading: false, }),

        [putLeaveInfo.REQUEST]: (state, { payload }) => ({ ...state }),
        [putLeaveInfo.SUCCESS]: (state, { payload }) => ({ ...state }),
        [putLeaveInfo.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [exportLeaveInfo.REQUEST]: (state, { payload }) => ({ ...state }),
        [exportLeaveInfo.SUCCESS]: (state, { payload }) => ({ ...state }),
        [exportLeaveInfo.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        //字段
        [getLeaveFieldList.REQUEST]: (state, { payload }) => ({ ...state, leaveFieldLoading: true, }),
        [getLeaveFieldList.SUCCESS]: (state, { payload }) => ({ ...state, leaveFieldList: payload, leaveFieldLoading: false }),
        [getLeaveFieldList.FAIL]: (state, { payload }) => ({ ...state, error: payload, leaveFieldLoading: false }),


        
        [putLeaveField.REQUEST]: (state, { payload }) => ({ ...state }),
        [putLeaveField.SUCCESS]: (state, { payload }) => ({ ...state }),
        [putLeaveField.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
     
        [statusLeaveField.REQUEST]: (state, { payload }) => ({ ...state }),
        [statusLeaveField.SUCCESS]: (state, { payload }) => ({ ...state }),
        [statusLeaveField.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        
        [getLeaveFieldSelect.REQUEST]: (state, { payload }) => ({ ...state }),
        [getLeaveFieldSelect.SUCCESS]: (state, { payload }) => ({ ...state, leaveFieldSelect: payload }),
        [getLeaveFieldSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        //类型
        [getLeaveTypeList.REQUEST]: (state, { payload }) => ({ ...state, leaveTypeLoading: true, }),
        [getLeaveTypeList.SUCCESS]: (state, { payload }) => ({ ...state, leaveTypeList: payload, leaveTypeLoading: false }),
        [getLeaveTypeList.FAIL]: (state, { payload }) => ({ ...state, error: payload, leaveTypeLoading: false }),

        [getLeaveTypeById.REQUEST]: (state, { payload }) => ({ ...state }),
        [getLeaveTypeById.SUCCESS]: (state, { payload }) => ({ ...state }),
        [getLeaveTypeById.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [putLeaveType.REQUEST]: (state, { payload }) => ({ ...state }),
        [putLeaveType.SUCCESS]: (state, { payload }) => ({ ...state }),
        [putLeaveType.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [statusLeaveType.REQUEST]: (state, { payload }) => ({ ...state }),
        [statusLeaveType.SUCCESS]: (state, { payload }) => ({ ...state }),
        [statusLeaveType.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [getLeaveTypeSelect.REQUEST]: (state, { payload }) => ({ ...state }),
        [getLeaveTypeSelect.SUCCESS]: (state, { payload }) => ({ ...state, leaveTypeselect: payload }),
        [getLeaveTypeSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [getLeaveTypecriteria.REQUEST]: (state, { payload }) => ({ ...state }),
        [getLeaveTypecriteria.SUCCESS]: (state, { payload }) => ({ ...state, leaveTypecriteria: payload }),
        [getLeaveTypecriteria.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



        //活动码

        [getPromotionCodeList.REQUEST]: (state, { payload }) => ({ ...state, promotionCodeLoading: true, }),
        [getPromotionCodeList.SUCCESS]: (state, { payload }) => ({ ...state, promotionCodeList: payload, promotionCodeLoading: false, }),
        [getPromotionCodeList.FAIL]: (state, { payload }) => ({ ...state, error: payload, promotionCodeLoading: false, }),

        [createPromotionCode.REQUEST]: (state, { payload }) => ({ ...state }),
        [createPromotionCode.SUCCESS]: (state, { payload }) => ({ ...state }),
        [createPromotionCode.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [putPromotionCode.REQUEST]: (state, { payload }) => ({ ...state }),
        [putPromotionCode.SUCCESS]: (state, { payload }) => ({ ...state }),
        [putPromotionCode.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [exportPromotionCode.REQUEST]: (state, { payload }) => ({ ...state }),
        [exportPromotionCode.SUCCESS]: (state, { payload }) => ({ ...state }),
        [exportPromotionCode.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [downPromotionCode.REQUEST]: (state, { payload }) => ({ ...state }),
        [downPromotionCode.SUCCESS]: (state, { payload }) => ({ ...state }),
        [downPromotionCode.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        //产品手册
        [getProductManualList.REQUEST]: (state, { payload }) => ({ ...state, productManualLoading: true, }),
        [getProductManualList.SUCCESS]: (state, { payload }) => ({ ...state, productManualList: payload, productManualLoading: false, }),
        [getProductManualList.FAIL]: (state, { payload }) => ({ ...state, error: payload, productManualLoading: false, }),

        [createProductManual.REQUEST]: (state, { payload }) => ({ ...state }),
        [createProductManual.SUCCESS]: (state, { payload }) => ({ ...state }),
        [createProductManual.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [putProductManual.REQUEST]: (state, { payload }) => ({ ...state }),
        [putProductManual.SUCCESS]: (state, { payload }) => ({ ...state }),
        [putProductManual.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [delProductManual.REQUEST]: (state, { payload }) => ({ ...state }),
        [delProductManual.SUCCESS]: (state, { payload }) => ({ ...state }),
        [delProductManual.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [disabledOrEnableProductManual.REQUEST]: (state, { payload }) => ({ ...state }),
        [disabledOrEnableProductManual.SUCCESS]: (state, { payload }) => ({ ...state }),
        [disabledOrEnableProductManual.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    },
    initialPrizeInfoState
)

export default reducer;