import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import { fasterActions } from '../../utils';
import { createAction, createActions, handleActions, combineActions } from 'redux-actions';

const moduleName = 'enterpriseinfo';

const initialState = {
  listLoaded: false,
  enterpriseinfoinfo:'',

  loadenterpriseinfolistLoaded:false,
  loadenterpriseinfolistLoading:false,
  enterpriseinfolist:[],
}

// Actions
export const enterpriseinfo = fasterActions(moduleName, 'enterpriseinfo');
export const loadenterpriseinfolist = fasterActions(moduleName, 'loadenterpriseinfolist');
export const editenterpriseinfo = fasterActions(moduleName, 'editenterpriseinfo');

export const editenterpriseinfoWarning = fasterActions(moduleName, 'editenterpriseinfoWarning');

const reducer = handleActions(
  {
    [enterpriseinfo.REQUEST]: (state, { payload }) => ({ ...state }),
    [enterpriseinfo.SUCCESS]: (state, { payload }) => ({ ...state, enterpriseinfoinfo: payload }),
    [enterpriseinfo.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    //list
    [loadenterpriseinfolist.REQUEST]: (state, { payload }) => ({ ...state, loadenterpriseinfolistLoading: true }),
    [loadenterpriseinfolist.SUCCESS]: (state, { payload }) => ({ ...state, loadenterpriseinfolistLoading: false, loadenterpriseinfolistLoaded: true, enterpriseinfolist: payload }),
    [loadenterpriseinfolist.FAIL]: (state, { payload }) => ({ ...state, loadenterpriseinfolistLoading: false, loadenterpriseinfolistLoaded: false, error: payload }),
    //edit
    [editenterpriseinfo.REQUEST]: (state, { payload }) => ({ ...state }),
    [editenterpriseinfo.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editenterpriseinfo.FAIL]: (state, { payload }) => ({ ...state}),
  
    [editenterpriseinfoWarning.REQUEST]: (state, { payload }) => ({ ...state }),
    [editenterpriseinfoWarning.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editenterpriseinfoWarning.FAIL]: (state, { payload }) => ({ ...state }),

  },
  initialState
);

export default reducer;
