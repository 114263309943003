import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import * as actions from './brand';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const loadBrandSelectionsEpic = actions$ => actions$.pipe(
  ofType(actions.loadBrandSelections.REQUEST.toString()),
  mergeMap(action => ajax({
    ...ajaxBaseConfig,
    url: action.payload && action.payload.status ? `${urls.data.brand.brands}/selections?status=${ action.payload.status }` : `${urls.data.brand.brands}/selections`,
  }).pipe(
    map(res => actions.loadBrandSelections.SUCCESS(Object.assign(res.response))),
    catchError(error => of(actions.loadBrandSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
    )
  )
  )
);
export const loadBrandPagerListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadBrandPagerList.REQUEST.toString()),
  mergeMap(action => { actionApi() 
    const { brandPageParam = {}, brandSearchKey = "" } = state$.value.brand;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.brand.brands}?page=${brandPageParam.page}&size=${brandPageParam.size}&sort=${brandPageParam.sort}&word=${brandSearchKey}`,
    }).pipe(
      map(res => actions.loadBrandPagerList.SUCCESS(Object.assign(res.response))),
      catchError(error => of(actions.loadBrandPagerList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
// 品牌详情
export const loadBrandByIdEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadBrandById.REQUEST.toString()),
  mergeMap(action => { actionApi() 
    const {id = '', callback} = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.brand.brands}/${id}`,
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.loadBrandById.SUCCESS(Object.assign(res.response));
      }),
      catchError(error => of(actions.loadBrandById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

// 创建品牌
export const createBrandEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.createBrand.REQUEST.toString()),
  mergeMap(action => { actionApi() 
    const { data = {}, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${ urls.data.brand.brands }`,
      method: 'POST',
      body: data
    }).pipe(
      concatMap(res => {
        callback && callback();
        return [actions.createBrand.SUCCESS(Object.assign(res.response)), actions.loadBrandPagerList.REQUEST({ brandPageParam: {...state$.value.brand.brandPageParam, page: 0}}), actions.loadBrandSelections.REQUEST()]
      }),
      catchError(error => of(actions.createBrand.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
// 编辑品牌
export const editBrandEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.editBrand.REQUEST.toString()),
  mergeMap(action => { actionApi() 
    const { data = {}, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${ urls.data.brand.brands }`,
      method: 'PUT',
      body: data
    }).pipe(
      concatMap(res => {
        callback && callback();
        return [actions.editBrand.SUCCESS(Object.assign(res.response)), actions.loadBrandPagerList.REQUEST(), actions.loadBrandSelections.REQUEST()]
      }),
      catchError(error => of(actions.editBrand.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
// 禁用，启用品牌
export const disabledOrEnabledBrandEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.disabledOrEnabledBrand.REQUEST.toString()),
  mergeMap(action => { actionApi() 
    const { id = '', status = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${ urls.data.brand.brands }/${id}/status?status=${status}`,
      method: 'PATCH',
    }).pipe(
      concatMap(res => [actions.disabledOrEnabledBrand.SUCCESS(res.response), actions.loadBrandPagerList.REQUEST()]),
      catchError(error => of(actions.disabledOrEnabledBrand.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
