import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'scancodeDetail';

/**
 * TODO: 
 */

export const banquetList = fasterActions(moduleName, 'banquetList');
export const consumerList = fasterActions(moduleName, 'consumerList');
export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const officePostSelection = fasterActions(moduleName, 'officePostSelection');
export const cityManagerPostSelection = fasterActions(moduleName, 'cityManagerPostSelection');
export const tagTypeSelection = fasterActions(moduleName, 'tagTypeSelection');

const initialState = {
    pageData:[],
    pageParams:{
      page:0,
      size:20,
      startTime:'',
      endTime:''
    },
    officePostData:[],
    cityManagerPostData:[],
    tagTypeData:[],
    loading:false,
}

const reducer = handleActions(
  { 
    [changeDictPaging]: (state, { payload }) => {
        return { ...state, pageParams: { ...payload} }
    },

    [banquetList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [banquetList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [banquetList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [consumerList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [consumerList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [consumerList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [officePostSelection.REQUEST]: (state, { payload }) => ({ ...state, officePostData: []}),
    [officePostSelection.SUCCESS]: (state, { payload }) => ({ ...state, officePostData: payload }),
    [officePostSelection.FAIL]: (state, { payload }) => ({ ...state}),

    [cityManagerPostSelection.REQUEST]: (state, { payload }) => ({ ...state, cityManagerPostData: []}),
    [cityManagerPostSelection.SUCCESS]: (state, { payload }) => ({ ...state, cityManagerPostData: payload }),
    [cityManagerPostSelection.FAIL]: (state, { payload }) => ({ ...state}),

    [tagTypeSelection.REQUEST]: (state, { payload }) => ({ ...state, tagTypeData: []}),
    [tagTypeSelection.SUCCESS]: (state, { payload }) => ({ ...state, tagTypeData: payload }),
    [tagTypeSelection.FAIL]: (state, { payload }) => ({ ...state}),
  },
  initialState
);

export default reducer;
