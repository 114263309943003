import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'batchScrap';

export const loadScraps = fasterActions(moduleName, 'loadScraps');
export const viewScraps = fasterActions(moduleName, 'viewScraps');
export const batchScraps = fasterActions(moduleName, 'batchScraps');


const INIT_VIEW_SCRAPS = `${moduleName}/INIT_VIEW_SCRAPS`;
export function initViewScraps() {
  return { type: INIT_VIEW_SCRAPS };
}





const initialState = {

}

const reducer = handleActions(
  {
    [loadScraps.REQUEST]: (state, { payload }) => ({ ...state, loadScrapsLoading: true, loadScrapsParams: payload }),
    [loadScraps.SUCCESS]: (state, { payload }) => ({ ...state, loadScrapsLoading: false, loadScrapsLoaded: true, scraps: payload }),
    [loadScraps.FAIL]: (state, { payload }) => ({ ...state, loadScrapsLoading: false, loadScrapsLoaded: false, error: payload }),

    [viewScraps.REQUEST]: (state, { payload }) => ({ ...state, viewScrapsLoading: true }),
    [viewScraps.SUCCESS]: (state, { payload }) => ({ ...state, viewScrapsLoading: false, viewScrapsLoaded: true, scrapsList: payload }),
    [viewScraps.FAIL]: (state, { payload }) => ({ ...state, viewScrapsLoading: false, viewScrapsLoaded: false, error: payload, scrapsList: {} }),

    [batchScraps.REQUEST]: (state, { payload }) => ({ ...state, batchScrapsLoading: true }),
    [batchScraps.SUCCESS]: (state, { payload }) => ({ ...state, batchScrapsLoading: false, batchScrapsLoaded: true, batchScraps: payload }),
    [batchScraps.FAIL]: (state, { payload }) => ({ ...state, batchScrapsLoading: false, batchScrapsLoaded: false, error: payload }),

    [INIT_VIEW_SCRAPS]: (state, { payload }) => ({ ...state, scrapsList: [] }),
  },
  initialState
)

export default reducer;